import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TravelAuthFooterButtons from "./TravelAuthFooterButtons";
import { PrimaryButton, LightButton } from "best-common-react";
import { FormattedMessage } from "react-intl";

class TravelAuthFooter extends React.Component {
  render() {
    const { travelAuthFormStore, prospectProfileStore, authStore } = this.props.rootStore;

    const SubmitButton = (
      <PrimaryButton
        key="submit"
        onClick={() => travelAuthFormStore.submit(prospectProfileStore.prospect.amtProspectId)}
      >
        {authStore.isBOC ? (
          <FormattedMessage id="TravelAuthForm.bocSubmit" defaultMessage="Submit" />
        ) : (
          <FormattedMessage id="TravelAuthForm.submit" defaultMessage="Submit to BOC" />
        )}
      </PrimaryButton>
    );

    const ApproveButton = (
      <PrimaryButton
        key="approve"
        onClick={() => travelAuthFormStore.approve(prospectProfileStore.prospect.amtProspectId)}
      >
        <FormattedMessage id="TravelAuthForm.approve" defaultMessage="Approve" />
      </PrimaryButton>
    );

    const RejectButton = (
      <LightButton key="reject" onClick={() => travelAuthFormStore.reject(prospectProfileStore.prospect.amtProspectId)}>
        <FormattedMessage id="TravelAuthForm.reject" defaultMessage="Reject" />
      </LightButton>
    );

    const SaveButton = (
      <LightButton
        key="save"
        disabled={!travelAuthFormStore.travelExpensesValid || !travelAuthFormStore.saveEnabled}
        onClick={() => travelAuthFormStore.save(prospectProfileStore.prospect.amtProspectId)}
      >
        <FormattedMessage id="TravelAuthForm.save" defaultMessage="Save" />
      </LightButton>
    );

    const DeleteButton = (
      <LightButton key="delete" onClick={() => travelAuthFormStore.setShowDeleteModal(true)}>
        <FormattedMessage id="TravelAuthForm.delete" defaultMessage="Delete" />
      </LightButton>
    );

    const CloseButton = (
      <LightButton key="close" onClick={travelAuthFormStore.close}>
        <FormattedMessage id="TravelAuthForm.close" defaultMessage="Close" />
      </LightButton>
    );

    const CancelButton = (
      <LightButton key="cancel" onClick={() => travelAuthFormStore.setShowCancelModal(true)}>
        <FormattedMessage id="TravelAuthForm.cancel" defaultMessage="Cancel" />
      </LightButton>
    );

    const EditButton = (
      <LightButton key="edit" onClick={travelAuthFormStore.edit}>
        <FormattedMessage id="TravelAuthForm.edit" defaultMessage="Edit" />
      </LightButton>
    );

    const EditToResubmitButton = (
      <LightButton key="editToResubmit" onClick={() => travelAuthFormStore.setShowResubmitModal(true)}>
        <FormattedMessage id="TravelAuthForm.editToResubmit" defaultMessage="Edit to Resubmit" />
      </LightButton>
    );

    const buttons = {
      submit: SubmitButton,
      approve: ApproveButton,
      reject: RejectButton,
      save: SaveButton,
      delete: DeleteButton,
      close: CloseButton,
      cancel: CancelButton,
      edit: EditButton,
      editToResubmit: EditToResubmitButton
    };

    return <TravelAuthFooterButtons buttons={buttons} />;
  }
}

TravelAuthFooter.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TravelAuthFooter));
