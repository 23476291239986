import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import AuthStore from "./AuthStore";
import AuthApi from "../httpClients/AuthApi";
import AmtApi from "../httpClients/AmtApi";
import AmtRouterStore from "./AmtRouterStore";
import CommonStore from "./CommonStore";
import HttpInterceptor from "../httpClients/HttpInterceptor";
import AlertStore from "./AlertStore";
import ErrorWarningStore from "./ErrorWarningStore";
import TranslationsStore from "./translations/TranslationsStore";
import ClubsStore from "./clubs/ClubsStore";
import ProspectsStore from "./prospects/ProspectsStore";
import ProspectProfileStore from "./profile/ProspectProfileStore";
import CheckInHistoryStore from "./profile/CheckInHistoryStore";
import LookupStore from "./lookups/LookupStore";
import NewProspectStore from "./newProspect/NewProspectStore";
import TravelAuthFormWidgetStore from "./travelForms/TravelAuthFormWidgetStore";
import TravelAuthFormStore from "./profile/TravelAuthFormStore";
import CheckInModalStore from "./profile/CheckInModalStore";
import CheckOutModalStore from "./profile/CheckOutModalStore";
import SystemEventStore from "./SystemEventStore";
import MonthsFromSigningStore from "./monthsFromSigning/MonthsFromSigningStore";
import HelpStore from "./help/HelpStore";

class RootStore {
  constructor() {
    this.routerStore = new AmtRouterStore();
    this.history = syncHistoryWithStore(createBrowserHistory(), this.routerStore);

    this.registerApis();
    this.registerStores();
  }

  registerApis() {
    this.amtApi = new AmtApi();
    this.authApi = new AuthApi(this.routerStore);
  }

  registerStores() {
    this.alertStore = new AlertStore();
    this.errorWarningStore = new ErrorWarningStore(this.routerStore);
    this.authStore = new AuthStore(this.routerStore, this.authApi, this.amtApi);
    this.lookupStore = new LookupStore(this.authStore, this.amtApi);
    this.systemEventStore = new SystemEventStore(this.authStore, this.amtApi);
    this.commonStore = new CommonStore(
      this.authStore,
      this.amtApi,
      this.lookupStore,
      this.alertStore,
      this.errorWarningStore,
      this.routerStore,
      this.systemEventStore
    );
    this.httpInterceptor = new HttpInterceptor(
      this.authStore,
      this.alertStore,
      this.routerStore,
      this.errorWarningStore
    );
    this.translationsStore = new TranslationsStore();
    this.clubsStore = new ClubsStore(this.commonStore);
    this.monthsFromSigningStore = new MonthsFromSigningStore(this.commonStore);
    this.prospectsStore = new ProspectsStore(this.commonStore, this.monthsFromSigningStore);
    this.prospectProfileStore = new ProspectProfileStore(this.commonStore);
    this.checkInHistoryStore = new CheckInHistoryStore(this.commonStore, this.prospectProfileStore);
    this.newProspectStore = new NewProspectStore(this.commonStore, this.prospectProfileStore);
    this.travelAuthFormStore = new TravelAuthFormStore(
      this.commonStore,
      this.prospectProfileStore,
      this.checkInHistoryStore
    );
    this.travelAuthFormWidgetStore = new TravelAuthFormWidgetStore(this.commonStore);
    this.checkInModalStore = new CheckInModalStore(
      this.commonStore,
      this.prospectProfileStore,
      this.checkInHistoryStore
    );
    this.checkOutModalStore = new CheckOutModalStore(
      this.commonStore,
      this.prospectProfileStore,
      this.checkInHistoryStore,
      this.prospectsStore
    );
    this.helpStore = new HelpStore(this.commonStore);
  }
}

export default RootStore;
