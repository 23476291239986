import React from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PropTypes from "prop-types";
import { Icon } from "best-common-react";
import styled from "styled-components";

const isHidden = comments => !comments || comments === null || comments.trim() === "";
const popover = (title, comments) => (
  <Popover id={!title ? "comments-popover" : "popover-basic"}>
    {title && <Popover.Title as="h3">{title}</Popover.Title>}
    <Popover.Content>{comments}</Popover.Content>
  </Popover>
);

const CommentsIcon = styled(Icon).attrs(() => ({
  className: "fa-md icon-cog",
  iconName: "fa-comment"
}))`
  color: ${props => props.theme.primary};
  margin-left: 5px;
`;

const CommentsBubble = ({ title, comments, disabled = false, placement }) => {
  return (
    <div>
      {!isHidden(comments) && !disabled && (
        <OverlayTrigger rootClose trigger="click" placement={placement} overlay={popover(title, comments)}>
          <CommentsIcon />
        </OverlayTrigger>
      )}
    </div>
  );
};

CommentsBubble.propTypes = {
  title: PropTypes.string,
  comments: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  placement: PropTypes.string
};

export default CommentsBubble;
