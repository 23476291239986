import React from "react";
import { AccordionTableWrapper } from "best-common-react";
import { inject, observer } from "mobx-react";
import ClubsTableMobile from "./ClubsTableMobile";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import ClubCheckInsLinkFormatter from "../utilities/ClubCheckInsLinkFormatter";
import ClubProspectsLinkFormatter from "../utilities/ClubProspectsLinkFormatter";
import ZeroNumberCellFormatter from "../utilities/ZeroNumberCellFormatter";

const club = <FormattedMessage id="ClubsTable.club" defaultMessage="Club" />,
  checkedIn = <FormattedMessage id="ClubsTable.checkedIn" defaultMessage="Checked-In" />,
  approaching = <FormattedMessage id="ClubsTable.approaching" defaultMessage="Approaching" />,
  overdue = <FormattedMessage id="ClubsTable.overdue" defaultMessage="Overdue" />,
  checkInsThisPeriod = <FormattedMessage id="ClubsTable.checkInsThisPeriod" defaultMessage="Check Ins This Period" />,
  activeTravelAuths = (
    <FormattedMessage id="ClubsTable.activeTravelAuths" defaultMessage="Active Travel Authorizations" />
  ),
  travelAuthsThisPeriod = (
    <FormattedMessage id="ClubsTable.travelAuthsThisPeriod" defaultMessage="Travel Authorizations This Period" />
  );

class ClubsTable extends React.Component {
  constructor() {
    super();
    this.getColumns = this.getColumns.bind(this);
    this.getTabletColumns = this.getTabletColumns.bind(this);
  }
  getColumns() {
    return [
      {
        key: "orgName",
        name: club,
        width: 200,
        resizable: false,
        sortable: true,
        formatter: ClubProspectsLinkFormatter,
        getRowMetaData: row => row
      },
      { key: "checkedInProspects", name: checkedIn, width: 110, sortable: true, formatter: ZeroNumberCellFormatter },
      {
        key: "approachingProspects",
        name: approaching,
        width: 150,
        sortable: true,
        formatter: ZeroNumberCellFormatter
      },
      { key: "overdueProspects", name: overdue, width: 120, sortable: true, formatter: ZeroNumberCellFormatter },
      {
        key: "checkIns",
        name: checkInsThisPeriod,
        width: 200,
        sortable: true,
        formatter: ClubCheckInsLinkFormatter,
        getRowMetaData: row => row
      },
      {
        key: "activeTravelAuths",
        name: activeTravelAuths,
        width: 240,
        sortable: true,
        formatter: ZeroNumberCellFormatter
      },
      {
        key: "currentTravelAuths",
        name: travelAuthsThisPeriod,
        sortable: true,
        formatter: ZeroNumberCellFormatter
      }
    ];
  }

  getTabletColumns() {
    return [
      {
        primaryKey: "orgName",
        name: club,
        width: 200,
        resizable: false,
        sortable: true,
        formatter: ClubProspectsLinkFormatter,
        getRowMetaData: row => row
      },
      {
        primaryKey: "checkedInProspects",
        name: checkedIn,
        width: 110,
        sortable: true,
        formatter: ZeroNumberCellFormatter
      },
      {
        primaryKey: "approachingProspects",
        name: approaching,
        width: 150,
        sortable: true,
        formatter: ZeroNumberCellFormatter
      },
      { primaryKey: "overdueProspects", name: overdue, width: 120, sortable: true, formatter: ZeroNumberCellFormatter },
      {
        primaryKey: "checkIns",
        name: checkInsThisPeriod,
        width: 200,
        sortable: true,
        formatter: ClubCheckInsLinkFormatter,
        getRowMetaData: row => row
      },
      {
        primaryKey: "activeTravelAuths",
        name: activeTravelAuths,
        width: 240,
        sortable: true,
        formatter: ZeroNumberCellFormatter
      },
      {
        primaryKey: "currentTravelAuths",
        name: travelAuthsThisPeriod,
        width: 240,
        sortable: true,
        formatter: ZeroNumberCellFormatter
      }
    ];
  }

  render() {
    const { clubsStore } = this.props.rootStore;

    return (
      <div className="row">
        <div className="col-12 full-page-table">
          <AccordionTableWrapper
            id={"clubs-table"}
            data={this.props.data}
            columns={this.getColumns()}
            tabletColumns={this.getTabletColumns()}
            sortFunc={clubsStore.setSortDirection}
            accordion={ClubsTableMobile}
            headerRowHeight={35}
            height={400}
            sortColumn={clubsStore.sortFilters["keys"][0]}
            sortDirection={clubsStore.sortFilters["direction"]}
            minColumnWidth={280}
          />
        </div>
      </div>
    );
  }
}

ClubsTable.propTypes = {
  rootStore: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired
};

export default inject("rootStore")(observer(ClubsTable));
