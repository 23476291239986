import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, SearchBox, Icon } from "best-common-react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

const ClearUserSearch = styled.span`
  position: absolute;
  right: 6px;
  top: 8px;
`;

const ClearableInput = styled.div.attrs(() => ({
  className: "btn-group"
}))`
  width: 100%;
`;

class TravelFormsSearch extends React.Component {
  render() {
    const { travelAuthFormWidgetStore } = this.props.rootStore;
    return (
      <div className={"form-group"}>
        <BestLabel>
          <FormattedMessage id="TravelFormsSearch.searchTravelForms" defaultMessage="Search" />
        </BestLabel>
        <ClearableInput>
          <SearchBox
            placeholder="type to filter forms"
            value={travelAuthFormWidgetStore.searchTerm}
            onChange={term => travelAuthFormWidgetStore.setSearchTerm(term.target.value)}
          />
          {travelAuthFormWidgetStore.searchTerm.length > 0 ? (
            <ClearUserSearch>
              <Icon iconName="fa-times-circle" onClick={travelAuthFormWidgetStore.clearSearchTerm} />
            </ClearUserSearch>
          ) : null}
        </ClearableInput>
      </div>
    );
  }
}

TravelFormsSearch.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TravelFormsSearch));
