import { useEffect, useState } from "react";
import ActuatorApi from "../../httpClients/ActuatorApi";

function OnlineChecker() {
  const [, /* state */ setState] = useState();

  useEffect(() => {
    const blockingActuatorCall = async () => {
      await ActuatorApi.checkHealth()
        .then(({ data }) => {
          if (!!data && data.status === "DOWN") {
            setState(() => {
              throw new Error("The AMT backend service is down.");
            });
          }
        })
        .catch(err => {
          setState(() => {
            throw new Error("The AMT backend service is down.");
          });
        });
    };
    blockingActuatorCall().then(r => {});
  }, []);

  return null;
}

export default OnlineChecker;
