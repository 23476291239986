import React from "react";
import { FormColumn2Wide, BestTitle } from "best-common-react";
import { FormattedMessage } from "react-intl";
import Document from "./Document";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import HelpDocumentsFileAttachments from "./HelpDocumentsFileAttachments";

const HelpDocuments = ({ rootStore: { helpStore } }) => {
  const title = (
    <BestTitle>
      <FormattedMessage id="HelpDocuments.title" defaultMessage="Help Documents" />
    </BestTitle>
  );

  const renderViewMode = () => {
    return (
      <FormColumn2Wide>
        {title}
        <div>
          {helpStore.documents &&
            helpStore.documents.map((doc, i) => {
              return <Document key={doc.title + i} downloadFn={getFile} file={doc} />;
            })}
        </div>
      </FormColumn2Wide>
    );
  };

  const renderEditMode = () => {
    return (
      <div className="col-12">
        {title}
        <HelpDocumentsFileAttachments data={helpStore.documents} downloadFn={getFile} />
      </div>
    );
  };

  const getFile = (event, fileName, fileIdentifier, contentType) => {
    event.preventDefault();
    helpStore.getHelpDocumentFile(fileName, fileIdentifier, contentType);
  };

  const { editMode } = helpStore;

  return editMode ? renderEditMode() : renderViewMode();
};

HelpDocuments.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(HelpDocuments));
