import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  CardBody,
  CardContainer,
  CardFooter,
  CardHeader,
  DesktopTabletView,
  LightButton,
  StickyFooterButtons
} from "best-common-react";
import { FormattedMessage, intlShape, defineMessages } from "react-intl";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import TravelFormsHeader from "../components/travelAuthFormsWidget/TravelFormsHeader";
import TravelFormsTable from "../components/travelAuthFormsWidget/TravelFormsTable";
import ErrorWarningModal from "../components/common/ErrorWarningModal";

class TravelAuthFormsWidgetContainer extends Component {
  render() {
    const { authStore, errorWarningStore, travelAuthFormWidgetStore } = this.props.rootStore;

    const messages = defineMessages({
      title: {
        id: "TavelFormsWidget.title",
        defaultMessage: "Travel Forms"
      }
    });

    return (
      <div>
        <Helmet title={this.context.intl.formatMessage(messages.title)} />
        <div className={"container-fluid"}>
          <CardContainer>
            <CardHeader>
              <FormattedMessage id="TravelFormsWidget.header" defaultMessage="Travel Authorization Forms" />
            </CardHeader>
            <CardBody>
              <ErrorWarningModal show={errorWarningStore.showModal} />
              <TravelFormsHeader />
              <TravelFormsTable />
            </CardBody>
            <CardFooter>
              <StickyFooterButtons>
                <DesktopTabletView>
                  {authStore.isBOC ? (
                    <React.Fragment>
                      <LightButton
                        disabled={!travelAuthFormWidgetStore.submittedFormSelected}
                        onClick={() =>
                          travelAuthFormWidgetStore.approveTravelForms(travelAuthFormWidgetStore.submittedTravelForms)
                        }
                      >
                        <FormattedMessage id="TravelFormsWidget.approve" defaultMessage="Approve" />
                      </LightButton>
                      <LightButton
                        disabled={!travelAuthFormWidgetStore.submittedFormSelected}
                        onClick={() =>
                          travelAuthFormWidgetStore.rejectTravelForms(travelAuthFormWidgetStore.submittedTravelForms)
                        }
                      >
                        <FormattedMessage id="TravelFormsWidget.reject" defaultMessage="Reject" />
                      </LightButton>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  {authStore.isClub ? (
                    <LightButton
                      disabled={!travelAuthFormWidgetStore.preliminaryFormSelected}
                      onClick={() =>
                        travelAuthFormWidgetStore.submitTravelForms(travelAuthFormWidgetStore.preliminaryTravelForms)
                      }
                    >
                      <FormattedMessage id="TravelFormsWidget.submitToBoc" defaultMessage="Submit to BOC" />
                    </LightButton>
                  ) : (
                    ""
                  )}
                </DesktopTabletView>
                <LightButton onClick={travelAuthFormWidgetStore.exportTravelFormsTable}>
                  <FormattedMessage id="TravelFormsWidget.export" defaultMessage="Export" />
                </LightButton>
              </StickyFooterButtons>
            </CardFooter>
          </CardContainer>
        </div>
      </div>
    );
  }
}

TravelAuthFormsWidgetContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

TravelAuthFormsWidgetContainer.contextTypes = {
  intl: intlShape
};

export default inject("rootStore")(observer(TravelAuthFormsWidgetContainer));
