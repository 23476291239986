import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import StatusFormatter from "./StatusFormatter";

const RowWrapper = styled.span`
  display: flex;
`;

const TopColumn = styled.span`
  flex: 33%;
`;

const Card = styled.span`
  font-weight: 400;
`;

const StatusWrapper = styled.span`
  margin-top: 10px;
  float: left;
`;

const PrimaryInfo = styled.div`
  color: black;
  font-size: 14px;
`;

const SecondaryInfo = styled.div`
  color: ${props => props.theme["dark-grey"]};
  font-size: 12px;
`;

const DaysUsed = styled(SecondaryInfo)`
  margin-top: 10px;
  float: right;
`;

class ProspectsMobileCard extends React.Component {
  render() {
    const { prospect, theme } = this.props;
    return (
      <Card>
        <RowWrapper>
          <TopColumn>
            <PrimaryInfo>{prospect.lastName}</PrimaryInfo>
            <SecondaryInfo>{prospect.extendedLastName}</SecondaryInfo>
          </TopColumn>
          <TopColumn>
            <PrimaryInfo>{prospect.firstName}</PrimaryInfo>
            <SecondaryInfo>{prospect.middleName}</SecondaryInfo>
          </TopColumn>
          <TopColumn>
            <PrimaryInfo>{prospect.position1}</PrimaryInfo>
          </TopColumn>
        </RowWrapper>
        <StatusWrapper>
          <StatusFormatter
            textColor={theme["dark-grey"]}
            textSize="12px"
            value={prospect.status ? prospect.status : ""}
          />
        </StatusWrapper>
        <DaysUsed>{prospect.daysUsedTotal}</DaysUsed>
      </Card>
    );
  }
}

ProspectsMobileCard.propTypes = {
  prospect: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withTheme(ProspectsMobileCard);
