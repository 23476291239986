import axios from "axios";
import { AlertConstants } from "../constants/AlertConstants";
import BulkRequestHelper from "../utilities/BulkRequestHelper";

/**
 * Error processing and message
 * processing from backend
 */

function getAlertText(error) {
  if (error.response) {
    switch (error.response.status) {
      case 403:
        return "You do not have the correct permission to do this action";
      case 404:
        return "Resource not found";
      case 415:
        if (error.response.request.responseURL.indexOf("checkin") > -1) {
          return "Only JPEG files are supported";
        }
        return "Unsupported File Upload";
      default:
        return "Ebis AMT error please contact support";
    }
  } else if (!!error.message) return error.message;
}

function toggleLoading(style) {
  let amtLoader = document.getElementById("amt-loader");
  if (amtLoader !== null) amtLoader.style.display = style;
}

class HttpInterceptor {
  constructor(authStore, alertStore, routerStore, errorWarningStore) {
    let pendingRequests = 0;
    axios.interceptors.request.use(
      config => {
        pendingRequests++;
        if (
          config.url.indexOf("actuator/info") === -1 &&
          config.url.indexOf("lookup/") === -1 &&
          !routerStore.isLoginTab
        ) {
          toggleLoading("block");
        }
        return authStore.getAccessToken().then(
          accessToken => {
            config.headers.Authorization = `Bearer ${accessToken}`;
            return Promise.resolve(config);
          },
          error => {
            console.error(error);
            config.headers.Authorization = `Bearer ${authStore.accessToken}`;
            return Promise.resolve(config);
          }
        );
      },
      function(error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function(response) {
        pendingRequests--;
        if (pendingRequests === 0) toggleLoading("none", routerStore.isLoginTab);
        if (response && response.data && response.data.alerts) {
          alertStore.createResponseAlerts(response.data.alerts);
        }
        return response;
      },
      function(error) {
        pendingRequests--;
        if (pendingRequests === 0 && !!document.getElementById("amt-loader"))
          document.getElementById("amt-loader").style.display = "none";
        if (error) {
          if (error.response && error.response.data && "invalid_token" === error.response.data.error) {
            authStore.refresh();
            return Promise.reject(error);
          } else if (error.response && error.response.data && "invalid_grant" === error.response.data.error) {
            authStore.logout();
            return Promise.reject(error);
          } else {
            if (error.response && error.response.data) {
              if (error.response.data.alerts) {
                alertStore.createResponseAlerts(error.response.data.alerts);
              } else if (error.response.data.errors && error.response.data.successes) {
                errorWarningStore.setBulkResponse(
                  error.response.data,
                  BulkRequestHelper.getRequestType(error.request.responseURL),
                  BulkRequestHelper.getStatus(error.request.responseURL)
                );
              } else if (error.response.data.warnings || error.response.data.errors) {
                errorWarningStore.setWarnings(error.response.data.warnings);
                errorWarningStore.setErrors(error.response.data.errors);
              }
              return Promise.reject(error);
            }

            alertStore.addAlert({
              type: AlertConstants.TYPES.DANGER,
              text: getAlertText(error)
            });

            return Promise.reject(error);
          }
        }
      }
    );
  }
}

export default HttpInterceptor;
