import React from "react";
import { AccordionTableWrapper, MobileView, DesktopTabletView } from "best-common-react";
import { inject, observer } from "mobx-react";
import TravelAuthHistoryTableMobile from "./TravelAuthHistoryTableMobile";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DateFormatter from "../../utilities/DateFormatter";
import BooleanCheckFormatter from "../../utilities/BooleanCheckFormatter";
import TravelFormsLinkFormatter from "../../travelAuthFormsWidget/TravelFormsLinkFormatter";
import styled from "styled-components";

const club = <FormattedMessage id="TravelAuthHistoryTable.club" defaultMessage="Club" />,
  status = <FormattedMessage id="TravelAuthHistoryTable.status" defaultMessage="Status" />,
  submitted = <FormattedMessage id="TravelAuthHistoryTable.submitted" defaultMessage="Submitted" />,
  approved = <FormattedMessage id="TravelAuthHistoryTable.approved" defaultMessage="Approved" />,
  arrival = <FormattedMessage id="TravelAuthHistoryTable.firstTravelDate" defaultMessage="First Travel Date" />,
  departure = (
    <FormattedMessage id="TravelAuthHistoryTable.dateTravelCompleted" defaultMessage="Date Travel Completed" />
  ),
  didNotTravel = <FormattedMessage id="TravelAuthHistoryTable.didNotTravel" defaultMessage="Did Not Travel" />;

const TravelAuthWrapper = styled.div.attrs({
  className: "row"
})`
  & #accordion-table-wrapper {
    .react-grid-Canvas {
      .react-grid-Cell {
        background-color: #f7f7f7;
      }
    }
  }
`;

const TravelAuthHistoryTable = ({ rootStore: { travelAuthFormStore } }) => {
  const getColumns = () => {
    return [
      { key: "orgCode", name: club, width: 75, sortable: true },
      {
        key: "status",
        name: status,
        width: 130,
        sortable: true,
        formatter: TravelFormsLinkFormatter,
        getRowMetaData: row => row
      },
      { key: "submittedTs", name: submitted, width: 120, sortable: true, formatter: DateFormatter },
      { key: "approvedTs", name: approved, width: 120, sortable: true, formatter: DateFormatter },
      { key: "arrivalTs", name: arrival, width: 155, sortable: true, formatter: DateFormatter },
      { key: "departureTs", name: departure, width: 200, sortable: true, formatter: DateFormatter },
      travelAuthFormStore.isDidNotTravelAvailableOnProfile
        ? {
            key: "travelIncompleteFlag",
            name: didNotTravel,
            width: 120,
            sortable: true,
            formatter: BooleanCheckFormatter
          }
        : null,
      // HACK: fills in extra space at end of table so last column can be center aligned
      { key: "@@DUMMY@@" }
    ].filter(config => !!config);
  };

  // Render the table for desktop/tablet/mobile
  // rowHeight: The height of each individual row item (Different for desktop/tablet and mobile)
  // headerHeight: The size of the header row (non-existent for mobile)
  // maxHeight: The maximum size the table should become after which we start scrolling
  const getTable = (rowHeight = 35, headerHeight = 35, maxHeight) => {
    const rowCount = travelAuthFormStore.displayedHistory.length;
    const headerAndScrollbarHeight = headerHeight ? headerHeight + 15 : 0;
    const dynamicTableHeight = rowCount * rowHeight + headerAndScrollbarHeight;
    // If no max height is given, use a height that is calculated based on the number of rows and the height of each individual row
    // Otherwise choose the smaller of maxHeight and dynamicTableHeight, giving the table a maximum size.
    const tableHeight = !maxHeight ? dynamicTableHeight : Math.min(maxHeight, dynamicTableHeight);
    return (
      <AccordionTableWrapper
        data={travelAuthFormStore.displayedHistory}
        columns={getColumns()}
        sortFunc={travelAuthFormStore.setSortDirection}
        accordion={TravelAuthHistoryTableMobile}
        headerRowHeight={headerHeight}
        height={tableHeight}
        sortColumn={travelAuthFormStore.sortFilters["keys"][0]}
        sortDirection={travelAuthFormStore.sortFilters["direction"]}
      />
    );
  };

  const mobileCardHeight = 142;
  return (
    <TravelAuthWrapper>
      <div className="col-12">
        <MobileView>{getTable(mobileCardHeight, 0)}</MobileView>
        <DesktopTabletView>{getTable(35, 35, 400)}</DesktopTabletView>
      </div>
    </TravelAuthWrapper>
  );
};

TravelAuthHistoryTable.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TravelAuthHistoryTable));
