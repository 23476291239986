import React from "react";
import { inject, observer } from "mobx-react";
import ClickableTableFilter from "../common/ClickableTableFilter";
import { ClubsConstants } from "../../constants/ClubsConstants";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class StatusFilters extends React.Component {
  filterTable = status => {
    return this.props.rootStore.clubsStore.setCurrentStatusFilter(status);
  };

  constructor() {
    super();
    this.allClubsFilter = this.filterTable.bind(this, ClubsConstants.ALL_CLUBS);
    this.checkedInFilter = this.filterTable.bind(this, ClubsConstants.CHECKED_IN);
    this.approachingFilter = this.filterTable.bind(this, ClubsConstants.APPROACHING);
    this.overdueFilter = this.filterTable.bind(this, ClubsConstants.OVERDUE);
  }

  render() {
    const { clubsStore } = this.props.rootStore;

    return (
      <div>
        <ClickableTableFilter
          name={<FormattedMessage id="ClubStatusFilters.allClubs" defaultMessage="All Clubs" />}
          count={-1}
          filter={this.allClubsFilter}
          isActive={ClubsConstants.ALL_CLUBS === clubsStore.currentStatusFilter}
        />
        <ClickableTableFilter
          name={<FormattedMessage id="ClubStatusFilters.checkedIn" defaultMessage="Checked In" />}
          count={clubsStore.checkedInCount}
          filter={this.checkedInFilter}
          isActive={ClubsConstants.CHECKED_IN === clubsStore.currentStatusFilter}
        />
        <ClickableTableFilter
          name={<FormattedMessage id="ClubStatusFilters.approaching" defaultMessage="Approaching" />}
          count={clubsStore.approachingCount}
          filter={this.approachingFilter}
          isActive={ClubsConstants.APPROACHING === clubsStore.currentStatusFilter}
        />
        <ClickableTableFilter
          name={<FormattedMessage id="ClubStatusFilters.overdue" defaultMessage="Overdue" />}
          count={clubsStore.overdueCount}
          filter={this.overdueFilter}
          isActive={ClubsConstants.OVERDUE === clubsStore.currentStatusFilter}
        />
      </div>
    );
  }
}

StatusFilters.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(StatusFilters));
