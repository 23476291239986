import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

class DateFormatter extends React.Component {
  // Unix epoch to human readable
  formatDate(dateInput) {
    if (!!dateInput) {
      const date = moment(dateInput)
        .utc()
        .format("MM/DD/YYYY");
      if (date !== "Invalid date") {
        return date;
      }
    }
    // If no date is given, display nothing in table cell
    return "";
  }

  render() {
    const { value } = this.props;
    const formattedDate = this.formatDate(value);
    return formattedDate;
  }
}

DateFormatter.propTypes = {
  value: PropTypes.any
};

export default DateFormatter;
