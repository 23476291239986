import React from "react";
import { LightButton, Modal, ModalBody, ModalHeaderWithTitleAndClose, PrimaryButton } from "best-common-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ProspectCheckOutModalBody from "./ProspectCheckOutModalBody";
import { FormattedMessage, intlShape, defineMessages } from "react-intl";

const ModalFooterDiv = styled.div.attrs({
  id: "modal-footer"
})`
  justify-content: space-between;
  background-color: #ececec;
  padding: 16px;
`;

ModalFooterDiv.displayName = "Footer";

const FooterButtons = styled.div`
  float: right;
  button:not(:last-child) {
    margin-right: 0.625rem;
  }
`;

const messages = defineMessages({
  areYouSure: {
    id: "ProspectCheckOutModal.areYouSure",
    defaultMessage: "Are you sure you want to check out {prospectCount} prospect(s)?"
  }
});

class ProspectCheckOutModal extends React.Component {
  constructor(props) {
    super(props);
    this.renderModalBody = this.renderModalBody.bind(this);
  }

  renderModalBody() {
    const { isMulti, prospectCount } = this.props;
    const areYouSure = this.context.intl.formatMessage(messages.areYouSure, {
      prospectCount: " " + prospectCount + " "
    });
    if (isMulti) {
      return <div>{areYouSure}</div>;
    } else {
      return <ProspectCheckOutModalBody />;
    }
  }
  render() {
    const { show, close, checkOut, isMulti } = this.props;

    const checkOutSingleText = (
      <FormattedMessage id="ProspectCheckOutModal.checkOutSingle" defaultMessage="Check Out" />
    );
    const checkOutMultiText = (
      <FormattedMessage id="ProspectCheckOutModal.checkOutMulti" defaultMessage="Check Out Prospect(s)" />
    );
    const checkOutText = isMulti ? checkOutMultiText : checkOutSingleText;
    const closeText = isMulti ? (
      <FormattedMessage id="ProspectCheckOutModal.back" defaultMessage="Back" />
    ) : (
      <FormattedMessage id="ProspectCheckOutModal.cancel" defaultMessage="Cancel" />
    );
    const title = <FormattedMessage id="ProspectCheckOutModal.title" defaultMessage="Prospect Check-Out" />;
    return (
      <div>
        <Modal show={show} onClose={() => {}} size="lg">
          <ModalHeaderWithTitleAndClose title={title} close={close} />
          <ModalBody>
            <div>{this.renderModalBody()}</div>
          </ModalBody>
          <ModalFooterDiv>
            <FooterButtons>
              <PrimaryButton onClick={checkOut}>{checkOutText}</PrimaryButton>
              <LightButton onClick={close}>{closeText}</LightButton>
            </FooterButtons>
          </ModalFooterDiv>
        </Modal>
      </div>
    );
  }
}

ProspectCheckOutModal.defaultProps = {
  show: false,
  isMulti: false,
  prospectCount: 1
};

ProspectCheckOutModal.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func.isRequired,
  checkOut: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  prospectCount: PropTypes.number
};

ProspectCheckOutModal.contextTypes = {
  intl: intlShape
};

export default ProspectCheckOutModal;
