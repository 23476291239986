import { extendObservable, action, autorun } from "mobx";
import _ from "lodash";
class LookupStore {
  constructor(authStore, amtApi) {
    this.authStore = authStore;
    this.amtApi = amtApi;

    this.defaults = {
      lookups: {},
      loaded: false,
      // Selecting a country will filter the list of districts we want to show
      filteredDistricts: [],
      // Selecting a district will filter the list of cities we want to show
      filteredCities: [],
      selectedCountryId: 0,
      selectedDistrictId: 0
    };

    extendObservable(this, {
      lookups: this.defaults["lookups"],
      loaded: this.defaults["loaded"],
      filteredDistricts: this.defaults["filteredDistricts"],
      filteredCities: this.defaults["filteredCities"],
      selectedCountryId: this.defaults["selectedCountryId"],
      selectedDistrictId: this.defaults["selectedDistrictId"],
      setSelectedCountryId: action(countryId => {
        if (!countryId) {
          return;
        }
        // Select the country
        this.selectedCountryId = countryId;
        // Reset the district and city
        this.selectedDistrictId = this.defaults["selectedDistrictId"];
        // Populate the districts for selection
        this.getDistrictDropdownValues(countryId).then(response => {
          if (!!response) {
            this.filteredDistricts = this.getDropdownOptions(response);
          }
        });
      }),
      setSelectedDistrictId: action(districtId => {
        if (!districtId) {
          return;
        }
        // Select the district
        this.selectedDistrictId = districtId;
        // Populate the cities for selection
        this.getCityDropdownValues(districtId).then(response => {
          if (!!response) {
            this.filteredCities = this.getDropdownOptions(response);
          }
        });
      }),
      setLookups: action(lookups => {
        this.lookups = lookups;
      })
    });

    autorun(() => {
      if (this.authStore.loggedIn) {
        this.fetchLookups();
      }
    });
  }

  fetchLookups() {
    this.amtApi.getLookups().then(response => {
      if (!!response) {
        this.lookups = response;
      }
      this.loaded = true;
    });
  }

  // Generic helper to get a value from a list of lookups, given the lookup ID
  getValue(values, id) {
    // ALready given a dropdown value
    if (id && id.value && id.label) {
      return id.label;
    }
    if (values) {
      const lookup = values.find(value => {
        return value.id === id || Number(value.id) === id;
      });
      if (lookup) {
        return lookup.value;
      }
    }
  }
  /**
   * This method inserts a new option at the start of the lookup.
   * Example: The org dropdown needs all the orgs PLUS an ALL option.
   * @param {lookups} lookups
   * @param {value} value
   */
  getDropdownOptionsWithInitialOption(lookups, value) {
    let initialPlaceholder = "All";
    if (!!value) {
      initialPlaceholder = value;
    }
    let lookupWithNewItem = _.cloneDeep(lookups);
    // If given a bad list of lookups, dropdown will be empty
    if (!lookupWithNewItem) {
      return [];
    } else {
      lookupWithNewItem.unshift({
        id: 0,
        value: initialPlaceholder
      });
    }

    // Transform the keys for use with dropdowns
    return lookupWithNewItem.map(lookup => {
      return {
        value: lookup.id,
        label: lookup.value
      };
    });
  }
  getDropdownOptions(lookups) {
    // If given a bad list of lookups, dropdown will be empty
    if (!lookups) {
      return [];
    }
    // Transform the keys for use with dropdowns
    return lookups.map(lookup => {
      return {
        value: lookup.id,
        label: lookup.value,
        activeFlag: lookup.activeFlag
      };
    });
  }

  getDropdownOptionsWithDisabledOptions(lookups) {
    return this.getDropdownOptions(lookups).map(lookup => {
      return {
        value: lookup.value,
        label: lookup.label,
        isDisabled: !lookup.activeFlag
      };
    });
  }

  getDistrictDropdownValues(countryId) {
    if (this.authStore.loggedIn) {
      return this.amtApi.getDistricts(countryId);
    }
  }

  getCityDropdownValues(districtId) {
    if (this.authStore.loggedIn) {
      return this.amtApi.getCities(districtId);
    }
  }

  getBackendVersion() {
    return this.amtApi.getBackEndVersion();
  }

  // Get an item from a dropdown by lookup id
  getDropdownItem(options, id) {
    // Given an empty list or empty id
    if (!options || id === null || id === undefined) {
      return "";
    }
    // Given a dropdown item already
    if (id.value && id.label) {
      return id;
    }
    return options.find(item => {
      if (item.value === id || Number(item.value) === id) {
        // Given dropdown list
        return item;
      } else if (item.id === id || Number(item.id) === id) {
        // Given lookup list
        return {
          value: item.id,
          label: item.value
        };
      } else {
        return "";
      }
    });
  }
}

export default LookupStore;
