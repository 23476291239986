import { action, extendObservable, decorate, computed, autorun } from "mobx";
import { AlertConstants } from "../../constants/AlertConstants";
import { CheckInConstants } from "../../constants/CheckInConstants";
import { ErrorWarningConstants } from "../../constants/ErrorWarningConstants";
import moment from "moment";

class CheckInModalStore {
  constructor(commonStore, prospectProfileStore, checkInHistoryStore) {
    this.amtApi = commonStore.amtApi;
    this.authStore = commonStore.authStore;
    this.alertStore = commonStore.alertStore;
    this.errorWarningStore = commonStore.errorWarningStore;
    this.routerStore = commonStore.routerStore;
    this.lookupStore = commonStore.lookupStore;
    this.prospectProfileStore = prospectProfileStore;
    this.checkInHistoryStore = checkInHistoryStore;
    this.defaults = {
      checkIn: {
        amtProspectCheckInId: 0,
        amtProspectId: 0,
        checkedInDate: "",
        checkedInTime: "",
        trainingFacilityId: 0,
        otherCountryId: 0,
        locationNote: "",
        photo: null,
        checkInClub: ""
      },
      locationAdded: false,
      photoAdded: false,
      photoConfirmed: false,
      photoErrors: [],
      maxPhotoMB: "5MB",
      // Club users will be able to select a checkbox for "Player Not Present"
      // If the player is not present, the club will be allowed to move to the next screen
      playerNotPresent: false
    };

    extendObservable(this, {
      checkIn: this.defaults["checkIn"],
      locationAdded: this.defaults["locationAdded"],
      photoAdded: this.defaults["photoAdded"],
      photoConfirmed: this.defaults["photoConfirmed"],
      photoErrors: this.defaults["photoErrors"],
      maxPhotoMB: this.defaults["maxPhotoMB"],
      playerNotPresent: this.defaults["playerNotPresent"],
      canCheckInProspect: action(() => {
        if (this.checkIn && this.prospectProfileStore.prospectId) {
          this.canCheckIn();
        }
      }),
      next: action(() => {
        const now = moment();
        // For display on the modal
        this.checkIn.checkedInDateDisplay = now.format("MM/DD/YYYY");
        this.checkIn.checkedInTimeDisplay = now.format("LT");
        // For persisting to the backend
        this.checkIn.checkedInDate = now.format("YYYY-MM-DD");
        this.checkIn.checkedInTime = now.format("HH.mm.ss.SSS");

        if (!this.locationAdded) {
          this.locationAdded = true;
          this.photoAdded = this.checkIn.photo && !!(this.checkIn.photo !== this.defaults["checkIn"].photo);
        } else if (!this.photoAdded) {
          this.locationAdded = true;
          this.photoAdded = true;
          if (this.playerNotPresent) {
            // If the playerNotPresent box is checked, we can move directly to the final step
            this.photoConfirmed = true;
          }
        } else if (!this.photoConfirmed) {
          this.locationAdded = true;
          this.photoAdded = true;
          this.photoConfirmed = true;
        }
      }),
      previous: action(() => {
        if (this.photoConfirmed) {
          this.locationAdded = true;
          this.photoAdded = true;
          this.photoConfirmed = false;
          this.resetPhoto();
        } else if (this.locationAdded) {
          this.locationAdded = false;
          this.photoAdded = false;
          this.resetPhoto();
        }
      }),
      save: action(() => {
        if (this.checkIn) {
          this.saveCheckIn(this.checkIn);
        }
      }),
      updateProspect: action((prospectId, orgCode) => {
        this.checkIn.amtProspectId = prospectId;
        this.checkIn.orgCode = orgCode;
      }),
      updateLocation: action(location => {
        this.checkIn.trainingFacilityId = location.value * 1;
        this.checkIn.trainingFacilityName = location.label;
        this.checkIn.otherCountryId = 0;
      }),
      updateCountry: action(country => {
        this.checkIn.otherCountryId = country;
      }),
      updateComments: action(comments => {
        this.checkIn.locationNote = comments;
      }),
      resetStore: action(() => {
        this.checkIn = this.defaults["checkIn"];
        this.locationAdded = this.defaults["locationAdded"];
        this.photoAdded = this.defaults["photoAdded"];
        this.photoConfirmed = this.defaults["photoConfirmed"];
        this.playerNotPresent = this.defaults["playerNotPresent"];
      }),
      resetPhoto: action(() => {
        this.checkIn.photo = this.defaults.checkIn.photo;
        this.photoErrors = this.defaults.photoErrors;
      }),
      updatePhoto: action(photo => {
        this.checkIn.photo = photo;
        this.photoAdded = true;
        this.populateCheckInPhotoErrorsList(photo);
      })
    });
  }

  /**
   * Given a photo, determine if there are any errors with it
   * If so, add the errors to a list for rendering
   */
  populateCheckInPhotoErrorsList(photo) {
    this.photoErrors = this.defaults["photoErrors"];

    if (photo && this.photoAdded) {
      if (!photo.startsWith("data:image/jpeg")) {
        this.photoErrors.push(CheckInConstants.errors.PHOTO_FORMAT_INVALID);
      }

      Promise.all([this.getMaxPhotoSizeInMb(), this.isPhotoTooLarge(photo)]).then(values => {
        if (values) {
          if (values[0]) {
            // If value retrieved from ebis2, overwrite our default
            this.maxPhotoMB = values[0].value;
          }
          if (values[1]) {
            this.photoErrors.push(CheckInConstants.errors.PHOTO_TOO_LARGE);
          }
        }
      });
    }
  }

  /**
   * If a photo is too large (>4MB) then we will display a warning
   *
   * Estimation for file size from base64 string taken from
   * https://en.wikipedia.org/wiki/Base64#MIME
   *
   * bytes = (string_length(encoded_string) - 814) / 1.37
   *
   */
  isPhotoTooLarge(base64Photo) {
    if (!base64Photo) {
      return false;
    }

    return this.getMaxPhotoSize().then(constant => {
      const maxSize = constant.value;
      return !isNaN(maxSize) && (base64Photo.length - 814) / 1.37 > maxSize;
    });
  }

  // Returns max photo size numeric e.g. 4000000
  getMaxPhotoSize() {
    return this.amtApi.getLookup("EBIS_CONSTANTS").then(constants => {
      return constants.find(constant => constant.code === CheckInConstants.ebisConstants.MAX_PHOTO_SIZE);
    });
  }

  // Returns max photo size e.g. 4MB
  getMaxPhotoSizeInMb() {
    return this.amtApi.getLookup("EBIS_CONSTANTS").then(constants => {
      return constants.find(constant => constant.code === CheckInConstants.ebisConstants.MAX_PHOTO_SIZE_MB);
    });
  }

  canCheckIn() {
    this.checkIn.amtProspectId = this.prospectProfileStore.prospectId;
    this.checkIn.checkInClub = this.authStore.userData.orgClub;
    // For BOC, we can't grab the logged in org, we need the prospect's org
    if (this.authStore.isBOC) {
      this.checkIn.orgCode = this.lookupStore.getValue(
        this.prospectProfileStore.prospect.prospectOrgs,
        this.checkIn.amtProspectId
      );
    } else {
      const orgId = this.authStore.userData.org;
      const orgCode = this.lookupStore.getValue(this.lookupStore.lookups.ORGS_ONLY_SHORT, orgId);
      this.checkIn.orgCode = orgCode;
    }

    this.amtApi.canCheckIn(this.checkIn).then(response => {
      if (response && response.amtProspectId) {
        this.prospectProfileStore.setShowProspectCheckInModal(true);
      } else {
        if (this.errorWarningStore.errorWarningCode === ErrorWarningConstants.CODES.AGE_REQUIREMENT_CHECK) {
          this.errorWarningStore.setOverrideHandler(() => {
            this.prospectProfileStore.setShowProspectCheckInModal(true);
          });
        } else if (
          this.authStore.isBOC &&
          this.errorWarningStore.errorWarningCode === ErrorWarningConstants.CODES.OVERDUE_PERIOD_CHECK
        ) {
          this.errorWarningStore.setOverrideHandler(() => {
            this.checkIn.overrideFlag = this.errorWarningStore.overrideWarningsFlag;
            this.canCheckIn();
          });
        } else if (
          (this.authStore.isBOC || this.authStore.isClub) &&
          this.errorWarningStore.errorWarningCode === ErrorWarningConstants.CODES.CHECK_IN_PROSPECT_MISSING_PHONE_NUMBER
        ) {
          this.errorWarningStore.setOverrideHandler(() => {
            this.checkIn.overrideFlag = this.errorWarningStore.overrideWarningsFlag;
            this.canCheckIn();
          });
        } else {
          // The store should get reset on a default hard error.
          this.resetStore();
        }
        this.prospectProfileStore.setShowProspectCheckInModal(false);
      }
    });
  }

  saveCheckIn(checkIn) {
    checkIn.overrideFlag = this.errorWarningStore.overrideWarningsFlag;
    if (!checkIn.amtProspectId) {
      checkIn.amtProspectId = this.prospectProfileStore.prospectId;
    }
    this.amtApi.saveCheckIn(checkIn).then(response => {
      if (response && response.amtProspectCheckInId) {
        // The checkin and alert store should get reset on success.
        this.resetStore();
        this.checkInHistoryStore.resetStore();
        this.alertStore.resetStore();
        this.errorWarningStore.resetStore();

        // Refresh the profile
        this.routerStore.refresh();
        this.prospectProfileStore.showAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Checked In"
        });
      } else {
        if (this.errorWarningStore.errorWarningCode === ErrorWarningConstants.CODES.AGE_REQUIREMENT_CHECK) {
          this.errorWarningStore.setOverrideHandler(() => {
            this.save();
          });
        } else {
          // The store should get reset on a hard error.
          this.resetStore();
        }
      }
      this.prospectProfileStore.setShowProspectCheckInModal(false);
    });

    autorun(() => {
      if (!this.prospectProfileStore.showProspectCheckInModal) {
        this.resetStore();
      }
    });
  }

  get isNextEnabled() {
    if (!this.locationAdded) {
      return (
        (!this.authStore.isBOC || this.checkIn.amtProspectId) &&
        // Check-In Location must always be selected
        this.checkIn.trainingFacilityId &&
        // If we show the Location Comments input, then it is required
        (!this.showComments || this.checkIn.locationNote) &&
        // If we show the Country dropdown, then it is required
        (!this.isCountryOtherOffsite || this.checkIn.otherCountryId)
      );
    } else if (this.locationAdded && !this.photoAdded) {
      if (this.authStore.isClub && this.playerNotPresent) {
        // Club users can check playerNotPresent to allow bypass of photo
        return true;
      }
      // No photo entered
      return false;
    } else if (this.locationAdded && this.photoAdded && this.photoErrors.length > 0) {
      // Photo entered, but there are errors
      return false;
    }
    return true;
  }

  get isCountryOtherOffsite() {
    return this.checkInHistoryStore.isCountryOtherOffsite(this.checkIn);
  }

  get isCountryDROffsite() {
    return this.checkInHistoryStore.isCountryDROffsite(this.checkIn);
  }

  get isCountryVZOffsite() {
    return this.checkInHistoryStore.isCountryVZOffsite(this.checkIn);
  }

  get showComments() {
    return this.isCountryOtherOffsite || this.isCountryDROffsite || this.isCountryVZOffsite;
  }
}

decorate(CheckInModalStore, {
  isNextEnabled: computed,
  isCountryOtherOffsite: computed,
  isCountryDROffsite: computed,
  isCountryVZOffsite: computed,
  showComments: computed
});

export default CheckInModalStore;
