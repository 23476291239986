export const ErrorWarningConstants = {
  CODES: {
    REQUIRED_FIELD: "REQUIRED_FIELD",
    VIEW_DENIED: "AMT_VIEW_DENIED",
    AGE_REQUIREMENT_CHECK: "AGE_REQUIREMENT_CHECK",
    OVERDUE_PERIOD_CHECK: "OVER_DUE_PERIOD_CHECK",
    PLAYER_OUT_OF_DAYS: "PLAYER_OUT_OF_DAYS",
    TRAVEL_DATES_INVALID: "TRAVEL_DATES_INVALID",
    TRAVEL_AUTH_PROSPECT_MISSING_PHONE_NUMBER: "TRAVEL_AUTH_PROSPECT_MISSING_PHONE_NUMBER",
    CHECK_IN_PROSPECT_MISSING_PHONE_NUMBER: "CHECK_IN_PROSPECT_MISSING_PHONE_NUMBER",
    INT_PLAYER_STATUS_CHECK_NEEDED: "INT_PLAYER_STATUS_CHECK_NEEDED",
    // Sometimes we want to handle a "Default" case for errors
    CHECK_IN_DEFAULT_ERROR: "CHECK_IN_DEFAULT_ERROR"
  }
};
