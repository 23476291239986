import { action, computed, decorate, extendObservable, isObservable } from "mobx";
import { AuthConstants } from "../constants/AuthConstants";
import _ from "lodash";
import { CommonConstants } from "../constants/CommonConstants";

const ENV = process.env.REACT_APP_ENV;

class AuthStore {
  constructor(routerStore, authApi, amtApi) {
    const authStore = this;
    this.routerStore = routerStore;
    this.authApi = authApi;
    this.amtApi = amtApi;

    if (this.authApi.getTokenManager()) {
      this.authApi.getTokenManager().on("expired", function(key) {
        console.debug(`OKTA ${key} has expired at`, new Date().getTime().toString());
        authStore.authApi.getTokenManager().renew(key);
      });

      this.authApi.getTokenManager().on("renewed", function(key, newToken) {
        authStore[key] = newToken[key];

        console.debug(`OKTA ${key} expires at`, new Date(newToken.expiresAt * 1000).toString());
      });
    }

    this.defaults = {
      mobileNavOpen: false,
      accessToken: "",
      idToken: "",
      loginError: false,
      isLoggingIn: false,
      refreshInProgress: false,
      userData: {}
    };

    extendObservable(this, {
      mobileNavOpen: this.defaults["mobileNavOpen"],
      accessToken: this.defaults["accessToken"],
      idToken: this.defaults["idToken"],
      loginError: this.defaults["loginError"],
      isLoggingIn: this.defaults["isLoggingIn"],
      refreshInProgress: this.defaults["refreshInProgress"],
      userData: this.defaults["userData"],

      checkLocalStorage: action(() => {
        //Okta reads form localstorage but returns promeses so this is nessecary
        this.userData = localStorage.getItem(AuthConstants.KEYS.USER_DATA)
          ? JSON.parse(localStorage.getItem(AuthConstants.KEYS.USER_DATA))
          : this.defaults["userData"];
        Promise.all(this.authApi.getToken()).then(oktaData => {
          this.accessToken = oktaData[0] ? oktaData[0] : this.defaults["accessToken"];
          this.idToken = oktaData[1] ? oktaData[1] : this.defaults["idToken"];
        });
      }),
      setLoggedIn: action(value => {
        this.accessToken = value.accessToken;
        this.idToken = value.idToken;
      }),
      setLocalStorage: action(userData => {
        if (userData) {
          localStorage.setItem(AuthConstants.KEYS.USER_DATA, JSON.stringify(userData));
        }
        if (userData && userData.org) {
          localStorage.setItem(CommonConstants.KEYS.SELECTED_ORG, JSON.stringify(userData.org));
        }
      }),
      setUserData: action(data => {
        this.userData = data;
      }),
      getUserInfo: action(() => {
        Promise.all([this.authApi.getUserInfo(), this.amtApi.getLookup("ORGSNOMSBNOBOC")]).then(data => {
          let userData = data[0];
          let orgLookup = data[1];

          if (userData.hasOwnProperty("groups")) {
            if (userData.groups.indexOf("AMT-BOC-ADMIN") > -1) {
              userData.org = 4;
            } else {
              for (let index in orgLookup) {
                let org = orgLookup[index];
                if (userData.groups.indexOf(org.value) > -1) {
                  userData.org = parseInt(org.id, 10);
                  userData.orgClub = org.value;
                  break;
                }
              }
            }

            if (userData) {
              this.setUserData(userData);
              this.setLocalStorage(userData);
            }
            this.isLoggingIn = false;
          }
        });
      }),
      login: action(() => {
        this.isLoggingIn = true;
        Promise.all(this.authApi.getToken()).then(
          data => {
            if (!data || data.error || data.length === 0 || data[0] === undefined || data[1] === undefined) {
              this.isLoggingIn = false;
            } else {
              let token = {
                accessToken: data[0],
                idToken: data[1]
              };
              this.setLoggedIn(token);
              this.getUserInfo();
            }
          },
          error => {
            console.error(error);
          }
        );
      }),
      logout: action(() => {
        localStorage.clear();
        this.checkLocalStorage();
        this.userData = this.defaults["userData"];
        this.routerStore.history.push("/login");
        //window.location = "/login";
      }),
      refresh: action(() => {}),
      setMobileNavOpen: action(value => {
        this.mobileNavOpen = value;
      })
    });
  }

  get isProd() {
    return !!ENV && ENV.toLowerCase() === "prod";
  }

  get loggedIn() {
    return (
      !!this.accessToken &&
      this.accessToken.length > 0 &&
      this.userData &&
      this.userData.hasOwnProperty("preferred_username")
    );
  }

  get isBOC() {
    let groups = isObservable(this.userData.groups) ? this.userData.groups.toJSON() : this.userData.groups;
    return (
      this.loggedIn &&
      _.some(groups, group => {
        return group.toUpperCase() === "AMT-BOC-ADMIN";
      })
    );
  }

  get isClub() {
    let groups = isObservable(this.userData.groups) ? this.userData.groups.toJSON() : this.userData.groups;
    return (
      this.loggedIn &&
      _.some(groups, group => {
        return group.toUpperCase() === "AMT-CLUB-ADMIN";
      })
    );
  }

  get isAdmin() {
    let groups = isObservable(this.userData.groups) ? this.userData.groups.toJSON() : this.userData.groups;
    return (
      this.loggedIn &&
      _.some(groups, group => {
        return group.toUpperCase() === "AMT-CLUB-ADMIN" || group.toUpperCase() === "AMT-BOC-ADMIN";
      })
    );
  }

  get isGlobal() {
    let authorities = isObservable(this.userData.authorities)
      ? this.userData.authorities.toJSON()
      : this.userData.authorities;
    return (
      this.loggedIn &&
      _.some(authorities, authority => {
        return authority.authority.toLowerCase() === "global";
      })
    );
  }

  get currentOrg() {
    if (this.userData && this.userData.org) {
      return this.userData.org;
    } else {
      return -1;
    }
  }

  get currentUserId() {
    if (this.userData) {
      return this.userData.userId;
    } else {
      return -1;
    }
  }

  serialize(obj) {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  getAccessToken() {
    return this.authApi.getAccessToken();
  }
}

decorate(AuthStore, {
  isProd: computed,
  loggedIn: computed,
  isBOC: computed,
  isClub: computed,
  isAdmin: computed,
  isGlobal: computed,
  currentOrg: computed,
  currentUserId: computed
});

export default AuthStore;
