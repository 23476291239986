import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TravelAuthFormSection from "./TravelAuthFormSection";
import { FormInput, BestLabel } from "best-common-react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const CheckBox = styled.input`
  margin-right: 5px;
`;

class DrugTesting extends React.Component {
  render() {
    const { travelAuthFormStore } = this.props.rootStore;
    const { travelAuthForm } = travelAuthFormStore;
    return (
      <div className="col-12">
        <TravelAuthFormSection title={<FormattedMessage id="DrugTesting.title" defaultMessage="Drug Testing" />}>
          <div className="form-group">
            <CheckBox
              name="drugTestCheckbox"
              type="checkbox"
              disabled={!travelAuthFormStore.isFieldEditable("drugTestCheckbox")}
              checked={!!travelAuthForm.drugTestInterestFlag}
              onChange={event => (travelAuthForm.drugTestInterestFlag = event.target.checked)}
            />
            <BestLabel>
              <FormattedMessage
                id="DrugTesting.expectedDuringTravel"
                defaultMessage="Expected Drug Test During Travel"
              />
            </BestLabel>
          </div>
          {travelAuthForm.drugTestInterestFlag ? (
            <FormInput
              name="drugTestComments"
              label={<FormattedMessage id="DrugTesting.drugTestComments" defaultMessage="Comments" />}
              isRequired={travelAuthForm.drugTestInterestFlag}
              value={travelAuthForm.travelAuthDrugTestComment ? travelAuthForm.travelAuthDrugTestComment.comments : ""}
              onChangeFn={comments => travelAuthFormStore.setDrugTestComment(comments)}
              editable={travelAuthFormStore.isFieldEditable("drugTestComments")}
            />
          ) : null}
        </TravelAuthFormSection>
      </div>
    );
  }
}

DrugTesting.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(DrugTesting));
