import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

/**
 * Using `this.props.dependentValues` is a hack
 * taken from https://github.com/adazzle/react-data-grid/issues/42
 * to access row data inside formatter. Please be aware,
 * there might be better ways of doing this (such as
 * adding an optional prop function to the accordion prop
 * where we can specify the getRow method, and build
 * a more suitable object)
 */

const dependentValueProp = {
  amtTravelAuthFormId: PropTypes.number
};

class TravelFormsLinkFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    dependentValues: PropTypes.shape(dependentValueProp).isRequired,
    rootStore: PropTypes.object.isRequired
  };

  render() {
    const { amtTravelAuthFormId } = this.props.dependentValues;
    const name = this.props.value;
    return (
      <Link id="travel-form-link-formatter" to={"/travel-auth-form/" + amtTravelAuthFormId}>
        {name}
      </Link>
    );
  }
}

export default inject("rootStore")(observer(TravelFormsLinkFormatter));
