import React from "react";
import { FormInput } from "best-common-react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

const LocationContainer = styled.div`
  margin-top: 40px;
`;

class CheckInModalLocation extends React.Component {
  render() {
    const { authStore, lookupStore, checkInModalStore, prospectProfileStore } = this.props.rootStore;
    const prospectOrgsForDropdown = lookupStore.getDropdownOptionsWithDisabledOptions(
      prospectProfileStore.prospect.prospectOrgs
    );
    const locationsForDropdown = lookupStore.getDropdownOptions(lookupStore.lookups.AMT_TRAINING_FACILITIES);
    const countriesForDropdown = lookupStore.getDropdownOptions(lookupStore.lookups.INT_PLAYER_COUNTRIES);
    const { checkIn } = checkInModalStore;

    return (
      <LocationContainer>
        <div className="row">
          {authStore.isBOC ? (
            <div className="col-12 col-sm-5">
              <FormInput
                name="checkInOrg"
                type="select"
                label={
                  <FormattedMessage
                    id="ProspectCheckInModal.checkInOnBehalfOf"
                    defaultMessage="Check-In On Behalf Of"
                  />
                }
                isRequired={authStore.isBOC}
                value={lookupStore.getDropdownItem(prospectOrgsForDropdown, checkIn.amtProspectId)}
                options={prospectOrgsForDropdown}
                onChangeFn={prospectOrg => checkInModalStore.updateProspect(prospectOrg.value * 1, prospectOrg.label)}
                editable={true}
              />
            </div>
          ) : (
            ""
          )}

          <div className={authStore.isBOC ? "col-12 col-sm-7" : "col-12"}>
            <FormInput
              name="checkInLocation"
              type="select"
              label={<FormattedMessage id="ProspectCheckInModal.checkInLocation" defaultMessage="Check-In Location" />}
              isRequired={true}
              value={lookupStore.getDropdownItem(locationsForDropdown, checkIn.trainingFacilityId)}
              options={locationsForDropdown}
              onChangeFn={location => checkInModalStore.updateLocation(location)}
              editable={true}
            />
          </div>
        </div>

        {// Other Country
        checkInModalStore.isCountryOtherOffsite ? (
          <div className="row">
            <div className="col-12">
              <FormInput
                name="checkInLocation"
                type="select"
                label={<FormattedMessage id="ProspectCheckInModal.otherCountry" defaultMessage="Country" />}
                isRequired={true}
                value={lookupStore.getDropdownItem(countriesForDropdown, checkIn.otherCountryId)}
                options={countriesForDropdown}
                onChangeFn={country => checkInModalStore.updateCountry(country.value * 1)}
                editable={true}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        {// DR Offsite, VZ Offsite, or Other Country Offsite
        checkInModalStore.showComments ? (
          <div className="row">
            <div className="col-12">
              <FormInput
                name="locationComments"
                label={
                  <FormattedMessage id="ProspectCheckInModal.locationComments" defaultMessage="Location Comments" />
                }
                isRequired={true}
                value={checkIn.locationNote}
                onChangeFn={comments => checkInModalStore.updateComments(comments)}
                editable={true}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </LocationContainer>
    );
  }
}

CheckInModalLocation.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CheckInModalLocation));
