import React from "react";
import PropTypes from "prop-types";
import { Icon } from "best-common-react";

const CheckInPhotoUploadFormatter = ({ value, onChange, index }) => {
  const id = `photo-upload-${index}`;
  return (
    <div onClick={() => document.getElementById(id).click()}>
      <input style={{ display: "none" }} id={id} type="file" accept="image/jpeg" onChange={onChange} />
      <Icon iconName="fa-upload" className="fa-md" />
    </div>
  );
};

CheckInPhotoUploadFormatter.defaults = {
  onChange: () => {}
};
CheckInPhotoUploadFormatter.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  index: PropTypes.number.isRequired
};

export default CheckInPhotoUploadFormatter;
