import React from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton,
  StickyFooterButtons
} from "best-common-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const ConfirmMessage = styled.div.attrs(() => ({
  className: "mb-3"
}))`
  font-size: 1.125rem;
  font-weight: 400;
`;

class CancelModal extends React.Component {
  render() {
    return (
      <div>
        <Modal show={this.props.show} onClose={() => {}} size="lg">
          <ModalHeaderWithTitleAndClose
            title={<FormattedMessage id="CancelModal.title" defaultMessage="Are you sure?" />}
            close={this.props.close}
          />
          <ModalBody>
            <ConfirmMessage>
              <FormattedMessage id="CancelModal.confirmMessage" defaultMessage="Any unsaved changes will be lost." />
            </ConfirmMessage>
          </ModalBody>
          <ModalFooter>
            <StickyFooterButtons>
              <PrimaryButton onClick={this.props.cancel}>
                <FormattedMessage id="CancelModal.discard" defaultMessage="Discard Changes" />
              </PrimaryButton>
              <LightButton onClick={this.props.back}>
                <FormattedMessage id="CancelModal.back" defaultMessage="Back" />
              </LightButton>
            </StickyFooterButtons>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

CancelModal.defaultProps = {
  show: false,
  close: () => {}
};

CancelModal.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  cancel: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired
};

export default CancelModal;
