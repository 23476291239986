import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const getProspectId = row => {
  // Coming from the prospects page we will have a prospectId
  // Coming from the potential match modal we will have an amtProspectId
  // If for some reason we don't have either of these, just return null
  if (!row || (!row.prospectId && !row.amtProspectId)) {
    return null;
  }
  const { prospectId, amtProspectId } = row;
  return prospectId || amtProspectId;
};

const ProspectLinkFormatter = ({ value, row }) => {
  const name = value;
  const id = getProspectId(row);

  return id ? <Link to={"/prospect/" + id}>{name}</Link> : <div>{name}</div>;
};

ProspectLinkFormatter.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired
};

export default ProspectLinkFormatter;
