export const CheckInConstants = {
  ebisConstants: {
    MAX_PHOTO_SIZE: "AMT_MAX_PHOTO_SIZE",
    MAX_PHOTO_SIZE_MB: "AMT_MAX_PHOTO_SIZE_STR"
  },
  errors: {
    PHOTO_TOO_LARGE: "PHOTO_TOO_LARGE",
    PHOTO_FORMAT_INVALID: "PHOTO_FORMAT_INVALID"
  }
};
