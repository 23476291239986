import React from "react";
import {
  FormColumn2Wide,
  BestTitle,
  BestLabel,
  TextArea,
  Input,
  StickyFooterButtons,
  LightButton
} from "best-common-react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

const SubmitButton = ({ disabled, send }) => {
  return (
    <div style={{ marginTop: ".75em" }}>
      <StickyFooterButtons>
        <LightButton disabled={disabled} onClick={send}>
          <FormattedMessage id="ContactUs.send" defaultMessage="Send" />
        </LightButton>
      </StickyFooterButtons>
    </div>
  );
};

const RequiredIndicator = styled.span`
  margin-left: 0.25rem;
  color: red;
  font-size: 0.875rem;
`;

const ContactInstructions = styled.div`
  font-size: 0.875rem;
  font-family: helvetica;
  margin-bottom: 2rem;
`;

class ContactUs extends React.Component {
  render() {
    const { helpStore } = this.props.rootStore;
    const { subject, message, setSubject, setMessage } = helpStore;

    return (
      <FormColumn2Wide>
        <BestTitle>
          <FormattedMessage id="ContactUs.title" defaultMessage="Contact Us" />
        </BestTitle>
        <div>
          <ContactInstructions>
            <FormattedMessage
              id="ContactUs.contactInstructions"
              defaultMessage="Please contact us with any questions using the form below, or you can email us at AMT@mlb.com"
            />
          </ContactInstructions>
          <BestLabel>
            <FormattedMessage id="ContactUs.subject" defaultMessage="Subject" />
          </BestLabel>
          <RequiredIndicator>*</RequiredIndicator>
          <Input id="subject" value={subject} onChange={e => setSubject(e.target.value)} />
        </div>
        <div>
          <BestLabel>
            <FormattedMessage id="ContactUs.message" defaultMessage="Message" />
          </BestLabel>
          <RequiredIndicator>*</RequiredIndicator>
          <TextArea value={message} onChange={e => setMessage(e.target.value)} />
        </div>
        <SubmitButton disabled={!subject || !message} send={() => helpStore.sendContactForm(subject, message)} />
      </FormColumn2Wide>
    );
  }
}

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  send: PropTypes.func.isRequired
};

ContactUs.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ContactUs));
