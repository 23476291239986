import React from "react";
import { FormattedMessage } from "react-intl";

class NewProspectHeader extends React.Component {
  render() {
    return (
      <div>
        <FormattedMessage id="NewProspectHeader.createNewProspect" defaultMessage="Create a New Prospect" />
      </div>
    );
  }
}

export default NewProspectHeader;
