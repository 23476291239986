import React from "react";
import { StickyFooterButtons } from "best-common-react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

class TravelAuthFooterButtons extends React.Component {
  constructor() {
    super();
    this.renderStateChangeButtons = this.renderStateChangeButtons.bind(this);
    this.renderFormModeButtons = this.renderFormModeButtons.bind(this);
    this.renderDeleteButton = this.renderDeleteButton.bind(this);
  }

  renderStateChangeButtons() {
    const { authStore, travelAuthFormStore } = this.props.rootStore;
    const { buttons } = this.props;

    const buttonsToRender = [];

    if (travelAuthFormStore.isPreliminary || travelAuthFormStore.isRejected) {
      // Submit button
      // When the Travel Form is in view or edit mode, Club Users will see a Submit button at the bottom of the form
      // The button will appear on unsaved or preliminary Travel Forms
      // NOTE: We're also showing the button for BOC
      // And we are also showing it on rejected forms, so that users can resubmit like in ebis
      buttonsToRender.push(buttons.submit);
    } else if (authStore.isBOC && travelAuthFormStore.isSubmitted) {
      // Approve/Reject buttons
      // When viewing a submitted Travel Authorization Form, BOC users will see an Approve(blue) button
      // When viewing a submitted Travel Authorization Form, BOC users will see a Reject(grey) button
      buttonsToRender.push(buttons.approve);
      buttonsToRender.push(buttons.reject);
    } else if (authStore.isBOC && travelAuthFormStore.isApproved) {
      // Reject button for BOC on Approved forms
      buttonsToRender.push(buttons.reject);
    }

    return buttonsToRender;
  }

  renderFormModeButtons() {
    const { travelAuthFormStore, authStore } = this.props.rootStore;
    const { buttons } = this.props;

    const buttonsToRender = [];

    if (travelAuthFormStore.editMode) {
      // If in edit mode, allow save and cancel
      buttonsToRender.push(buttons.save);
      buttonsToRender.push(buttons.cancel);
    } else {
      // In view mode:
      // BOC can edit Approved, Preliminary, and Submitted
      // Clubs can edit in any state, but can only edit comments once submitted

      buttonsToRender.push(buttons.edit);

      if (!authStore.isBOC && travelAuthFormStore.isApproved) {
        buttonsToRender.push(buttons.editToResubmit);
        buttonsToRender.push(buttons.editExpenses);
      }

      buttonsToRender.push(buttons.close);
    }

    return buttonsToRender;
  }

  renderDeleteButton() {
    const { travelAuthFormStore } = this.props.rootStore;
    const { buttons } = this.props;
    // When the Travel Form is in Preliminary State and in View or Edit Mode
    // There will be a Delete button at the bottom of the page
    // However, make sure there is a current ID
    // We don't want to show the delete button when creating a new form, as it doesn't exist yet
    if (travelAuthFormStore.isPreliminary && travelAuthFormStore.travelAuthForm.amtTravelAuthFormId) {
      return <React.Fragment>{buttons.delete}</React.Fragment>;
    }
  }

  render() {
    const { authStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        <React.Fragment>
          {authStore.isAdmin ? this.renderStateChangeButtons() : ""}
          {authStore.isAdmin ? this.renderDeleteButton() : ""}
          {this.renderFormModeButtons()}
        </React.Fragment>
      </StickyFooterButtons>
    );
  }
}

TravelAuthFooterButtons.propTypes = {
  rootStore: PropTypes.object.isRequired,
  buttons: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TravelAuthFooterButtons));
