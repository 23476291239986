export const MonthsFromSigningConstants = {
  ELIGIBLE_TO_SIGN_ID: 0,
  AGE_ELIGIBLE_ID: 1,
  ZERO_TO_SIX_ID: 2,
  SIX_TO_TWELVE_ID: 3,
  TWELVE_TO_EIGHTEEN_ID: 4,
  ELIGIBLE_TO_SIGN_ON_BIRTHDAY_ID: 5,
  ELIGIBLE_TO_SIGN_LIMIT: "AMT_CHKIN_ES_LIM",
  ELIGIBLE_TO_SIGN_WINDOW_LENGTH: "AMT_CHKIN_ES_WIN_LEN",
  AGE_ELIGIBLE_LIMIT: "AMT_CHKIN_AE_LIM",
  AGE_ELIGIBLE_WINDOW_LENGTH: "AMT_CHKIN_AE_WIN_LEN",
  MS1_LIMIT: "AMT_CHKIN_MS1_LIM",
  MS1_WINDOW_LENGTH: "AMT_CHKIN_MS1_WIN_LEN",
  MS2_LIMIT: "AMT_CHKIN_MS2_LIM",
  MS2_WINDOW_LENGTH: "AMT_CHKIN_MS2_WIN_LEN",
  MS3_LIMIT: "AMT_CHKIN_MS3_LIM",
  MS3_WINDOW_LENGTH: "AMT_CHKIN_MS3_WIN_LEN"
};
