import React from "react";
import { inject, observer } from "mobx-react";
import { AlertHolder } from "best-common-react";
import PropTypes from "prop-types";

const AmtAlerts = ({ rootStore: { alertStore } }) => (
  <div id="amt-alerts">
    <AlertHolder fullWidth={false} alerts={alertStore.alerts} onRemove={alertStore.removeAlert} />
  </div>
);

AmtAlerts.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(AmtAlerts));
