import React from "react";
import { inject, observer } from "mobx-react";
import ClickableTableFilter from "../common/ClickableTableFilter";
import { ProspectsConstants } from "../../constants/ProspectsConstants";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const Left = styled.div`
  float: left;
`;
class StatusFilters extends React.Component {
  filterTable = status => {
    return this.props.rootStore.prospectsStore.setCurrentStatusFilter(status);
  };

  constructor() {
    super();
    this.prospectsFilter = this.filterTable.bind(this, ProspectsConstants.PROSPECTS);
    this.checkedInFilter = this.filterTable.bind(this, ProspectsConstants.CHECKED_IN);
    this.approachingFilter = this.filterTable.bind(this, ProspectsConstants.APPROACHING);
    this.overdueFilter = this.filterTable.bind(this, ProspectsConstants.OVERDUE);
  }

  render() {
    const { prospectsStore } = this.props.rootStore;

    return (
      <Left>
        <ClickableTableFilter
          name={<FormattedMessage id="ProspectStatusFilters.prospects" defaultMessage="Prospects" />}
          count={prospectsStore.prospectsByOrg.length}
          filter={() => prospectsStore.setCurrentStatusFilter(ProspectsConstants.PROSPECTS)}
          isActive={ProspectsConstants.PROSPECTS === prospectsStore.currentStatusFilter}
        />
        <ClickableTableFilter
          name={<FormattedMessage id="ProspectStatusFilters.checkedIn" defaultMessage="Checked In" />}
          count={prospectsStore.checkedIn.length}
          filter={() => prospectsStore.setCurrentStatusFilter(ProspectsConstants.CHECKED_IN)}
          isActive={ProspectsConstants.CHECKED_IN === prospectsStore.currentStatusFilter}
        />
        <ClickableTableFilter
          name={<FormattedMessage id="ProspectStatusFilters.approaching" defaultMessage="Approaching" />}
          count={prospectsStore.approaching.length}
          filter={() => prospectsStore.setCurrentStatusFilter(ProspectsConstants.APPROACHING)}
          isActive={ProspectsConstants.APPROACHING === prospectsStore.currentStatusFilter}
        />
        <ClickableTableFilter
          name={<FormattedMessage id="ProspectStatusFilters.overdue" defaultMessage="Overdue" />}
          count={prospectsStore.overdue.length}
          filter={() => prospectsStore.setCurrentStatusFilter(ProspectsConstants.OVERDUE)}
          isActive={ProspectsConstants.OVERDUE === prospectsStore.currentStatusFilter}
        />
      </Left>
    );
  }
}

StatusFilters.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(StatusFilters));
