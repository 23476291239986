import React from "react";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import NewProspectHeader from "../components/newProspect/NewProspectHeader";
import NewProspectFooter from "../components/newProspect/NewProspectFooter";
import NewProspectForm from "../components/newProspect/NewProspectForm";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { intlShape, defineMessages } from "react-intl";
import { Helmet } from "react-helmet";

class NewProspectContainer extends React.Component {
  render() {
    const messages = defineMessages({
      title: {
        id: "NewProspectContainer.title",
        defaultMessage: "New Prospect"
      }
    });

    return (
      <div>
        <Helmet title={this.context.intl.formatMessage(messages.title)} />
        <div className={"container-fluid"}>
          <CardContainer>
            <CardHeader>
              <NewProspectHeader />
            </CardHeader>
            <CardBody>
              <NewProspectForm />
            </CardBody>
            <CardFooter>
              <NewProspectFooter />
            </CardFooter>
          </CardContainer>
        </div>
      </div>
    );
  }
}

NewProspectContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

NewProspectContainer.contextTypes = {
  intl: intlShape
};

export default inject("rootStore")(observer(NewProspectContainer));
