import React from "react";
import * as OktaSignIn from "@okta/okta-signin-widget/dist/js/okta-sign-in-no-jquery";
import styled from "styled-components";
import bg from "../../assets/login/bg.png";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import "@okta/okta-signin-widget/dist/css/okta-theme.css";
import "../../styles/okta-login-overrides.css";

const environment = process.env.REACT_APP_ENV;

const LoginRoot = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
`;

const LoginBG = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
`;

const LoginWrapper = styled.div.attrs(() => ({
  className: "d-flex align-items-center justify-content-center"
}))`
  height: calc(100vh - 30px);
`;

class Login extends React.Component {
  constructor(props) {
    let added = false;
    super(props);

    document.title = "EBIS AMT";

    this.signIn = new OktaSignIn({
      baseUrl: process.env.REACT_APP_OIDC_ISSUER.split("/oauth2")[0],
      clientId: process.env.REACT_APP_OIDC_CLIENTID,
      redirectUri: window.location.origin + "/implicit/callback",
      logo: "/ebis-login-logo@2x.png",
      authParams: {
        issuer: process.env.REACT_APP_OIDC_ISSUER,
        responseType: ["id_token", "token"],
        display: "page",
        scopes: process.env.REACT_APP_OIDC_SCOPE.split(" ")
      }
    });
    this.signIn.on("pageRendered", function(data) {
      if (!added) {
        added = true;
        const accent = document.createElement("div");
        const container = document.createElement("div");
        const title = document.createElement("div");
        const line = document.createElement("div");
        const subTitle = document.createElement("div");
        const env = document.createElement("div");
        const link = document.createElement("a");

        link.innerText = "Register New User";
        link.href = process.env.REACT_APP_REGISTER_URL;
        link.className = "link";
        link.target = "_blank";
        link.style = "padding-bottom: 14px;display:block";

        container.id = "amt-title-container";

        title.innerText = "AMT";
        title.id = "amt-title";

        if (environment === "stg") {
          accent.id = "accent-stg";
          env.id = "environment-stg";
        } else {
          accent.id = "accent-dev";
          env.id = "environment-dev";
        }

        env.innerHTML = environment;

        subTitle.id = "amt-subtitle";
        subTitle.innerHTML = "Academy Management Tool";

        container.appendChild(title);
        container.appendChild(line);
        container.appendChild(subTitle);
        document.getElementsByClassName("okta-sign-in-header auth-header")[0].appendChild(container);
        if (environment !== "prod" && environment !== "production") {
          let mainContainer = document.getElementsByClassName("auth-container main-container no-beacon")[0];
          document.getElementsByClassName("auth-container main-container no-beacon")[0].prepend(accent);
          mainContainer.insertBefore(env, mainContainer.childNodes[3]);
        }

        document.getElementsByClassName("auth-footer")[0].prepend(link);
      }
    });
  }
  componentDidMount() {
    this.signIn.renderEl(
      { el: "#sign-in-widget" },
      () => {
        /**
         * In this flow, the success handler will not be called beacuse we redirect
         * to the Okta org for the authentication workflow.
         */
      },
      err => {
        console.log(err);
      }
    );
  }
  render() {
    return (
      <LoginRoot>
        <LoginBG />
        <LoginWrapper>
          <div id="sign-in-widget" />
        </LoginWrapper>
      </LoginRoot>
    );
  }
}

export default Login;
