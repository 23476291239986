import React from "react";
import { LightButton, Modal, ModalBody, ModalHeaderWithTitleAndClose } from "best-common-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import defaultPhoto from "../../assets/image/default-prospect-photo.png";

const ModalFooterDiv = styled.div.attrs({
  id: "modal-footer"
})`
  justify-content: space-between;
  background-color: #ececec;
  padding: 16px;
`;

ModalFooterDiv.displayName = "Footer";

const FooterButtons = styled.div`
  float: right;
  button:not(:last-child) {
    margin-right: 0.625rem;
  }
`;

const Photo = styled.div`
  background-color: initial;
  text-align: center;
  overflow: hidden;
  border: 0;
  width: 100%;
  margin: auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

class ProspectPhotoModal extends React.Component {
  render() {
    const { show, close } = this.props;
    const title = <FormattedMessage id="ProspectPhotoModal.title" defaultMessage="Check In Photo" />;
    const { checkInHistoryStore } = this.props.rootStore;
    return (
      <div>
        <Modal
          show={show}
          onClose={() => {
            console.log("closed");
          }}
          size="lg"
        >
          <ModalHeaderWithTitleAndClose title={title} close={close} />
          <ModalBody>
            <Photo>
              {checkInHistoryStore.selectedAmtProspectCheckInPhoto !== null ? (
                <img src={checkInHistoryStore.selectedAmtProspectCheckInPhoto} alt="" />
              ) : (
                <img src={defaultPhoto} alt="" />
              )}
            </Photo>
          </ModalBody>
          <ModalFooterDiv>
            <FooterButtons>
              <LightButton onClick={close}>
                <FormattedMessage id="ProspectPhotoModal.close" defaultMessage="Close" />
              </LightButton>
            </FooterButtons>
          </ModalFooterDiv>
        </Modal>
      </div>
    );
  }
}

ProspectPhotoModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  show: PropTypes.bool,
  close: PropTypes.func.isRequired
};

export default inject("rootStore")(observer(ProspectPhotoModal));
