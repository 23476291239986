import React from "react";
import { Accordion, BestLabel } from "best-common-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import DateFormatter from "../utilities/DateFormatter";
import ProspectLinkFormatter from "../utilities/ProspectLinkFormatter";

const Wrapper = styled.div`
  > div:first-child {
    margin-top: 0px !important;
  }
`;

const Card = styled.span`
  font-weight: 400;
`;

const RowWrapper = styled.span`
  display: flex;
`;

const NameColumn = styled.span`
  flex: 33%;
`;

const PrimaryInfo = styled.div`
  color: black;
  font-size: 14px;
`;

const SecondaryInfo = styled.div`
  color: ${props => props.theme["dark-grey"]};
  font-size: 12px;
`;

class CurrentCheckInTableMobile extends React.Component {
  render() {
    const { data } = this.props;
    const prospectValues = {
      amtProspectId: data.amtProspectId,
      firstName: data.firstName,
      lastName: data.lastName
    };
    return (
      <div>
        <Wrapper>
          <Accordion>
            <Accordion.Header isToggle={true}>
              <Card>
                <RowWrapper>
                  <NameColumn>
                    <PrimaryInfo>
                      <ProspectLinkFormatter value={data.lastName} row={prospectValues} />
                    </PrimaryInfo>
                    <SecondaryInfo>{data.extendedLastName}</SecondaryInfo>
                  </NameColumn>
                  <NameColumn>
                    <PrimaryInfo>
                      <ProspectLinkFormatter value={data.firstName} row={prospectValues} />
                    </PrimaryInfo>
                    <SecondaryInfo>{data.middleName}</SecondaryInfo>
                  </NameColumn>
                </RowWrapper>
              </Card>
            </Accordion.Header>
            <Accordion.Body>
              <div>
                <BestLabel>
                  <FormattedMessage id="CurrentCheckInTableMobile.lastCheckedIn" defaultMessage="Last Checked In" />
                </BestLabel>
                <div>
                  <DateFormatter value={data.lastCheckInTs} />
                </div>
              </div>
              <div>
                <BestLabel>
                  <FormattedMessage id="CurrentCheckInTableMobile.location" defaultMessage="Location" />
                </BestLabel>
                <div>{data.location}</div>
              </div>
            </Accordion.Body>
          </Accordion>
        </Wrapper>
      </div>
    );
  }
}

CurrentCheckInTableMobile.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CurrentCheckInTableMobile));
