import React from "react";
import { inject, observer } from "mobx-react";
import styled, { withTheme } from "styled-components";
import { DropdownItem, NavBar, NavBarSignout, NavbarUsernameDropdown, Navigation } from "best-common-react";
import navlogo from "../../assets/logo/ebis-app-logo@2x.png";
import NavigationLinks from "./NavigationLinks";
import LanguageSelector from "../translations/LanguageSelector";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import "../../styles/react-common-overrides.css";
import Moment from "moment";

const NavBarSignoutLink = styled(DropdownItem)`
  z-index: 1051 !important;
`;

const NavContainer = styled.div`
  margin-bottom: 100px;
`;

const NameDiv = styled.div`
  width: 90px;
  height: 34px;
  background-color: #071f40;
  position: relative;
  left: -35px;
`;

const Name = styled.div`
  width: 67px;
  height: 30px;
  font-family: OpenSans, sans-serif !important;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2.5px;
  text-align: right;
  color: #ffffff;
  margin: 0 auto;
  padding-top: 1px;
`;

const Line = styled.div`
  width: auto;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to left, #c60a0a, #002e6d);
  border-image-slice: 1;
`;

const AmtNavigation = ({ rootStore: { authStore, routerStore, lookupStore }, theme }) => {
  const signOut = <FormattedMessage id="Navigation.signOut" defaultMessage="Sign Out" />;

  let username = authStore.userData ? authStore.userData.preferred_username : "";
  let gitInfo = process.env.REACT_APP_GIT;

  if (gitInfo === undefined) gitInfo = "";

  let builds = [{ name: "frontend", build: gitInfo.indexOf(" -> ") > 0 ? gitInfo.split(" -> ")[1] : gitInfo }];

  lookupStore.getBackendVersion().then(response => {
    let gitInfo;
    let buildVersion;
    let buildTime;
    if (response.hasOwnProperty("build")) {
      if (response.build.hasOwnProperty("version")) {
        buildVersion = `${response.build.version}`;
      }
      if (response.build.hasOwnProperty("time")) {
        buildTime = `${response.build.time}`;
      }
    }
    if (response.hasOwnProperty("git") && response.git.hasOwnProperty("commit")) {
      gitInfo = `${response.git.branch}-${response.git.commit.id}`;
    }

    const backendInfo = [buildVersion, buildTime, gitInfo].join(" | ");
    if (backendInfo) {
      builds.push({ name: "backend", build: backendInfo });
    }
  });

  const env = process.env.REACT_APP_ENV;

  return (
    <NavContainer>
      <NavBar env={env} displayEnvIndicator={!routerStore.isLoginTab}>
        <NavBarSignout env={env} loggedIn={authStore.loggedIn}>
          <LanguageSelector />
          <NavbarUsernameDropdown username={username} color={theme["mlb-blue"]}>
            <NavBarSignoutLink onClick={authStore.logout}>{signOut}</NavBarSignoutLink>
          </NavbarUsernameDropdown>
        </NavBarSignout>
        <Navigation
          loggedIn={authStore.loggedIn}
          isLoginTab={routerStore.isLoginTab}
          logo={navlogo}
          mobileNavOpen={authStore.mobileNavOpen}
          toggleMobileNav={authStore.setMobileNavOpen}
          builds={builds}
          tabletSupport={true}
        >
          <NameDiv>
            <Line />
            <Name>AMT</Name>
            <Line />
          </NameDiv>
          <NavigationLinks signOut={signOut} />
        </Navigation>
      </NavBar>
    </NavContainer>
  );
};

AmtNavigation.propTypes = {
  rootStore: PropTypes.object.isRequired,
  theme: PropTypes.object
};

export default inject("rootStore")(withTheme(observer(AmtNavigation)));
