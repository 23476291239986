import React from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  PrimaryButton,
  StickyFooterButtons
} from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const ConfirmMessage = styled.div.attrs(() => ({
  className: "mb-3"
}))`
  font-size: 1.125rem;
  font-weight: 300;
`;

const confirmationText = (
  <FormattedMessage id="DeleteModal.confirmationText" defaultMessage="Are you sure you want to delete this Form?" />
);
const permanentActionText = (
  <FormattedMessage
    id="DeleteModal.permanentActionText"
    defaultMessage="This is a permanent action that cannot be undone."
  />
);
const yesText = <FormattedMessage id="DeleteModal.yes" defaultMessage="Yes" />;

class DeleteModal extends React.Component {
  render() {
    const { travelAuthFormStore } = this.props.rootStore;

    return (
      <div>
        <Modal show={this.props.show} onClose={() => {}} size="lg">
          <ModalHeaderWithTitleAndClose
            title="Warning"
            close={() => {
              travelAuthFormStore.setShowDeleteModal(false);
            }}
          />
          <ModalBody>
            <div>
              <ConfirmMessage>{confirmationText}</ConfirmMessage>
              <div>{permanentActionText}</div>
            </div>
          </ModalBody>
          <ModalFooter>
            <StickyFooterButtons>
              <PrimaryButton onClick={travelAuthFormStore.delete}>{yesText}</PrimaryButton>
              <LightButton onClick={() => travelAuthFormStore.setShowDeleteModal(false)}>No</LightButton>
            </StickyFooterButtons>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

DeleteModal.defaultProps = {
  show: false
};

DeleteModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  show: PropTypes.bool
};

export default inject("rootStore")(observer(DeleteModal));
