import { action, computed, decorate, extendObservable } from "mobx";
import { autorun } from "mobx";
import { saveAs } from "file-saver";
import WidgetStore from "../WidgetStore";
import _ from "lodash";
import { AlertConstants } from "../../constants/AlertConstants";
import { TravelFormConstants } from "../../constants/TravelFormConstants";

/**
 * This store will house all logic related to the
 * travelForms page (/travelForms)
 *
 * We are using lodash for its filter, sorting, and
 * searching functionality, why reinvent the wheel?
 * Also, we are using moment to facilitate date
 * management
 */

class TravelAuthFormWidgetStore extends WidgetStore {
  constructor(commonStore) {
    super();
    this.authStore = commonStore.authStore;
    this.amtApi = commonStore.amtApi;
    this.routerStore = commonStore.routerStore;
    this.alertStore = commonStore.alertStore;

    // Register functions for use in external modules
    this.filterBySearchTerm = this.filterBySearchTerm.bind(this);
    this.filterByStatus = this.filterByStatus.bind(this);
    this.filterByOrg = this.filterByOrg.bind(this);

    this.defaults = {
      // Data necessary
      travelForms: [],
      selectedFormIds: [],
      preliminaryTravelForms: [],
      submittedTravelForms: [],

      // Fields on page
      searchTerm: "",
      status: { value: 1, label: "Submitted" },
      org: { value: -1, label: "All" },

      // Misc
      sortFilters: {
        direction: "DESC",
        key: "submittedTs"
      },
      statusDropDownList: [
        { value: -1, label: "All", pattern: "" },
        { value: 0, label: "Preliminary", pattern: "Eligible to Sign" },
        { value: 1, label: "Submitted", pattern: "Submitted" },
        { value: 2, label: "Approved", pattern: "Approved" },
        { value: 3, label: "Rejected", pattern: "Rejected" }
      ],
      orgDropDownList: []
    };

    extendObservable(this, {
      travelForms: this.defaults["travelForms"],
      selectedFormIds: this.defaults["selectedFormIds"],
      preliminaryTravelForms: this.defaults["preliminaryTravelForms"],
      submittedTravelForms: this.defaults["submittedTravelForms"],
      searchTerm: this.defaults["searchTerm"],
      status: this.defaults["status"],
      sortFilters: this.defaults["sortFilters"],
      org: this.defaults.org,
      setTravelForms: action(travelForms => {
        this.travelForms = travelForms;
      }),
      setStatus: action(status => {
        this.status = status;
      }),
      setSearchTerm: action(searchTerm => {
        this.searchTerm = searchTerm;
      }),
      clearSearchTerm: action(() => {
        this.searchTerm = "";
      }),
      setSortDirection: action((sortColumn, sortDirection) => {
        this.sortFilters.key = sortColumn;
        this.sortFilters.direction = sortDirection;
      }),
      setOrg: action(selectedOrg => {
        this.org = selectedOrg;
      }),
      resetStore: action(() => {
        this.travelForms = this.defaults["travelForms"];
        this.selectedFormIds = this.defaults["selectedFormIds"];
        this.searchTerm = this.defaults["searchTerm"];
        this.sortFilters = this.defaults["sortFilters"];
        // this.statusDropDownList = this.defaults["statusDropDownList"];
      }),
      exportTravelFormsTable: action(() => {
        let params = {};

        if (this.status.label !== "All") {
          params.status = this.status.label;
        }

        if (this.org.value > 0) {
          params.orgId = this.org.value;
        }

        if (this.searchTerm !== "") {
          params.searchTerm = this.searchTerm;
        }

        if (this.sortFilters.direction !== "NONE") {
          params.sortDirection = this.sortFilters.direction;
        }

        params.sortKeys = this.sortFilters.key.toString();

        this.amtApi.exportTravelFormTable(params).then(file => {
          saveAs(file, "travelForms.xls");
        });
      }),
      addSelectedForms: action(rows => {
        rows.forEach(row => {
          if (this.showCheckBox(row.status)) {
            this.selectedFormIds.push(row.amtTravelAuthFormId);
            if (row.status === TravelFormConstants.PRELIMINARY) {
              this.preliminaryTravelForms.push(row);
            }
            if (row.status === TravelFormConstants.SUBMITTED) {
              this.submittedTravelForms.push(row);
            }
          }
        });
      }),
      removeSelectedForms: action(rows => {
        let formIdsToRemove = rows.map(row => row.amtTravelAuthFormId);
        // Remove any selected preliminary forms
        this.preliminaryTravelForms = this.preliminaryTravelForms.filter(form => {
          return !formIdsToRemove.includes(form.amtTravelAuthFormId);
        });
        // Remove any selected submitted forms
        this.submittedTravelForms = this.submittedTravelForms.filter(form => {
          return !formIdsToRemove.includes(form.amtTravelAuthFormId);
        });
        // Remove any selected forms
        this.selectedFormIds = _.difference(this.selectedFormIds, formIdsToRemove);
      }),
      resetSelectedForms: action(() => {
        this.selectedFormIds = [];
        this.preliminaryTravelForms = [];
        this.submittedTravelForms = [];
      })
    });

    autorun(() => {
      if (this.routerStore.isTravelFormsTab && this.authStore.loggedIn) {
        this.updateTravelForms();
      } else {
        this.resetStore();
      }
    });
  }

  get preliminaryFormSelected() {
    return this.preliminaryTravelForms.length > 0;
  }

  get submittedFormSelected() {
    return this.submittedTravelForms.length > 0;
  }

  /*
   * This method checks if the row should have checkbox behavior.
   */
  showCheckBox(status) {
    let showCheckBoxOnStatuses = [TravelFormConstants.PRELIMINARY];
    if (this.authStore.loggedIn && this.authStore.isBOC) {
      showCheckBoxOnStatuses.push(TravelFormConstants.SUBMITTED);
    }
    if (!!status) {
      return showCheckBoxOnStatuses.includes(status);
    }
    return false;
  }
  /**
   * Gets called from within autorun (upon component render)
   * and makes call to backend (using amtApi) to get travelForms
   */
  updateTravelForms() {
    this.resetSelectedForms();

    this.amtApi.getTravelAuthForms().then(response => {
      if (!!response) {
        this.setTravelForms(response);
      }
    });
  }

  get displayedTravelForms() {
    if (!this.travelForms) {
      return [];
    }
    let toDisplay = this.travelForms.map(form => {
      return {
        amtProspectId: form.amtProspectId,
        amtTravelAuthFormId: form.amtTravelAuthFormId,
        isSelected: _.includes(this.selectedFormIds, form.amtTravelAuthFormId),
        arrivalTs: form.arrivalTs,
        departureTs: form.departureTs,
        extendedLastName: form.extendedLastName,
        firstName: form.firstName,
        lastName: form.lastName,
        middleName: form.middleName,
        orgCode: form.orgCode,
        orgId: form.orgId,
        prospectFullName: form.prospectFullName,
        status: form.status,
        submittedTs: form.submittedTs,
        travelExpenseTotal: form.travelExpenseTotal ? form.travelExpenseTotal : 0
      };
    });
    toDisplay = this.filterBySearchTerm(toDisplay, this.searchTerm);
    toDisplay = this.filterByStatus(toDisplay, this.status);
    toDisplay = this.filterByOrg(toDisplay, this.org);
    return this.sort(toDisplay, this.sortFilters);
  }

  /**
   * Processes the dropdown values for the status
   * dropdown in the travelForms page. This method is
   * called as the "options" value for the dropdown. It
   * will return a list of dropdowns that have been
   * properly formatted and processed (if need be)
   *
   * PLEASE NOTE: This might not need to be computed
   * since it (might) be static.
   */

  get statusDropDownList() {
    const isBOC = this.authStore.isBOC;
    return this.defaults.statusDropDownList.filter(status => {
      if (!isBOC || status.label !== "Preliminary") {
        return {
          value: status.value,
          label: status.label
        };
      }
      return undefined;
    });
  }

  /**
   * Function to filter data based on input search
   * term and whether the travelForms have given search term
   */

  filterBySearchTerm(travelForms, searchTerm) {
    if (searchTerm.length <= 0) {
      return travelForms;
    } else {
      return _.filter(travelForms, prospect => {
        return (
          this.searchTermInField(prospect.firstName, searchTerm) ||
          this.searchTermInField(prospect.lastName, searchTerm) ||
          // Need the clause where they enter the full name
          this.searchTermInField(prospect.lastName + prospect.firstName, searchTerm)
        );
      });
    }
  }

  filterByStatus(travelForms, status) {
    if (!travelForms) {
      return [];
    }
    if (status.label === "All") {
      return travelForms;
    }
    return travelForms.filter(travelForm => {
      return travelForm.status === status.label;
    });
  }

  filterByOrg(travelForms, org) {
    if (!travelForms) {
      return [];
    }
    if (org.label === "All") {
      return travelForms;
    }
    return travelForms.filter(travelForm => {
      return travelForm.orgId === parseInt(org.value, 10);
    });
  }

  submitTravelForms(preliminaryTravelForms) {
    this.amtApi.bulkUpdateTravelAuthFormState(preliminaryTravelForms, "SM").then(response => {
      if (response && response.successes && response.successes.length > 0) {
        this.updateTravelForms();
        this.showSuccess(response.successes.length + " Travel Authorization Form(s) Submitted");
      }
    });
  }

  approveTravelForms(submittedForms) {
    this.amtApi.bulkUpdateTravelAuthFormState(submittedForms, "AP").then(response => {
      if (response && response.successes && response.successes.length > 0) {
        this.updateTravelForms();
        this.showSuccess(response.successes.length + " Travel Authorization Form(s) Approved");
      }
    });
  }

  rejectTravelForms(submittedForms) {
    this.amtApi.bulkUpdateTravelAuthFormState(submittedForms, "RE").then(response => {
      if (response && response.successes.length > 0) {
        this.updateTravelForms();
        this.showSuccess(response.successes.length + " Travel Authorization Form(s) Rejected");
      }
    });
  }

  showSuccess(text) {
    this.alertStore.addAlert({
      type: AlertConstants.TYPES.SUCCESS,
      text: text
    });
  }

  sort(data, searchFilters) {
    let { direction, key } = searchFilters;
    const sorted = _.sortBy(
      data,
      item => item[key],
      item => -item.amtTravelAuthFormId
    );

    if (direction === "DESC") {
      return sorted.reverse();
    }

    return sorted;
  }
}

decorate(TravelAuthFormWidgetStore, {
  displayedTravelForms: computed,
  preliminaryFormSelected: computed,
  submittedFormSelected: computed,
  statusDropDownList: computed
});

export default TravelAuthFormWidgetStore;
