import { computed, decorate } from "mobx";
import { RouterStore } from "mobx-react-router";
import { RouteConstants } from "../constants/RouteConstants";

class AmtRouterStore extends RouterStore {
  previousPathName = "";
  truePreviousPathName = "";

  get isLoginTab() {
    return this.location.pathname === RouteConstants.LOGIN;
  }

  get isClubsTab() {
    return this.location.pathname === RouteConstants.CLUBS;
  }

  get isProspectsTab() {
    return this.location.pathname === RouteConstants.PROSPECTS;
  }

  get isProspectProfileTab() {
    return !!this.location.pathname.includes("/prospect/");
  }

  get isNewProspectTab() {
    return this.location.pathname === RouteConstants.NEW_PROSPECT;
  }

  get isTravelFormsTab() {
    return this.location.pathname === RouteConstants.TRAVEL_FORMS;
  }

  get isTravelAuthFormTab() {
    return !!this.location.pathname.includes("/travel-auth-form/");
  }

  get isHelpTab() {
    return this.location.pathname === RouteConstants.HELP;
  }

  getPathParams(path) {
    let pathSplit = path.split("/");
    let urlSplit = this.location.pathname.split("/");

    let values = {};
    pathSplit.forEach((i, index) => {
      if (i.includes(":")) {
        let key = i.substr(1);
        values[key] = urlSplit[index];
      }
    });
    return values;
  }

  refresh() {
    this.truePreviousPathName = this.location.pathname;
    this.history.replace(this.location.pathname);
  }

  pushHistory(path, replace) {
    if (path !== this.location.pathname) {
      // Moving to a different url
      // Track the current url as previousPathName
      // and move to the new one
      this.previousPathName = this.location.pathname;
    }
    this.truePreviousPathName = this.location.pathname;

    if (replace) {
      this.history.replace(path);
    } else {
      this.history.push(path);
    }
  }

  goToTravelForm(travelAuthFormId, replace) {
    this.pushHistory(`/travel-auth-form/${travelAuthFormId}`, replace);
  }

  goToProspect(prospectId, replace) {
    this.pushHistory(`/prospect/${prospectId}`, replace);
  }
}

decorate(AmtRouterStore, {
  isLoginTab: computed,
  isProspectsTab: computed,
  isProspectProfileTab: computed,
  isNewProspectTab: computed,
  isTravelFormsTab: computed,
  isHelpTab: computed
});

export default AmtRouterStore;
