import React from "react";
import ReactDOM from "react-dom";
import { AccordionTableWrapper, BestSelect, Input, MobileView, DesktopTabletView } from "best-common-react";
import { inject, observer } from "mobx-react";
import CheckInHistoryTableMobile from "./CheckInHistoryTableMobile";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Moment from "moment";
import { Icon } from "best-common-react";
import styled from "styled-components";
import PhotoFormatter from "../../utilities/PhotoFormatter";
import CheckInPhotoUploadFormatter from "./CheckInPhotoUploadFormatter";
import { CommonConstants } from "../../../constants/CommonConstants";
import CommentsBubble from "../../common/CommentsBubble";
import CheckInDate from "./CheckInDate";

const club = <FormattedMessage id="CheckInHistoryTable.club" defaultMessage="Club" />,
  checkInLocation = <FormattedMessage id="CheckInHistoryTable.checkInLocation" defaultMessage="Check In Location" />,
  checkInDate = <FormattedMessage id="CheckInHistoryTable.checkInDate" defaultMessage="Check In Date" />,
  checkOutDate = <FormattedMessage id="CheckInHistoryTable.checkOutDate" defaultMessage="Check Out Date" />,
  days = <FormattedMessage id="CheckInHistoryTable.days" defaultMessage="Days" />,
  country = <FormattedMessage id="CheckInHistoryTable.otherCountryId" defaultMessage="Country" />,
  comments = <FormattedMessage id="CheckInHistoryTable.locationNote" defaultMessage="Location Comments" />;

const CheckInWrapper = styled.div.attrs({
  className: "row"
})`
  & #accordion-table-wrapper {
    .react-grid-Canvas {
      .react-grid-Cell {
        background-color: #f7f7f7;
      }
    }
  }
`;

const StyledSelect = styled(BestSelect)`
  height: 32px;
  div {
    height: 32px;
    min-height: 32px;
    line-height: 2rem;
  }
`;

const CheckInHistoryTable = ({ rootStore: { authStore, lookupStore, checkInHistoryStore, prospectProfileStore } }) => {
  const getColumns = () => {
    const facilitiesForDropdown = lookupStore.getDropdownOptions(lookupStore.lookups.AMT_TRAINING_FACILITIES);
    const countriesForDropdown = lookupStore.getDropdownOptions(lookupStore.lookups.INT_PLAYER_COUNTRIES);

    const tableEl = document.getElementById("profile-container");
    const tablePortal = ({ children }) => ReactDOM.createPortal(children, tableEl);

    let commentsFormatter = ({ row, value }) => {
      if (checkInHistoryStore.showComments(row)) {
        if (checkInHistoryStore.editMode) {
          return (
            <Input
              className="form-control-sm"
              name="locationNote"
              value={value}
              onChange={event => {
                checkInHistoryStore.setHistoryRowProp(row.amtProspectCheckInId, "locationNote", event.target.value);
              }}
            />
          );
        } else {
          return <CommentsBubble title={comments} comments={value} placement="left" />;
        }
      } else {
        return null;
      }
    };

    let countryFormatter = ({ row, value }) => {
      if (checkInHistoryStore.isCountryOtherOffsite(row)) {
        if (authStore.isBOC && checkInHistoryStore.editMode) {
          return (
            <StyledSelect
              value={lookupStore.getDropdownItem(countriesForDropdown, value)}
              options={countriesForDropdown}
              onChange={country => {
                checkInHistoryStore.setHistoryRowProp(row.amtProspectCheckInId, "otherCountryId", country.value * 1);
              }}
              isDisabled={!checkInHistoryStore.editMode}
              portal={document.body}
            />
          );
        } else {
          return checkInHistoryStore.getCountry(value);
        }
      } else {
        return null;
      }
    };

    let facilityFormatter = ({ row, value }) => {
      if (authStore.isBOC && checkInHistoryStore.editMode) {
        return (
          <StyledSelect
            value={lookupStore.getDropdownItem(facilitiesForDropdown, value)}
            options={facilitiesForDropdown}
            onChange={facility => {
              checkInHistoryStore.setHistoryRowProp(row.amtProspectCheckInId, "trainingFacilityId", facility.value * 1);
            }}
            isDisabled={!checkInHistoryStore.editMode}
            portal={document.body}
          />
        );
      } else {
        return checkInHistoryStore.getTrainingFacilityLocation(value);
      }
    };

    let CheckedInTsFormatter = ({ row, value }) => {
      const editable = !!(authStore.isBOC && checkInHistoryStore.editMode);
      return (
        <CheckInDate
          row={row}
          value={value}
          editable={editable}
          portal={tablePortal}
          onChange={date =>
            checkInHistoryStore.handleCheckInHistoryDateChange(row.amtProspectCheckInId, "checkedInDate", date)
          }
          maxDate={row.checkedOutDate ? Moment(row.checkedOutDate).toDate() : null}
        />
      );
    };

    CheckedInTsFormatter.propTypes = {
      // The data-row for this checkin
      row: PropTypes.object.isRequired,
      // The value of the checkedoutts
      value: PropTypes.string
    };

    let CheckedOutTsFormatter = ({ row, value }) => {
      // Only allow BOC to edit checkout ts of entries that already have a checkout ts
      // Otherwise they can edit a check in that does not yet have a corresponding checkout
      const editable = !!(authStore.isBOC && checkInHistoryStore.editMode && row.checkedOutDate);
      return (
        <CheckInDate
          row={row}
          value={value}
          editable={editable}
          portal={tablePortal}
          onChange={date =>
            checkInHistoryStore.handleCheckInHistoryDateChange(row.amtProspectCheckInId, "checkedOutDate", date)
          }
          minDate={Moment(row.checkedInDate).toDate()}
          maxDate={Moment().toDate()}
        />
      );
    };

    CheckedOutTsFormatter.propTypes = {
      // The data-row for this checkin
      row: PropTypes.object.isRequired,
      // The value of the checkedoutts
      value: PropTypes.string
    };

    let photoUploadFormatter = ({ row, value }) => {
      if (checkInHistoryStore.showPhotoUpload(row)) {
        return (
          <CheckInPhotoUploadFormatter
            index={row.index}
            onChange={event => {
              checkInHistoryStore.setHistoryRowProp(row.amtProspectCheckInId, "photo", event);
            }}
          />
        );
      } else {
        return null;
      }
    };

    const checkInDeleteFormatter = ({ index, row }) => {
      if (authStore.isBOC && checkInHistoryStore.editMode) {
        const id = `check-in-delete-${index}`;
        return (
          <div
            onClick={() => {
              checkInHistoryStore.setOpenDeleteCheckInModal();
              checkInHistoryStore.setDeleteCheckInRow(row);
            }}
          >
            <Icon iconName="fa-trash-alt" className="fa-md" />
          </div>
        );
      } else {
        return null;
      }
    };

    let photoFormatter = ({ value, dependentValues }) => {
      return (
        <PhotoFormatter
          value={value}
          dependentValues={dependentValues}
          onClick={() => {
            prospectProfileStore.openProspectPhotoModal();
            checkInHistoryStore.setSelectedAmtProspectCheckInPhoto(dependentValues.amtProspectCheckInId);
          }}
        />
      );
    };

    // TODO: Why do we need this proptype validation and not others
    photoFormatter.propTypes = {
      value: PropTypes.any,
      dependentValues: PropTypes.any
    };

    let columns = [
      {
        key: "photo",
        name: "",
        width: 45,
        formatter: photoFormatter,
        getRowMetaData: row => row
      },
      {
        key: "photoUpload",
        name: "",
        width: 45,
        formatter: photoUploadFormatter
      },
      { key: "orgCode", name: club, width: 90, sortable: true },
      {
        key: "trainingFacilityId",
        name: checkInLocation,
        width: checkInHistoryStore.editMode ? 215 : 185,
        sortable: true,
        formatter: facilityFormatter
      },
      {
        key: "checkedInDate",
        name: checkInDate,
        width: checkInHistoryStore.editMode ? 180 : 160,
        sortable: true,
        formatter: CheckedInTsFormatter
      },
      {
        key: "checkedOutDate",
        name: checkOutDate,
        width: checkInHistoryStore.editMode ? 180 : 155,
        sortable: true,
        formatter: CheckedOutTsFormatter
      },
      { key: "days", name: days, sortable: true },
      {
        key: "otherCountryId",
        name: country,
        width: checkInHistoryStore.editMode ? 215 : 185,
        sortable: true,
        formatter: countryFormatter
      },
      { key: "locationNote", name: comments, width: 215, sortable: true, formatter: commentsFormatter },
      {
        key: "checkInDelete",
        name: "",
        width: 45,
        formatter: checkInDeleteFormatter
      }
    ];

    return columns;
  };

  // Render the table for desktop/tablet/mobile
  // rowHeight: The height of each individual row item (Different for desktop/tablet and mobile)
  // headerHeight: The size of the header row (non-existent for mobile)
  // maxHeight: The maximum size the table should become after which we start scrolling
  const getTable = (rowHeight = 35, headerHeight = 35, maxHeight) => {
    const rowCount = checkInHistoryStore.displayedHistory.length;
    const headerAndScrollbarHeight = headerHeight ? headerHeight + 15 : 0;
    const dynamicTableHeight = rowCount * rowHeight + headerAndScrollbarHeight;
    // If no max height is given, use a height that is calculated based on the number of rows and the height of each individual row
    // Otherwise choose the smaller of maxHeight and dynamicTableHeight, giving the table a maximum size.
    const tableHeight = !maxHeight ? dynamicTableHeight : Math.min(maxHeight, dynamicTableHeight);
    return (
      <AccordionTableWrapper
        data={checkInHistoryStore.displayedHistory}
        columns={getColumns()}
        sortFunc={checkInHistoryStore.setSortDirection}
        accordion={CheckInHistoryTableMobile}
        headerRowHeight={headerHeight}
        height={tableHeight}
        sortColumn={checkInHistoryStore.sortFilters.key}
        sortDirection={checkInHistoryStore.sortFilters.direction}
      />
    );
  };

  const mobileCardHeight = 94;
  return (
    <CheckInWrapper>
      <div className="col-12">
        <MobileView>{getTable(mobileCardHeight, 0)}</MobileView>
        <DesktopTabletView>{getTable(35, 35, 400)}</DesktopTabletView>
      </div>
    </CheckInWrapper>
  );
};

CheckInHistoryTable.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CheckInHistoryTable));
