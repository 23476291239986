import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ClickableStatus = styled.button.attrs({
  className: "btn btn-link"
})`
  text-decoration: none !important;
  padding: 0px;
`;

const ActiveMarker = styled.div`
  ${({ active }) =>
    active &&
    `
    background-color: #002e6d;

    `};
  height: 10px;
  width: 10px;
  margin-right: 5px;

  -moz-border-radius: 50%;
  border-radius: 50%;
`;

const FilterWrapper = styled.span`
  display: flex;
  align-items: center;
`;

class ClickableTableFilter extends React.Component {
  render() {
    return (
      <FilterWrapper>
        <ActiveMarker active={this.props.isActive} />
        <ClickableStatus onClick={this.props.filter}>
          {this.props.name} {this.props.count >= 0 ? "(" + this.props.count + ")" : ""}
        </ClickableStatus>
      </FilterWrapper>
    );
  }
}

ClickableTableFilter.propTypes = {
  filter: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  isActive: PropTypes.bool.isRequired
};

export default ClickableTableFilter;
