import React from "react";
import styled from "styled-components";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import PropTypes from "prop-types";

const NameWrapper = styled.span`
  color: ${props => props.theme["sky-blue"]};
`;

class ProspectName extends React.Component {
  getHighlighted(key) {
    const { suggestion, query } = this.props;

    const formattedQuery = query.replace(",", "");
    const matches = match(suggestion[key], formattedQuery);
    const parts = parse(suggestion[key], matches);
    return parts.map((part, index) => {
      return part.highlight ? (
        <strong key={String(index)}>{part.text}</strong>
      ) : (
        <span key={String(index)}>{part.text}</span>
      );
    });
  }

  render() {
    const { suggestion } = this.props;
    return (
      <NameWrapper>
        {this.getHighlighted("lastName")}
        <span>, </span>
        {this.getHighlighted("firstName")}
        {suggestion.middleName ? <span> {suggestion.middleName[0]}</span> : null}
      </NameWrapper>
    );
  }
}

ProspectName.propTypes = {
  suggestion: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired
};

export default ProspectName;
