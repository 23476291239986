import * as axios from "axios";

const SERVICE_URL = process.env.REACT_APP_SERVICES_URL;

const doRequest = (endpointUrl, method, body, headers, options, responseType, transformResponse) => {
  const url = `${SERVICE_URL}/${endpointUrl}`;

  return axios({
    url,
    method,
    data: body || undefined,
    headers: headers || {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    ...(options || {}),
    responseType,
    transformResponse
  });
};

export default class BaseApi {
  constructor() {
    if (new.target === BaseApi) {
      throw new TypeError("Cannot construct BaseApi instances directly");
    }
  }

  static get = (url, headers, options, responseType, transformResponse) =>
    doRequest(url, "get", null, headers, options, responseType, transformResponse);

  static post = (url, body, headers, options) => doRequest(url, "post", body, headers, options);

  static put = (url, body, headers) => doRequest(url, "put", body, headers);

  static patch = (url, body, headers) => doRequest(url, "patch", body, headers);

  static delete = (url, headers) => doRequest(url, "delete", null, headers);

  static json = url => axios.get(url);

  static download = (url, headers, options, responseType, transformResponse) =>
    BaseApi.get(url, headers, options, responseType || "blob", transformResponse);
}
