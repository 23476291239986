import React from "react";
import {
  LightButton,
  PrimaryButton,
  Modal,
  ModalBody,
  ModalHeaderWithTitleAndClose,
  ModalFooter,
  StickyFooterButtons
} from "best-common-react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

class DeleteProspectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0
    };
  }

  renderBody(step) {
    if (step === 0) {
      return (
        <React.Fragment>
          <div>
            <FormattedMessage
              id="DeleteProspectModal.body1"
              defaultMessage="You are attempting to delete this player from the AMT. All of the player's information will be lost and cannot be recovered. Would you like to continue?"
            />
          </div>
          <div className="hint-message">
            <FormattedMessage
              id="DeleteProspectModal.hint1"
              defaultMessage="This will not delete the player from EBIS."
            />
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div>
            <FormattedMessage
              id="DeleteProspectModal.body2"
              defaultMessage="Deleting this profile will PERMANENTLY remove the player from the system."
            />
          </div>
          <div className="hint-message">
            <FormattedMessage
              id="DeleteProspectModal.hint2"
              defaultMessage="This is a permanent action that cannot be undone."
            />
          </div>
        </React.Fragment>
      );
    }
  }

  deleteClickHandler(step) {
    const { prospectProfileStore } = this.props.rootStore;
    if (step === 1) {
      prospectProfileStore.deleteProspect();
    }
  }

  closeModal() {
    const { prospectProfileStore } = this.props.rootStore;
    prospectProfileStore.closeDeleteProspectModal();
    this.setState({ step: 0 });
  }

  render() {
    const { show } = this.props;

    const title = <FormattedMessage id="DeleteProspectModal.warning" defaultMessage="WARNING" />;

    return (
      <div>
        <Modal show={show} onClose={() => {}} size="lg">
          <ModalHeaderWithTitleAndClose title={title} close={() => this.closeModal()} />
          <ModalBody>{this.renderBody(this.state.step)}</ModalBody>
          <ModalFooter>
            <StickyFooterButtons>
              <PrimaryButton
                onClick={() => {
                  this.deleteClickHandler(this.state.step);
                  this.setState({ step: this.state.step + 1 });
                }}
              >
                <FormattedMessage id="DeleteProspectModal.deletePlayer" defaultMessage="Delete Player" />
              </PrimaryButton>
              <LightButton onClick={() => this.closeModal()}>
                <FormattedMessage id="DeleteProspectModal.cancel" defaultMessage="Cancel" />
              </LightButton>
            </StickyFooterButtons>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

DeleteProspectModal.defaultProps = {
  show: false
};

DeleteProspectModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  show: PropTypes.bool
};

export default inject("rootStore")(observer(DeleteProspectModal));
