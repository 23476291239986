import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { StickyFooterButtons, LightButton } from "best-common-react";
import { FormattedMessage } from "react-intl";
import CancelModal from "../common/CancelModal";

const HelpFooter = ({ enableEdit, isSaveEnabled, cancel, editMode, save }) => {
  const [showCancelModal, setShowCancelModal] = useState(false);

  const renderViewMode = () => {
    return (
      <LightButton onClick={enableEdit}>
        <FormattedMessage id="HelpFooter.edit" defaultMessage="Edit" />
      </LightButton>
    );
  };

  const renderEditMode = () => {
    return (
      <Fragment>
        <LightButton disabled={!isSaveEnabled} onClick={save}>
          <FormattedMessage id="HelpFooter.save" defaultMessage="Save" />
        </LightButton>
        <CancelModal
          show={showCancelModal}
          close={() => setShowCancelModal(false)}
          cancel={() => {
            cancel();
            setShowCancelModal(false);
          }}
          back={() => setShowCancelModal(false)}
        />
        <LightButton onClick={() => setShowCancelModal(true)}>
          <FormattedMessage id="HelpFooter.cancel" defaultMessage="Cancel" />
        </LightButton>
      </Fragment>
    );
  };

  return <StickyFooterButtons>{editMode ? renderEditMode() : renderViewMode()}</StickyFooterButtons>;
};

HelpFooter.propTypes = {
  enableEdit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  isSaveEnabled: PropTypes.bool.isRequired
};

export default HelpFooter;
