import { Component } from "react";
import { inject, observer } from "mobx-react";

import { withAuth } from "@okta/okta-react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

class OktaAuthHelper extends Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: null };
  }

  async componentDidMount() {
    this.checkAuthentication();
  }

  async componentDidUpdate() {
    this.checkAuthentication();
  }

  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    const { authStore } = this.props.rootStore;

    if (this.props.location.pathname !== "/implicit/callback" && authenticated !== this.state.authenticated) {
      if (authenticated) {
        authStore.checkLocalStorage();
        if (!authStore.loggedIn && !authStore.isLoggingIn) {
          authStore.login();
        }
      }
      this.setState({ authenticated });
    }
  }

  render() {
    return "";
  }
}

OktaAuthHelper.propTypes = {
  rootStore: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default inject("rootStore")(withRouter(withAuth(observer(OktaAuthHelper))));
