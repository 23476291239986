import React from "react";
import { inject, observer } from "mobx-react";
import { BestSelect, BestLabel } from "best-common-react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

class TravelFormStatusDropdown extends React.Component {
  render() {
    const { travelAuthFormWidgetStore } = this.props.rootStore;

    return (
      <div className="form-group">
        <BestLabel name="monthsFromSigning" label="Months from Signing">
          <FormattedMessage id="TravelFormStatusDropdown.status" defaultMessage="Status" />
        </BestLabel>
        <BestSelect
          name="Status"
          value={travelAuthFormWidgetStore.status}
          onChange={travelAuthFormWidgetStore.setStatus}
          options={travelAuthFormWidgetStore.statusDropDownList}
        />
      </div>
    );
  }
}

TravelFormStatusDropdown.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TravelFormStatusDropdown));
