import React from "react";
import { BestSelect } from "best-common-react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Moment from "moment";
import defaultPhoto from "../../../assets/image/default-prospect-photo.png";
import CheckInPhotoUploadFormatter from "./CheckInPhotoUploadFormatter";
import { CommonConstants } from "../../../constants/CommonConstants";
import CheckInDate from "./CheckInDate";

const Card = styled.div`
  border: solid 1px #d2d2d2;
  background-color: #f7f7f7;
  padding: 10px;
`;

const Wrapper = styled.span`
  display: flex;
`;

const ProspectImage = styled.div`
  margin-right: 10px;
  flex: 10%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const CheckInDetails = styled.div`
  flex: 90%;
  font-weight: 400;
`;

const formatDateStr = dateStr => {
  if (!dateStr) {
    return null;
  }
  return Moment(dateStr).format("MM/DD/YYYY");
};

class CheckInHistoryTableMobile extends React.Component {
  renderView(data, checkInHistoryStore) {
    return (
      <Card>
        <Wrapper>
          <ProspectImage>
            {data.photo !== "" && data.photo !== undefined ? (
              <img src={data.photo} alt="" />
            ) : (
              <img src={defaultPhoto} alt="" />
            )}
            {checkInHistoryStore.showPhotoUpload(data) && (
              <CheckInPhotoUploadFormatter
                index={data.index}
                onChange={event => {
                  checkInHistoryStore.setHistoryRowProp(data.amtProspectCheckInId, "photo", event);
                }}
              />
            )}
          </ProspectImage>
          <CheckInDetails>
            <div>{data.orgCode}</div>
            <div>{checkInHistoryStore.getTrainingFacilityLocation(data.trainingFacilityId)}</div>
            <div>
              {formatDateStr(data.checkedInDate)} - {formatDateStr(data.checkedOutDate)}
            </div>
          </CheckInDetails>
        </Wrapper>
      </Card>
    );
  }
  renderEdit(data, checkInHistoryStore) {
    const { lookupStore } = this.props.rootStore;
    const facilitiesForDropdown = lookupStore.getDropdownOptions(lookupStore.lookups.AMT_TRAINING_FACILITIES);
    return (
      <Card>
        <Wrapper>
          <ProspectImage>
            {data.photo !== "" && data.photo !== undefined ? (
              <img src={data.photo} alt="" />
            ) : (
              <img src={defaultPhoto} alt="" />
            )}
            {checkInHistoryStore.showPhotoUpload(data) && (
              <CheckInPhotoUploadFormatter
                index={data.index}
                onChange={event => {
                  checkInHistoryStore.setHistoryRowProp(data.amtProspectCheckInId, "photo", event);
                }}
              />
            )}
          </ProspectImage>
          <CheckInDetails>
            <div>{data.orgCode}</div>
            <div>
              <BestSelect
                value={lookupStore.getDropdownItem(facilitiesForDropdown, data.trainingFacilityId)}
                options={facilitiesForDropdown}
                onChange={country => {
                  checkInHistoryStore.setHistoryRowProp(
                    data.amtProspectCheckInId,
                    "trainingFacilityId",
                    country.value * 1
                  );
                }}
                isDisabled={!checkInHistoryStore.editMode}
              />
            </div>
            <div>
              <CheckInDate
                row={data}
                value={data.checkedInDate}
                editable
                onChange={date =>
                  checkInHistoryStore.handleCheckInHistoryDateChange(data.amtProspectCheckInId, "checkedInDate", date)
                }
                maxDate={data.checkedOutDate ? Moment(data.checkedOutDate).toDate() : null}
              />
              <div>-</div>
              <CheckInDate
                row={data}
                value={data.checkedOutDate}
                editable
                onChange={date =>
                  checkInHistoryStore.handleCheckInHistoryDateChange(data.amtProspectCheckInId, "checkedOutDate", date)
                }
                minDate={Moment(data.checkedInDate).toDate()}
                maxDate={Moment().toDate()}
              />
            </div>
          </CheckInDetails>
        </Wrapper>
      </Card>
    );
  }
  render() {
    const { data } = this.props;
    const { authStore, checkInHistoryStore } = this.props.rootStore;
    return authStore.isBOC && checkInHistoryStore.editMode
      ? this.renderEdit(data, checkInHistoryStore)
      : this.renderView(data, checkInHistoryStore);
  }
}

CheckInHistoryTableMobile.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CheckInHistoryTableMobile));
