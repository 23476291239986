import React from "react";
import ProspectTable from "../components/prospects/ProspectsTable";
import ProspectsHeader from "../components/prospects/ProspectsHeader";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { intlShape, defineMessages, FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import ProspectCheckOutModal from "../components/profile/checkOutModal/ProspectCheckOutModal";
import ProspectsFooter from "../components/prospects/ProspectsFooter";

class ProspectsContainer extends React.Component {
  render() {
    const { prospectsStore, checkOutModalStore } = this.props.rootStore;

    const messages = defineMessages({
      title: {
        id: "ProspectsContainer.title",
        defaultMessage: "Prospects"
      }
    });

    return (
      <div>
        <Helmet title={this.context.intl.formatMessage(messages.title)} />
        <div className={"container-fluid"}>
          <CardContainer>
            <CardHeader>
              <FormattedMessage id="ProspectsContainer.header" defaultMessage="Prospects this Period" /> (
              {prospectsStore.getPeriod()})
            </CardHeader>
            <CardBody>
              <ProspectCheckOutModal
                show={prospectsStore.showProspectCheckOutModal}
                close={prospectsStore.closeProspectCheckOutModal}
                checkOut={() => {
                  checkOutModalStore.checkOutProspects(prospectsStore.selectedProspects);
                  prospectsStore.closeProspectCheckOutModal();
                }}
                isMulti
                prospectCount={prospectsStore.selectedProspects.length}
              />
              <ProspectsHeader />
              <ProspectTable data={prospectsStore.displayedProspects} />
            </CardBody>
            <CardFooter>
              <ProspectsFooter />
            </CardFooter>
          </CardContainer>
        </div>
      </div>
    );
  }
}

ProspectsContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

ProspectsContainer.contextTypes = {
  intl: intlShape
};

export default inject("rootStore")(observer(ProspectsContainer));
