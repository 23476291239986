import * as React from "react";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import PropTypes from "prop-types";

const AppDisconnectedMessage = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f2f3f4;
  text-align: center;
  position: relative;
  z-index: 100;
`;

class HealthCheckErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.error) {
      return <AppDisconnectedMessage>{!!this.state.error.message && this.state.error.message}</AppDisconnectedMessage>;
    }
    // Normally, just render children
    return this.props.children;
  }
}

HealthCheckErrorBoundary.propTypes = {
  children: PropTypes.any
};

export default HealthCheckErrorBoundary;
