import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { observer } from "mobx-react";
import defaultPhoto from "../../assets/image/default-prospect-photo.png";

const Photo = styled.div`
  background-color: black;
  text-align: center;
  overflow: hidden;
  border: 2px solid ${props => props.theme["dark-grey"]};
  width: 100%;
  cursor: pointer;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const dependentValueProp = {
  photo: PropTypes.string,
  onClick: () => {}
};

class PhotoFormatter extends React.Component {
  static propTypes = {
    value: PropTypes.any,
    dependentValues: PropTypes.shape(dependentValueProp).isRequired,
    onClick: PropTypes.func
  };

  render() {
    const { dependentValues, onClick } = this.props;

    return (
      <Photo onClick={onClick}>
        {dependentValues.photo !== "" && dependentValues.photo !== undefined ? (
          <img src={dependentValues.photo} alt="" />
        ) : (
          <img src={defaultPhoto} alt="" />
        )}
      </Photo>
    );
  }
}

export default observer(PhotoFormatter);
