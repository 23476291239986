import axios from "axios/index";

/**
 * This is where the API endpoints
 * for ebis are stored.
 */

const SERVICES_URL = process.env.REACT_APP_SERVICES_URL;

class AmtApi {
  getClubs() {
    let url = SERVICES_URL + "/widget/clubs/";
    return axios.get(url).then(response => response.data);
  }
  getClubCheckIns(orgId) {
    let url = SERVICES_URL + "/widget/clubs/check-ins/" + orgId;
    return axios.get(url).then(response => response.data);
  }
  getProspects() {
    let url = SERVICES_URL + "/widget/prospect/";
    return axios.get(url).then(response => response.data);
  }
  getQuickSearchProspects() {
    let url = SERVICES_URL + "/prospect/quickSearch";
    return axios.get(url).then(response => response.data);
  }
  getProspect(prospectId) {
    let url = SERVICES_URL + "/prospect/" + prospectId;
    return axios.get(url).then(response => response.data);
  }
  saveProspect(prospect) {
    let url = SERVICES_URL + "/prospect/save";
    return axios
      .post(url, prospect)
      .then(response => response.data)
      .catch(error => error);
  }
  deleteProspect(prospectId) {
    let url = SERVICES_URL + `/prospect/delete/${prospectId}`;
    return axios
      .post(url, prospectId)
      .then(response => response.data)
      .catch(error => error);
  }
  getPotentialProspectMatches(prospect) {
    let url = SERVICES_URL + "/prospect/matches";
    return axios
      .post(url, prospect)
      .then(response => response.data)
      .catch(error => error);
  }
  addToProspectList(prospectId) {
    let url = SERVICES_URL + "/prospect/list/" + prospectId;
    return axios
      .post(url, prospectId)
      .then(response => response.data)
      .catch(error => error);
  }
  createProspectFromEbis(originId, prospectId) {
    let url = SERVICES_URL + "/prospect/createFromEbis/" + originId + "/" + prospectId;
    return axios.get(url).then(response => response.data);
  }
  getCheckins(prospectId) {
    let url = SERVICES_URL + "/checkin/table/" + prospectId;
    return axios.get(url).then(response => response.data);
  }
  saveCheckins(prospectId, checkIns) {
    let url = SERVICES_URL + "/checkin/table/" + prospectId;
    return axios
      .post(url, checkIns)
      .then(response => response.data)
      .catch(error => error);
  }
  getMostRecentCheckIn(prospectId) {
    let url = SERVICES_URL + "/checkin/prospect/" + prospectId + "/mostRecent";
    return axios.get(url).then(response => response.data);
  }
  canCheckIn(checkIn) {
    let url = SERVICES_URL + "/checkin/canCheckIn/";
    return axios
      .post(url, checkIn)
      .then(response => response.data)
      .catch(error => error);
  }
  saveCheckIn(checkIn) {
    let url = SERVICES_URL + "/checkin/save";
    return axios
      .post(url, checkIn)
      .then(response => response.data)
      .catch(error => error);
  }
  deleteCheckIn(checkIn) {
    let url = SERVICES_URL + "/checkin/delete";
    return axios
      .post(url, checkIn)
      .then(response => response.data)
      .catch(error => error);
  }
  checkOut(checkInId) {
    let url = SERVICES_URL + "/checkout/" + checkInId;
    return axios
      .post(url, checkInId)
      .then(response => response.data)
      .catch(error => error);
  }
  getLookupByTypeAndId(lookupType, lookupId) {
    let url = SERVICES_URL + "/lookup";
    const params = { lookupType, lookupId };
    return axios.get(url, { params }).then(response => response.data);
  }
  getLookup(lookupCode) {
    let url = SERVICES_URL + "/lookup/" + lookupCode;
    return axios.get(url).then(response => response.data);
  }
  getLookups() {
    let url = SERVICES_URL + "/lookup/all";
    return axios.get(url).then(response => response.data);
  }
  getCities(districtId) {
    let url = SERVICES_URL + "/lookup/cities/" + districtId;
    return axios.get(url).then(response => response.data);
  }
  getDistricts(countryId) {
    let url = SERVICES_URL + "/lookup/districts/" + countryId;
    return axios.get(url).then(response => response.data);
  }
  getSystemEvents() {
    let url = SERVICES_URL + "/systemEvent/all";
    return axios.get(url).then(response => response.data);
  }
  getTravelAuthForm(travelAuthFormId) {
    let url = SERVICES_URL + "/travelAuthForm/" + travelAuthFormId;
    return axios.get(url).then(response => response.data);
  }
  getTravelAuthFormHistory(prospectId) {
    let url = SERVICES_URL + "/travelAuthForm/history/" + prospectId;
    return axios.get(url).then(response => response.data);
  }
  saveTravelAuthForm(travelAuthForm) {
    let url = SERVICES_URL + "/travelAuthForm/save";
    return axios
      .post(url, travelAuthForm)
      .then(response => response.data)
      .catch(error => error);
  }
  updateTravelAuthFormState(travelAuthForm, targetStatus) {
    let url = SERVICES_URL + "/travelAuthForm/updateState/" + targetStatus;
    return axios
      .post(url, travelAuthForm)
      .then(response => response.data)
      .catch(error => error);
  }
  getTravelAuthCommentTemplate(commentToAdd) {
    let url = SERVICES_URL + "/travelAuthForm/comments/template";
    return axios
      .post(url, commentToAdd)
      .then(response => response.data)
      .catch(error => error);
  }
  saveTravelAuthComment(comment) {
    let url = SERVICES_URL + "/travelAuthForm/comments/save";
    return axios
      .post(url, comment)
      .then(response => response.data)
      .catch(error => error);
  }

  bulkUpdateTravelAuthFormState(travelAuthForms, targetStatus) {
    //This uses the AmtTravelAuthFormView model in the widget which is different from the AmtTravelAuthForm model used
    //everywhere else. Only uses this for the widget.
    let url = SERVICES_URL + "/widget/travelAuthForm/bulkUpdateState/" + targetStatus;
    return axios
      .post(url, travelAuthForms)
      .then(response => response.data)
      .catch(error => error);
  }
  deleteTravelAuthForm(travelAuthFormId) {
    let url = SERVICES_URL + "/travelAuthForm/delete/" + travelAuthFormId;
    return axios
      .delete(url, travelAuthFormId)
      .then(response => response.data)
      .catch(error => error);
  }
  getTravelAuthForms() {
    let url = SERVICES_URL + "/widget/travelAuthForm";
    return axios.get(url).then(response => response.data);
  }
  exportTravelFormTable(params) {
    let url = SERVICES_URL + "/widget/travelAuthForm/export";
    return axios
      .get(url, {
        params: params,
        responseType: "blob",
        headers: {
          Accept: "application/vnd.ms-excel"
        }
      })
      .then(response => response.data);
  }
  exportProspectTable(params) {
    let url = SERVICES_URL + "/widget/prospect/export";
    return axios
      .get(url, {
        params: params,
        responseType: "blob",
        headers: {
          Accept: "application/vnd.ms-excel"
        }
      })
      .then(response => response.data);
  }
  exportClubTable(params) {
    let url = SERVICES_URL + "/widget/clubs/export";
    return axios
      .get(url, {
        params: params,
        responseType: "blob",
        headers: {
          Accept: "application/vnd.ms-excel"
        }
      })
      .then(response => response.data);
  }
  getBackEndVersion() {
    const url = SERVICES_URL + "/actuator/info";
    return axios.get(url).then(response => response.data);
  }
  getCheckInPhoto(checkInPhotoId) {
    const url = SERVICES_URL + "/checkin/photo/" + checkInPhotoId;
    return axios.get(url, { responseType: "arraybuffer" }).then(response => {
      try {
        return `data:${response.headers["content-type"]};base64,${btoa(
          new Uint8Array(response.data).reduce(function(data, byte) {
            return data + String.fromCharCode(byte);
          }, "")
        )}`;
      } catch (e) {
        console.error(e);
      }
    });
  }
  getCheckInThumbnail(checkInPhotoId) {
    const url = SERVICES_URL + "/checkin/thumbnail/" + checkInPhotoId;
    return axios.get(url, { responseType: "arraybuffer" }).then(response => {
      try {
        return `data:${response.headers["content-type"]};base64,${btoa(
          new Uint8Array(response.data).reduce(function(data, byte) {
            return data + String.fromCharCode(byte);
          }, "")
        )}`;
      } catch (e) {
        console.error(e);
      }
    });
  }
  getFile(fileIdentifier) {
    let url = SERVICES_URL + "/file/" + fileIdentifier;
    return axios.get(url, { responseType: "arraybuffer" }).then(response => response.data);
  }
  getFileById(fileAttachmentId) {
    let url = SERVICES_URL + "/file/id/" + fileAttachmentId;
    return axios.get(url, { responseType: "arraybuffer" }).then(response => response.data);
  }
  uploadFile(file) {
    let url = SERVICES_URL + "/file/upload";
    return axios
      .post(url, file, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => response.data)
      .catch(error => error);
  }
  contact(subject, message) {
    let url = SERVICES_URL + "/help/contact";
    return axios
      .post(url, { subject, message })
      .then(response => response.data)
      .catch(error => error);
  }

  getAllHelpDocuments() {
    let url = SERVICES_URL + "/help/document/all";
    return axios.get(url).then(response => response.data);
  }

  saveHelpDocuments(documents) {
    let url = SERVICES_URL + "/help/save";
    return axios
      .post(url, documents)
      .then(response => response.data)
      .catch(error => error);
  }
}

export default AmtApi;
