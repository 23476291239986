class CommonStore {
  constructor(authStore, amtApi, lookupStore, alertStore, errorWarningStore, routerStore, systemEventStore) {
    this.authStore = authStore;
    this.amtApi = amtApi;
    this.lookupStore = lookupStore;
    this.alertStore = alertStore;
    this.errorWarningStore = errorWarningStore;
    this.routerStore = routerStore;
    this.systemEventStore = systemEventStore;
  }
}

export default CommonStore;
