import React from "react";
import PropTypes from "prop-types";
import { BestLabel, LightButton, PrimaryButton } from "best-common-react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import DateFormatter from "../utilities/DateFormatter";

const Item = styled.div`
  margin-bottom: 0.625rem;
`;

const ButtonWrapper = styled.div.attrs(() => ({
  className: "d-flex justify-content-center"
}))`
  button:not(:last-child) {
    margin-right: 0.625rem;
  }
`;

const Footer = styled.div`
  border-top: 1px solid ${props => props.theme["grey"]};
  padding-top: 0.625rem;
`;

class ProspectsMobileContent extends React.Component {
  checkOut(prospect) {
    const { prospectProfileStore, routerStore } = this.props.rootStore;
    const { prospectId } = prospect;
    routerStore.goToProspect(prospectId);
    prospectProfileStore.openProspectCheckOutModal();
  }
  checkIn(prospect) {
    const { checkInModalStore, routerStore } = this.props.rootStore;
    const { prospectId } = prospect;
    routerStore.goToProspect(prospectId);
    checkInModalStore.canCheckIn();
  }
  render() {
    const { prospect } = this.props;
    const { routerStore, authStore } = this.props.rootStore;

    return (
      <div>
        <Item>
          <BestLabel>
            <FormattedMessage id="ProspectsMobileContent.birthdate" defaultMessage="Birth Date" />
          </BestLabel>
          <div>
            <DateFormatter value={prospect.dateOfBirth} /> - {prospect.countryOfBirth}
          </div>
        </Item>
        <Item>
          <BestLabel>
            <FormattedMessage id="ProspectsMobileContent.lastCheckIn" defaultMessage="Last Check In" />
          </BestLabel>
          <div>
            <DateFormatter value={prospect.lastCheckIn} /> - {prospect.location}
          </div>
        </Item>
        <Item>
          <BestLabel>
            <FormattedMessage id="ProspectsMobileContent.checkInAllowed" defaultMessage="Next Check In Allowed" />
          </BestLabel>
          <div>
            <DateFormatter value={prospect.nextCheckInAllowed} />
          </div>
        </Item>
        <Item>
          <BestLabel>
            <FormattedMessage id="ProspectsMobileContent.monthsFromSigning" defaultMessage="Months From Signing" />
          </BestLabel>
          <div>{prospect.monthsFromSigning}</div>
        </Item>

        <Footer>
          <ButtonWrapper>
            {prospect.isCheckedIn && authStore.isAdmin ? (
              <PrimaryButton onClick={() => this.checkOut(prospect)}>
                <FormattedMessage id="ProspectsMobileContent.checkout" defaultMessage="Check Out" />
              </PrimaryButton>
            ) : (
              ""
            )}
            {!prospect.isCheckedIn && authStore.isAdmin ? (
              <PrimaryButton onClick={() => this.checkIn(prospect)}>
                <FormattedMessage id="ProspectsMobileContent.checkin" defaultMessage="Check In" />
              </PrimaryButton>
            ) : (
              ""
            )}
            <LightButton onClick={() => routerStore.goToProspect(prospect.prospectId)}>
              <FormattedMessage id="ProspectsMobileContent.viewProfile" defaultMessage="View Profile" />
            </LightButton>
          </ButtonWrapper>
        </Footer>
      </div>
    );
  }
}

ProspectsMobileContent.propTypes = {
  prospect: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ProspectsMobileContent));
