import React from "react";
import { inject, observer } from "mobx-react";
import { BestSelect, BestLabel } from "best-common-react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

class TravelFormOrgDropdown extends React.Component {
  render() {
    const { travelAuthFormWidgetStore, lookupStore } = this.props.rootStore;
    const orgs = lookupStore.getDropdownOptions(lookupStore.lookups.ORGSHORTNOMSBNOBOC);

    orgs.splice(0, 1);
    orgs.unshift({ value: -1, label: "All" });
    return (
      <div className="form-group">
        <BestLabel name="org" label="Org">
          <FormattedMessage id="TravelFormOrgDropdown.Org" defaultMessage="Org" />
        </BestLabel>
        <BestSelect
          name="org"
          value={travelAuthFormWidgetStore.org}
          onChange={travelAuthFormWidgetStore.setOrg}
          options={orgs}
        />
      </div>
    );
  }
}

TravelFormOrgDropdown.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TravelFormOrgDropdown));
