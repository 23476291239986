import React from "react";
import { LightButton, Modal, ModalBody, ModalFooter, ModalHeaderWithTitleAndClose } from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import TravelAuthFormStore from "../../../stores/profile/TravelAuthFormStore";

class VisaFeesModal extends React.Component {
  render() {
    const { travelAuthFormStore } = this.props.rootStore;

    return (
      <div>
        <Modal show={this.props.show} onClose={() => {}} size="lg">
          <ModalHeaderWithTitleAndClose
            title={<FormattedMessage id="VisaFeesModal.title" defaultMessage="Visa Fees" />}
            close={() => {
              travelAuthFormStore.setShowVisaFeesModal(false);
            }}
          />
          <ModalBody>
            <FormattedMessage
              id="VisaFeesModal.message"
              defaultMessage="Standard fees associated with required visa application for permissible tryout."
            />
          </ModalBody>
          <ModalFooter>
            <LightButton onClick={() => travelAuthFormStore.setShowVisaFeesModal(false)}>
              <FormattedMessage id="VisaFeesModal.close" defaultMessage="Close" />
            </LightButton>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

VisaFeesModal.defaultProps = {
  show: false
};

VisaFeesModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  show: PropTypes.bool
};

export default inject("rootStore")(observer(VisaFeesModal));
