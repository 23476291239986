export const RouteConstants = {
  BASE: "/",
  LOGIN: "/login",
  CLUBS: "/clubs",
  PROSPECTS: "/prospects",
  PROSPECT_PROFILE: "/prospect/:prospectId",
  NEW_PROSPECT: "/new-prospect",
  TRAVEL_FORMS: "/travel-forms",
  TRAVEL_AUTH_FORM: "/travel-auth-form/:travelAuthFormId",
  HELP: "/help"
};
