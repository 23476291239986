import React from "react";
import { Accordion } from "best-common-react";
import PropTypes from "prop-types";
import TravelFormsMobileCard from "./TravelFormsMobileCard";
import TravelFormsMobileContent from "./TravelFormsMobileContent";

class TravelFormsTableMobile extends React.Component {
  render() {
    return (
      <Accordion>
        <Accordion.Header isToggle={true}>
          <TravelFormsMobileCard travelAuthForm={this.props.data} />
        </Accordion.Header>
        <Accordion.Body>
          <TravelFormsMobileContent travelAuthForm={this.props.data} />
        </Accordion.Body>
      </Accordion>
    );
  }
}

TravelFormsTableMobile.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
};

export default TravelFormsTableMobile;
