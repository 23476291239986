import React from "react";
import { AccordionTableWrapper, MobileView, TabletView, DesktopView } from "best-common-react";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import ProspectLinkFormatter from "../utilities/ProspectLinkFormatter";
import DateFormatter from "../utilities/DateFormatter";
import StatusFormatter from "./StatusFormatter";
import ProspectsTableMobile from "./ProspectsTableMobile";

const Line = styled.div`
  border: solid 1px ${props => props.theme["grey"]}
  border-top: 0;
  margin-left: auto;
  margin-right: auto;
`;

const lastName = <FormattedMessage id="ProspectsTable.lastName" defaultMessage="Last" key={"lastName"} />,
  firstName = <FormattedMessage id="ProspectsTable.firstName" defaultMessage="First" key={"firstName"} />,
  extLast = <FormattedMessage id="ProspectsTable.extLast" defaultMessage="Ext Last" key={"extLast"} />,
  middleName = <FormattedMessage id="ProspectsTable.middleName" defaultMessage="Middle" key={"middleName"} />,
  position = <FormattedMessage id="ProspectsTable.position" defaultMessage="Position" key={"position"} />,
  birthDate = <FormattedMessage id="ProspectsTable.birthDate" defaultMessage="Birth Date" key={"birthDate"} />,
  birthCountry = <FormattedMessage id="ProspectsTable.birthCountry" defaultMessage="B.C." key={"birthCountry"} />,
  lastCheckIn = <FormattedMessage id="ProspectsTable.lastCheckIn" defaultMessage="Last Check In" key={"lastCheckIn"} />,
  location = <FormattedMessage id="ProspectsTable.location" defaultMessage="Location" key={"location"} />,
  daysUsed = <FormattedMessage id="ProspectsTable.daysUsed" defaultMessage="Days Used" key={"daysUsed"} />,
  status = <FormattedMessage id="ProspectsTable.status" defaultMessage="Status" key={"status"} />,
  checkInAllowed = (
    <FormattedMessage
      id="ProspectsTable.checkInAllowed"
      defaultMessage="Next Check In Allowed"
      key={"checkInAllowed"}
    />
  ),
  monthsFromSigning = (
    <FormattedMessage
      id="ProspectsTable.monthsFromSigning"
      defaultMessage="Months From Signing"
      key={"monthsFromSigning"}
    />
  ),
  orgCode = <FormattedMessage id="ProspectsTable.orgCode" defaultMessage="Org" key={"orgCode"} />;

const tabletLastName = (
    <div>
      {lastName}
      <Line key={"tabletLastName-line"} />
      {extLast}
    </div>
  ),
  tabletFirstName = (
    <div>
      {firstName}
      <Line key={"tabletFirstName-line"} />
      {middleName}
    </div>
  ),
  tabletBirthDate = (
    <div>
      {birthDate}
      <Line key={"tabletBirthDate-line"} />
      {birthCountry}
    </div>
  ),
  tabletLastCheckIn = (
    <div>
      {lastCheckIn}
      <Line key={"tabletLastCheckIn-line"} />
      {location}
    </div>
  ),
  tabletDaysUsed = (
    <div>
      {daysUsed}
      <Line key={"tabletDaysUsed-line"} />
      {checkInAllowed}
    </div>
  ),
  tabletPosition = (
    <div>
      {position}
      <Line key={"tabletPosition-line"} />
      {orgCode}
    </div>
  );

const ProspectsTable = ({ rootStore: { authStore, prospectsStore, translationsStore }, data }) => {
  let isEnglish = translationsStore.language === "en";
  const cols = [
    ...(authStore.isBOC
      ? [
          {
            key: "orgCode",
            locked: true,
            name: orgCode,
            width: 80,
            sortable: true
          }
        ]
      : []),
    {
      key: "lastName",
      name: lastName,
      sortable: true,
      locked: true,
      width: isEnglish ? 100 : 140,
      formatter: ProspectLinkFormatter,
      getRowMetaData: row => row
    },
    {
      key: "firstName",
      name: firstName,
      sortable: true,
      locked: true,
      width: isEnglish ? 100 : 140,
      formatter: ProspectLinkFormatter,
      getRowMetaData: row => row
    },
    {
      key: "extendedLastName",
      name: extLast,
      sortable: true,
      width: isEnglish ? 100 : 160
    },
    {
      key: "middleName",
      name: middleName,
      sortable: true,
      width: isEnglish ? 100 : 160
    },
    {
      key: "position1",
      name: position,
      sortable: true,
      width: 90
    },
    {
      key: "dateOfBirth",
      name: birthDate,
      sortable: true,
      width: isEnglish ? 100 : 185,
      formatter: DateFormatter
    },
    {
      key: "birthCountry",
      name: birthCountry,
      sortable: true,
      width: isEnglish ? 55 : 185
    },
    {
      key: "lastCheckIn",
      name: lastCheckIn,
      sortable: true,
      width: isEnglish ? 130 : 145,
      formatter: DateFormatter
    },
    {
      key: "location",
      name: location,
      sortable: true,
      width: isEnglish ? 160 : 195
    },
    {
      key: "daysUsed",
      name: daysUsed,
      sortable: true,
      width: isEnglish ? 105 : 120
    },
    {
      key: "status",
      name: status,
      sortable: true,
      width: 130,
      formatter: StatusFormatter
    },
    {
      key: "nextCheckInAllowed",
      name: checkInAllowed,
      sortable: true,
      width: isEnglish ? 195 : 155,
      formatter: DateFormatter
    },
    {
      key: "monthsFromSigning",
      name: monthsFromSigning,
      sortable: true
    }
  ];

  const tabletCols = [
    {
      name: tabletLastName,
      primaryKey: "lastName",
      secondaryKey: "extendedLastName",
      sortable: true,
      width: isEnglish ? 100 : 160,
      primaryFormatter: ProspectLinkFormatter
    },
    {
      name: tabletFirstName,
      primaryKey: "firstName",
      secondaryKey: "middleName",
      sortable: true,
      width: isEnglish ? 100 : 160,
      primaryFormatter: ProspectLinkFormatter
    },
    {
      name: tabletPosition,
      primaryKey: "position1",
      secondaryKey: "orgCode",
      sortable: true,
      width: 90
    },
    {
      name: tabletBirthDate,
      primaryKey: "dateOfBirth",
      secondaryKey: "birthCountry",
      sortable: true,
      width: isEnglish ? 100 : 185,
      primaryFormatter: DateFormatter
    },
    {
      name: tabletLastCheckIn,
      primaryKey: "lastCheckIn",
      secondaryKey: "location",
      sortable: true,
      width: isEnglish ? 130 : 195,
      primaryFormatter: DateFormatter
    },
    {
      name: tabletDaysUsed,
      primaryKey: "daysUsed",
      secondaryKey: "nextCheckInAllowed",
      sortable: true,
      width: isEnglish ? 195 : 155,
      secondaryFormatter: DateFormatter
    },
    {
      name: status,
      primaryKey: "status",
      sortable: true,
      width: 130,
      primaryFormatter: StatusFormatter
    },
    {
      name: monthsFromSigning,
      primaryKey: "monthsFromSigning",
      sortable: true,
      width: 185
    }
  ];

  const onRowsSelected = rows => {
    prospectsStore.addSelectedProspects(rows.map(row => row.row));
  };

  const onRowsDeselected = rows => {
    prospectsStore.removeSelectedProspects(rows.map(row => row.row));
  };

  const RowRenderer = ({ renderBaseRow, ...props }) => (
    <div className={prospectsStore.showCheckBox(props.row) ? "" : "removeCheckBox"}>{renderBaseRow(props)}</div>
  );

  const getTable = headerRowHeight => {
    return (
      <AccordionTableWrapper
        id={"prospects-table"}
        data={data}
        columns={cols}
        tabletColumns={tabletCols}
        sortFunc={prospectsStore.setSortDirection}
        accordion={ProspectsTableMobile}
        sortColumn={prospectsStore.sortFilters.key}
        sortDirection={prospectsStore.sortFilters.direction}
        rowSelection={{
          showCheckbox: true,
          onRowsSelected: onRowsSelected,
          onRowsDeselected: onRowsDeselected,
          selectBy: {
            isSelectedKey: "isSelected"
          },
          enableShiftSelect: true
        }}
        rowRenderer={RowRenderer}
        minColumnWidth={185}
        headerRowHeight={headerRowHeight}
      />
    );
  };

  return (
    <div className="row">
      <div className="col-12 full-page-table">
        <MobileView>{getTable(0)}</MobileView>
        <TabletView>{getTable(50)}</TabletView>
        <DesktopView>{getTable(35)}</DesktopView>
      </div>
    </div>
  );
};

ProspectsTable.propTypes = {
  rootStore: PropTypes.object.isRequired,
  row: PropTypes.object,
  renderBaseRow: PropTypes.object,
  data: PropTypes.array.isRequired
};

export default inject("rootStore")(observer(ProspectsTable));
