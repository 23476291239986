import React from "react";
import ClubsHeader from "../components/clubs/ClubsHeader";
import ClubsTable from "../components/clubs/ClubsTable";
import CurrentCheckInsModal from "../components/clubs/CurrentCheckInsModal";
import { CardBody, CardContainer, CardFooter, CardHeader, LightButton, StickyFooterButtons } from "best-common-react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { FormattedMessage, intlShape, defineMessages } from "react-intl";
import { Helmet } from "react-helmet";

class ClubsContainer extends React.Component {
  render() {
    const { clubsStore, prospectsStore } = this.props.rootStore;

    const messages = defineMessages({
      title: {
        id: "ClubsContainer.title",
        defaultMessage: "Clubs"
      }
    });

    return (
      <div>
        <CurrentCheckInsModal show={clubsStore.showCheckInsModal} />
        <Helmet title={this.context.intl.formatMessage(messages.title)} />
        <div className={"container-fluid"}>
          <CardContainer>
            <CardHeader>
              <FormattedMessage id="ClubsContainer.header" defaultMessage="Clubs" /> ({prospectsStore.getPeriod()})
            </CardHeader>
            <CardBody>
              <ClubsHeader />
              <ClubsTable data={clubsStore.displayedClubs} />
            </CardBody>
            <CardFooter>
              <StickyFooterButtons>
                <LightButton onClick={clubsStore.exportClubTable}>
                  <FormattedMessage id="ClubsFooterButton.export" defaultMessage="Export" />
                </LightButton>
              </StickyFooterButtons>
            </CardFooter>
          </CardContainer>
        </div>
      </div>
    );
  }
}

ClubsContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

ClubsContainer.contextTypes = {
  intl: intlShape
};

export default inject("rootStore")(observer(ClubsContainer));
