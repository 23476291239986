import Moment from "moment";

const minDate = new Date("1/1/1910");
const maxDate = new Date();

export const CommonConstants = {
  KEYS: {
    SELECTED_ORG: "selected_org"
  },
  DATES: {
    MINIMUM_DATE: minDate,
    MAXIMUM_DATE: maxDate
  }
};
