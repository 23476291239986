import { action, autorun, extendObservable, decorate, computed } from "mobx";
import _ from "lodash";
import { AlertConstants } from "../../constants/AlertConstants";
import TableHelper from "../../utilities/TableHelper";
import FormHelper from "../../utilities/FormHelper";
import { TravelFormConstants } from "../../constants/TravelFormConstants";
import { RouteConstants } from "../../constants/RouteConstants";
import { saveAs } from "file-saver";
import Moment from "moment";

class TravelAuthFormStore {
  constructor(commonStore, prospectProfileStore, checkInHistoryStore) {
    this.authStore = commonStore.authStore;
    this.amtApi = commonStore.amtApi;
    this.lookupStore = commonStore.lookupStore;
    this.routerStore = commonStore.routerStore;
    this.errorWarningStore = commonStore.errorWarningStore;
    this.alertStore = commonStore.alertStore;
    this.prospectProfileStore = prospectProfileStore;
    this.checkInHistoryStore = checkInHistoryStore;
    this.tableHelper = new TableHelper();
    this.formHelper = new FormHelper();

    this.defaults = {
      travelAuthFormId: 0,
      editMode: false,
      showDeleteModal: false,
      showResubmitModal: false,
      showCancelModal: false,
      travelAuthForm: {
        accompaniedByParentGuardian: false,
        countryFlyingFromId: 0,
        countryFlyingToId: 0,
        arrivalTsAsString: Moment(new Date()).format("MM/DD/YYYY"),
        departureTsAsString: Moment(new Date()).format("MM/DD/YYYY"),
        academyCheckinTsAsString: Moment(new Date()).format("MM/DD/YYYY"),
        academyCheckoutTsAsString: Moment(new Date()).format("MM/DD/YYYY"),
        requestingOrgId: 0,
        currentStatus: TravelFormConstants.PRELIMINARY,
        submittedState: {},
        approvedState: {},
        guardianFirstName: "",
        guardianLastName: "",
        guardianRelationToPlayerId: 0,
        guardianIsTrainerOrAgent: false,
        clubProvidedGuardianLodging: false,
        hotelName: "",
        hotelCity: "",
        hotelCheckinTsAsString: null,
        hotelCheckoutTsAsString: null,
        drugTestInterestFlag: false,
        travelAuthDrugTestComment: {
          comments: ""
        },
        daysInTrip: null,
        resubmittedTsAsString: null,
        resubmittedComments: "",
        expensesDueTsAsString: null,
        visaFees: {
          visaFees: 0.0
        },
        commentToAdd: "",
        fileAttachments: [],
        travelAuthComments: [],
        deletedTravelAuthComments: [],
        travelIncompleteFlag: false,
        fileAttachments: [],
        auditHistory: []
      },
      travelAuthFormExpense: {
        airTransportation: 0,
        details: null,
        groundTransportation: 0,
        hotel: 0,
        other: 0,
        sequenceNumber: 1
      },
      history: [],
      sortFilters: {
        direction: "DESC",
        keys: ["submittedTs"]
      }
    };

    this.defaults.pristineTravelAuthForm = _.cloneDeep(this.defaults.travelAuthForm);

    extendObservable(this, {
      travelAuthFormId: this.defaults["travelAuthFormId"],
      editMode: this.defaults["editMode"],
      travelAuthForm: this.defaults["travelAuthForm"],
      pristineTravelAuthForm: this.defaults["pristineTravelAuthForm"],
      history: this.defaults["history"],
      sortFilters: this.defaults["sortFilters"],
      showDeleteModal: this.defaults["showDeleteModal"],
      showResubmitModal: this.defaults["showResubmitModal"],
      showCancelModal: this.defaults["showCancelModal"],
      showVisaFeesModal: this.defaults["showVisaFeesModal"],
      setTravelAuthFormId: action(travelAuthFormId => {
        this.travelAuthFormId = travelAuthFormId;
      }),
      setHistory: action(history => {
        this.history = history;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.keys = [col];
        this.sortFilters.direction = direction;
      }),
      setShowDeleteModal: action(bool => {
        this.showDeleteModal = bool;
      }),
      setShowResubmitModal: action(bool => {
        this.showResubmitModal = bool;
      }),
      setShowCancelModal: action(bool => {
        this.showCancelModal = bool;
      }),
      setFileAttachments: action(files => {
        this.travelAuthForm.fileAttachments = files;
      }),
      updateFileAtIndex: action((newFile, index) => {
        this.travelAuthForm.fileAttachments[index] = newFile;
      }),
      setShowVisaFeesModal: action(bool => {
        this.showVisaFeesModal = bool;
      }),
      deleteFileAttachment: action(fileAttachmentId => {
        let index = this.travelAuthForm.fileAttachments
          .map(fileAttachment => fileAttachment.fileAttachmentId)
          .indexOf(fileAttachmentId);

        if (index >= 0 && index < this.travelAuthForm.fileAttachments.length) {
          const newFiles = this.deleteFileAtIndex(this.travelAuthForm.fileAttachments, index);
          this.setFileAttachments(newFiles);
        }
      }),
      addFileAttachments: action(files => {
        if (!!files) {
          // Add an empty files array for each new file
          const labels = [
            this.lookupStore.getValue(
              this.lookupStore.lookups.AMT_TRAVEL_AUTH_FA_TYPES,
              TravelFormConstants.GUARDIAN_DOCS_TYPE_ID
            )
          ];
          this.addFiles(files, labels);
        }
      }),
      getFile: action((fileName, fileIdentifier, contentType) => {
        this.amtApi.getFile(fileIdentifier).then(response => {
          if (!!response) {
            const blob = new Blob([response], { type: contentType });
            saveAs(blob, fileName);
          }
        });
      }),
      getFileById: action((fileName, fileId, contentType) => {
        this.amtApi.getFileById(fileId).then(response => {
          if (!!response) {
            const blob = new Blob([response], { type: contentType });
            saveAs(blob, fileName);
          }
        });
      }),
      setEditMode: action(bool => {
        this.editMode = bool;
      }),
      edit: action(() => {
        this.setEditMode(true);
        // Clone objects at time edit was pressed so we can revert
        this.pristineTravelAuthForm = _.cloneDeep(this.travelAuthForm);

        // Force re-render of the table to fix bug where delete icons were not showing
        // TODO: Figure out why this happend and how we can get the table to re-render
        // Without the cost of cloning the array here
        const attachments = _.cloneDeep(this.travelAuthForm.fileAttachments);
        this.travelAuthForm.fileAttachments = null;
        this.travelAuthForm.fileAttachments = attachments;
      }),
      close: action(() => {
        this.routerStore.goBack();
      }),
      cancel: action(() => {
        this.setEditMode(false);
        this.setShowCancelModal(false);

        // Not a saved form, so go back to the entry point
        // Preliminary forms are only editable, so go back as well
        if (!this.travelAuthFormId || Number(this.travelAuthFormId) === 0 || this.isPreliminary) {
          this.close();
        }

        this.travelAuthForm = this.pristineTravelAuthForm;
      }),
      save: action(amtProspectId => {
        if (this.travelAuthForm) {
          this.travelAuthForm.amtProspectId = amtProspectId;
          this.saveTravelAuthForm(this.travelAuthForm);
        }
      }),
      submit: action(amtProspectId => {
        if (this.travelAuthForm) {
          this.travelAuthForm.amtProspectId = amtProspectId;
          this.travelAuthForm.updatedOrgClub = this.authStore.userData.orgClub;
          this.submitTravelForm(this.travelAuthForm);
        }
      }),
      approve: action(amtProspectId => {
        if (this.travelAuthForm) {
          this.travelAuthForm.amtProspectId = amtProspectId;
          this.approveTravelForm(this.travelAuthForm);
        }
      }),
      reject: action(amtProspectId => {
        if (this.travelAuthForm) {
          this.travelAuthForm.amtProspectId = amtProspectId;
          this.rejectTravelForm(this.travelAuthForm);
        }
      }),
      delete: action(() => {
        if (this.travelAuthForm) {
          this.deleteTravelForm(this.travelAuthForm.amtTravelAuthFormId);
        }
      }),
      resubmit: action(amtProspectId => {
        if (this.travelAuthForm) {
          this.travelAuthForm.amtProspectId = amtProspectId;
          this.beginResubmissionProcess(this.travelAuthForm);
        }
      }),
      setClubProvidedGuardianLodging: action(isChecked => {
        this.travelAuthForm.clubProvidedGuardianLodging = isChecked;
        if (isChecked) {
          // Default checkin and checkout dates to today's date
          if (!this.travelAuthForm.hotelCheckinTsAsString) {
            this.travelAuthForm.hotelCheckinTsAsString = Moment(new Date()).format("MM/DD/YYYY");
          }
          if (!this.travelAuthForm.hotelCheckoutTsAsString) {
            this.travelAuthForm.hotelCheckoutTsAsString = Moment(new Date()).format("MM/DD/YYYY");
          }
        } else {
          // Don't default if not checked
          this.travelAuthForm.hotelCheckinTsAsString = null;
          this.travelAuthForm.hotelCheckoutTsAsString = null;
        }
      }),
      setTravelIncomplete: action(isChecked => {
        this.travelAuthForm.travelIncompleteFlag = !!isChecked;
      }),
      setAccompaniedByParentGuardian: action(isChecked => {
        this.travelAuthForm.accompaniedByParentGuardian = isChecked;
        if (!isChecked) {
          this.travelAuthForm.guardianFirstName = this.defaults["guardianFirstName"];
          this.travelAuthForm.guardianLastName = this.defaults["guardianLastName"];
          this.travelAuthForm.relationship = this.defaults["relationship"];
          this.travelAuthForm.clubProvidedGuardianLodging = this.defaults["clubProvidedGuardianLodging"];
          this.travelAuthForm.hotelName = this.defaults["hotelName"];
          this.travelAuthForm.hotelCity = this.defaults["hotelCity"];
          this.travelAuthForm.hotelCheckinTsAsString = this.defaults["hotelCheckinTsAsString"];
          this.travelAuthForm.hotelCheckoutTsAsString = this.defaults["hotelCheckoutTsAsString"];

          if (this.travelAuthForm.travelAuthFormExpenses) {
            this.travelAuthForm.travelAuthFormExpenses = this.travelAuthForm.travelAuthFormExpenses.filter(expense => {
              return expense.sequenceNumber === 1;
            });
          }
        } else {
          if (this.isApproved && this.travelAuthForm.travelAuthFormExpenses) {
            const parentExpense = _.cloneDeep(this.defaults.travelAuthFormExpense);
            parentExpense.sequenceNumber = 2;
            this.travelAuthForm.travelAuthFormExpenses.push(parentExpense);
          }
        }
      }),
      resetStore: action(() => {
        this.editMode = this.defaults["editMode"];
        this.travelAuthForm = this.defaults["travelAuthForm"];
        this.pristineTravelAuthForm = this.defaults["pristineTravelAuthForm"];
        this.showDeleteModal = this.defaults["showDeleteModal"];
        this.showResubmitModal = this.defaults["showResubmitModal"];
      }),
      addComment: action(() => {
        let comment = { comments: this.travelAuthForm.commentToAdd };
        this.amtApi.getTravelAuthCommentTemplate(comment).then(response => {
          if (!this.isPreliminary) {
            response.amtTravelAuthFormId = this.travelAuthFormId;
            this.saveTravelAuthComment(response);
          } else {
            this.travelAuthForm.travelAuthComments.push(response);
          }
          this.travelAuthForm.commentToAdd = "";
        });
      }),
      removeComment: action(index => {
        let comment = this.travelAuthForm.travelAuthComments[index];

        if (comment.getAmtTravelAuthCommentId !== null) {
          comment.deletedFlag = true;
          this.amtApi.saveTravelAuthComment(comment).then(response => {
            if (response) {
              this.showSuccess("Comment deleted");
              this.routerStore.refresh();
            }
          });
        } else {
          this.travelAuthForm.travelAuthComments.splice(index, 1);
        }
      }),
      setDrugTestComment: action(comments => {
        this.travelAuthForm.travelAuthDrugTestComment = {
          travelAuthFormid: this.travelAuthForm.travelAuthFormId,
          comments
        };
      }),
      arrivalDateChanged: action(date => {
        this.travelAuthForm.arrivalTsAsString = date ? Moment(date).format("MM/DD/YYYY") : date;
        this.travelAuthForm.academyCheckinTsAsString = date ? Moment(date).format("MM/DD/YYYY") : date;
      })
    });

    autorun(() => {
      if (this.onForm()) {
        this.parseUrl();
        if (this.travelAuthFormId !== "0") {
          this.fetchTravelAuthForm(this.travelAuthFormId);
        } else if (this.authStore.isAdmin) {
          this.edit();
        }
      }
    });

    autorun(() => {
      if (
        prospectProfileStore.onProfile() &&
        this.prospectProfileStore.prospectId &&
        this.prospectProfileStore.prospectId !== 0
      ) {
        this.resetStore();
        this.fetchTravelAuthHistory();
      }
    });

    autorun(() => {
      if (this.onForm() && this.travelAuthForm.amtTravelAuthFormId) {
        if (
          (this.travelAuthForm.currentStatus === TravelFormConstants.PRELIMINARY || this.authStore.isBOC) &&
          this.routerStore.truePreviousPathName !== this.routerStore.location.pathname &&
          this.routerStore.truePreviousPathName !== "/travel-auth-form/0" &&
          this.authStore.isAdmin
        ) {
          this.edit();
        } else if (this.travelAuthForm.currentStatus === TravelFormConstants.PRELIMINARY && this.showResubmitModal) {
          // If re-loading due to resubmission, load in edit mode
          this.edit();
          this.setShowResubmitModal(false);
        } else {
          this.setEditMode(false);
        }
      }
    });
  }

  parseUrl = () => {
    const pathParams = this.routerStore.getPathParams(RouteConstants.TRAVEL_AUTH_FORM);
    this.setTravelAuthFormId(this.getTravelAuthFormIdFromUrl(pathParams));
  };

  fetchTravelAuthForm(id) {
    this.amtApi.getTravelAuthForm(id).then(response => {
      if (!!response) {
        this.travelAuthForm = {
          ...response,
          travelIncompleteFlag: !!response.travelIncompleteFlag
        };
        this.loadFileAttachments();
        this.prospectProfileStore.fetchProspect(this.travelAuthForm.amtProspectId).then(() => {
          this.setRequestingOrg();
        });
        // Fetching the check in history will ensure we have the latest check in photo
        this.checkInHistoryStore.prospectId = this.travelAuthForm.amtProspectId;
        this.checkInHistoryStore.fetchCheckinHistory();

        if (
          this.isApproved &&
          (!!this.travelAuthForm.travelAuthFormExpense || this.travelAuthForm.travelAuthFormExpenses.length === 0)
        ) {
          const expense = _.cloneDeep(this.defaults.travelAuthFormExpense);
          expense.sequenceNumber = 1;
          this.travelAuthForm.travelAuthFormExpenses = [expense];
          if (this.travelAuthForm.accompaniedByParentGuardian) {
            const parentExpense = _.cloneDeep(this.defaults.travelAuthFormExpense);
            parentExpense.sequenceNumber = 2;
            this.travelAuthForm.travelAuthFormExpenses.push(parentExpense);
          }
        }
      }
    });
  }

  fetchTravelAuthHistory() {
    this.amtApi.getTravelAuthFormHistory(this.prospectProfileStore.prospectId).then(response => {
      this.history = response;
    });
  }

  sortHistory(checkIns, searchFilters) {
    let { direction, keys } = searchFilters;
    return this.tableHelper.sort(checkIns, keys, direction);
  }

  get displayedHistory() {
    let data = this.history;
    return this.sortHistory(data, this.sortFilters);
  }

  onForm() {
    return this.routerStore.isTravelAuthFormTab && this.authStore.loggedIn;
  }

  getTravelAuthFormIdFromUrl(pathParams) {
    return pathParams["travelAuthFormId"] || this.defaults["travelAuthFormId"];
  }

  setRequestingOrg() {
    const id = this.prospectProfileStore.prospect.orgId;
    this.amtApi.getLookupByTypeAndId("ORGSNOMSB", id).then(response => {
      this.travelAuthForm.requestingOrgId = id;
      this.travelAuthForm.requestingOrgName = response.description;
    });
  }

  saveTravelAuthForm(form) {
    this.amtApi.saveTravelAuthForm(form).then(response => {
      if (response && response.amtTravelAuthFormId) {
        if (this.onForm()) {
          if (!form.amtTravelAuthFormId) {
            this.routerStore.goToTravelForm(response.amtTravelAuthFormId, true);
          } else {
            this.routerStore.refresh();
          }
        }
        this.showSuccess("Travel Authorization Form saved");
      }
    });
  }
  saveTravelAuthComment(comment) {
    this.amtApi.saveTravelAuthComment(comment).then(response => {
      if (response) {
        this.showSuccess("Comment submitted");
        this.travelAuthForm.travelAuthComments.push(response);
      }
    });
    this.routerStore.refresh();
  }

  submitTravelForm(form) {
    this.amtApi.updateTravelAuthFormState(form, "SM").then(response => {
      if (!this.errorWarningStore.hasErrors && this.errorWarningStore.hasWarnings) {
        this.errorWarningStore.setOverrideHandler(() => {
          form.overrideFlag = true;
          this.submitTravelForm(form);
        });
      } else if (response && response.amtTravelAuthFormId) {
        if (this.onForm()) {
          if (!form.amtTravelAuthFormId) {
            this.routerStore.goToTravelForm(response.amtTravelAuthFormId, true);
          } else {
            this.routerStore.refresh();
          }
        }
        this.showSuccess("Travel Authorization Form submitted");
      }
    });
  }

  approveTravelForm(form) {
    this.amtApi.updateTravelAuthFormState(form, "AP").then(response => {
      if (!this.errorWarningStore.hasErrors && this.errorWarningStore.hasWarnings) {
        this.errorWarningStore.setOverrideHandler(() => {
          form.overrideFlag = true;
          this.approveTravelForm(form);
        });
      } else if (response && response.amtTravelAuthFormId) {
        if (this.onForm()) {
          this.routerStore.refresh();
        }
        this.showSuccess("Travel Authorization Form approved");
      }
    });
  }

  rejectTravelForm(form) {
    this.amtApi.updateTravelAuthFormState(form, "RE").then(response => {
      if (response && response.amtTravelAuthFormId) {
        this.routerStore.refresh();
        this.showSuccess("Travel Authorization Form rejected");
      }
    });
  }

  deleteTravelForm(formId) {
    this.amtApi.deleteTravelAuthForm(formId).then(response => {
      if (response) {
        this.showSuccess("Travel Authorization Form deleted");
        this.showDeleteModal = false;

        this.routerStore.goBack();
      }
    });
  }

  beginResubmissionProcess(form) {
    this.amtApi.updateTravelAuthFormState(form, "PR").then(response => {
      if (response && response.amtTravelAuthFormId) {
        this.routerStore.refresh();
      }
    });
  }

  showSuccess(text) {
    this.alertStore.addAlert({
      type: AlertConstants.TYPES.SUCCESS,
      text: text
    });
  }

  isStatus(status) {
    if (!this.travelAuthForm) {
      return false;
    }
    return this.travelAuthForm.currentStatus === status;
  }

  get isPreliminary() {
    return this.isStatus(TravelFormConstants.PRELIMINARY);
  }

  get isSubmitted() {
    return this.isStatus(TravelFormConstants.SUBMITTED);
  }

  get isApproved() {
    return this.isStatus(TravelFormConstants.APPROVED);
  }

  get isRejected() {
    return this.isStatus(TravelFormConstants.REJECTED);
  }

  loadFileAttachments() {
    if (!this.travelAuthForm.fileAttachments) {
      return;
    }

    const attachments = _.cloneDeep(this.travelAuthForm.fileAttachments);

    for (var fa = 0; fa < attachments.length; fa++) {
      if (attachments[fa].attachmentTypes) {
        attachments[fa].labels = [];

        for (var atp = 0; atp < attachments[fa].attachmentTypes.length; atp++) {
          let label = this.lookupStore.getValue(
            this.lookupStore.lookups.AMT_TRAVEL_AUTH_FA_TYPES,
            attachments[fa].attachmentTypes[atp]
          );

          if (label) {
            attachments[fa].labels.push(label);
          }
        }
      }
    }

    this.setFileAttachments(attachments);
  }

  addFiles(newFiles, labels) {
    if (!newFiles) {
      return;
    }

    // Use existing files if there are any
    let files;
    if (!this.travelAuthForm.fileAttachments || !Array.isArray(this.travelAuthForm.fileAttachments)) {
      files = [];
    } else {
      files = _.cloneDeep(this.travelAuthForm.fileAttachments);
    }

    // If we weren't given an array, wrap the input
    let filesToAdd;
    if (!Array.isArray(newFiles)) {
      filesToAdd = [newFiles];
    } else {
      filesToAdd = newFiles;
    }

    let mappedFiles = filesToAdd.map(file => {
      // Add Guardian Documents label to each new file
      file.attachmentTypes = [TravelFormConstants.GUARDIAN_DOCS_TYPE_ID];
      file.labels = labels;
      return file;
    });

    let fileNameMap = [];

    for (var nf = 0; nf < mappedFiles.length; nf++) {
      let formData = new FormData();
      formData.set("document", mappedFiles[nf]);
      fileNameMap[mappedFiles[nf].name] = mappedFiles[nf];

      this.amtApi.uploadFile(formData).then(response => {
        if (response && fileNameMap[response.fileName]) {
          response.attachmentTypes = fileNameMap[response.fileName].attachmentTypes;
          response.labels = fileNameMap[response.fileName].labels;
          files.push(response);
          this.setFileAttachments(files);
        }
      });
    }
  }

  canDeleteComment(comment) {
    return this.editMode && (this.authStore.isBOC || comment.amtTravelAuthCommentsId === null);
  }

  getObjectUrl(blob) {
    return window.URL.createObjectURL(blob);
  }

  deleteFileAtIndex(oldFiles, index) {
    if (index < 0 || index > oldFiles.length) {
      return oldFiles;
    }

    // Copy files to avoid mutation
    let files = _.cloneDeep(oldFiles);
    // Remove file at the given index
    files.splice(index, 1);
    return files;
  }

  // If the prospect has a current preliminary travel authorization form, return the id of that form
  // Otherwise, return 0 and the user will enter a blank form.
  getPreliminaryFormId(currentForms) {
    if (!currentForms) {
      return 0;
    }
    const prelimForm = currentForms.find(form => {
      return form.status === TravelFormConstants.PRELIMINARY;
    });
    return !prelimForm ? 0 : prelimForm.amtTravelAuthFormId;
  }

  // Some fields will have specific logic
  // But generally, the rule for editing most fields on the form is:
  // We must be in edit mode and either on a preliminary form (new or existing), or in edit mode and BOC user,
  // or the form is approved and we are a club user, in which case we can edit expenses or edit for resubmit.
  isFieldEditable(fieldName) {
    const newForm = !this.travelAuthFormId || Number(this.travelAuthFormId) === 0;

    const travelExpenseFields = [
      "airTransportationExpense",
      "groundTransportationExpense",
      "hotelExpense",
      "otherExpense",
      "otherExpenseDetails",
      "visaFees"
    ];

    if (fieldName === "fileAttachments" || fieldName === "fileAttachmentsDelete") {
      return this.editMode;
    }

    if (fieldName === "bocComments") {
      return this.editMode && this.authStore.isBOC;
    } else if (fieldName === "clubComments") {
      // Club comments is only editable by the club
      return this.editMode && this.authStore.isClub && !this.isApproved;
    } else if (travelExpenseFields.includes(fieldName)) {
      // Travel Expense fields are only on Approved forms
      return this.editMode && this.isApproved;
    } else {
      return this.editMode && (this.authStore.isBOC || newForm || this.isPreliminary);
    }
  }

  get travelIncompleteFieldVisible() {
    return this.authStore.isBOC || this.travelAuthForm.travelIncompleteFlag === true;
  }

  // TODO: This is just for backwards compatibility so we can deploy ahead of the Ebis 2021.1
  // release. This can be removed after the release.
  get isDidNotTravelAvailableOnProfile() {
    return this.history.some(travelAuth => travelAuth.hasOwnProperty("travelIncompleteFlag"));
  }

  get travelExpensesValid() {
    var valid = true;
    if (this.travelAuthForm.travelAuthFormExpenses) {
      this.travelAuthForm.travelAuthFormExpenses.forEach(expenseForm => {
        let formValid =
          expenseForm.airTransportation !== null &&
          expenseForm.airTransportation !== "" &&
          expenseForm.groundTransportation !== null &&
          expenseForm.groundTransportation !== "" &&
          expenseForm.hotel !== null &&
          expenseForm.hotel !== "" &&
          expenseForm.other !== null &&
          expenseForm.other !== "";
        if (!formValid) {
          valid = false;
        }
      });
    }
    return valid;
  }

  get saveEnabled() {
    // Alias functions to remove some clutter
    const has = this.formHelper.isDefined;
    const hasAny = this.formHelper.isAnyDefined;
    const hasAll = this.formHelper.isAllDefined;

    // Required fields
    const {
      countryFlyingFromId,
      countryFlyingToId,
      arrivalTsAsString,
      departureTsAsString,
      academyCheckinTsAsString,
      academyCheckoutTsAsString,
      accompaniedByParentGuardian,
      clubProvidedGuardianLodging,
      hotelName,
      hotelCity,
      hotelCheckinTsAsString,
      hotelCheckoutTsAsString,
      drugTestInterestFlag,
      travelAuthDrugTestComment
    } = this.travelAuthForm;

    // If accompanied by parent/guardian and club is providing PG lodging
    // then hotel info is required
    const parentGuardianRequiredFields = () => {
      if (!accompaniedByParentGuardian) {
        return true;
      }
      if (!clubProvidedGuardianLodging) {
        return true;
      }
      return hasAll([hotelName, hotelCity, hotelCheckinTsAsString, hotelCheckoutTsAsString]);
    };

    // If drug test interest flag checked, then comments are required
    const drugTestRequiredFields = () => {
      if (!drugTestInterestFlag) {
        return true;
      }
      return !!(travelAuthDrugTestComment && travelAuthDrugTestComment.comments);
    };

    return !!(
      // Always required
      (
        hasAll([
          countryFlyingFromId,
          countryFlyingToId,
          arrivalTsAsString,
          departureTsAsString,
          academyCheckinTsAsString,
          academyCheckoutTsAsString
        ]) &&
        countryFlyingFromId > 0 &&
        countryFlyingToId > 0 &&
        // Conditionally required
        parentGuardianRequiredFields() &&
        drugTestRequiredFields()
      )
    );
  }
}

decorate(TravelAuthFormStore, {
  travelExpensesValid: computed,
  isPreliminary: computed,
  isSubmitted: computed,
  isApproved: computed,
  isRejected: computed,
  travelIncompleteFieldVisible: computed,
  saveEnabled: computed,
  isDidNotTravelAvailableOnProfile: computed
});

export default TravelAuthFormStore;
