import React from "react";
import { LightButton, Modal, ModalBody, ModalFooter, ModalHeaderWithTitleAndClose } from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import MonthsFromSigningTable from "./MonthsFromSigningTable";

class MonthsFromSigningModal extends React.Component {
  render() {
    const { prospectProfileStore } = this.props.rootStore;

    return (
      <div>
        <Modal show={this.props.show} onClose={() => {}} size="lg">
          <ModalHeaderWithTitleAndClose
            title={<FormattedMessage id="MonthsFromSigningModal.title" defaultMessage="Allotted Check In Days" />}
            close={() => {
              prospectProfileStore.setShowMonthsFromSigningModal(false);
            }}
          />
          <ModalBody>
            <MonthsFromSigningTable />
          </ModalBody>
          <ModalFooter>
            <LightButton onClick={() => prospectProfileStore.setShowMonthsFromSigningModal(false)}>
              <FormattedMessage id="MonthsFromSigningModal.close" defaultMessage="Close" />
            </LightButton>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

MonthsFromSigningModal.defaultProps = {
  show: false
};

MonthsFromSigningModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  show: PropTypes.bool
};

export default inject("rootStore")(observer(MonthsFromSigningModal));
