import React, { Component } from "react";
import { Route, Router } from "react-router-dom";
import { ImplicitCallback, SecureRoute, Security } from "@okta/okta-react";
import RootStore from "./stores/RootStore";
import { ThemeProvider } from "styled-components";
import HomeContainer from "./containers/HomeContainer";
import CustomLoginComponent from "./containers/LoginContainer";
import ClubsContainer from "./containers/ClubsContainer";
import ProspectsContainer from "./containers/ProspectsContainer";
import NewProspectContainer from "./containers/NewProspectContainer";
import TravelFormsContainer from "./containers/TravelAuthFormsWidgetContainer";
import TravelAuthFormContainer from "./containers/TravelAuthFormContainer";
import HelpContainer from "./containers/HelpContainer";
import { addLocaleData } from "react-intl";
import esLocaleData from "react-intl/locale-data/es";
import { Provider } from "mobx-react";
import AmtIntlProvider from "./components/translations/AmtIntlProvider";
import AmtNavigation from "./components/navigation/AmtNavigation";
import OktaAuthHelper from "./components/login/OktaAuthHelper";
import { Theme } from "best-common-react";
import ProspectProfileContainer from "./containers/ProspectProfileContainer";
import AmtAlerts from "./components/alerts/AmtAlerts";
import AmtLoader from "./utilities/AmtLoader";
import { RouteConstants } from "./constants/RouteConstants";
import HealthCheckErrorBoundary from "./components/startup/HealthCheckErrorBoundary";
import OnlineChecker from "./components/startup/OnlineChecker";

const rootStore = new RootStore();

addLocaleData(esLocaleData);

class App extends Component {
  render() {
    return (
      <div>
        <HealthCheckErrorBoundary>
          <OnlineChecker />
          <Provider rootStore={rootStore}>
            <AmtIntlProvider>
              <Router history={rootStore.history}>
                <ThemeProvider theme={Theme}>
                  <Security auth={rootStore.authApi.oktaAuth}>
                    <OktaAuthHelper />
                    <AmtNavigation />
                    <AmtAlerts />
                    <AmtLoader />
                    <Route path={RouteConstants.BASE} exact component={HomeContainer} />
                    <Route path={RouteConstants.LOGIN} component={CustomLoginComponent} />
                    <SecureRoute path={RouteConstants.CLUBS} component={ClubsContainer} />
                    <SecureRoute path={RouteConstants.PROSPECTS} component={ProspectsContainer} />
                    <SecureRoute path={RouteConstants.PROSPECT_PROFILE} component={ProspectProfileContainer} />
                    <SecureRoute path={RouteConstants.NEW_PROSPECT} component={NewProspectContainer} />
                    <SecureRoute path={RouteConstants.TRAVEL_FORMS} component={TravelFormsContainer} />
                    <SecureRoute path={RouteConstants.TRAVEL_AUTH_FORM} component={TravelAuthFormContainer} />
                    <SecureRoute path={RouteConstants.HELP} component={HelpContainer} />
                    <Route path="/implicit/callback" component={ImplicitCallback} />
                  </Security>
                </ThemeProvider>
              </Router>
            </AmtIntlProvider>
          </Provider>
        </HealthCheckErrorBoundary>
      </div>
    );
  }
}

export default App;
