import React from "react";
import { AccordionTableWrapper } from "best-common-react";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { ProspectsConstants } from "../../../constants/ProspectsConstants";
import { MonthsFromSigningConstants } from "../../../constants/MonthsFromSigningConstants";
import MonthsFromSigningTableMobile from "./MonthsFromSigningTableMobile";

const monthsFromSigning = (
  <FormattedMessage id="MonthsFromSigningTable.monthsFromSigning" defaultMessage="Months From Signing" />
);
const days = <FormattedMessage id="MonthsFromSigningTable.days" defaultMessage="Days" />;

const MonthsFromSigningTable = ({ rootStore: { monthsFromSigningStore } }) => {
  const cols = [
    {
      key: "monthsFromSigning",
      name: monthsFromSigning,
      sortable: false,
      locked: true,
      width: 250
    },
    {
      key: "days",
      name: days,
      sortable: false,
      locked: true
    }
  ];

  const data = [
    {
      monthsFromSigning: ProspectsConstants.ELIGIBLE_TO_SIGN,
      days: `${monthsFromSigningStore.getValue(
        MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_LIMIT
      )} days in any ${monthsFromSigningStore.getValue(
        MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_WINDOW_LENGTH
      )}-day period`
    },
    {
      monthsFromSigning: ProspectsConstants.ELIGIBLE_TO_SIGN_ON_BIRTHDAY,
      days: `${monthsFromSigningStore.getValue(
        MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_LIMIT
      )} days in any ${monthsFromSigningStore.getValue(
        MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_WINDOW_LENGTH
      )}-day period`
    },
    {
      monthsFromSigning: ProspectsConstants.MONTHS_0_6,
      days: `${monthsFromSigningStore.getValue(
        MonthsFromSigningConstants.MS1_LIMIT
      )} days in any ${monthsFromSigningStore.getValue(MonthsFromSigningConstants.MS1_WINDOW_LENGTH)}-day period`
    },
    {
      monthsFromSigning: ProspectsConstants.MONTHS_6_12,
      days: `${monthsFromSigningStore.getValue(
        MonthsFromSigningConstants.MS2_LIMIT
      )} days in any ${monthsFromSigningStore.getValue(MonthsFromSigningConstants.MS2_WINDOW_LENGTH)}-day period`
    },
    {
      monthsFromSigning: ProspectsConstants.AGE_ELIGIBLE,
      days: `${monthsFromSigningStore.getValue(
        MonthsFromSigningConstants.AGE_ELIGIBLE_LIMIT
      )} days in any ${monthsFromSigningStore.getValue(
        MonthsFromSigningConstants.AGE_ELIGIBLE_WINDOW_LENGTH
      )}-day period`
    },
    {
      monthsFromSigning: ProspectsConstants.MONTHS_12_18,
      days: `${monthsFromSigningStore.getValue(MonthsFromSigningConstants.MS3_LIMIT)} days`
    }
  ];

  return (
    <div className="row">
      <div className="col-12">
        <AccordionTableWrapper
          id={"months-from-signing-table"}
          data={data}
          columns={cols}
          accordion={MonthsFromSigningTableMobile}
          height={247}
          headerRowHeight={35}
        />
      </div>
    </div>
  );
};

MonthsFromSigningTable.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(MonthsFromSigningTable));
