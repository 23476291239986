import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TravelAuthFormSection from "./TravelAuthFormSection";
import { FormInput, Icon, LightButton } from "best-common-react";
import { intlShape, defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";

const CommentsContainer = styled.div`
  background-color: ${props => props.theme["light-grey"]};
  font-size: 0.875rem;

  .comment-wrapper {
    border-left: 1px solid #ced4da;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
    padding: 1rem;

    &:first-child {
      border-top: 1px solid #ced4da;
    }

    .comment-header {
      display: flex;
      justify-content: space-between;
      font-size: 0.6875rem;

      .comment-creator {
        font-weight: 600;
      }
    }
  }
`;

const CommentsInput = styled.div`
  display: flex;
  padding-top: 1rem;

  .form-group {
    flex: 1;
    margin: 0;
    padding-right: 0.3rem;
  }
`;

const MAX_LENGTH = 800;

class Comments extends React.Component {
  hasCommentText() {
    const { travelAuthFormStore } = this.props.rootStore;
    const { travelAuthForm } = travelAuthFormStore;

    return travelAuthForm.commentToAdd && travelAuthForm.commentToAdd.trim() !== "";
  }

  getComments() {
    const comments = [];

    const { travelAuthFormStore } = this.props.rootStore;
    const { travelAuthForm } = travelAuthFormStore;

    if (travelAuthForm.travelAuthComments && travelAuthForm.travelAuthComments !== null) {
      travelAuthForm.travelAuthComments.forEach((comment, index) => {
        if (!comment.deletedFlag) {
          comments.push(
            <div className="comment-wrapper" key={"commentsWrapper" + index}>
              <div className="comment-header">
                <div>
                  <span className="comment-creator">{comment.createdUserName}</span> - {comment.createdTsDisplay}
                </div>
                <div>
                  {travelAuthFormStore.canDeleteComment(comment) && (
                    <Icon
                      style={{ marginLeft: "5px" }}
                      iconName="fa-trash-alt"
                      className="fa-md icon-cog"
                      onClick={() => travelAuthFormStore.removeComment(index)}
                    />
                  )}
                </div>
              </div>
              <div className="comment-body">{comment.comments}</div>
            </div>
          );
        }
      });
    }

    return comments;
  }

  render() {
    const { travelAuthFormStore } = this.props.rootStore;
    const { travelAuthForm } = travelAuthFormStore;

    const messages = defineMessages({
      placeholder: {
        id: "travelAuthForm.commentsPlaceholder",
        defaultMessage: "Begin typing to add a comment..."
      }
    });

    const commentsPlaceholder = this.context.intl.formatMessage(messages.placeholder);

    return (
      <div className="col-12">
        <TravelAuthFormSection title={<FormattedMessage id="Comments.title" defaultMessage="Comments" />}>
          <CommentsContainer>{this.getComments()}</CommentsContainer>

          {travelAuthFormStore.editMode && (
            <CommentsInput>
              <FormInput
                name={"travelAuthComments"}
                type="text"
                value={travelAuthForm.commentToAdd}
                placeholder={commentsPlaceholder}
                onChangeFn={comment => (travelAuthForm.commentToAdd = comment)}
                editable={true}
                maxLength={MAX_LENGTH}
              />
              <LightButton
                disabled={!this.hasCommentText()}
                onClick={() => travelAuthFormStore.addComment(travelAuthForm)}
              >
                <FormattedMessage id="comments.add" defaultMessage="Add" />
              </LightButton>
            </CommentsInput>
          )}
        </TravelAuthFormSection>
      </div>
    );
  }
}

Comments.propTypes = {
  rootStore: PropTypes.object.isRequired
};

Comments.contextTypes = {
  intl: intlShape
};

export default inject("rootStore")(observer(Comments));
