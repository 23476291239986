import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { inject, observer } from "mobx-react";

const Title = styled.div`
  width: 100%;
  color: ${props => props.theme["dark-grey"]};
  font-size: 0.875rem;
  font-weight: 300;
  border-bottom: 1px solid ${props => props.theme["grey"]};
  margin-bottom: 12px;
`;

const SubSection = styled.div`
  margin-bottom: 20px;
`;

class TravelAuthFormSection extends React.Component {
  render() {
    const { title, children } = this.props;
    return (
      <SubSection>
        <Title>{title}</Title>
        {React.Children.map(children, child => {
          return <div>{child}</div>;
        })}
      </SubSection>
    );
  }
}

TravelAuthFormSection.propTypes = {
  rootStore: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.any
};

export default inject("rootStore")(observer(TravelAuthFormSection));
