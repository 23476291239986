import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ClubProspectCount from "./ClubProspectCount";
import ClubProspectsLinkFormatter from "../utilities/ClubProspectsLinkFormatter";
import ClubCheckInsLinkFormatter from "../utilities/ClubCheckInsLinkFormatter";
import { ClubsConstants } from "../../constants/ClubsConstants";
import { Accordion, BestLabel } from "best-common-react";
import { FormattedMessage } from "react-intl";

const Card = styled.span`
  font-weight: 400;
`;
const checkInsThisPeriod = (
    <FormattedMessage id="ClubsTableMobile.checkInsThisPeriod" defaultMessage="Check Ins This Period" />
  ),
  activeTravelAuths = (
    <FormattedMessage id="ClubsTableMobile.activeTravelAuths" defaultMessage="Active Travel Authorizations" />
  ),
  travelAuthsThisPeriod = (
    <FormattedMessage id="ClubsTableMobile.travelAuthsThisPeriod" defaultMessage="Travel Authorizations This Period" />
  );

class ClubsTableMobile extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Accordion>
        <Accordion.Header isToggle={true}>
          <Card>
            <div>
              <ClubProspectsLinkFormatter value={data.orgName} dependentValues={data} />
            </div>
            <div>
              <ClubProspectCount type={ClubsConstants.CHECKED_IN} value={data.checkedInProspects} />
              <ClubProspectCount type={ClubsConstants.APPROACHING} value={data.approachingProspects} />
              <ClubProspectCount type={ClubsConstants.OVERDUE} value={data.overdueProspects} />
            </div>
          </Card>
        </Accordion.Header>
        <Accordion.Body>
          <div>
            <BestLabel>{checkInsThisPeriod}</BestLabel>
            <div>
              <ClubCheckInsLinkFormatter
                value={data.checkIns}
                dependentValues={{ orgId: data.orgId, orgName: data.orgName }}
              />
            </div>
          </div>
          <div>
            <BestLabel>{activeTravelAuths}</BestLabel>
            <div>{data.activeTravelAuths}</div>
          </div>
          <div>
            <BestLabel>{travelAuthsThisPeriod}</BestLabel>
            <div>{data.currentTravelAuths}</div>
          </div>
        </Accordion.Body>
      </Accordion>
    );
  }
}

ClubsTableMobile.propTypes = {
  data: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ClubsTableMobile));
