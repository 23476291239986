import React from "react";
import { LightButton, StickyFooterButtons } from "best-common-react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

const ProfileFooter = ({
  rootStore: { authStore, checkInHistoryStore, prospectProfileStore, travelAuthFormStore, routerStore }
}) => {
  const formIdToRequest = travelAuthFormStore.getPreliminaryFormId(travelAuthFormStore.history);

  const travelAuthButton =
    !prospectProfileStore.newProspectMode && authStore.isAdmin ? (
      <LightButton
        onClick={() => {
          routerStore.goToTravelForm(formIdToRequest);
        }}
      >
        <FormattedMessage id="Profile.requestTravelAuth" defaultMessage="Request Travel Authorization" />
      </LightButton>
    ) : null;

  const deleteButton = (
    <LightButton onClick={() => prospectProfileStore.openDeleteProspectModal()}>
      <FormattedMessage id="Profile.delete" defaultMessage="Delete" />
    </LightButton>
  );

  const renderEdit = () => {
    return (
      <React.Fragment>
        <LightButton
          disabled={!prospectProfileStore.saveEnabled}
          onClick={() => {
            if (authStore.isBOC && prospectProfileStore.prospectId) {
              checkInHistoryStore.saveWithProspect();
            } else {
              prospectProfileStore.save();
            }
          }}
        >
          <FormattedMessage id="Profile.save" defaultMessage="Save" />
        </LightButton>
        {travelAuthButton}
        {authStore.isBOC && !prospectProfileStore.newProspectMode && deleteButton}
        <LightButton onClick={() => prospectProfileStore.setShowCancelModal(true)}>
          <FormattedMessage id="Profile.cancel" defaultMessage="Cancel" />
        </LightButton>
      </React.Fragment>
    );
  };

  const renderView = () => {
    return (
      <React.Fragment>
        {authStore.isBOC ? (
          <LightButton
            onClick={() => {
              prospectProfileStore.edit();
              checkInHistoryStore.edit();
            }}
          >
            <FormattedMessage id="Profile.edit" defaultMessage="Edit" />
          </LightButton>
        ) : null}
        {travelAuthButton}
        {authStore.isBOC && !prospectProfileStore.newProspectMode && deleteButton}
        <LightButton onClick={prospectProfileStore.close}>
          <FormattedMessage id="Profile.close" defaultMessage="Close" />
        </LightButton>
      </React.Fragment>
    );
  };

  return (
    <StickyFooterButtons>
      {prospectProfileStore.editMode && authStore.isAdmin ? renderEdit() : renderView()}
    </StickyFooterButtons>
  );
};

ProfileFooter.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ProfileFooter));
