import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TravelAuthFormSection from "./TravelAuthFormSection";
import { BestLabel, FormColumn2Wide } from "best-common-react";
import DateFormatter from "../../utilities/DateFormatter";
import styled, { withTheme } from "styled-components";
import { FormattedMessage } from "react-intl";
import { TravelFormConstants } from "../../../constants/TravelFormConstants";
import Moment from "moment";

const Status = styled.div`
  ${({ color }) =>
    `
  color: ${color}
  `};
`;

class StatusAndDates extends React.Component {
  constructor() {
    super();
    this.getStatusColor = this.getStatusColor.bind(this);
  }

  getStatusColor(status) {
    const { theme } = this.props;
    switch (status) {
      case TravelFormConstants.PRELIMINARY:
        return theme["mlb-red"];
      default:
        return "black";
    }
  }

  render() {
    const { travelAuthFormStore } = this.props.rootStore;
    const { travelAuthForm } = travelAuthFormStore;

    let submittedTsAsString = null;
    if (travelAuthForm.submittedTs) {
      submittedTsAsString = Moment(travelAuthForm.submittedTs).format("MM/DD/YYYY");
    }
    let resubmittedTsAsString = null;
    if (travelAuthForm.resubmittedTs) {
      resubmittedTsAsString = Moment(travelAuthForm.resubmittedTs).format("MM/DD/YYYY");
    }

    return (
      <FormColumn2Wide>
        <TravelAuthFormSection title={<FormattedMessage id="StatusAndDates.title" defaultMessage="Status & Dates" />}>
          <div className="row">
            <FormColumn2Wide>
              <BestLabel>
                <FormattedMessage id="StatusAndDates.requestDate" defaultMessage="Request Date" />
              </BestLabel>
              <div>{submittedTsAsString}</div>
            </FormColumn2Wide>
            <FormColumn2Wide className="form-group">
              <BestLabel>
                <FormattedMessage id="StatusAndDates.requestingClub" defaultMessage="Requesting Club" />
              </BestLabel>
              <div>{travelAuthForm.requestingOrgName}</div>
            </FormColumn2Wide>
            <FormColumn2Wide className="form-group">
              <BestLabel>
                <FormattedMessage id="StatusAndDates.status" defaultMessage="Status" />
              </BestLabel>
              <Status color={this.getStatusColor(travelAuthForm.currentStatus)}>{travelAuthForm.currentStatus}</Status>
            </FormColumn2Wide>
            <FormColumn2Wide className="form-group">
              <BestLabel>
                <FormattedMessage id="StatusAndDates.submittedToBoc" defaultMessage="Submitted to BOC" />
              </BestLabel>
              <div>{travelAuthForm.submittedState ? travelAuthForm.submittedState.createdUser : ""}</div>
            </FormColumn2Wide>
            <FormColumn2Wide className="form-group">
              <BestLabel>
                <FormattedMessage id="StatusAndDates.approved" defaultMessage="Approved" />
              </BestLabel>
              <div>
                <DateFormatter
                  value={travelAuthForm.approvedState ? travelAuthForm.approvedState.createdTsAsString : null}
                />
              </div>
            </FormColumn2Wide>
            <FormColumn2Wide className="form-group">
              <BestLabel>
                <FormattedMessage id="StatusAndDates.approvedBy" defaultMessage="Approved By" />
              </BestLabel>
              <div>{travelAuthForm.approvedState ? travelAuthForm.approvedState.createdUser : ""}</div>
            </FormColumn2Wide>
            <FormColumn2Wide className="form-group">
              <BestLabel>
                <FormattedMessage id="StatusAndDates.resubmittedDate" defaultMessage="Resubmitted" />
              </BestLabel>
              <div>{resubmittedTsAsString}</div>
            </FormColumn2Wide>
            <FormColumn2Wide className="form-group">
              <BestLabel>
                <FormattedMessage id="StatusAndDates.resubmittedComments" defaultMessage="Resubmitted Comments" />
              </BestLabel>
              <div>{travelAuthForm.resubmittedComments}</div>
            </FormColumn2Wide>
          </div>
        </TravelAuthFormSection>
      </FormColumn2Wide>
    );
  }
}

StatusAndDates.propTypes = {
  rootStore: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(StatusAndDates)));
