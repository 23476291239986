import React, { Fragment } from "react";
import { FileAttachment, DeleteIcon, Input } from "best-common-react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

const fileName = <FormattedMessage id="HelpDocumentsFileAttachments.fileName" defaultMessage="Filename" />,
  title = <FormattedMessage id="HelpDocumentsFileAttachments.title" defaultMessage="Title" />,
  description = <FormattedMessage id="HelpDocumentsFileAttachments.description" defaultMessage="Description" />,
  size = <FormattedMessage id="HelpDocumentsFileAttachments.size" defaultMessage="Size" />;

const RequiredIndicator = styled.span`
  margin-left: 0.25rem;
  color: red;
  font-size: 0.875rem;
`;

const styledTitle = (
  <Fragment>
    {title}
    <RequiredIndicator>*</RequiredIndicator>
  </Fragment>
);

const HelpDocumentsFileAttachments = ({
  data,
  downloadFn,
  rootStore: {
    helpStore: { deleteDocument, editDocument, addDocuments }
  }
}) => {
  const SizeFormatter = ({ row }) => {
    const bytes = row.fileSize;
    // 1k is 2^10, 1m is 2^20, etc
    // Using log2, we can get the base 2 order of magnitude
    const index = Math.floor(Math.log2(bytes) / 10);
    // Divide the bytes by the appropriate power of 1024 ...
    // ... To get a value like 5.1 for 5.1mb in bytes
    const readableNumber = (bytes / Math.pow(1024, index)).toFixed(2);
    // Append the appropriate file size extension based on our...
    // ... previously computed order of magnitude
    return readableNumber + ["b", "Kb", "Mb", "Gb"][index];
  };

  const getFile = (event, fileName, fileAttachmentId, contentType) => {
    downloadFn(event, fileName, fileAttachmentId, contentType);
  };

  const FileNameFormatter = ({ row }) => {
    return (
      <button
        className="btn btn-link"
        onClick={event => getFile(event, row.fileName, row.fileAttachmentId, row.contentType)}
      >
        {row.fileName}
      </button>
    );
  };

  const TitleFormatter = ({ row }) => {
    return (
      <Input
        required
        className="form-control-sm"
        id="title"
        value={row.title}
        onChange={event => editDocument(row.title, row.fileAttachmentId, { title: event.target.value })}
      />
    );
  };
  const DescriptionFormatter = ({ row }) => {
    return (
      <Input
        className="form-control-sm"
        id="description"
        value={row.description}
        onChange={event => editDocument(row.title, row.fileAttachmentId, { description: event.target.value })}
      />
    );
  };

  const DeleteFormatter = ({ row }) => {
    return <DeleteIcon deleteFunc={() => deleteDocument(row.title, row.fileAttachmentId)} />;
  };
  // TODO: Why do we need these prop types to satisfy the linter, yet we don't need proptypes for sizeformatter?
  FileNameFormatter.propTypes = {
    row: PropTypes.object
  };
  TitleFormatter.propTypes = {
    row: PropTypes.object
  };
  DescriptionFormatter.propTypes = {
    row: PropTypes.object
  };
  DeleteFormatter.propTypes = {
    row: PropTypes.object
  };

  const cols = [
    {
      key: "fileName",
      name: fileName,
      width: 250,
      formatter: FileNameFormatter
    },
    {
      key: "title",
      name: styledTitle,
      width: 250,
      formatter: TitleFormatter
    },
    {
      key: "description",
      name: description,
      formatter: DescriptionFormatter
    },
    {
      key: "fileSize",
      name: size,
      width: 100,
      formatter: SizeFormatter
    },
    {
      key: "delete",
      name: "",
      width: 40,
      formatter: DeleteFormatter
    }
  ];

  const rowCount = data.length;
  const dynamicTableHeight = Math.min(400, rowCount * 35 + 50);
  return (
    <FileAttachment
      headerRowHeight={35}
      height={dynamicTableHeight}
      editable
      cols={cols}
      onFileAdd={addDocuments}
      files={data}
    />
  );
};

HelpDocumentsFileAttachments.propTypes = {
  data: PropTypes.array,
  downloadFn: PropTypes.func.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(HelpDocumentsFileAttachments));
