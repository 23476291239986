// Helper functions for forms in AMT

class FormHelper {
  constructor() {
    this.isDefined = this.isDefined.bind(this);
    this.isAnyDefined = this.isAnyDefined.bind(this);
    this.isAllDefined = this.isAllDefined.bind(this);
  }

  // Used to determine if something is defined. Useful for checking if required fields have been entered
  isDefined(obj) {
    if (obj === null || obj === undefined) {
      return false;
    } else if (obj.hasOwnProperty("value") && obj.hasOwnProperty("label")) {
      // Object is a dropdown item, so we want to check if it has a value entered
      // We do a truthy/falsy comparison because when a dropdown is "Blank"
      // There is still an object selected, usually
      // { value: 0, label: "" }
      // So value and label will both be falsy
      // We care about not having a blank label though, because the ids (value) are generally unknown
      return !!obj.label;
    } else {
      // We have a regular object
      return !!obj;
    }
  }

  isAnyDefined(objs) {
    if (objs === null || objs === undefined) {
      return false;
    } else {
      return objs.some(this.isDefined);
    }
  }

  isAllDefined(objs) {
    if (objs === null || objs === undefined) {
      return false;
    } else {
      return objs.every(this.isDefined);
    }
  }
}
export default FormHelper;
