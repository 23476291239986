export const ProspectsConstants = {
  PROSPECTS: "Prospects",
  CHECKED_IN: "Checked In",
  CHECKED_OUT: "Checked Out",
  APPROACHING: "Approaching",
  OVERDUE: "Overdue",
  DRAFT: "Draft",
  ALL_PLAYERS: "All Players",
  ELIGIBLE_TO_SIGN: "Eligible to Sign",
  ELIGIBLE_TO_SIGN_ON_BIRTHDAY: "Eligible to Sign on Birthday",
  AGE_ELIGIBLE: "Age Eligible - Not Registered",
  MONTHS_0_6: "0 - 6 Months",
  MONTHS_6_12: "6 - 12 Months",
  MONTHS_12_18: "12 - 18 Months"
};
