import React, { Fragment } from "react";

import { Photo, DesktopTabletView, MobileView, Checkbox } from "best-common-react";
import { inject, observer } from "mobx-react";
import CheckInModalPhotoErrors from "./CheckInModalPhotoErrors";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const InitialUploadText = (
  <FormattedMessage
    id="CheckInModalPhoto.initialUploadText"
    defaultMessage="Click here to upload a previously taken photo"
  />
);
const Discard = <FormattedMessage id="CheckInModalPhoto.discard" defaultMessage="Discard" />;

const CheckInModalPhoto = ({ rootStore: { checkInModalStore, authStore } }) => {
  const cameraSize = window.innerWidth >= 768 ? 360 : 275;
  const PhotoAndCamera = (
    <Fragment>
      <DesktopTabletView>
        <Photo
          photo={checkInModalStore.checkIn.photo}
          height={cameraSize}
          width={cameraSize}
          facingMode={"environment"}
          onCapture={photo => {
            checkInModalStore.updatePhoto(photo);
          }}
          enableUpload={authStore.isBOC}
          clearPhoto={checkInModalStore.resetPhoto}
          initialUploadText={InitialUploadText}
          discardText={Discard}
        />
      </DesktopTabletView>
      <MobileView>
        <Photo
          width={cameraSize}
          height={cameraSize}
          facingMode={"environment"}
          photo={checkInModalStore.checkIn.photo}
          onCapture={photo => {
            checkInModalStore.updatePhoto(photo);
          }}
          enableUpload={authStore.isBOC}
          clearPhoto={checkInModalStore.resetPhoto}
          initialUploadText={InitialUploadText}
          discardText={Discard}
        />
      </MobileView>
    </Fragment>
  );

  const PlayerNotPresent = (
    <div style={{ width: cameraSize, margin: "auto" }}>
      <Checkbox
        id="playerNotPresent"
        checked={!!checkInModalStore.playerNotPresent}
        onChange={event => (checkInModalStore.playerNotPresent = event.target.checked)}
        label={<FormattedMessage id="CheckInModalPhoto.playerNotPresent" defaultMessage="Player Not Present" />}
      />
    </div>
  );

  return (
    <div>
      {!checkInModalStore.playerNotPresent && PhotoAndCamera}
      {authStore.isClub && PlayerNotPresent}
      <CheckInModalPhotoErrors />
    </div>
  );
};

CheckInModalPhoto.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CheckInModalPhoto));
