import React from "react";

import PropTypes from "prop-types";

class ClickableLinkWrapper extends React.Component {
  render() {
    return (
      <div
        onClick={() => {
          if (this.props.onClickCall) {
            this.props.onClickCall();
          }
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

ClickableLinkWrapper.propTypes = {
  children: PropTypes.any,
  onClickCall: PropTypes.func
};

export default ClickableLinkWrapper;
