import React, { useState } from "react";
import PropTypes from "prop-types";
import { AccordionTableWrapper, Icon } from "best-common-react";
import { FormattedMessage } from "react-intl";
import DateFormatter from "../utilities/DateFormatter";
import TableHelper from "../../utilities/TableHelper";
import CommentsBubble from "../common/CommentsBubble";

const tableHelper = new TableHelper();

const created = (
    <FormattedMessage id="TravelAuthAuditHistory.created" defaultMessage="Travel Authorization form created" />
  ),
  submitted = <FormattedMessage id="TravelAuthAuditHistory.submitted" defaultMessage="Submitted to BOC" />,
  returnedToPrelim = (
    <FormattedMessage id="TravelAuthAuditHistory.returnedToPrelim" defaultMessage="Returned to Preliminary" />
  ),
  guardianDocumentsUploaded = (
    <FormattedMessage id="TravelAuthAuditHistory.guardianDocs" defaultMessage="Guardian Documents Uploaded" />
  ),
  miscDocumentsUploaded = (
    <FormattedMessage id="TravelAuthAuditHistory.miscDocs" defaultMessage="Misc. Documents Uploaded" />
  ),
  approved = <FormattedMessage id="TravelAuthAuditHistory.approved" defaultMessage="Approved" />,
  rejected = <FormattedMessage id="TravelAuthAuditHistory.rejected" defaultMessage="Rejected" />,
  expensesSaved = <FormattedMessage id="TravelAuthAuditHistory.expensesSaved" defaultMessage="Travel Expenses saved" />,
  commentSaved = <FormattedMessage id="TravelAuthAuditHistory.commentSaved" defaultMessage="Comment Saved" />,
  drugTestCommentSaved = (
    <FormattedMessage id="TravelAuthAuditHistory.drugTestCommentSaved" defaultMessage="Drug Test Comment Saved" />
  ),
  fileDeleted = <FormattedMessage id="TravelAuthAuditHistory.fileDeleted" defaultMessage="File Attachment Deleted" />,
  visaFees = <FormattedMessage id="TravelAuthAuditHistory.visaFees" defaultMessage="Visa Fees saved" />;

const getStatusMessage = id => {
  switch (id) {
    case -1:
      return created;
    case 1:
      return returnedToPrelim;
    case 2:
      return submitted;
    case 3:
      return rejected;
    case 4:
      return approved;
    default:
      return "";
  }
};

const DescriptionFormatter = ({ row }) => {
  if (row.isGuardianDoc) {
    if (row.deletedFlag) {
      return fileDeleted;
    }
    return guardianDocumentsUploaded;
  } else if (row.isMiscDoc) {
    if (row.deletedFlag) {
      return fileDeleted;
    }
    return miscDocumentsUploaded;
  } else if (row.isTravelExpense) {
    return expensesSaved;
  } else if (row.isComment) {
    return commentSaved;
  } else if (row.isDrugTestComment) {
    return drugTestCommentSaved;
  } else if (row.isVisaChange) {
    return visaFees;
  } else {
    return getStatusMessage(row.amtTravelAuthFormStatusId);
  }
};

const TravelAuthAuditHistory = ({ data, getFile }) => {
  const [sortColumn, setSortColumn] = useState("createdTs");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [auditHistory, setAuditHistory] = useState(tableHelper.sort(data, sortColumn, sortDirection));

  const changeSort = (col, dir) => {
    setSortColumn(col);
    setSortDirection(dir);
    const sortedAuditHistory = tableHelper.sort(auditHistory, col, dir);
    setAuditHistory(sortedAuditHistory);
  };

  const IconFormatter = ({ row }) => {
    if (!row.deletedFlag && (row.isGuardianDoc || row.isMiscDoc)) {
      return (
        <Icon
          iconName="fa-paperclip"
          onClick={() => {
            getFile(row.fileName, row.fileAttachmentId, row.contentType);
          }}
        />
      );
    } else if (row.stateComments) {
      return (
        <CommentsBubble
          title={<FormattedMessage id="TravelAuthAuditHistory.comment" defaultMessage="Comment" />}
          comments={row.stateComments}
        />
      );
    } else {
      return null;
    }
  };

  const columns = [
    {
      key: "createdTs",
      name: <FormattedMessage id="TravelAuthAuditHistory.date" defaultMessage="Date" />,
      sortable: true,
      formatter: DateFormatter,
      width: 100
    },
    {
      key: "icon",
      name: "",
      width: 50,
      formatter: IconFormatter
    },
    {
      key: "desc",
      name: <FormattedMessage id="TravelAuthAuditHistory.description" defaultMessage="Description" />,
      formatter: DescriptionFormatter
    },
    {
      key: "createdUser",
      name: <FormattedMessage id="TravelAuthAuditHistory.user" defaultMessage="User" />,
      width: 250
    }
  ];

  return (
    <AccordionTableWrapper
      id={"audit-history-table"}
      data={auditHistory}
      columns={columns}
      accordion={null}
      headerRowHeight={35}
      sortDirection={sortDirection}
      sortColumn={sortColumn}
      sortFunc={changeSort}
    />
  );
};

TravelAuthAuditHistory.propTypes = {
  data: PropTypes.array.isRequired,
  getFile: PropTypes.func
};

export default TravelAuthAuditHistory;
