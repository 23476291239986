import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import PropTypes from "prop-types";

const LanguageButton = styled.button.attrs({
  className: "btn btn-sm btn-link"
})`
  text-decoration: none;
  color: ${props => props.theme["mlb-blue"]};

  &:hover {
    text-decoration: none;
  }
`;

const DisabledLanguageButton = styled.button.attrs({
  className: "btn btn-sm btn-link disabled"
})`
  text-decoration: none;
`;

class DesktopLanguageSelector extends React.Component {
  render() {
    const { translationsStore } = this.props.rootStore;
    const english = translationsStore.english;
    const spanish = translationsStore.spanish;
    let enButton;
    let esButton;
    if (translationsStore.getCurrentLanguage() === "en") {
      enButton = <DisabledLanguageButton>{english}</DisabledLanguageButton>;
      esButton = <LanguageButton onClick={() => translationsStore.setLanguage("es")}>{spanish}</LanguageButton>;
    } else {
      enButton = <LanguageButton onClick={() => translationsStore.setLanguage("en")}>{english}</LanguageButton>;
      esButton = <DisabledLanguageButton>{spanish}</DisabledLanguageButton>;
    }

    return (
      <span>
        {enButton}
        {esButton}
      </span>
    );
  }
}

DesktopLanguageSelector.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(DesktopLanguageSelector));
