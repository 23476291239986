import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PrimaryButton } from "best-common-react";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

const Container = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const ActiveButton = styled(PrimaryButton)`
  width: 100%;
`;

const DisabledButton = styled.div.attrs({
  className: "btn disabled"
})`
  width: 100%;
  border: 1px solid ${props => props.theme["white-two"]} !important;
  border-radius: 4px !important;
`;

class CheckInCheckOutButton extends React.Component {
  constructor(props) {
    super(props);
    this.renderCheckOutButton = this.renderCheckOutButton.bind(this);
    this.renderCheckInButton = this.renderCheckInButton.bind(this);
  }

  // Will be displayed for Checked In prospects
  renderCheckOutButton() {
    const { prospectProfileStore } = this.props.rootStore;
    const CheckOutCalendar = styled.i.attrs({
      className: "fa fa-calendar-times disabled"
    })`
      margin-right: 0.5rem;
    `;
    return (
      <ActiveButton onClick={prospectProfileStore.openProspectCheckOutModal}>
        <Container>
          <CheckOutCalendar />
          <FormattedMessage id="ProfileSummary.checkOut" defaultMessage="Check Out" />
        </Container>
      </ActiveButton>
    );
  }

  // Will be displayed for non-Checked In Prospects.
  // Will be disabled for Draft prospects.
  renderCheckInButton() {
    const CheckInCalendar = styled.i.attrs({
      className: "fa fa-calendar-check disabled"
    })`
      margin-right: 0.5rem;
    `;

    const { checkInModalStore, prospectProfileStore } = this.props.rootStore;
    const { prospect } = this.props;

    const checkInCalendar = <CheckInCalendar />;
    const checkInMessage = <FormattedMessage id="ProfileSummary.checkIn" defaultMessage="Check In" />;

    if (!prospectProfileStore.newProspectMode || prospect.isCheckedOut) {
      return (
        <ActiveButton onClick={() => checkInModalStore.canCheckIn()}>
          <Container>
            <React.Fragment>{checkInCalendar}</React.Fragment>
            <React.Fragment>{checkInMessage}</React.Fragment>
          </Container>
        </ActiveButton>
      );
    } else {
      return (
        <DisabledButton>
          <Container>
            <React.Fragment>{checkInCalendar}</React.Fragment>
            <React.Fragment>{checkInMessage}</React.Fragment>
          </Container>
        </DisabledButton>
      );
    }
  }

  render() {
    const { prospect } = this.props;

    // If Prospect is Checked In, show Check Out
    // Otherwise, we show Check In
    return prospect.isCheckedIn ? this.renderCheckOutButton() : this.renderCheckInButton();
  }
}

CheckInCheckOutButton.propTypes = {
  rootStore: PropTypes.object.isRequired,
  prospect: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CheckInCheckOutButton));
