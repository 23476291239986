import { extendObservable, autorun } from "mobx";
import { MonthsFromSigningConstants } from "../../constants/MonthsFromSigningConstants";

class MonthsFromSigningStore {
  constructor(commonStore) {
    this.lookupStore = commonStore.lookupStore;
    this.authStore = commonStore.authStore;

    this.defaults = {
      monthsFromSigningValues: []
    };

    extendObservable(this, {
      monthsFromSigningValues: this.defaults["monthsFromSigningValues"]
    });

    autorun(() => {
      if (this.authStore.loggedIn && this.lookupStore.loaded) {
        this.populateValues();
      }
    });
  }

  // Get value of months from signing lookup for display (whole number)
  getValue(code) {
    let lookup = this.monthsFromSigningValues.find(e => e.code === code);
    if (lookup && lookup.value) {
      return Number(lookup.value).toFixed(0);
    }
    return null;
  }

  // Populate the values array with all the months from signing values we need
  populateValues() {
    const lookupValues = this.lookupStore.lookups.AMT_MFS_TYPES;
    if (lookupValues && Array.isArray(lookupValues)) {
      this.populateValue(MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_LIMIT, lookupValues);
      this.populateValue(MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_WINDOW_LENGTH, lookupValues);
      this.populateValue(MonthsFromSigningConstants.AGE_ELIGIBLE_LIMIT, lookupValues);
      this.populateValue(MonthsFromSigningConstants.AGE_ELIGIBLE_WINDOW_LENGTH, lookupValues);
      this.populateValue(MonthsFromSigningConstants.MS1_LIMIT, lookupValues);
      this.populateValue(MonthsFromSigningConstants.MS1_WINDOW_LENGTH, lookupValues);
      this.populateValue(MonthsFromSigningConstants.MS2_LIMIT, lookupValues);
      this.populateValue(MonthsFromSigningConstants.MS2_WINDOW_LENGTH, lookupValues);
      this.populateValue(MonthsFromSigningConstants.MS3_LIMIT, lookupValues);
      this.populateValue(MonthsFromSigningConstants.MS3_WINDOW_LENGTH, lookupValues);
    }
  }

  // Given a lookup code, find that lookup and return the value
  getValueFromLookups(code, lookups) {
    const lookup = lookups.find(lookup => {
      return lookup.code === code;
    });
    if (lookup) {
      return lookup.value;
    }
    // No lookup found for given code
    return null;
  }

  // Add a {code, value} pair to the values array
  setValue(code, value) {
    if (code) {
      this.monthsFromSigningValues.push({ code, value });
    }
  }

  // Get value from lookups and set in this store's array
  populateValue(code, lookups) {
    if (code) {
      const value = this.getValueFromLookups(code, lookups);
      this.setValue(code, value);
    }
  }
}

export default MonthsFromSigningStore;
