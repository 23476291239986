import React from "react";
import PropTypes from "prop-types";
import { Icon } from "best-common-react";
import { FormattedMessage } from "react-intl";

const BooleanCheckFormatter = ({ value, align }) =>
  value ? (
    <div style={{ textAlign: align }}>
      <Icon style={{ color: "black", cursor: "default" }} iconName="fa-check" />
    </div>
  ) : null;

BooleanCheckFormatter.propTypes = {
  align: PropTypes.oneOf(["left", "center", "right"]),
  value: PropTypes.bool.isRequired
};

BooleanCheckFormatter.defaultProps = {
  align: "center"
};

export default BooleanCheckFormatter;
