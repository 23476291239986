import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TravelAuthFormSection from "./TravelAuthFormSection";
import { FormInput, FormColumn2Wide, PriceFormatter, Icon } from "best-common-react";
import { intlShape, defineMessages, FormattedMessage } from "react-intl";
import DateFormatter from "../../utilities/DateFormatter";
import { BestLabel } from "best-common-react";
import VisaFeesModal from "../../profile/visaFeesModal/VisaFeesModal";

const TravelExpensesWrapper = styled.div`
  border: 1px solid ${props => props.theme.grey};
  font-size: 0.875rem;
`;

const LabelHeaders = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${props => props.theme["light-grey"]};
  font-weight: 600;
  padding: 0.3rem 0.94rem;
`;

const ExpenseRow = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  padding: 0.3rem 0;

  .expense-input {
    padding: 0 0.94rem;
    text-align: right;
    .form-group {
      margin-bottom: 0;

      input {
        text-align: right;
      }
    }
  }

  border-top: 1px solid ${props => props.theme.grey};
`;

const ExpenseDetails = styled.div`
  padding: 0.3rem 0.94rem;
  padding-top: 0;

  .form-group {
    margin: 0;

    input {
      height: 56px;
    }
  }
`;

const ExpensesDueDtWrapper = styled.div`
  @media (min-width: 992px) {
    padding-top: 0;
  }

  padding: 10px 0;
`;

const VisaFeesWrapper = styled.div`
  padding-left: 15px;
  padding-bottom: 0px;
  width: 100%;
`;

const Required = styled.span`
  color: ${props => props.theme["mlb-red"]};
`;

const FormWrapper = styled.div.attrs(() => ({
  className: "col-12 form-group"
}))``;

const CheckBox = styled.input`
  margin-right: 5px;
  margin-top: 5px;
`;

const parseFloatOrDefaultZero = str => parseFloat(str) || 0;

const calculateExpenseTotal = expense =>
  parseFloatOrDefaultZero(expense.airTransportation) +
  parseFloatOrDefaultZero(expense.groundTransportation) +
  parseFloatOrDefaultZero(expense.hotel) +
  parseFloatOrDefaultZero(expense.other);

const CURRENCY_FORMATTER = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

class TravelExpenses extends React.Component {
  getVisaFees() {
    const { travelAuthFormStore } = this.props.rootStore;
    const { travelAuthForm } = travelAuthFormStore;
    const { travelAuthFormExpenses } = travelAuthForm;

    const messages = defineMessages({
      visaFeesTitle: {
        id: "travelAuthForm.visaFees",
        defaultMessage: "Visa Fees"
      }
    });

    const visaFeesTitle = this.context.intl.formatMessage(messages.visaFeesTitle);

    const visaFeesIcon = (
      <span>
        {visaFeesTitle}
        <Icon
          style={{ color: "#ffae47", marginLeft: "5px" }}
          onClick={() => travelAuthFormStore.setShowVisaFeesModal(true)}
          iconName="fa-info-circle"
          className="fa-md icon-cog"
          title={visaFeesTitle}
        />
      </span>
    );

    const visaFees = (
      <VisaFeesWrapper>
        <VisaFeesModal show={travelAuthFormStore.showVisaFeesModal} />

        <div className="row">
          <FormColumn2Wide>
            <FormInput
              name={"visaFees"}
              type="currency"
              value={travelAuthForm.visaFees.visaFees ? travelAuthForm.visaFees.visaFees.toString() : "0"}
              label={visaFeesIcon}
              formatter={PriceFormatter}
              onChangeFn={value => (travelAuthForm.visaFees.visaFees = value)}
              editable={travelAuthFormStore.isFieldEditable("visaFees")}
            />
          </FormColumn2Wide>

          {travelAuthFormStore.travelIncompleteFieldVisible && (
            <FormColumn2Wide>
              <BestLabel>
                <CheckBox
                  name="travelNotCompleted"
                  type="checkbox"
                  checked={travelAuthForm.travelIncompleteFlag}
                  disabled={!travelAuthFormStore.isFieldEditable("travelNotCompleted")}
                  onChange={event => travelAuthFormStore.setTravelIncomplete(event.target.checked)}
                />
                <span>
                  <FormattedMessage id="travelAuthForm.didNotTravel" defaultMessage="Did Not Travel" />
                </span>
              </BestLabel>
            </FormColumn2Wide>
          )}
        </div>
      </VisaFeesWrapper>
    );

    return visaFees;
  }

  getExpense(index, field) {
    const { travelAuthFormStore } = this.props.rootStore;
    const { travelAuthForm } = travelAuthFormStore;
    if (travelAuthForm.travelAuthFormExpenses[index][field] === null) {
      return "0";
    } else {
      return travelAuthForm.travelAuthFormExpenses[index][field].toString();
    }
  }

  getExpenseTemplate(expense, index) {
    const { travelAuthFormStore } = this.props.rootStore;
    const { travelAuthForm } = travelAuthFormStore;

    let gridClasses = "col-12 col-md-auto col-lg-6";
    let Wrapper = FormColumn2Wide;
    if (!travelAuthForm.accompaniedByParentGuardian) {
      Wrapper = FormWrapper;
      gridClasses = "col-12 col-md-auto";
    }

    let totalCost = calculateExpenseTotal(expense);
    const key = "expense-" + index;

    const wrapper = (
      <Wrapper key={index}>
        <ExpensesDueDtWrapper>
          <BestLabel>
            <FormattedMessage id="travelAuthForm.expensesDueTs" defaultMessage="Expenses Due" />
          </BestLabel>
          <div>
            <DateFormatter value={travelAuthForm.expensesDueTsAsString} />
          </div>
        </ExpensesDueDtWrapper>

        <TravelExpensesWrapper>
          <LabelHeaders>
            <div className="text-nowrap">
              {expense.sequenceNumber === 1 ? (
                <FormattedMessage id="TravelExpenses.playerTitle" defaultMessage="Player" />
              ) : (
                <FormattedMessage id="TravelExpenses.parentTitle" defaultMessage="Parent/Guardian" />
              )}
            </div>
            <div className="text-nowrap">{CURRENCY_FORMATTER.format(totalCost)}</div>
          </LabelHeaders>
          <ExpenseRow>
            <div className={"expense-label " + gridClasses}>
              <FormattedMessage id="TravelExpenses.airTransportation" defaultMessage="Air Transportation" />
              {travelAuthFormStore.isFieldEditable("airTransportationExpense") ? <Required>*</Required> : ""}
            </div>
            <div className={"expense-input " + gridClasses}>
              <FormInput
                name={key + "air"}
                type="currency"
                value={this.getExpense(index, "airTransportation")}
                label={""}
                isRequired={true}
                onChangeFn={value => (travelAuthForm.travelAuthFormExpenses[index].airTransportation = value)}
                editable={travelAuthFormStore.isFieldEditable("airTransportationExpense")}
                formatter={PriceFormatter}
              />
            </div>
          </ExpenseRow>

          <ExpenseRow>
            <div className={"expense-label " + gridClasses}>
              <FormattedMessage id="TravelExpenses.groundTransportation" defaultMessage="Ground Transportation" />
              {travelAuthFormStore.isFieldEditable("groundTransportationExpense") ? <Required>*</Required> : ""}
            </div>
            <div className={"expense-input " + gridClasses}>
              <FormInput
                name={key + "ground"}
                type="currency"
                label={""}
                isRequired={true}
                value={this.getExpense(index, "groundTransportation")}
                onChangeFn={value => (travelAuthForm.travelAuthFormExpenses[index].groundTransportation = value)}
                editable={travelAuthFormStore.isFieldEditable("groundTransportationExpense")}
                formatter={PriceFormatter}
              />
            </div>
          </ExpenseRow>

          <ExpenseRow>
            <div className={"expense-label " + gridClasses}>
              <FormattedMessage id="TravelExpenses.hotel" defaultMessage="Hotel" />
              {travelAuthFormStore.isFieldEditable("hotelExpense") ? <Required>*</Required> : ""}
            </div>
            <div className={"expense-input " + gridClasses}>
              <FormInput
                name={key + "hotel"}
                type="currency"
                label={""}
                isRequired={true}
                value={this.getExpense(index, "hotel")}
                onChangeFn={value => (travelAuthForm.travelAuthFormExpenses[index].hotel = value)}
                editable={travelAuthFormStore.isFieldEditable("hotelExpense")}
                formatter={PriceFormatter}
              />
            </div>
          </ExpenseRow>

          <ExpenseRow>
            <div className={"expense-label " + gridClasses}>
              <FormattedMessage id="TravelExpenses.other" defaultMessage="Other Expenses (meals, etc)" />
              {travelAuthFormStore.isFieldEditable("otherExpense") ? <Required>*</Required> : ""}
            </div>
            <div className={"expense-input " + gridClasses}>
              <FormInput
                name={key + "other"}
                type="currency"
                label={""}
                isRequired={true}
                value={this.getExpense(index, "other")}
                onChangeFn={value => (travelAuthForm.travelAuthFormExpenses[index].other = value)}
                editable={travelAuthFormStore.isFieldEditable("otherExpense")}
                formatter={PriceFormatter}
              />
            </div>
          </ExpenseRow>

          <ExpenseDetails>
            <FormInput
              name={key + "details"}
              type="text"
              label={""}
              value={travelAuthForm.travelAuthFormExpenses[index].details}
              onChangeFn={value => (travelAuthForm.travelAuthFormExpenses[index].details = value)}
              editable={travelAuthFormStore.isFieldEditable("otherExpenseDetails")}
              placeholder="other expense details"
            />
          </ExpenseDetails>
        </TravelExpensesWrapper>
      </Wrapper>
    );

    return wrapper;
  }

  getExpenses() {
    const { travelAuthFormStore } = this.props.rootStore;
    const { travelAuthForm } = travelAuthFormStore;
    const { travelAuthFormExpenses } = travelAuthForm;

    const expenses = [];
    if (travelAuthFormExpenses) {
      expenses.push(this.getExpenseTemplate(travelAuthForm.travelAuthFormExpenses[0], 0));

      if (travelAuthForm.accompaniedByParentGuardian) {
        expenses.push(this.getExpenseTemplate(travelAuthForm.travelAuthFormExpenses[1], 1));
      }
    }
    return expenses;
  }

  getTravelExpenseTotal() {
    const { travelAuthForm } = this.props.rootStore.travelAuthFormStore;

    return (
      parseFloatOrDefaultZero(travelAuthForm.visaFees.visaFees) +
      travelAuthForm.travelAuthFormExpenses
        .map(calculateExpenseTotal)
        .reduce((sum, totalForPerson) => sum + totalForPerson, 0)
    );
  }

  render() {
    return (
      <div className="col-12">
        <TravelAuthFormSection title={<FormattedMessage id="TravelExpenses.title" defaultMessage="Travel Expenses" />}>
          <div className="row" style={{ paddingBottom: 10 }}>
            <div className="col-12">
              <BestLabel>
                <FormattedMessage id="TravelExpenses.total" defaultMessage="Travel Expense Total" />
              </BestLabel>
              <div>{CURRENCY_FORMATTER.format(this.getTravelExpenseTotal())}</div>
            </div>
          </div>
          <div className="row">{this.getVisaFees()}</div>
          <div className="row">{this.getExpenses()}</div>
        </TravelAuthFormSection>
      </div>
    );
  }
}

TravelExpenses.propTypes = {
  rootStore: PropTypes.object.isRequired
};

TravelExpenses.contextTypes = {
  intl: intlShape
};

export default inject("rootStore")(observer(TravelExpenses));
