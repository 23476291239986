import * as React from "react";
import { inject, observer } from "mobx-react";
import { IntlProvider } from "react-intl";
import PropTypes from "prop-types";

class AmtIntlProvider extends React.Component {
  render() {
    const { translationsStore } = this.props.rootStore;
    const loc = translationsStore.getCurrentLanguage();
    const messages = translationsStore.getTranslations();
    return (
      <IntlProvider locale={loc} key={loc} messages={messages}>
        {this.props.children}
      </IntlProvider>
    );
  }
}

AmtIntlProvider.propTypes = {
  rootStore: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(AmtIntlProvider));
