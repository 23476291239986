import React from "react";
import { inject, observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import Login from "../components/login/Login";

class LoginPage extends React.Component {
  render() {
    const {
      rootStore: { authStore, routerStore }
    } = this.props;
    if (routerStore.isLoginTab && authStore.loggedIn) {
      return <Redirect to="/" />;
    } else {
      return <Login />;
    }
  }
}

LoginPage.propTypes = {
  rootStore: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(LoginPage));
