import { action, extendObservable, autorun } from "mobx";
import { AlertConstants } from "../../constants/AlertConstants";

class CheckOutModalStore {
  constructor(commonStore, prospectProfileStore, checkInHistoryStore, prospectsStore) {
    this.authStore = commonStore.authStore;
    this.amtApi = commonStore.amtApi;
    this.alertStore = commonStore.alertStore;
    this.routerStore = commonStore.routerStore;
    this.checkInHistoryStore = checkInHistoryStore;
    this.prospectProfileStore = prospectProfileStore;
    this.prospectsStore = prospectsStore;

    // Register functions for external use
    this.checkOutProspect = this.checkOutProspect.bind(this);

    this.defaults = {
      mostRecentCheckIn: {}
    };

    extendObservable(this, {
      mostRecentCheckIn: this.defaults["mostRecentCheckIn"],
      setMostRecentCheckIn: action((prospectId, checkIn) => {
        this.mostRecentCheckIn[prospectId] = checkIn;
      })
    });

    autorun(() => {
      if (
        this.prospectProfileStore.onProfile() &&
        this.checkInHistoryStore.prospectId &&
        this.prospectProfileStore.showProspectCheckOutModal
      ) {
        this.getMostRecentCheckIn(this.checkInHistoryStore.prospectId);
      }
    });
  }

  checkOutProspect(prospectId) {
    const id = this.mostRecentCheckIn[prospectId].amtProspectCheckInId;
    this.amtApi.checkOut(id).then(response => {
      if (response) {
        this.showCheckOutAlert();
        this.checkInHistoryStore.fetchCheckinHistory();
        // Refresh the page (The Prospect's status on his profile will be updated to Check-Out)
        this.routerStore.refresh();
        this.checkInHistoryStore.setEditMode(false);
      }
    });
  }

  checkOutProspects(prospectIds) {
    // Populate most recent check ins for prospects
    prospectIds.forEach(prospectId => {
      // Get the most recent check in for the prospect
      this.amtApi.getMostRecentCheckIn(prospectId).then(response => {
        if (!!response) {
          const checkIn = response;
          if (checkIn) {
            const checkInId = checkIn.amtProspectCheckInId;
            this.amtApi.checkOut(checkInId).then(response => {
              if (!!response) {
                // Now that there's newly checked out prospects, we need to update the table
                this.prospectsStore.updateProspects();
              }
            });
          }
        }
      });
    });
    this.showCheckOutAlert();
  }

  showCheckOutAlert() {
    this.alertStore.addAlert({
      type: AlertConstants.TYPES.SUCCESS,
      text: "Checked Out"
    });
  }

  // Get the prospects most recent checkin
  getMostRecentCheckIn(prospectId) {
    this.amtApi.getMostRecentCheckIn(prospectId).then(response => {
      if (!!response) {
        this.mostRecentCheckIn[prospectId] = response;
      }
    });
  }

  // Get the facility name prospect is currently checked in to
  getCurrentTrainingFacilityLocation(prospectId) {
    if (prospectId) {
      const checkIn = this.mostRecentCheckIn[prospectId];
      if (checkIn) {
        const { trainingFacilityId } = checkIn;
        if (trainingFacilityId) {
          return this.checkInHistoryStore.getTrainingFacilityLocation(trainingFacilityId);
        }
      }
    }
    return "";
  }
}

export default CheckOutModalStore;
