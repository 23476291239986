import React from "react";
import { Accordion } from "best-common-react";
import PropTypes from "prop-types";
import ProspectsMobileCard from "./ProspectsMobileCard";
import ProspectsMobileContent from "./ProspectsMobileContent";

class ProspectsTableMobile extends React.Component {
  render() {
    return (
      <Accordion>
        <Accordion.Header isToggle={true}>
          <ProspectsMobileCard prospect={this.props.data} />
        </Accordion.Header>
        <Accordion.Body>
          <ProspectsMobileContent prospect={this.props.data} />
        </Accordion.Body>
      </Accordion>
    );
  }
}

ProspectsTableMobile.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired
};

export default ProspectsTableMobile;
