import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import PropTypes from "prop-types";

const LanguageButton = styled.div`
  font-size: 1.125rem;
  padding: 8px 1rem;
  color: ${props => (props.isActive ? "gray !important" : "white !important;")};
  @media (min-width: 768px) {
    background-color: ${props => (props.isActive ? "#01214e !important" : null)};
  }
  &:hover {
    cursor: pointer;
  }
`;

class MobileLanguageSelector extends React.Component {
  getButton(language) {
    const { translationsStore } = this.props.rootStore;
    const english = translationsStore.english;
    const spanish = translationsStore.spanish;
    const displayText = language === "en" ? english : spanish;
    return (
      <LanguageButton
        onClick={() => translationsStore.setLanguage(language)}
        isActive={translationsStore.getCurrentLanguage() === language}
      >
        {displayText}
      </LanguageButton>
    );
  }

  render() {
    return (
      <span>
        {this.getButton("en")}
        {this.getButton("es")}
      </span>
    );
  }
}

MobileLanguageSelector.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(MobileLanguageSelector));
