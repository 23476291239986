import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import NationalIdFormatter from "./NationalIdFormatter";
import DateFormatter from "../utilities/DateFormatter";
import { BestLabel } from "best-common-react";

class PotentialMatchMobileContent extends React.Component {
  render() {
    const { match } = this.props;

    const city = match.birthCity || match.birthCityLkup;
    const dob = match.dateOfBirth || match.birthDateAsString;
    const nationalId = match.nationalIdNumber || match.idNumber || match.nationalIdValue;
    return (
      <div>
        <div>
          <BestLabel>Birth City</BestLabel>
          <div>{city}</div>
        </div>
        <div>
          <BestLabel>Birth Date</BestLabel>
          <div>
            <DateFormatter value={dob} />
          </div>
        </div>
        <div>
          <BestLabel>National ID</BestLabel>
          <NationalIdFormatter value={nationalId} dependentValues={match} />
        </div>
      </div>
    );
  }
}

PotentialMatchMobileContent.propTypes = {
  match: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(PotentialMatchMobileContent));
