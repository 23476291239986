import { action, extendObservable, autorun, decorate, computed } from "mobx";
import { AlertConstants } from "../../constants/AlertConstants";
import { saveAs } from "file-saver";
import _ from "lodash";

class HelpStore {
  constructor(commonStore) {
    this.amtApi = commonStore.amtApi;
    this.alertStore = commonStore.alertStore;
    this.routerStore = commonStore.routerStore;
    this.authStore = commonStore.authStore;

    this.defaults = {
      subject: "",
      message: "",
      documents: [],
      pristineDocuments: [],
      editMode: false
    };

    extendObservable(this, {
      subject: this.defaults.subject,
      message: this.defaults.message,
      documents: this.defaults.documents,
      pristineDocuments: this.defaults.pristineDocuments,
      editMode: this.defaults.editMode,
      setSubject: action(subject => {
        this.subject = subject;
      }),
      setMessage: action(message => {
        this.message = message;
      }),
      setDocuments: action(documents => {
        this.documents = documents;
      }),
      enableEdit: action(() => {
        this.editMode = true;
        this.pristineDocuments = this.documents;
      }),
      disableEdit: action(() => {
        this.editMode = false;
      }),
      cancel: action(() => {
        this.disableEdit();
        this.resetDocuments();
      }),
      save: action(() => {
        this.disableEdit();
        this.amtApi.saveHelpDocuments(this.documents).then(response => {
          if (!!response) {
            this.alertStore.addAlert({
              type: AlertConstants.TYPES.SUCCESS,
              text: "Saved"
            });
            console.log(response);
          }
        });
      }),
      editDocument: action((title, fileAttachmentId, changes) => {
        let newDocuments = _.cloneDeep(this.documents);
        let index = this.documents.findIndex(doc => doc.title === title && doc.fileAttachmentId === fileAttachmentId);
        const documentWithChanges = Object.assign(newDocuments[index], changes);
        newDocuments[index] = documentWithChanges;
        this.setDocuments(newDocuments);
      }),
      deleteDocument: action((title, fileAttachmentId) => {
        let index = this.documents.findIndex(doc => doc.title === title && doc.fileAttachmentId === fileAttachmentId);
        if (index >= 0 && index < this.documents.length) {
          let newDocuments = _.cloneDeep(this.documents);
          newDocuments.splice(index, 1);
          this.setDocuments(newDocuments);
        }
      }),
      addDocuments: action(newFiles => {
        // We will append to the existing documents
        let documents = _.cloneDeep(this.documents);

        // If we weren't given an array, wrap the input
        let filesToAdd;
        if (!Array.isArray(newFiles)) {
          filesToAdd = [newFiles];
        } else {
          filesToAdd = newFiles;
        }
        for (let i = 0; i < filesToAdd.length; i++) {
          let doc = filesToAdd[i];
          let formData = new FormData();
          formData.set("document", doc);
          // Upload the file and set the id
          this.amtApi.uploadFile(formData).then(response => {
            if (!!response) {
              documents.unshift({
                fileAttachmentId: response.fileAttachmentId,
                fileName: response.fileName,
                fileSize: response.fileSize,
                contentType: response.contentType,
                updatedTsAsString: response.updatedTsAsString,
                title: "",
                description: ""
              });
              this.setDocuments(documents);
            }
          });
        }
      }),
      resetForm: action(() => {
        this.subject = this.defaults.subject;
        this.message = this.defaults.message;
      }),
      resetDocuments: action(() => {
        this.documents = this.pristineDocuments;
      }),
      resetStore: action(() => {
        this.subject = this.defaults.subject;
        this.message = this.defaults.message;
        this.documents = this.defaults.documents;
        this.editMode = this.defaults.editMode;
      })
    });

    autorun(() => {
      if (this.onHelpPage()) {
        this.getAllHelpDocuments();
      }
    });
  }

  onHelpPage() {
    return this.routerStore.isHelpTab && this.authStore.loggedIn;
  }

  sendContactForm(subject, message) {
    this.amtApi.contact(subject, message).then(() => {
      this.alertStore.addAlert({
        type: AlertConstants.TYPES.SUCCESS,
        text: "Sent"
      });
      this.resetForm();
    });
  }

  getAllHelpDocuments() {
    this.amtApi.getAllHelpDocuments().then(response => {
      if (!!response) {
        this.documents = response.body;
      }
    });
  }

  getHelpDocumentFile(fileName, fileId, contentType) {
    this.amtApi.getFileById(fileId).then(response => {
      if (!!response) {
        const blob = new Blob([response], { type: contentType });
        saveAs(blob, fileName);
      }
    });
  }

  getDocument(documents, title, fileId) {
    return documents.find(doc => doc.title === title && doc.fileAttachmentId === fileId);
  }

  // Only BOC should see the footer at all
  get isEditable() {
    return this.authStore.loggedIn && this.authStore.isBOC;
  }

  // Save should only be enabled when all required fields are filled in
  get isSaveEnabled() {
    return this.documents.every(doc => !!doc.title);
  }
}

decorate(HelpStore, {
  isEditable: computed
});

export default HelpStore;
