import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestQuickSearch } from "best-common-react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import ProspectsSearchResult from "./ProspectsSearchResult";

const Small = styled.small.attrs({
  className: "text-muted px-1 d-none d-xs-none d-sm-none d-md-inline d-lg-none"
})`
  visibility: hidden;
`;

const Container = styled.div`
  min-width: 140px;
  display: inline-block;
`;

class ProspectsSearch extends React.Component {
  renderSuggestion = (suggestion, { query }) => {
    return <ProspectsSearchResult suggestion={suggestion} query={query} />;
  };

  render() {
    const { prospectsStore, routerStore, translationsStore } = this.props.rootStore;
    let isEnglish = translationsStore.language === "en";
    return (
      <div className={"form-group"}>
        {isEnglish ? (
          <Container>
            <BestLabel>
              <FormattedMessage id="ProspectsSearch.searchProspects" defaultMessage="Search Prospects" />
            </BestLabel>
          </Container>
        ) : (
          <BestLabel>
            <FormattedMessage id="ProspectsSearch.searchProspects" defaultMessage="Search Prospects" />
          </BestLabel>
        )}
        <Small>
          <FormattedMessage
            id="ProspectsSearch.monthsFromSigningDescription"
            defaultMessage="(Eligibility to Enter Club Facilities)"
          />
        </Small>
        <BestQuickSearch
          options={prospectsStore.quickSearchProspects}
          getSuggestionValue={prospect => prospect.prospectId + ""}
          renderSuggestion={this.renderSuggestion}
          getSuggestions={prospectsStore.filterBySearchTerm}
          placeholder="begin typing to search prospects"
          onSuggestionSelected={(event, { suggestionValue }) => {
            routerStore.goToProspect(suggestionValue);
          }}
        />
      </div>
    );
  }
}

ProspectsSearch.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ProspectsSearch));
