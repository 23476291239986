import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import CheckInHistory from "./checkInHistory/CheckInHistory";
import TravelAuthHistory from "./travelAuthFormHistory/TravelAuthHistory";
import ProfilePersonalInfo from "./ProfilePersonalInfo";
import ProfileCountryId from "./ProfileCountryId";
import ProfileTrainerDetails from "./ProfileTrainerDetails";
import ProfileComments from "./ProfileComments";

class ProfileBody extends React.Component {
  render() {
    const { authStore, prospectProfileStore, checkInHistoryStore, travelAuthFormStore } = this.props.rootStore;

    return (
      <div>
        {!prospectProfileStore.newProspectMode && checkInHistoryStore.history.length > 0 ? <CheckInHistory /> : null}
        {!prospectProfileStore.newProspectMode && travelAuthFormStore.history.length > 0 ? <TravelAuthHistory /> : null}
        <ProfilePersonalInfo />
        <ProfileCountryId />
        <ProfileTrainerDetails />
        {authStore.isBOC && <ProfileComments />}
      </div>
    );
  }
}

ProfileBody.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ProfileBody));
