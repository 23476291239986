import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Accordion, FormInput, BestLabel } from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

const TitleHolder = styled.div`
  color: ${props => props.theme["dark-grey"]};
`;

const ProfileComments = ({
  rootStore: {
    prospectProfileStore: { prospect, editMode }
  }
}) => {
  return (
    <div className="mt-2">
      <Accordion startOpen={true}>
        <Accordion.Header isToggle={true}>
          <TitleHolder>
            <FormattedMessage id="Profile.Comments.title" defaultMessage="Comments" />
          </TitleHolder>
        </Accordion.Header>
        <Accordion.Body color="white">
          <FormInput
            type="textarea"
            name="prospect.bocComments"
            label={<FormattedMessage id="Profile.Comments.bocComments" defaultMessage="BOC Comments" />}
            value={prospect.bocComments}
            onChangeFn={event => (prospect.bocComments = event.target.value)}
            editable={editMode}
          />
        </Accordion.Body>
      </Accordion>
    </div>
  );
};

ProfileComments.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ProfileComments));
