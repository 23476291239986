import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { RouteConstants } from "../../constants/RouteConstants";
import { Link } from "react-router-dom";

class ClubProspectsLinkFormatter extends React.Component {
  constructor(props) {
    super(props);
    this.loadProspects = this.loadProspects.bind(this);
  }

  loadProspects() {
    const { prospectsStore } = this.props.rootStore;
    const { orgId } = this.props.dependentValues;
    prospectsStore.setOrgId(orgId);
  }
  render() {
    const name = this.props.value;
    return (
      <Link to={RouteConstants.PROSPECTS} onClick={this.loadProspects}>
        {name}
      </Link>
    );
  }
}

const dependentValueProp = {
  orgId: PropTypes.number
};

ClubProspectsLinkFormatter.propTypes = {
  value: PropTypes.string.isRequired,
  dependentValues: PropTypes.shape(dependentValueProp).isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ClubProspectsLinkFormatter));
