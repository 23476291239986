import React from "react";
import { CardBody, CardContainer, CardFooter, CardHeader, TabSwitcher } from "best-common-react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import ErrorWarningModal from "../components/common/ErrorWarningModal";
import ProfileHeader from "../components/profile/ProfileHeader";
import ProfileSummary from "../components/profile/ProfileSummary";
import TravelAuthForm from "../components/travelAuthForm/TravelAuthForm";
import TravelAuthFooter from "../components/travelAuthForm/footer/TravelAuthFooter";
import CancelModal from "../components/common/CancelModal";
import { FormattedMessage } from "react-intl";
import TravelAuthAuditHistory from "../components/travelAuthForm/TravelAuthAuditHistory";
import { intlShape, defineMessages } from "react-intl";

const messages = defineMessages({
  profile: {
    id: "TravelAuthFormContainer.profile",
    defaultMessage: "Travel Form - {lastName}, {firstName}"
  }
});

class TravelAuthFormContainer extends React.Component {
  render() {
    const { errorWarningStore, travelAuthFormStore, authStore } = this.props.rootStore;
    const { prospectProfileStore } = travelAuthFormStore;
    const { prospect } = prospectProfileStore;

    const tabTitle = this.context.intl.formatMessage(messages.profile, {
      firstName: prospect.firstName,
      lastName: prospect.lastName
    });
    document.title = tabTitle;

    const title = <FormattedMessage id="TravelAuthForm.title" defaultMessage="Travel Authorization" />;
    const travelAuthorization = <TravelAuthForm title={title} />;
    const history = (
      <TravelAuthAuditHistory
        data={travelAuthFormStore.travelAuthForm.auditHistory}
        getFile={travelAuthFormStore.getFileById}
      />
    );

    const tabs = [
      {
        name: title,
        component: travelAuthorization
      },
      {
        name: <FormattedMessage id="TravelAuthForm.history" defaultMessage="History" />,
        component: history
      }
    ];

    return (
      <div>
        <div className={"container-fluid"}>
          <CardContainer>
            <CardHeader>
              <ProfileHeader />
            </CardHeader>
            <CardBody>
              <ErrorWarningModal show={errorWarningStore.showModal} />
              <CancelModal
                show={travelAuthFormStore.showCancelModal}
                close={() => travelAuthFormStore.setShowCancelModal(false)}
                cancel={travelAuthFormStore.cancel}
                back={() => travelAuthFormStore.setShowCancelModal(false)}
              />
              <div className="row">
                <div className="col-md-12 col-lg-2">
                  <ProfileSummary mode="travelAuthForm" />
                </div>
                <div className="col-md-12 col-lg-10">
                  {authStore.isBOC ? <TabSwitcher tabs={tabs} /> : travelAuthorization}
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <TravelAuthFooter />
            </CardFooter>
          </CardContainer>
        </div>
      </div>
    );
  }
}

TravelAuthFormContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

TravelAuthFormContainer.contextTypes = {
  intl: intlShape
};

export default inject("rootStore")(observer(TravelAuthFormContainer));
