import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import Moment from "moment";
import "moment-timezone";
import DateFormatter from "../../utilities/DateFormatter";
import defaultPhoto from "../../../assets/image/default-prospect-photo.png";

const Container = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const Photo = styled.div`
  background-color: black;
  text-align: center;
  overflow: hidden;
  border: 2px solid ${props => props.theme["dark-grey"]};
  width: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ListRow = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  color: ${props => props.theme["dark-grey"]};
  font-size: 0.875rem;
  margin-bottom: 0;
`;

const Data = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
`;

class ProspectCheckOutModalBody extends React.Component {
  render() {
    const { prospectProfileStore, checkOutModalStore, checkInHistoryStore } = this.props.rootStore;
    const { prospect } = prospectProfileStore;
    const fullName = prospectProfileStore.fullName;
    const checkOutDate = new Date();
    return (
      <Container>
        <div className="row">
          <div className="col-sm-12 col-md-4">
            <Photo>
              {checkInHistoryStore.latestPhoto !== null ? (
                <img src={checkInHistoryStore.latestPhoto} alt="" />
              ) : (
                <img src={defaultPhoto} alt="" />
              )}
            </Photo>
          </div>

          <div className="col-sm-12 col-md-8">
            <div className="row">
              <ListRow className="col-12">
                <Label>
                  <FormattedMessage id="ProspectCheckOutModalBody.prospect" defaultMessage="Prospect" />
                </Label>
                <Data>{fullName}</Data>
              </ListRow>
            </div>

            <div className="row">
              <ListRow className="col-12">
                <Label>
                  <FormattedMessage id="ProspectCheckOutModalBody.checkingOutOf" defaultMessage="Checking Out Of" />
                </Label>
                <Data>{checkOutModalStore.getCurrentTrainingFacilityLocation(prospect.amtProspectId)}</Data>
              </ListRow>
            </div>

            <div className="row">
              <ListRow className="col-6">
                <Label>
                  <FormattedMessage id="ProspectCheckOutModalBody.on" defaultMessage="On" />
                </Label>
                <Data>
                  <DateFormatter value={checkOutDate} />
                </Data>
              </ListRow>

              <ListRow className="col-6">
                <Label>
                  <FormattedMessage id="ProspectCheckOutModalBody.at" defaultMessage="At" />
                </Label>
                <Data>
                  {Moment.tz !== undefined
                    ? Moment(checkOutDate.getTime())
                        .tz(Moment.tz.guess())
                        .format("hh:mm a")
                    : ""}
                </Data>
              </ListRow>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

ProspectCheckOutModalBody.propTypes = {
  rootStore: PropTypes.object.isRequired,
  photo: PropTypes.string
};

export default inject("rootStore")(observer(ProspectCheckOutModalBody));
