import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import StatusFormatter from "../prospects/StatusFormatter";
import ProspectLinkFormatter from "../utilities/ProspectLinkFormatter";
import { inject, observer } from "mobx-react";

const ProspectName = styled.div`
  color: black;
`;

class ProfileHeader extends React.Component {
  render() {
    const { prospectProfileStore } = this.props.rootStore;
    const { prospect } = prospectProfileStore;

    const fullName = prospectProfileStore.fullName;
    return (
      <div>
        {prospect.amtProspectId ? (
          <ProspectLinkFormatter value={fullName} row={prospect} />
        ) : (
          <ProspectName>{fullName}</ProspectName>
        )}
        <StatusFormatter value={prospect.status ? prospect.status : ""} />
      </div>
    );
  }
}

ProfileHeader.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ProfileHeader));
