import _ from "lodash";
import TableHelper from "../utilities/TableHelper";

class WidgetStore {
  constructor() {
    this.searchTermInField = this.searchTermInField.bind(this);
    this.formatForComparison = this.formatForComparison.bind(this);
    this.tableHelper = new TableHelper();
  }
  // Format string for comparison in searches
  formatForComparison(str) {
    return !str ? "" : str.replace(/[^A-Za-z]+/g, "").toLowerCase();
  }

  // Returns true if field is defined and field starts with search term
  // Otherwise, returns false.
  searchTermInField(field, searchTerm) {
    return (
      !!field && !!searchTerm && _.startsWith(this.formatForComparison(field), this.formatForComparison(searchTerm))
    );
  }

  /**
   * Sort the data by the selected column and direction.
   **/
  sort(data, searchFilters) {
    let { direction, keys } = searchFilters;
    return this.tableHelper.sort(data, keys, direction);
  }
}

export default WidgetStore;
