import { action, extendObservable, computed, decorate } from "mobx";
import { ErrorWarningConstants } from "../constants/ErrorWarningConstants";

class ErrorWarningStore {
  constructor(routerStore) {
    this.routerStore = routerStore;

    this.defaults = {
      errors: [],
      warnings: [],
      bulkResponse: {
        errors: [],
        successes: [],
        type: "",
        status: ""
      },
      overrideWarningsFlag: false,
      // Function to call when override button is pressed
      overrideHandler: undefined
    };

    extendObservable(this, {
      errors: this.defaults.errors,
      warnings: this.defaults.warnings,
      bulkResponse: this.defaults.bulkResponse,
      overrideWarningsFlag: this.defaults.overrideWarningsFlag,
      overrideHandler: this.defaults.overrideHandler,
      setErrors: action(response => {
        this.errors = response;
      }),
      setWarnings: action(response => {
        this.warnings = response;
      }),
      setBulkResponse: action((response, responseType, responseStatus) => {
        this.bulkResponse.errors = response.errors;
        this.bulkResponse.successes = response.successes;
        this.bulkResponse.type = responseType;
        this.bulkResponseStatus = responseStatus;
      }),
      toggleOverrideWarningsFlag: action(() => {
        this.overrideWarningsFlag = !this.overrideWarningsFlag;
      }),
      setOverrideWarningsFlag: action(bool => {
        this.overrideWarningsFlag = bool;
      }),
      setOverrideHandler: action(fn => {
        this.overrideHandler = () => {
          this.setOverrideWarningsFlag(true);
          fn();
        };
      }),
      resetErrorsAndWarnings: action(() => {
        this.errors = this.defaults.errors;
        this.warnings = this.defaults.warnings;
        this.bulkResponse = this.defaults.bulkResponse;
      }),
      resetStore: action(() => {
        this.resetErrorsAndWarnings();
        this.errors = this.defaults.errors;
        this.warnings = this.defaults.warnings;
        this.bulkResponse = this.defaults.bulkResponse;
        this.overrideWarningsFlag = this.defaults.overrideWarningsFlag;
        this.overrideHandler = this.defaults.overrideHandler;
      }),
      closeModal: action(() => {
        if (this.hasAuthorizationError) {
          this.routerStore.history.goBack();
        }
        this.resetStore();
      })
    });
  }

  get bulkResponseMessage() {
    return `${this.bulkResponse.successes.length} ${this.bulkResponse.type} were successfully ${this.bulkResponse.status}, ${this.bulkResponse.errors.length} ${this.bulkResponse.type} had errors`;
  }

  get hasErrors() {
    return this.errors && this.errors.length > 0;
  }

  get hasAuthorizationError() {
    return this.hasErrors && this.errors.filter(e => e.code === ErrorWarningConstants.CODES.VIEW_DENIED).length > 0;
  }

  get hasWarnings() {
    return this.warnings && this.warnings.length > 0;
  }

  get hasBulkResponses() {
    return this.hasBulkResponseErrors || this.hasBulkResponseSuccesses;
  }

  get hasBulkResponseErrors() {
    return this.bulkResponse.errors && this.bulkResponse.errors.length > 0;
  }

  get hasBulkResponseSuccesses() {
    return this.bulkResponse.successes && this.bulkResponse.successes.length > 0;
  }

  get showModal() {
    return this.hasErrors || this.hasWarnings || this.hasBulkResponses;
  }

  // Get the first code in the appropriate collection
  // There may be a better way to do this. Unsure.
  get errorWarningCode() {
    if (this.hasWarnings) {
      return this.warnings[0].code;
    } else if (this.hasErrors) {
      return this.errors[0].code;
    } else if (this.hasBulkResponseErrors) {
      return this.bulkResponse.errors[0].code;
    }
    return null;
  }
}

decorate(ErrorWarningStore, {
  bulkResponseMessage: computed,
  hasBulkResponses: computed,
  hasBulkResponseErrors: computed,
  hasBulkResponseSuccesses: computed,
  hasErrors: computed,
  hasAuthorizationError: computed,
  hasWarnings: computed,
  showModal: computed,
  errorWarningCode: computed
});

export default ErrorWarningStore;
