import React from "react";
import styled from "styled-components";
import { Accordion } from "best-common-react";
import { FormattedMessage } from "react-intl";
import CheckInHistoryTable from "./CheckInHistoryTable";
import DeleteCheckInModalConfirmation from "../checkInModal/DeleteCheckInModalConfirmation";

const TitleHolder = styled.div`
  color: ${props => props.theme["dark-grey"]};
`;

class CheckInHistory extends React.Component {
  render() {
    return (
      <div className="mt-2">
        <DeleteCheckInModalConfirmation />
        <Accordion startOpen={true}>
          <Accordion.Header isToggle={true}>
            <TitleHolder>
              <FormattedMessage id="CheckInHistory.checkInHistory" defaultMessage="Check In History" />
            </TitleHolder>
          </Accordion.Header>
          <Accordion.Body color="white">
            <CheckInHistoryTable />
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

export default CheckInHistory;
