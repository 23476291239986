import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel } from "best-common-react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import TravelFormsLinkFormatter from "../../travelAuthFormsWidget/TravelFormsLinkFormatter";
import DateFormatter from "../../utilities/DateFormatter";
import BooleanCheckFormatter from "../../utilities/BooleanCheckFormatter";

const Card = styled.div`
  border: solid 1px ${props => props.theme["grey"]};
  background-color: ${props => props.theme["almost-white"]};
  padding: 10px;
`;

const TravelAuthDetails = styled.div`
  font-weight: 400;
`;

class TravelAuthHistoryTableMobile extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Card>
        <TravelAuthDetails className="row">
          <div className="col-6">
            <TravelFormsLinkFormatter
              value={data.status}
              dependentValues={{ amtTravelAuthFormId: data.amtTravelAuthFormId }}
            />
          </div>
          <div className="col-6 text-right">
            <div>{data.orgCode}</div>
          </div>
        </TravelAuthDetails>

        <TravelAuthDetails className="row">
          <div className="col-6">
            <BestLabel>
              <FormattedMessage id="TravelAuthHistoryTableMobile.firstTravelDate" defaultMessage="First Travel Date" />
            </BestLabel>
            <div>
              <DateFormatter value={data.arrivalTs} />
            </div>
          </div>

          <div className="col-6">
            <BestLabel>
              <FormattedMessage
                id="TravelAuthHistoryTableMobile.dateTravelCompleted"
                defaultMessage="Date Travel Completed"
              />
            </BestLabel>
            <div>
              <DateFormatter value={data.departureTs} />
            </div>
          </div>
        </TravelAuthDetails>

        <TravelAuthDetails className="row">
          <div className="col-6">
            <BestLabel>
              <FormattedMessage id="TravelAuthHistoryTableMobile.submitted" defaultMessage="Submitted" />
            </BestLabel>
            <div>
              <DateFormatter value={data.submittedTs} />
            </div>
          </div>

          <div className="col-6">
            <BestLabel>
              <FormattedMessage id="TravelAuthHistoryTableMobile.approved" defaultMessage="Approved" />
            </BestLabel>
            <div>
              <DateFormatter value={data.approvedTs} />
            </div>
          </div>
        </TravelAuthDetails>

        {data.travelIncompleteFlag && (
          <TravelAuthDetails className="row">
            <div className="col-6">
              <BestLabel>
                <FormattedMessage id="TravelAuthHistoryTableMobile.didNotTravel" defaultMessage="Did Not Travel" />
              </BestLabel>
              <div>
                <BooleanCheckFormatter value={data.travelIncompleteFlag} align="left" />
              </div>
            </div>
          </TravelAuthDetails>
        )}
      </Card>
    );
  }
}

TravelAuthHistoryTableMobile.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TravelAuthHistoryTableMobile));
