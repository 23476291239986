import React from "react";
import { DatePickerInput } from "best-common-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";
import { CommonConstants } from "../../../constants/CommonConstants";

const StyledDate = styled(DatePickerInput)`
  height: 32px;
`;

const CheckInDate = ({
  row,
  value,
  editable,
  onChange,
  minDate = CommonConstants.DATES.MINIMUM_DATE,
  maxDate,
  ...rest
}) => {
  // Only allow BOC to edit checkout ts of entries that already have a checkout ts
  // Otherwise they can edit a check in that does not yet have a corresponding checkout
  if (!editable) {
    if (!value) {
      return null;
    }
    return moment(value).format("MM/DD/YYYY");
  }

  return (
    <StyledDate
      strictParsing
      editable={editable}
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
};

CheckInDate.propTypes = {
  // The data-row for this checkin
  row: PropTypes.object.isRequired,
  // The value of the checkedoutts
  value: PropTypes.string,
  editable: PropTypes.bool,
  // Function to run on change of the value
  onChange: PropTypes.func.isRequired,
  // The minumum allowable date in the selector
  minDate: PropTypes.instanceOf(Date),
  // The maximum allowable date in the selector
  maxDate: PropTypes.instanceOf(Date)
};

export default CheckInDate;
