import React from "react";
import { CardBody, CardContainer, CardHeader, CardFooter } from "best-common-react";
import { intlShape, defineMessages, FormattedMessage } from "react-intl";
import { Helmet } from "react-helmet";
import ContactUs from "../components/help/ContactUs";
import HelpDocuments from "../components/help/HelpDocuments";
import HelpFooter from "../components/help/HelpFooter";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

const messages = defineMessages({
  title: {
    id: "HelpContainer.title",
    defaultMessage: "Help"
  }
});

class HelpContainer extends React.Component {
  render() {
    const { helpStore } = this.props.rootStore;
    const { isEditable, enableEdit, isSaveEnabled, cancel, editMode, save } = helpStore;

    return (
      <div>
        <Helmet title={this.context.intl.formatMessage(messages.title)} />
        <div className={"container-fluid"}>
          <CardContainer>
            <CardHeader>
              <FormattedMessage id="HelpContainer.header" defaultMessage="Help" />
            </CardHeader>
            <CardBody>
              <div className="row">
                {!editMode && <ContactUs />}
                <HelpDocuments />
              </div>
            </CardBody>
            {isEditable ? (
              <CardFooter>
                <HelpFooter
                  editMode={editMode}
                  enableEdit={enableEdit}
                  isSaveEnabled={isSaveEnabled}
                  cancel={cancel}
                  save={save}
                />
              </CardFooter>
            ) : (
              <div />
            )}
          </CardContainer>
        </div>
      </div>
    );
  }
}

HelpContainer.contextTypes = {
  intl: intlShape
};

HelpContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(HelpContainer));
