import React from "react";
import { inject, observer } from "mobx-react";
import ProspectsDropdown from "./ProspectsDropdown";
import ProspectsFilter from "./StatusFilters";
import ProspectsSearch from "./search/ProspectsSearch";
import styled from "styled-components";
import { BestLabel, BestSelect, MobileView, PrimaryButton } from "best-common-react";
import { RouteConstants } from "../../constants/RouteConstants";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import className from "classnames";

const HeaderWrapper = styled.div.attrs(() => ({
  className: "mb-3"
}))`
  margin-bottom: 20px;
`;

const NewProspectButton = styled(PrimaryButton)`
  width: 100%;
  margin-bottom: 20px;
`;

class ProspectsHeader extends React.Component {
  render() {
    const { authStore, lookupStore, prospectsStore, routerStore } = this.props.rootStore;
    const orgs = lookupStore.getDropdownOptionsWithInitialOption(lookupStore.lookups.ORGS_ONLY_SHORT, "All");

    const prospectSearchClasses = className(
      "col-xs-12",
      "col-sm-12",
      { "col-md-3": authStore.isBOC, "col-md-4": !authStore.isBOC },
      { "col-lg-3": authStore.isBOC, "col-lg-5": !authStore.isBOC },
      { "col-xl-3": authStore.isBOC, "col-xl-5": !authStore.isBOC },
      "order-md-4",
      "order-lg-4"
    );

    const prospectDropdownClasses = className(
      "col-xs-12",
      "col-sm-12",
      { "col-md-3": authStore.isBOC, "col-md-4": !authStore.isBOC },
      "col-lg-5",
      "col-xl-5",
      "order-md-4",
      "order-lg-4"
    );

    return (
      <HeaderWrapper>
        <div className="col-12">
          <div className="row">
            {authStore.isBOC ? (
              <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 order-md-1 order-lg-1">
                <BestLabel name="club" label="Club">
                  <FormattedMessage id="ProspectsHeader.club" defaultMessage="Club" />
                </BestLabel>
                <BestSelect
                  name="org"
                  value={lookupStore.getDropdownItem(orgs, prospectsStore.orgId)}
                  onChange={org => prospectsStore.setOrgId(org.value * 1)}
                  options={orgs}
                />
              </div>
            ) : (
              ""
            )}
            <div className={prospectSearchClasses}>
              <ProspectsSearch />
            </div>
            <div className={prospectDropdownClasses}>
              <ProspectsDropdown />
            </div>
            {authStore.isAdmin ? (
              <MobileView>
                <div className="col-12">
                  <NewProspectButton onClick={() => routerStore.history.push(RouteConstants.NEW_PROSPECT)}>
                    <FormattedMessage id="ProspectsHeader.newProspect" defaultMessage="New Prospect" />
                  </NewProspectButton>
                </div>
              </MobileView>
            ) : (
              ""
            )}
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 order-md-2 order-lg-2">
              <ProspectsFilter />
            </div>
          </div>
        </div>
      </HeaderWrapper>
    );
  }
}

ProspectsHeader.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ProspectsHeader));
