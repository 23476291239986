import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { CheckInConstants } from "../../../constants/CheckInConstants";

const CheckInModalPhotoErrors = ({ rootStore: { checkInModalStore } }) => {
  const getError = errorCode => {
    if (photoErrors.includes(CheckInConstants.errors.PHOTO_TOO_LARGE)) {
      // TODO: Make this translatable
      return `Photo is too large.  Please reduce photo size to less than ${checkInModalStore.maxPhotoMB} to ensure the photo is uploaded successfully.`;
    }

    if (photoErrors.includes(CheckInConstants.errors.PHOTO_FORMAT_INVALID)) {
      return "Invalid file format. Please upload your file as a .jpg";
    }
  };

  const { photoErrors } = checkInModalStore;

  if (photoErrors.length > 0) {
    const renderErrors = () => {
      const Error = styled.div`
        text-align: center;
        color: ${props => props.theme["mlb-red"]};
      `;
      return photoErrors.map(errorCode => {
        return <Error key={errorCode}>{getError(errorCode)}</Error>;
      });
    };

    return <div>{renderErrors()}</div>;
  } else {
    return null;
  }
};

CheckInModalPhotoErrors.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CheckInModalPhotoErrors));
