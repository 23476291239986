class BulkRequestHelper {
  static getRequestType(url) {
    if (url.indexOf("travelAuthForm")) {
      return "Travel Authorization Form(s)";
    }
  }
  static getStatus(url) {
    if (url.indexOf("SM")) {
      return "submitted";
    } else if (url.indexOf("AP")) {
      return "approved";
    } else if (url.indexOf("RE")) {
      return "rejected";
    }
  }
}

export default BulkRequestHelper;
