import React from "react";
import { CardBody, CardContainer, CardFooter, CardHeader } from "best-common-react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import ErrorWarningModal from "../components/common/ErrorWarningModal";
import ProfileHeader from "../components/profile/ProfileHeader";
import ProfileSummary from "../components/profile/ProfileSummary";
import ProfileFooter from "../components/profile/ProfileFooter";
import ProfileBody from "../components/profile/ProfileBody";
import ProspectCheckInModal from "../components/profile/checkInModal/ProspectCheckInModal";
import ProspectCheckOutModal from "../components/profile/checkOutModal/ProspectCheckOutModal";
import ProspectPhotoModal from "../components/profile/ProspectPhotoModal";
import CancelModal from "../components/common/CancelModal";
import DeleteProspectModal from "../components/profile/DeleteProspectModal";
import { intlShape, defineMessages } from "react-intl";
import MonthsFromSigningModal from "../components/profile/monthsFromSigningModal/MonthsFromSigningModal";

const messages = defineMessages({
  profile: {
    id: "ProspectProfileContainer.profile",
    defaultMessage: "Profile - {lastName}, {firstName}"
  }
});

class ProspectProfileContainer extends React.Component {
  render() {
    const { errorWarningStore, checkInHistoryStore, checkOutModalStore, prospectProfileStore } = this.props.rootStore;

    const { prospect } = prospectProfileStore;

    const title = this.context.intl.formatMessage(messages.profile, {
      firstName: prospect.firstName,
      lastName: prospect.lastName
    });
    document.title = title;

    return (
      <div>
        <div id="profile-container" className={"container-fluid"}>
          <CardContainer>
            <CardHeader>
              <ProfileHeader />
            </CardHeader>
            <CardBody>
              <ErrorWarningModal show={errorWarningStore.showModal} />
              <CancelModal
                show={prospectProfileStore.showCancelModal}
                close={() => prospectProfileStore.setShowCancelModal(false)}
                cancel={() => {
                  prospectProfileStore.cancel();
                  checkInHistoryStore.cancel();
                }}
                back={() => prospectProfileStore.setShowCancelModal(false)}
              />
              <ProspectCheckInModal show={prospectProfileStore.showProspectCheckInModal} />
              <ProspectCheckOutModal
                show={prospectProfileStore.showProspectCheckOutModal}
                close={prospectProfileStore.closeProspectCheckOutModal}
                checkOut={() => {
                  prospectProfileStore.closeProspectCheckOutModal();
                  checkOutModalStore.checkOutProspect(prospect.amtProspectId);
                }}
              />
              <ProspectPhotoModal
                show={prospectProfileStore.showProspectPhotoModal}
                close={prospectProfileStore.closeProspectPhotoModal}
              />
              <MonthsFromSigningModal show={prospectProfileStore.showMonthsFromSigningModal} />
              <DeleteProspectModal show={prospectProfileStore.showDeleteProspectModal} />
              <div className="row">
                <div className="col-md-12 col-lg-2">
                  <ProfileSummary mode="profile" />
                </div>
                <div className="col-md-12 col-lg-10">
                  <ProfileBody />
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <ProfileFooter />
            </CardFooter>
          </CardContainer>
        </div>
      </div>
    );
  }
}

ProspectProfileContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

ProspectProfileContainer.contextTypes = {
  intl: intlShape
};

export default inject("rootStore")(observer(ProspectProfileContainer));
