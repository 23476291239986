import React from "react";
import { Accordion, Icon } from "best-common-react";
import PropTypes from "prop-types";
import PotentialMatchMobileCard from "./PotentialMatchMobileCard";
import PotentialMatchMobileContent from "./PotentialMatchMobileContent";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { ProspectsConstants } from "../../constants/ProspectsConstants";
import { FormattedMessage } from "react-intl";

const ButtonHolder = styled.div`
  height: 30px;
  width: 100%;
  background-color: white;
  border: 1px solid ${props => props.theme["grey"]};
  border-bottom: none;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`;

const Wrapper = styled.div`
  > div:first-child {
    margin-top: 0px !important;
  }
`;

class PotentialMatchTableMobile extends React.Component {
  constructor(props) {
    super(props);

    this.renderCheckInIcon = this.renderCheckInIcon.bind(this);
    this.renderAddProspectIcon = this.renderAddProspectIcon.bind(this);
  }
  renderCheckInIcon() {
    const { data, rootStore } = this.props;
    const { checkInModalStore, routerStore } = rootStore;
    // If the Club has a profile for the potential match, there will be a check in icon
    // Don't show for prospects who are already checked in
    const showCheckInIcon = data.status !== ProspectsConstants.CHECKED_IN && data.onCurrentProspectList;
    return (
      showCheckInIcon && (
        <span
          style={{ float: "right" }}
          onClick={() => {
            // clicking the check in icon will load the prospect's profile and launch the check in flow
            routerStore.goToProspect(data.amtProspectId);
            // Run rule checks for the check in modal
            checkInModalStore.canCheckIn();
          }}
        >
          <Icon
            iconName="fa-calendar-check"
            className="fa-md"
            title={
              <FormattedMessage id="PotentialMatchTableMobile.checkInProspect" defaultMessage="Check In Prospect" />
            }
          />
        </span>
      )
    );
  }
  renderAddProspectIcon() {
    const { data, rootStore } = this.props;
    const { newProspectStore } = rootStore;
    // If the Club does not have a profile for the potential match, there will be an add player icon
    const showAddProspectIcon = !data.onCurrentProspectList;
    return (
      showAddProspectIcon && (
        <span style={{ float: "left" }} onClick={() => newProspectStore.addToProspectList(data)}>
          <Icon
            iconName="fa-user-plus"
            className="fa-md"
            title={
              <FormattedMessage
                id="PotentialMatchTableMobile.addProspect"
                defaultMessage="Add to current Prospect list"
              />
            }
          />
        </span>
      )
    );
  }
  render() {
    const { data } = this.props;
    // A match will have one of these IDs, while the entered prospect will have neither
    const isMatch = !!data.playerId || !!data.amtProspectId;
    return (
      <div>
        {isMatch ? (
          <ButtonHolder>
            {this.renderCheckInIcon()}
            {this.renderAddProspectIcon()}
          </ButtonHolder>
        ) : (
          ""
        )}
        <Wrapper>
          <Accordion>
            <Accordion.Header isToggle={true}>
              <PotentialMatchMobileCard isMatch={isMatch} match={this.props.data} />
            </Accordion.Header>
            <Accordion.Body>
              <PotentialMatchMobileContent match={this.props.data} />
            </Accordion.Body>
          </Accordion>
        </Wrapper>
      </div>
    );
  }
}

PotentialMatchTableMobile.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(PotentialMatchTableMobile));
