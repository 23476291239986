import React from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalHeaderWithTitleAndClose,
  AccordionTableWrapper,
  MobileView,
  DesktopTabletView
} from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CurrentCheckInTableMobile from "./CurrentCheckInTableMobile";
import DateFormatter from "../utilities/DateFormatter";
import ProspectLinkFormatter from "../utilities/ProspectLinkFormatter";
import { FormattedMessage, intlShape, defineMessages } from "react-intl";

const ConfirmMessage = styled.div.attrs(() => ({
  className: "mb-3"
}))`
  font-size: 1.125rem;
  font-weight: 400;
`;

const ModalFooterDiv = styled.div.attrs({
  id: "modal-footer"
})`
  justify-content: space-between;
  background-color: #ececec;
  padding: 16px;
`;

ModalFooterDiv.displayName = "Footer";

const FooterButtons = styled.div.attrs({
  className: "d-flex"
})`
  justify-content: flex-end;
  button:not(:last-child) {
    margin-right: 0.625rem;
  }
`;

const CheckInModalWrapper = styled.div`
  #accordion-table-wrapper { 
    .react-grid-Container {
      .react-grid-Main {
       .react-grid-Grid {
        div:nth-child(2) {
         .react-grid-Viewport {
          .react-grid-Canvas {
            overflow: initial !important;
          }
        }
      }
    }
  }
`;

const lastName = <FormattedMessage id="CurrentCheckInsModal.lastName" defaultMessage="Last" />,
  firstName = <FormattedMessage id="CurrentCheckInsModal.firstName" defaultMessage="First" />,
  extLast = <FormattedMessage id="CurrentCheckInsModal.extLast" defaultMessage="Ext Last" />,
  middleName = <FormattedMessage id="CurrentCheckInsModal.middleName" defaultMessage="Middle" />,
  lastCheckIn = <FormattedMessage id="CurrentCheckInsModal.lastCheckIn" defaultMessage="Last Check In" />,
  location = <FormattedMessage id="CurrentCheckInsModal.location" defaultMessage="Location" />;

class CurrentCheckInsModal extends React.Component {
  constructor() {
    super();
    this.cols = [
      {
        key: "lastName",
        name: lastName,
        width: 100,
        formatter: ProspectLinkFormatter,
        getRowMetaData: row => row
      },
      {
        key: "firstName",
        name: firstName,
        width: 100,
        formatter: ProspectLinkFormatter,
        getRowMetaData: row => row
      },
      {
        key: "extendedLastName",
        name: extLast,
        width: 100
      },
      {
        key: "middleName",
        name: middleName,
        width: 100
      },
      {
        key: "lastCheckInTs",
        name: lastCheckIn,
        width: 120,
        formatter: DateFormatter
      },
      {
        key: "location",
        name: location
      }
    ];
  }
  render() {
    const { clubsStore } = this.props.rootStore;

    const messages = defineMessages({
      prospectThisPeriod: {
        id: "CurrentCheckInsModal.prospectThisPeriod",
        defaultMessage: "{checkInCount} prospect this period"
      },
      prospectsThisPeriod: {
        id: "CurrentCheckInsModal.prospectsThisPeriod",
        defaultMessage: "{checkInCount} prospects this period"
      }
    });
    const close = <FormattedMessage id="CurrentCheckInsModal.close" defaultMessage="Close" />;

    const checkInCount = clubsStore.checkIns.length;

    const message = checkInCount > 1 ? messages.prospectsThisPeriod : messages.prospectThisPeriod;

    return (
      <div>
        <Modal show={this.props.show} onClose={() => {}} size="lg">
          <ModalHeaderWithTitleAndClose
            title={clubsStore.checkInOrgName}
            close={() => {
              clubsStore.setShowCheckInsModal(false);
            }}
          />
          <ModalBody>
            <CheckInModalWrapper>
              <ConfirmMessage>{this.context.intl.formatMessage(message, { checkInCount })}</ConfirmMessage>
              <AccordionTableWrapper
                id={"entered-prospect-table"}
                data={clubsStore.checkIns}
                columns={this.cols}
                accordion={CurrentCheckInTableMobile}
                headerRowHeight={35}
                height={50 + checkInCount * 35}
              />
            </CheckInModalWrapper>
          </ModalBody>
          <ModalFooterDiv>
            <FooterButtons>
              <MobileView>
                <LightButton onClick={() => clubsStore.setShowCheckInsModal(false)} style={{ width: "100%" }}>
                  {close}
                </LightButton>
              </MobileView>
              <DesktopTabletView>
                <LightButton onClick={() => clubsStore.setShowCheckInsModal(false)}>{close}</LightButton>
              </DesktopTabletView>
            </FooterButtons>
          </ModalFooterDiv>
        </Modal>
      </div>
    );
  }
}

CurrentCheckInsModal.defaultProps = {
  show: false
};

CurrentCheckInsModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  show: PropTypes.bool
};

CurrentCheckInsModal.contextTypes = {
  intl: intlShape
};

export default inject("rootStore")(observer(CurrentCheckInsModal));
