import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import TravelAuthFormSection from "./TravelAuthFormSection";
import { FormInput, BestLabel, FormColumn2Wide } from "best-common-react";
import Moment from "moment";
import DateFormatter from "../../utilities/DateFormatter";
import { FormattedMessage } from "react-intl";

const Note = styled.span`
  font-size: 0.688rem;
`;

const NoteText = styled.span`
  color: ${props => props.theme["dark-grey"]};
`;
const NoteIndicator = styled.span`
  color: ${props => props.theme["mlb-red"]};
`;

const RelationshipWrapper = styled.span`
  > div {
    margin-bottom: 0px !important;
  }
`;

const CheckBox = styled.input`
  margin-right: 5px;
  margin-top: 5px;
`;

const LodgingWrapper = styled.div.attrs(() => ({
  className: "co-12 form-group"
}))`
  margin-top: 1rem;
`;
class ParentGuardian extends React.Component {
  render() {
    const { travelAuthFormStore, lookupStore } = this.props.rootStore;
    const { travelAuthForm } = travelAuthFormStore;
    const relationshipsForDropdown = lookupStore.getDropdownOptions(lookupStore.lookups.AMT_TRAVEL_AUTH_RELATIONS);
    return (
      <div className="col-12">
        <TravelAuthFormSection
          title={<FormattedMessage id="ParentGuardian.title" defaultMessage="Accompanying Parent/Guardian" />}
        >
          <div className="row">
            <div className="col-12 form-group">
              <BestLabel>
                <CheckBox
                  name="clubProvidedGuardianLodgingCheckbox"
                  type="checkbox"
                  checked={!!travelAuthForm.accompaniedByParentGuardian}
                  disabled={!travelAuthFormStore.isFieldEditable("accompaniedByParentGuardian")}
                  onChange={event => travelAuthFormStore.setAccompaniedByParentGuardian(event.target.checked)}
                />
                <span>
                  <FormattedMessage
                    id="ParentGuardian.accompaniedByParentGuardian"
                    defaultMessage="The Player will be accompanied by a Parent/Guardian"
                  />
                </span>
              </BestLabel>
            </div>
            {travelAuthForm.accompaniedByParentGuardian ? (
              <React.Fragment>
                <FormColumn2Wide>
                  <FormInput
                    name="guardianFirstName"
                    label={<FormattedMessage id="ParentGuardian.guardianFirstName" defaultMessage="First Name" />}
                    value={travelAuthForm.guardianFirstName}
                    onChangeFn={name => (travelAuthForm.guardianFirstName = name)}
                    editable={travelAuthFormStore.isFieldEditable("guardianFirstName")}
                    isRequired={true}
                  />
                </FormColumn2Wide>
                <FormColumn2Wide>
                  <FormInput
                    name="guardianLastName"
                    label={<FormattedMessage id="ParentGuardian.guardianLastName" defaultMessage="Last Name" />}
                    value={travelAuthForm.guardianLastName}
                    onChangeFn={name => (travelAuthForm.guardianLastName = name)}
                    editable={travelAuthFormStore.isFieldEditable("guardianLastName")}
                    isRequired={true}
                  />
                </FormColumn2Wide>
                <FormColumn2Wide>
                  <RelationshipWrapper>
                    <FormInput
                      name="relationship"
                      type="select"
                      label={
                        <FormattedMessage id="ParentGuardian.relationship" defaultMessage="Relationship to Player" />
                      }
                      value={lookupStore.getDropdownItem(
                        relationshipsForDropdown,
                        travelAuthForm.guardianRelationToPlayerId
                      )}
                      options={relationshipsForDropdown}
                      onChangeFn={relationship => (travelAuthForm.guardianRelationToPlayerId = relationship.value)}
                      editable={travelAuthFormStore.isFieldEditable("relationship")}
                      isRequired={true}
                    />
                    {travelAuthFormStore.isFieldEditable("relationship") &&
                    !travelAuthFormStore.isApproved &&
                    travelAuthForm.guardianRelationToPlayerId === 1 ? (
                      <Note>
                        <NoteIndicator>*</NoteIndicator>
                        <NoteText>
                          <FormattedMessage
                            id="ParentGuardian.documentsRequiredNote"
                            defaultMessage="Please attach documentation that proves legal guardianship"
                          />
                        </NoteText>
                      </Note>
                    ) : (
                      ""
                    )}
                  </RelationshipWrapper>
                </FormColumn2Wide>
                <LodgingWrapper>
                  <BestLabel>
                    <CheckBox
                      name="clubProvidedGuardianLodgingCheckbox"
                      type="checkbox"
                      disabled={!travelAuthFormStore.isFieldEditable("clubProvidedGuardianLodgingCheckbox")}
                      checked={!!travelAuthForm.clubProvidedGuardianLodging}
                      onChange={event => travelAuthFormStore.setClubProvidedGuardianLodging(event.target.checked)}
                    />
                    <span>
                      <FormattedMessage
                        id="ParentGuardian.clubProvidingLodging"
                        defaultMessage="The Club is providing lodging for parent/guardian"
                      />
                    </span>
                  </BestLabel>
                </LodgingWrapper>
                <FormColumn2Wide>
                  <FormInput
                    name="hotelName"
                    label={<FormattedMessage id="ParentGuardian.hotelName" defaultMessage="Hotel Name" />}
                    isRequired={travelAuthForm.clubProvidedGuardianLodging}
                    value={travelAuthForm.hotelName}
                    onChangeFn={name => (travelAuthForm.hotelName = name)}
                    editable={travelAuthFormStore.isFieldEditable("hotelName")}
                  />
                </FormColumn2Wide>
                <FormColumn2Wide>
                  <FormInput
                    name="hotelCity"
                    label={<FormattedMessage id="ParentGuardian.hotelCity" defaultMessage="Hotel City" />}
                    isRequired={travelAuthForm.clubProvidedGuardianLodging}
                    value={travelAuthForm.hotelCity}
                    onChangeFn={city => (travelAuthForm.hotelCity = city)}
                    editable={travelAuthFormStore.isFieldEditable("hotelCity")}
                  />
                </FormColumn2Wide>
                <FormColumn2Wide>
                  <FormInput
                    type="date"
                    name="checkInDate"
                    label={<FormattedMessage id="ParentGuardian.checkInDate" defaultMessage="Check-in Date" />}
                    isRequired={travelAuthForm.clubProvidedGuardianLodging}
                    value={travelAuthFormStore.travelAuthForm.hotelCheckinTsAsString || null}
                    onChangeFn={date =>
                      (travelAuthFormStore.travelAuthForm.hotelCheckinTsAsString = date
                        ? Moment(date).format("MM/DD/YYYY")
                        : date)
                    }
                    editable={travelAuthFormStore.isFieldEditable("checkInDate")}
                  />
                </FormColumn2Wide>
                <FormColumn2Wide>
                  <FormInput
                    type="date"
                    name="checkOutDate"
                    label={<FormattedMessage id="ParentGuardian.checkOutDate" defaultMessage="Check-out Date" />}
                    isRequired={travelAuthForm.clubProvidedGuardianLodging}
                    value={travelAuthFormStore.travelAuthForm.hotelCheckoutTsAsString || null}
                    onChangeFn={date =>
                      (travelAuthFormStore.travelAuthForm.hotelCheckoutTsAsString = date
                        ? Moment(date).format("MM/DD/YYYY")
                        : date)
                    }
                    editable={travelAuthFormStore.isFieldEditable("checkOutDate")}
                  />
                </FormColumn2Wide>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </TravelAuthFormSection>
      </div>
    );
  }
}

ParentGuardian.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ParentGuardian));
