import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { inject } from "mobx-react";
import TravelFormsLinkFormatter from "./TravelFormsLinkFormatter";

const RowWrapper = styled.span`
  display: flex;
`;

const TopColumn = styled.span`
  flex: 33%;
`;

const Card = styled.span`
  font-weight: 400;
`;

const StatusWrapper = styled.span`
  margin-top: 10px;
  float: left;
`;

const PrimaryInfo = styled.div`
  color: black;
  font-size: 14px;
`;

const SecondaryInfo = styled.div`
  color: ${props => props.theme["dark-grey"]};
  font-size: 12px;
`;

class TravelFormsMobileCard extends React.Component {
  render() {
    const { travelAuthForm } = this.props;
    const { authStore } = this.props.rootStore;
    return (
      <Card>
        <RowWrapper>
          <TopColumn>
            <PrimaryInfo>{travelAuthForm.lastName}</PrimaryInfo>
            <SecondaryInfo>{travelAuthForm.extendedLastName}</SecondaryInfo>
          </TopColumn>
          <TopColumn>
            <PrimaryInfo>{travelAuthForm.firstName}</PrimaryInfo>
            <SecondaryInfo>{travelAuthForm.middleName}</SecondaryInfo>
          </TopColumn>
          {authStore.isBOC ? (
            <TopColumn>
              <PrimaryInfo>{travelAuthForm.orgCode}</PrimaryInfo>
            </TopColumn>
          ) : null}
        </RowWrapper>
        <StatusWrapper>
          <TravelFormsLinkFormatter value={travelAuthForm.status} dependentValues={travelAuthForm} />
        </StatusWrapper>
      </Card>
    );
  }
}

TravelFormsMobileCard.propTypes = {
  travelAuthForm: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(TravelFormsMobileCard);
