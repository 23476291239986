import React from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  StickyFooterButtons,
  RequiredInputWithLabel,
  TextArea
} from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const ResubmissionComments = styled.div.attrs(() => ({
  className: "form-group"
}))`
  margin-top: 5%;
  margin-bottom: 10%;
`;

const SubTitle = styled.div`
  font-size: 1.125rem;
`;

const Hint = styled.div.attrs(() => ({
  className: "hint-message"
}))`
  margin-top: 2.5%;
  color: ${props => props.theme["dark-grey"]};
  font-size: 0.875rem;
`;

const ResubmitModal = ({ rootStore: { travelAuthFormStore, prospectProfileStore }, show = false }) => {
  return (
    <div>
      <Modal show={show} onClose={() => {}} size="lg">
        <ModalHeaderWithTitleAndClose
          title={<FormattedMessage id="ResubmitModal.title" defaultMessage="Are you sure?" />}
          close={() => {
            travelAuthFormStore.setShowResubmitModal(false);
          }}
        />
        <ModalBody>
          <SubTitle>
            <FormattedMessage
              id="ResubmitModal.body"
              defaultMessage="The Travel Form will move back to Preliminary and will need to be submitted again to BOC for Approval"
            />
          </SubTitle>
          <Hint>
            <FormattedMessage id="ResubmitModal.hint" defaultMessage="Do you wish to continue?" />
          </Hint>
          <ResubmissionComments>
            <RequiredInputWithLabel
              name="resubmissionComments"
              label={
                <FormattedMessage id="ResubmitModal.resubmissionComments" defaultMessage="Resubmission Comments" />
              }
            >
              <TextArea
                id="resubmissionComments"
                value={travelAuthFormStore.travelAuthForm.resubmittedComments}
                onChange={event => (travelAuthFormStore.travelAuthForm.resubmittedComments = event.target.value)}
              />
            </RequiredInputWithLabel>
          </ResubmissionComments>
        </ModalBody>
        <ModalFooter>
          <StickyFooterButtons>
            <LightButton
              disabled={!travelAuthFormStore.travelAuthForm.resubmittedComments}
              onClick={() => {
                travelAuthFormStore.resubmit(prospectProfileStore.prospect.amtProspectId);
              }}
            >
              <FormattedMessage id="ResubmitModal.continue" defaultMessage="Continue" />
            </LightButton>
            <LightButton onClick={() => travelAuthFormStore.setShowResubmitModal(false)}>
              <FormattedMessage id="ResubmitModal.cancel" defaultMessage="Cancel" />
            </LightButton>
          </StickyFooterButtons>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ResubmitModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  show: PropTypes.bool
};

export default inject("rootStore")(observer(ResubmitModal));
