import React from "react";
import { LightButton, StickyFooterButtons, DesktopTabletView } from "best-common-react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";

class ProspectsFooter extends React.Component {
  render() {
    const { prospectsStore, authStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        {authStore.isAdmin ? (
          <DesktopTabletView>
            <LightButton
              disabled={prospectsStore.isCheckoutDisabled}
              onClick={prospectsStore.openProspectCheckOutModal}
            >
              <FormattedMessage id="ProspectsFooterButton.checkout" defaultMessage="Checkout" />
            </LightButton>
          </DesktopTabletView>
        ) : (
          ""
        )}
        <LightButton onClick={prospectsStore.exportProspectTable}>
          <FormattedMessage id="ProspectsFooterButton.export" defaultMessage="Export" />
        </LightButton>
      </StickyFooterButtons>
    );
  }
}

ProspectsFooter.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ProspectsFooter));
