import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";

class NationalIdFormatter extends React.Component {
  render() {
    const { value, dependentValues } = this.props;
    const { countryOfIssueCode, nationalIdCountryCode } = dependentValues;
    return (
      <div>
        <span>{value}</span>
        <span>
          {!!value && (!!countryOfIssueCode || !!nationalIdCountryCode) ? " - " : ""}
          {countryOfIssueCode || nationalIdCountryCode || ""}
        </span>
      </div>
    );
  }
}

const dependentValueProp = {
  countryOfIssue: PropTypes.string,
  nationalIdCountryCode: PropTypes.string
};

NationalIdFormatter.propTypes = {
  value: PropTypes.string,
  dependentValues: PropTypes.shape(dependentValueProp).isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(NationalIdFormatter));
