import React from "react";
import { AccordionTableWrapper, PriceFormatter } from "best-common-react";
import { inject, observer } from "mobx-react";
import TravelFormsTableMobile from "./TravelFormsTableMobile";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import TravelFormsLinkFormatter from "./TravelFormsLinkFormatter";
import DateFormatter from "../utilities/DateFormatter";
import ProspectLinkFormatter from "../utilities/ProspectLinkFormatter";

const player = <FormattedMessage id="TravelFormsTable.player" defaultMessage="Player" />,
  club = <FormattedMessage id="TravelFormsTable.club" defaultMessage="Club" />,
  status = <FormattedMessage id="TravelFormsTable.status" defaultMessage="Status" />,
  submitted = <FormattedMessage id="TravelFormsTable.submitted" defaultMessage="Submitted" />,
  arrivalDate = <FormattedMessage id="TravelFormsTable.firstTravelDate" defaultMessage="First Travel Date" />,
  departureDate = <FormattedMessage id="TravelFormsTable.dateTravelCompleted" defaultMessage="Date Travel Completed" />,
  travelExpenseTotal = (
    <FormattedMessage id="TravelFormsTable.travelExpenseTotal" defaultMessage="Travel Expense Total" />
  );

class TravelFormsTable extends React.Component {
  constructor(props) {
    super(props);
    this.getColumns = this.getColumns.bind(this);
  }
  getColumns() {
    const { authStore } = this.props.rootStore;
    const cols = [
      {
        key: "prospectFullName",
        name: player,
        sortable: true,
        locked: true,
        formatter: ProspectLinkFormatter,
        getRowMetaData: row => row
      },
      {
        key: "status",
        name: status,
        sortable: true,
        formatter: TravelFormsLinkFormatter,
        getRowMetaData: row => row
      },
      { key: "submittedTs", name: submitted, sortable: true, formatter: DateFormatter },
      { key: "arrivalTs", name: arrivalDate, sortable: true, formatter: DateFormatter },
      { key: "departureTs", name: departureDate, sortable: true, formatter: DateFormatter },
      { key: "travelExpenseTotal", name: travelExpenseTotal, sortable: true, formatter: PriceFormatter }
    ];

    if (authStore.isBOC) {
      cols.unshift({
        key: "orgCode",
        name: club,
        sortable: true,
        locked: true,
        getRowMetaData: row => row
      });
    }

    return cols.map(column => {
      if (!column.width) {
        // Width has not been set, generate it dynamically based on language
        column.width = this.getColumnWidth(column.key);
      }
      return column;
    });
  }

  getColumnWidth(key) {
    const { translationsStore } = this.props.rootStore;
    const isEnglish = translationsStore.language === "en";
    switch (key) {
      case "prospectFullName":
        return 220;
      case "orgCode":
        return 60;
      case "status":
        return isEnglish ? 100 : 140;
      case "submittedTs":
        return isEnglish ? 110 : 160;
      case "arrivalTs":
        return isEnglish ? 150 : 160;
      case "departureTs":
        return isEnglish ? 190 : 190;
      default:
        return undefined;
    }
  }

  render() {
    const { travelAuthFormWidgetStore } = this.props.rootStore;

    const onRowsSelected = rows => {
      travelAuthFormWidgetStore.addSelectedForms(rows.map(row => row.row));
    };

    const onRowsDeselected = rows => {
      travelAuthFormWidgetStore.removeSelectedForms(rows.map(row => row.row));
    };

    const RowRenderer = ({ renderBaseRow, ...props }) => {
      return (
        <div className={travelAuthFormWidgetStore.showCheckBox(props.row.status) ? "" : "removeCheckBox"}>
          {renderBaseRow(props)}
        </div>
      );
    };

    return (
      <div className="row">
        <div className="col-12 full-page-table">
          <AccordionTableWrapper
            id={"travel-forms-table"}
            data={travelAuthFormWidgetStore.displayedTravelForms}
            columns={this.getColumns()}
            sortFunc={travelAuthFormWidgetStore.setSortDirection}
            accordion={TravelFormsTableMobile}
            headerRowHeight={35}
            height={400}
            sortColumn={travelAuthFormWidgetStore.sortFilters["key"]}
            sortDirection={travelAuthFormWidgetStore.sortFilters["direction"]}
            rowSelection={{
              showCheckbox: true,
              onRowsSelected: onRowsSelected,
              onRowsDeselected: onRowsDeselected,
              selectBy: {
                isSelectedKey: "isSelected"
              },
              enableShiftSelect: true
            }}
            rowRenderer={RowRenderer}
          />
        </div>
      </div>
    );
  }
}

TravelFormsTable.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TravelFormsTable));
