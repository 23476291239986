import { action, computed, decorate, extendObservable } from "mobx";
import _ from "lodash";
import { autorun } from "mobx";
import { ProspectsConstants } from "../../constants/ProspectsConstants";
import { MonthsFromSigningConstants } from "../../constants/MonthsFromSigningConstants";
import { saveAs } from "file-saver";
import WidgetStore from "../WidgetStore";

/**
 * This store will house all logic related to the
 * Prospects page (/prospects)
 *
 * We are using lodash for its filter, sorting, and
 * searching functionality, why reinvent the wheel?
 * Also, we are using moment to facilitate date
 * management
 */
class ProspectsStore extends WidgetStore {
  constructor(commonStore, monthsFromSigningStore) {
    super();
    this.authStore = commonStore.authStore;
    this.amtApi = commonStore.amtApi;
    this.routerStore = commonStore.routerStore;
    this.systemEventStore = commonStore.systemEventStore;
    this.monthsFromSigningStore = monthsFromSigningStore;

    // Register functions for use in external modules
    this.filterBySearchTerm = this.filterBySearchTerm.bind(this);

    this.defaults = {
      // Data necessary
      prospects: [],
      selectedProspectIds: [],
      quickSearchProspects: [],
      currentStatusFilter: ProspectsConstants.CHECKED_IN,

      // Fields on page
      orgId: 0,
      searchTerm: "",
      eligibility: { value: -1, label: ProspectsConstants.ALL_PLAYERS },

      // Misc
      sortFilters: {
        direction: "ASC",
        key: "lastName"
      },
      eligibilityDropDownList: [{ value: -1, label: ProspectsConstants.ALL_PLAYERS, pattern: "" }],

      showProspectCheckOutModal: false
    };

    extendObservable(this, {
      prospects: this.defaults["prospects"],
      selectedProspectIds: this.defaults["selectedProspectIds"],
      quickSearchProspects: this.defaults["quickSearchProspects"],
      orgId: this.defaults["orgId"],
      currentStatusFilter: this.defaults["currentStatusFilter"],
      searchTerm: this.defaults["searchTerm"],
      eligibility: this.defaults["eligibility"],
      sortFilters: this.defaults["sortFilters"],
      showProspectCheckOutModal: this.defaults["showProspectCheckOutModal"],
      eligibilityDropDownList: this.defaults["eligibilityDropDownList"],
      setProspects: action(prospects => {
        this.prospects = prospects;
      }),
      setQuickSearchProspects: action(prospects => {
        this.quickSearchProspects = prospects;
      }),
      setOrgId: action(orgId => {
        this.orgId = orgId;
      }),
      setCurrentStatusFilter: action(status => {
        this.currentStatusFilter = status;
      }),
      setSearchTerm: action(searchTerm => {
        this.searchTerm = searchTerm;
      }),
      clearSearchTerm: action(() => {
        this.searchTerm = "";
      }),
      setEligibility: action(eligibility => {
        this.eligibility = eligibility;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      openProspectCheckOutModal: action(() => {
        this.showProspectCheckOutModal = true;
      }),
      closeProspectCheckOutModal: action(() => {
        this.showProspectCheckOutModal = false;
      }),
      /**
       * We should reset the store when we are no
       * longer on the page. However, because the
       * prospectProfile store is dependent on the
       * data here, we won't reset it until we have
       * the backend running properly
       */
      resetStore: action(() => {
        this.prospects = this.defaults["prospects"];
        this.selectedProspectIds = this.defaults["selectedProspectIds"];
        this.quickSearchProspects = this.defaults["quickSearchProspects"];
        this.currentStatusFilter = this.defaults["currentStatusFilter"];
        this.orgId = this.defaults["orgId"];
        this.searchTerm = this.defaults["searchTerm"];
        this.eligibility = this.defaults["eligibility"];
        this.sortFilters = this.defaults["sortFilters"];
        this.showProspectCheckOutModal = this.defaults["showProspectCheckOutModal"];
      }),
      exportProspectTable: action(() => {
        let params = { orgId: this.orgId ? this.orgId : 0 };

        if (this.currentStatusFilter !== "" && this.currentStatusFilter !== ProspectsConstants.PROSPECTS) {
          params.status = this.currentStatusFilter;
        }

        if (this.eligibility.value !== "" && this.eligibility.value !== -1) {
          params.eligibility = this.eligibility.value;
        }

        if (this.sortFilters.direction !== "NONE") {
          params.sortDirection = this.sortFilters.direction;
        }

        params.sortKeys = this.sortFilters.key.toString();

        this.amtApi.exportProspectTable(params).then(file => {
          saveAs(file, "prospects.xls");
        });
      }),
      addSelectedProspects: action(rows => {
        rows.forEach(row => {
          if (this.showCheckBox(row)) {
            this.selectedProspectIds.push(row.prospectId);
          }
        });
      }),
      removeSelectedProspects: action(rows => {
        let prospectIdsToRemove = rows.map(row => row.prospectId);
        this.selectedProspectIds = _.difference(this.selectedProspectIds, prospectIdsToRemove);
      }),
      resetSelectedProspects: action(() => {
        this.selectedProspectIds = [];
      })
    });

    autorun(() => {
      if (this.routerStore.isProspectsTab && this.authStore.loggedIn) {
        if (!this.authStore.isBOC) {
          this.orgId = this.authStore.userData.org;
        }
        this.updateProspects();
        this.updateEligibilityDropDownList();
      } else {
        this.resetStore();
      }
    });
  }

  /**
   * Gets called from within autorun (upon component render)
   * and makes call to backend (using amtApi) to get prospects
   */
  updateProspects() {
    this.resetSelectedProspects();

    // The population of prospects for the table
    this.amtApi.getProspects().then(response => {
      if (!!response) {
        this.setProspects(response);
      }
    });
    // The population of prospects for the quick search
    this.amtApi.getQuickSearchProspects().then(response => {
      if (!!response) {
        this.setQuickSearchProspects(response);
      }
    });
  }

  sortProspects(prospects, searchFilters) {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(prospects, [prospect => _.toLower(prospect["lastName"])]);
    } else if (direction === "ASC") {
      if (searchFilters.key === "dateOfBirth") {
        return _.sortBy(prospects, [prospect => prospect.dateOfBirth]);
      } else if (searchFilters.key === "lastCheckIn") {
        return _.sortBy(prospects, [prospect => prospect.lastCheckIn]);
      }
      return _.sortBy(prospects, [prospect => _.toLower(prospect[searchFilters.key])]);
    } else if (direction === "DESC") {
      if (searchFilters.key === "dateOfBirth") {
        return _.sortBy(prospects, [prospect => prospect.dateOfBirth]).reverse();
      } else if (searchFilters.key === "lastCheckIn") {
        return _.sortBy(prospects, [prospect => prospect.lastCheckIn]).reverse();
      }
      return _.sortBy(prospects, [prospect => _.toLower(prospect[searchFilters.key])]).reverse();
    } else {
      return prospects;
    }
  }

  get prospectsByStatus() {
    if (this.currentStatusFilter === ProspectsConstants.PROSPECTS) {
      return this.prospectsByOrg;
    }
    if (this.currentStatusFilter === ProspectsConstants.CHECKED_IN) {
      return this.checkedIn;
    }
    if (this.currentStatusFilter === ProspectsConstants.APPROACHING) {
      return this.approaching;
    }
    return this.overdue;
  }

  get prospectsByOrg() {
    return !this.orgId ? this.prospects : this.prospects.filter(p => p.orgId === this.orgId);
  }

  get checkedIn() {
    return this.prospectsByOrg.filter(p => p.isCheckedIn);
  }

  get approaching() {
    return this.prospectsByOrg.filter(p => p.status === ProspectsConstants.APPROACHING);
  }

  get overdue() {
    return this.prospectsByOrg.filter(p => p.status === ProspectsConstants.OVERDUE);
  }

  get displayedProspects() {
    let toDisplay = this.prospectsByStatus
      .filter(prospect => {
        const value = this.eligibility.value;
        const index = this.eligibilityDropDownList.findIndex(status => status.value === value);
        if (value === -1) {
          return true;
        }
        return (
          this.eligibilityDropDownList[index] &&
          prospect.monthsFromSigning.toUpperCase() === this.eligibilityDropDownList[index].pattern.toUpperCase()
        );
      })
      .map(prospect => {
        return {
          prospectId: prospect.prospectId,
          orgCode: prospect.orgCode,
          isSelected: _.includes(this.selectedProspectIds, prospect.prospectId),
          lastName: prospect.lastName,
          firstName: prospect.firstName,
          extendedLastName: prospect.extendedLastName,
          middleName: prospect.middleName,
          position1: prospect.position1,
          dateOfBirth: prospect.dateOfBirth,
          birthCountry: prospect.birthCountry,
          lastCheckIn: prospect.lastCheckIn,
          location: prospect.location,
          daysUsed: prospect.daysUsed,
          status: prospect.status,
          nextCheckInAllowed: prospect.nextCheckInAllowed,
          monthsFromSigning: prospect.monthsFromSigning,
          isCheckedIn: prospect.isCheckedIn,
          isCheckedOut: prospect.isCheckedOut
        };
      });
    // The Prospects filter has the special behavior
    // Where we must always have Draft prospects on top of the list
    if (this.currentStatusFilter === ProspectsConstants.PROSPECTS) {
      // Partition the prospects into two groups, one with Draft status and one without
      const partitionedProspects = _.partition(toDisplay, prospect => prospect.status === ProspectsConstants.DRAFT);
      // Sort the individual partitions seperately, so that they are still grouped appropriately
      const draftProspects = this.sortProspects(partitionedProspects[0], this.sortFilters);
      const nonDraftProspects = this.sortProspects(partitionedProspects[1], this.sortFilters);

      return draftProspects.concat(nonDraftProspects);
    }
    return this.sortProspects(toDisplay, this.sortFilters);
  }

  /**
   * Function to filter data based on input search
   * term and whether the prospects have given search term
   */

  filterBySearchTerm(prospects, searchTerm) {
    if (searchTerm.length < 3) {
      return [];
    } else {
      return _.filter(prospects, prospect => {
        return (
          this.searchTermInField(prospect.firstName, searchTerm) ||
          this.searchTermInField(prospect.lastName, searchTerm) ||
          // Need the clause where they enter the full name
          this.searchTermInField(prospect.lastName + prospect.firstName, searchTerm)
        );
      });
    }
  }

  // Given the current date, return the period start.
  // Should be 1/1 if date between 1/1 and 7/1 inclusive
  // Otherwise should be 7/2
  getPeriodStart(date) {
    if (
      this.systemEventStore.events["AMT_CHECK_IN_PERIOD"] &&
      this.systemEventStore.events["AMT_CHECK_IN_PERIOD"].startDtAsString
    ) {
      const periodStartDt = new Date(this.systemEventStore.events["AMT_CHECK_IN_PERIOD"].startDtAsString);
      return periodStartDt.getMonth() + 1 + "/" + periodStartDt.getDate();
    } else {
      return this.isFirstPeriod(date) ? "1/1" : "7/2";
    }
  }

  // Given the current date, return the period end.
  // Should be 7/1 if date between 1/1 and 7/1 inclusive
  // Otherwise should be 12/31
  getPeriodEnd(date) {
    if (
      this.systemEventStore.events["AMT_CHECK_IN_PERIOD"] &&
      this.systemEventStore.events["AMT_CHECK_IN_PERIOD"].endDtAsString
    ) {
      const periodEndDt = new Date(this.systemEventStore.events["AMT_CHECK_IN_PERIOD"].endDtAsString);
      return periodEndDt.getMonth() + 1 + "/" + periodEndDt.getDate();
    } else {
      return this.isFirstPeriod(date) ? "7/1" : "12/31";
    }
  }

  isFirstPeriod(date) {
    const jan1 = new Date(date.getFullYear(), 0, 1);
    const july1 = new Date(date.getFullYear(), 6, 1);
    return date >= jan1 && date <= july1;
  }

  getPeriod() {
    const today = new Date();
    return this.getPeriodStart(today) + " - " + this.getPeriodEnd(today);
  }

  showCheckBox(prospect) {
    return prospect.isCheckedIn;
  }

  // Update eligibilityDropDownList with values from lookups
  updateEligibilityDropDownList() {
    this.eligibilityDropDownList = [
      { value: -1, label: ProspectsConstants.ALL_PLAYERS, pattern: "" },
      {
        value: MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_ID,
        label: `${ProspectsConstants.ELIGIBLE_TO_SIGN} (${this.monthsFromSigningStore.getValue(
          MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_LIMIT
        )} Days in any ${this.monthsFromSigningStore.getValue(
          MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_WINDOW_LENGTH
        )}-day period)`,
        pattern: ProspectsConstants.ELIGIBLE_TO_SIGN
      },
      {
        value: MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_ON_BIRTHDAY_ID,
        label: `${ProspectsConstants.ELIGIBLE_TO_SIGN_ON_BIRTHDAY} (${this.monthsFromSigningStore.getValue(
          MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_LIMIT
        )} Days in any ${this.monthsFromSigningStore.getValue(
          MonthsFromSigningConstants.ELIGIBLE_TO_SIGN_WINDOW_LENGTH
        )}-day period)`,
        pattern: ProspectsConstants.ELIGIBLE_TO_SIGN_ON_BIRTHDAY
      },
      {
        value: MonthsFromSigningConstants.AGE_ELIGIBLE_ID,
        label: `${ProspectsConstants.AGE_ELIGIBLE} (${this.monthsFromSigningStore.getValue(
          MonthsFromSigningConstants.AGE_ELIGIBLE_LIMIT
        )} Days in any ${this.monthsFromSigningStore.getValue(
          MonthsFromSigningConstants.AGE_ELIGIBLE_WINDOW_LENGTH
        )}-day period)`,
        pattern: ProspectsConstants.AGE_ELIGIBLE
      },
      {
        value: MonthsFromSigningConstants.ZERO_TO_SIX_ID,
        label: `${ProspectsConstants.MONTHS_0_6} (${this.monthsFromSigningStore.getValue(
          MonthsFromSigningConstants.MS1_LIMIT
        )} Days in any ${this.monthsFromSigningStore.getValue(
          MonthsFromSigningConstants.MS1_WINDOW_LENGTH
        )}-day period)`,
        pattern: ProspectsConstants.MONTHS_0_6
      },
      {
        value: MonthsFromSigningConstants.SIX_TO_TWELVE_ID,
        label: `${ProspectsConstants.MONTHS_6_12} (${this.monthsFromSigningStore.getValue(
          MonthsFromSigningConstants.MS2_LIMIT
        )} Days in any ${this.monthsFromSigningStore.getValue(
          MonthsFromSigningConstants.MS2_WINDOW_LENGTH
        )}-day period)`,
        pattern: ProspectsConstants.MONTHS_6_12
      },
      {
        value: MonthsFromSigningConstants.TWELVE_TO_EIGHTEEN_ID,
        label: `${ProspectsConstants.MONTHS_12_18} (${this.monthsFromSigningStore.getValue(
          MonthsFromSigningConstants.MS3_LIMIT
        )} Days)`,
        pattern: ProspectsConstants.MONTHS_12_18
      }
    ];
  }

  // If none are checked, then disable
  get isCheckoutDisabled() {
    return this.selectedProspectIds.length === 0;
  }

  get selectedProspects() {
    return this.selectedProspectIds;
  }
}

decorate(ProspectsStore, {
  displayedProspects: computed,
  isCheckoutDisabled: computed,
  selectedProspects: computed,
  checkedIn: computed,
  approaching: computed,
  overdue: computed,
  prospectsByOrg: computed
});

export default ProspectsStore;
