import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Accordion } from "best-common-react";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import TravelAuthHistoryTable from "./TravelAuthHistoryTable";

const TitleHolder = styled.div`
  color: ${props => props.theme["dark-grey"]};
`;

class TravelAuthHistory extends React.Component {
  render() {
    return (
      <div className="mt-2">
        <Accordion startOpen={true}>
          <Accordion.Header isToggle={true}>
            <TitleHolder>
              <FormattedMessage id="TravelAuthHistory.travelAuth" defaultMessage="Travel Authorization" />
            </TitleHolder>
          </Accordion.Header>
          <Accordion.Body color="white">
            <TravelAuthHistoryTable />
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

TravelAuthHistory.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TravelAuthHistory));
