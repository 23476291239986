import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Icon } from "best-common-react";

const DocumentCard = styled.div`
  border: solid 1px ${props => props.theme.grey};
  background-color: ${props => props.theme.white};
  padding: 1rem;
  margin-bottom: 0.25rem;
`;

const Link = styled.a`
  font-size: 0.875rem;
  font-family: Helvetica;
  margin-left: 0.5rem;
`;

const Content = styled.div`
  color: ${props => props.theme["dark-grey"]};
  font-size: 0.875rem;
  font-family: Helvetica;
  margin-left: 1.75rem;
`;

const Document = ({ file, downloadFn }) => {
  const getFile = event => {
    downloadFn(event, file.fileName, file.fileAttachmentId, file.contentType);
  };

  return (
    <DocumentCard>
      <div>
        <Icon iconName="fa-download" className="fa-md" onClick={event => getFile(event)} />
        <Link href="" onClick={event => getFile(event)}>
          {file.title}
        </Link>
      </div>
      <Content>{file.description}</Content>
    </DocumentCard>
  );
};

Document.propTypes = {
  file: PropTypes.object.isRequired,
  downloadFn: PropTypes.func.isRequired
};

export default Document;
