import React from "react";
import PropTypes from "prop-types";
import { BestLabel, LightButton } from "best-common-react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { inject, observer } from "mobx-react";
import DateFormatter from "../utilities/DateFormatter";

const Item = styled.div`
  margin-bottom: 0.625rem;
`;

const ButtonWrapper = styled.div.attrs({
  className: "d-flex justify-content-center"
})`
  button:not(:last-child) {
    margin-right: 0.625rem;
  }
`;

const Footer = styled.div`
  border-top: 1px solid ${props => props.theme["grey"]};
  padding-top: 0.625rem;
`;

class TravelFormsMobileContent extends React.Component {
  render() {
    const { travelAuthForm } = this.props;
    const { travelAuthFormWidgetStore, authStore } = this.props.rootStore;
    return (
      <div>
        <Item>
          <BestLabel>
            <FormattedMessage id="TravelFormsMobileContent.submitted" defaultMessage="Submitted" />
          </BestLabel>
          <div>
            <DateFormatter value={travelAuthForm.submittedTs} />
          </div>
        </Item>
        <Item>
          <BestLabel>
            <FormattedMessage id="TravelFormsMobileContent.firstTravelDate" defaultMessage="First Travel Date" />
          </BestLabel>
          <div>
            <DateFormatter value={travelAuthForm.arrivalTs} />
          </div>
        </Item>
        <Item>
          <BestLabel>
            <FormattedMessage
              id="TravelFormsMobileContent.dateTravelCompleted"
              defaultMessage="Date Travel Completed"
            />
          </BestLabel>
          <div>
            <DateFormatter value={travelAuthForm.departureTs} />
          </div>
        </Item>
        <Item>
          <BestLabel>
            <FormattedMessage id="TravelFormsMobileContent.travelExpenseTotal" defaultMessage="Travel Expense Total" />
          </BestLabel>
          <div>{travelAuthForm.travelExpenseTotal}</div>
        </Item>

        {travelAuthForm.status === "Submitted" && authStore.isBOC ? (
          <Footer>
            <ButtonWrapper>
              <LightButton onClick={() => travelAuthFormWidgetStore.approveTravelForms([travelAuthForm])}>
                <FormattedMessage id="TravelFormsMobileContent.approve" defaultMessage="Approve" />
              </LightButton>
              <LightButton onClick={() => travelAuthFormWidgetStore.rejectTravelForms([travelAuthForm])}>
                <FormattedMessage id="TravelFormsMobileContent.reject" defaultMessage="Reject" />
              </LightButton>
            </ButtonWrapper>
          </Footer>
        ) : (
          ""
        )}

        {travelAuthForm.status === "Preliminary" && authStore.isClub ? (
          <Footer>
            <ButtonWrapper>
              <LightButton onClick={() => travelAuthFormWidgetStore.submitTravelForms([travelAuthForm])}>
                <FormattedMessage id="TravelFormsMobileContent.submitToBoc" defaultMessage="Submit to BOC" />
              </LightButton>
            </ButtonWrapper>
          </Footer>
        ) : (
          ""
        )}
      </div>
    );
  }
}

TravelFormsMobileContent.propTypes = {
  travelAuthForm: PropTypes.object.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TravelFormsMobileContent));
