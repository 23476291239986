import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Accordion, FormColumn4Wide, FormInput, BestLabel } from "best-common-react";
import { inject, observer } from "mobx-react";
import { FormattedMessage, intlShape, defineMessages } from "react-intl";
import DateFormatter from "../utilities/DateFormatter";
import Moment from "moment";
import { CommonConstants } from "../../constants/CommonConstants";

const TitleHolder = styled.div`
  color: ${props => props.theme["dark-grey"]};
`;

const CheckBox = styled.input`
  margin-left: 5px;
  margin-right: 5px;
`;

const messages = defineMessages({
  position: {
    id: "ProfilePersonalInfo.position",
    defaultMessage: "Position {pos}"
  }
});
class ProfilePersonalInfo extends React.Component {
  getPositionItems() {
    const { lookupStore, prospectProfileStore } = this.props.rootStore;
    const { prospect } = prospectProfileStore;
    const positionsForDropdown = lookupStore.getDropdownOptions(lookupStore.lookups.AMT_POSITION_LKUP);
    const positions = [];
    for (let i = 1; i <= 4; i++) {
      const key = "position" + i + "Id";
      const label = this.context.intl.formatMessage(messages.position, { pos: " " + i });
      if (prospect.positions && prospect.positions.length > 0) {
        positions.push(
          <FormColumn4Wide key={i}>
            <FormInput
              name={key}
              type="select"
              label={label}
              isRequired={i === 1}
              value={lookupStore.getDropdownItem(positionsForDropdown, prospect.positions[i - 1].positionId)}
              options={positionsForDropdown}
              onChangeFn={selected => (prospect.positions[i - 1].positionId = selected.value)}
              editable={prospectProfileStore.editMode}
            />
          </FormColumn4Wide>
        );
      }
    }
    return positions;
  }

  render() {
    const { prospectProfileStore } = this.props.rootStore;
    const { prospect } = prospectProfileStore;

    return (
      <div className="mt-2">
        <Accordion startOpen={true}>
          <Accordion.Header isToggle={true}>
            <TitleHolder>
              <FormattedMessage id="ProfilePersonalInfo.personalInfo" defaultMessage="Personal Info" />
            </TitleHolder>
          </Accordion.Header>
          <Accordion.Body color="white">
            <div className="row">
              <FormColumn4Wide>
                <FormInput
                  name="firstName"
                  label={<FormattedMessage id="ProfilePersonalInfo.firstName" defaultMessage="First Name" />}
                  isRequired={true}
                  value={prospect.firstName}
                  onChangeFn={value => (prospect.firstName = value)}
                  editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <FormInput
                  name="middleName"
                  label={
                    <span>
                      <FormattedMessage id="ProfilePersonalInfo.middleName" defaultMessage="Middle" />
                      {prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis ? (
                        <React.Fragment>
                          <CheckBox
                            type="checkbox"
                            disabled={!prospectProfileStore.editMode || prospectProfileStore.addingFromEbis}
                            checked={!!prospect.middleNameFlag}
                            onChange={event => {
                              prospect.middleNameFlag = event.target.checked;
                              prospect.middleName = "";
                            }}
                          />
                          <BestLabel>N/A</BestLabel>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </span>
                  }
                  isRequired={!prospect.middleNameFlag}
                  value={prospect.middleName}
                  onChangeFn={value => (prospect.middleName = value)}
                  editable={
                    prospectProfileStore.editMode && !prospect.middleNameFlag && !prospectProfileStore.addingFromEbis
                  }
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <FormInput
                  name="lastName"
                  label={<FormattedMessage id="ProfilePersonalInfo.lastName" defaultMessage="Last Name" />}
                  isRequired={true}
                  value={prospect.lastName}
                  onChangeFn={value => (prospect.lastName = value)}
                  editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <FormInput
                  name="extendedLastName"
                  label={
                    <span>
                      <FormattedMessage id="ProfilePersonalInfo.extLastName" defaultMessage="Ext Last" />
                      {prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis ? (
                        <React.Fragment>
                          <CheckBox
                            type="checkbox"
                            disabled={!prospectProfileStore.editMode || prospectProfileStore.addingFromEbis}
                            checked={!!prospect.extendedLastNameFlag}
                            onChange={event => {
                              prospect.extendedLastNameFlag = event.target.checked;
                              prospect.extendedLastName = "";
                            }}
                          />
                          <BestLabel>N/A</BestLabel>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </span>
                  }
                  isRequired={!prospect.extendedLastNameFlag}
                  value={prospect.extendedLastName}
                  onChangeFn={value => (prospect.extendedLastName = value)}
                  editable={
                    prospectProfileStore.editMode &&
                    !prospect.extendedLastNameFlag &&
                    !prospectProfileStore.addingFromEbis
                  }
                />
              </FormColumn4Wide>

              <FormColumn4Wide>
                <FormInput
                  type="date"
                  name="dateOfBirth"
                  label={<FormattedMessage id="ProfilePersonalInfo.dateOfBirth" defaultMessage="Birth Date" />}
                  isRequired={true}
                  formatter={DateFormatter}
                  value={
                    prospect.dateOfBirthAsString
                      ? Moment(prospect.dateOfBirthAsString, "MM/DD/YYYY")
                      : prospect.dateOfBirthAsString
                  }
                  onChangeFn={value =>
                    (prospect.dateOfBirthAsString = value ? Moment(value).format("MM/DD/YYYY") : value)
                  }
                  editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <BestLabel>
                  <FormattedMessage id="Profile.CountryAndId.ebisId" defaultMessage="EBIS ID" />
                </BestLabel>
                <div>{"0" !== prospect.personId ? prospect.personId : ""}</div>
              </FormColumn4Wide>
              {this.getPositionItems()}
            </div>
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

ProfilePersonalInfo.propTypes = {
  rootStore: PropTypes.object.isRequired
};

ProfilePersonalInfo.contextTypes = {
  intl: intlShape
};

export default inject("rootStore")(observer(ProfilePersonalInfo));
