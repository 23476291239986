import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ProspectsConstants } from "../../constants/ProspectsConstants";

const Status = styled.div`
  color: ${props => props.color};
  font-size: ${props => props.size};
`;

const StatusWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const StatusMarker = styled.div`
  height: 14px;
  width: 14px;
  margin-right: 5px;

  -moz-border-radius: 2px;
  border-radius: 2px;

  background-color: ${props => props.color};
`;

let colors = {};
colors[ProspectsConstants.CHECKED_IN] = "#49c083";
colors[ProspectsConstants.APPROACHING] = "#fcb755";
colors[ProspectsConstants.OVERDUE] = "#ed1c34";

const StatusFormatter = ({ value, textColor, textSize }) => {
  const color = colors[value] != null ? colors[value] : "#d2d2d2";
  return (
    <StatusWrapper>
      <StatusMarker color={color} />
      <Status color={textColor} size={textSize}>
        {value}
      </Status>
    </StatusWrapper>
  );
};

StatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  textSize: PropTypes.string
};

export default StatusFormatter;
