import React from "react";
import { inject, observer } from "mobx-react";
import { BestSelect, BestLabel } from "best-common-react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";

class ProspectsDropdown extends React.Component {
  render() {
    const { prospectsStore } = this.props.rootStore;

    return (
      <div className="form-group">
        <BestLabel name="monthsFromSigning" label="Months from Signing">
          <FormattedMessage id="ProspectsDropdown.monthsFromSigning" defaultMessage="Months from Signing" />
        </BestLabel>
        <small id="monthsFromSigningSmallText" className="text-muted px-1">
          <FormattedMessage
            id="ProspectsDropdown.monthsFromSigningDescription"
            defaultMessage="(Eligibility to Enter Club Facilities)"
          />
        </small>
        <BestSelect
          name="eligibility"
          value={prospectsStore.eligibility}
          onChange={prospectsStore.setEligibility}
          options={prospectsStore.eligibilityDropDownList}
        />
      </div>
    );
  }
}

ProspectsDropdown.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ProspectsDropdown));
