import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { PacmanLoader, FadeLoader } from "react-spinners";

const Parent = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1051;
  display: none;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.85;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledPacLoader = styled(PacmanLoader)`
  margin: 0 auto;
  opacity: 1;
`;

const StyledFadeLoader = styled(FadeLoader)`
  margin: 0 auto;
  opacity: 1;
`;

const environment = process.env.REACT_APP_ENV;

class AmtLoader extends React.Component {
  static propTypes = {
    theme: PropTypes.object.isRequired
  };

  render() {
    return (
      <Parent id={"amt-loader"}>
        <Overlay>
          {environment !== null && environment.toLowerCase() === "local".toLowerCase() ? (
            <StyledPacLoader sizeUnit={"px"} size={25} color={this.props.theme.mlbNavy} />
          ) : (
            <StyledFadeLoader color={this.props.theme.mlbNavy} />
          )}
        </Overlay>
      </Parent>
    );
  }
}

export default withTheme(AmtLoader);
