import React from "react";
import {
  BestProgressBar,
  LightButton,
  Modal,
  ModalBody,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CheckInModalLocation from "./CheckInModalLocation";
import CheckInModalConfirmation from "./CheckInModalConfirmation";
import { FormattedMessage } from "react-intl";
import CheckInModalPhoto from "./CheckInModalPhoto";

const ModalFooterDiv = styled.div.attrs({
  id: "modal-footer"
})`
  justify-content: space-between;
  background-color: #ececec;
  padding: 16px;
`;

ModalFooterDiv.displayName = "Footer";

const FooterButtons = styled.div`
  button:not(:last-child) {
    margin-right: 0.625rem;
  }
`;

class ProspectCheckInModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checkInButtonDisabled: false };
  }

  render() {
    const { checkInModalStore, prospectProfileStore } = this.props.rootStore;

    const { show } = this.props;

    const renderModalBody = () => {
      // Conditionally display other modal bodies (Photo, Confirmation)
      const { locationAdded, photoConfirmed } = checkInModalStore;
      if (!locationAdded) {
        return <CheckInModalLocation />;
      } else if (!photoConfirmed) {
        return <CheckInModalPhoto />;
      } else if (photoConfirmed) {
        return <CheckInModalConfirmation photo={checkInModalStore.checkIn.photo} />;
      }
    };

    return (
      <div>
        <Modal show={show} onClose={() => {}} size="lg">
          <ModalHeaderWithTitleAndClose
            title={<FormattedMessage id="ProspectCheckInModal.title" defaultMessage="Prospect Check-In" />}
            close={() => {
              checkInModalStore.resetStore();
              prospectProfileStore.setShowProspectCheckInModal(false);
            }}
          />
          <ModalBody>
            <div>
              <BestProgressBar
                nodes={[
                  {
                    label: <FormattedMessage id="ProspectCheckInModal.progress1Label" defaultMessage="Location" />,
                    completed: checkInModalStore.locationAdded
                  },
                  {
                    label: <FormattedMessage id="ProspectCheckInModal.progress2Label" defaultMessage="Photo" />,
                    completed: checkInModalStore.photoConfirmed
                  },
                  {
                    label: <FormattedMessage id="ProspectCheckInModal.progress3Label" defaultMessage="Confirmation" />,
                    completed: checkInModalStore.locationAdded && checkInModalStore.photoConfirmed
                  }
                ]}
              />
              {renderModalBody()}
            </div>
          </ModalBody>
          <ModalFooterDiv>
            <FooterButtons className={"d-flex justify-content-between"}>
              {checkInModalStore.locationAdded ? (
                <LightButton onClick={() => checkInModalStore.previous()}>
                  <FormattedMessage id="ProspectCheckInModal.previous" defaultMessage="Previous" />
                </LightButton>
              ) : (
                <LightButton
                  onClick={() => {
                    checkInModalStore.resetStore();
                    prospectProfileStore.setShowProspectCheckInModal(false);
                  }}
                >
                  <FormattedMessage id="ProspectCheckInModal.cancel" defaultMessage="Cancel" />
                </LightButton>
              )}

              {checkInModalStore.locationAdded && checkInModalStore.photoAdded && checkInModalStore.photoConfirmed ? (
                <PrimaryButton
                  disabled={this.state.checkInButtonDisabled}
                  onClick={() => {
                    this.setState({ checkInButtonDisabled: true });
                    checkInModalStore.save();
                  }}
                >
                  <FormattedMessage id="ProspectCheckInModal.checkIn" defaultMessage="Check In" />
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  onClick={() => {
                    this.setState({ checkInButtonDisabled: false });
                    checkInModalStore.next();
                  }}
                  disabled={!checkInModalStore.isNextEnabled}
                >
                  <FormattedMessage id="ProspectCheckInModal.next" defaultMessage="Next" />
                </PrimaryButton>
              )}
            </FooterButtons>
          </ModalFooterDiv>
        </Modal>
      </div>
    );
  }
}

ProspectCheckInModal.defaultProps = {
  show: false
};

ProspectCheckInModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  show: PropTypes.bool
};

export default inject("rootStore")(observer(ProspectCheckInModal));
