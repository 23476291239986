import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { EmptyCell } from "./ZeroNumberCellFormatter";

const NameLink = styled.button.attrs({
  className: "btn btn-link"
})`
  text-decoration: none !important;
  padding: 0;
`;

class ClubCheckInsLinkFormatter extends React.Component {
  constructor(props) {
    super(props);
    this.openCheckInsModal = this.openCheckInsModal.bind(this);
  }

  openCheckInsModal() {
    const { clubsStore } = this.props.rootStore;
    const { orgId, orgName } = this.props.dependentValues;
    clubsStore.setCheckInOrgName(orgName);
    clubsStore.loadCheckInsModal(orgId, true);
  }

  render() {
    const count = this.props.value;
    return count > 0 ? (
      <NameLink id="club-check-ins-link-formatter" onClick={this.openCheckInsModal}>
        {count}
      </NameLink>
    ) : (
      <EmptyCell>{count}</EmptyCell>
    );
  }
}

const dependentValueProp = {
  orgId: PropTypes.number,
  orgName: PropTypes.string
};

ClubCheckInsLinkFormatter.propTypes = {
  value: PropTypes.number.isRequired,
  dependentValues: PropTypes.shape(dependentValueProp).isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ClubCheckInsLinkFormatter));
