import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ClubsConstants } from "../../constants/ClubsConstants";

const CountWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const CountMarker = styled.span`
  ${({ color }) =>
    `
    display: flex;
    align-items: center;
    justify-content: center;
    
    height: 14px;
    width: 14px;
    margin-right: 5px;
    
    font-size: 0.75rem;

    -moz-border-radius: 2px;
    border-radius: 2px;

    color: white;
    background-color: ${color}

    `};
`;

let colors = {};
colors[ClubsConstants.CHECKED_IN] = "#49c083";
colors[ClubsConstants.APPROACHING] = "#fcb755";
colors[ClubsConstants.OVERDUE] = "#ed1c34";

const ClubProspectCount = ({ value, type }) => {
  const color = colors[type] != null ? colors[type] : "#666666";
  return <CountWrapper>{value ? <CountMarker color={color}>{value}</CountMarker> : ""}</CountWrapper>;
};

ClubProspectCount.propTypes = {
  value: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired
};

export default ClubProspectCount;
