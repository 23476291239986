import React from "react";
import { StickyFooterButtons, LightButton } from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

class NewProspectFooter extends React.Component {
  render() {
    const { newProspectStore, authStore } = this.props.rootStore;
    return (
      <StickyFooterButtons>
        {authStore.isAdmin ? (
          <LightButton disabled={!newProspectStore.nextEnabled} onClick={newProspectStore.next}>
            <FormattedMessage id="NewProspectFooter.next" defaultMessage="Next" />
          </LightButton>
        ) : (
          ""
        )}
      </StickyFooterButtons>
    );
  }
}

NewProspectFooter.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(NewProspectFooter));
