import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BestLabel } from "best-common-react";
import { inject, observer } from "mobx-react";
import TripInfo from "./sections/TripInfo";
import StatusAndDates from "./sections/StatusAndDates";
import ParentGuardian from "./sections/ParentGuardian";
import FileAttachments from "./sections/FileAttachments";
import DrugTesting from "./sections/DrugTesting";
import TravelExpenses from "./sections/TravelExpenses";
import DeleteModal from "./DeleteModal";
import ResubmitModal from "./ResubmitModal";
import Comments from "./sections/Comments";
import { FormattedMessage } from "react-intl";

const Container = styled.div.attrs({
  className: "mt-2"
})`
  width: 100%;
  background-color: ${props => props.theme["white"]} !important;
  border: 1px solid ${props => props.theme["grey"]} !important;
`;

const Title = styled.div`
  font-size: 1rem;
  color: ${props => props.theme["dark-grey"]};
  margin: 0.75rem;
`;

const FormHeader = ({ children }) => {
  const TitleSeperator = styled.div`
    border-bottom: 1px solid ${props => props.theme["grey"]};
  `;
  return (
    <div>
      {children}
      <TitleSeperator />
    </div>
  );
};
FormHeader.propTypes = {
  children: PropTypes.any,
  theme: PropTypes.object
};

const FormBody = styled.div`
  padding: 1rem;
`;

const Note = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
`;

class TravelAuthForm extends React.Component {
  render() {
    const { travelAuthFormStore } = this.props.rootStore;
    return (
      <Container>
        <FormHeader>
          <Title>{this.props.title}</Title>
        </FormHeader>
        <FormBody>
          <DeleteModal show={travelAuthFormStore.showDeleteModal} />
          <ResubmitModal show={travelAuthFormStore.showResubmitModal} />
          <div className="row">
            <TripInfo />
            <StatusAndDates />
          </div>
          <div className="row">
            <ParentGuardian />
          </div>
          <div className="row">
            <DrugTesting />
          </div>
          <div className="row">
            <FileAttachments />
          </div>
          {travelAuthFormStore.isApproved && (
            <div className="row">
              <TravelExpenses />
            </div>
          )}

          <div className="row">
            <Comments />
          </div>

          {!travelAuthFormStore.isApproved && (
            <React.Fragment>
              <BestLabel>
                <FormattedMessage id="TravelAuthForm.note" defaultMessage="Note" />:
              </BestLabel>
              <Note>
                <FormattedMessage
                  id="TravelAuthForm.noteText"
                  defaultMessage="This form must be submitted to BOC at least three (3) days prior to the trip. The prospect should not travel until an MLB employee approves"
                />
              </Note>
            </React.Fragment>
          )}
        </FormBody>
      </Container>
    );
  }
}

TravelAuthForm.propTypes = {
  rootStore: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element])
};

export default inject("rootStore")(observer(TravelAuthForm));
