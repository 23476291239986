import React from "react";
import { Accordion } from "best-common-react";
import PropTypes from "prop-types";

const MonthsFromSigningTableMobile = row => {
  return (
    <Accordion>
      <Accordion.Header isToggle={true}>
        <div>{row.data.monthsFromSigning}</div>
      </Accordion.Header>
      <Accordion.Body>
        <div>{row.data.days}</div>
      </Accordion.Body>
    </Accordion>
  );
};

MonthsFromSigningTableMobile.propTypes = {
  row: PropTypes.object.isRequired
};

export default MonthsFromSigningTableMobile;
