import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import Moment from "moment";
import "moment-timezone";
import DateFormatter from "../../utilities/DateFormatter";
import defaultPhoto from "../../../assets/image/default-prospect-photo.png";

const ConfirmationContainer = styled.div`
  margin-top: 20px;
`;

const Photo = styled.div`
  background-color: black;
  text-align: center;
  overflow: hidden;
  border: 2px solid ${props => props.theme["dark-grey"]};
  width: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const ConfirmationListRow = styled.div`
  margin-bottom: 5px;
`;

const ConfirmationLabel = styled.label`
  color: ${props => props.theme["dark-grey"]};
  font-size: 0.875rem;
  margin-bottom: 0;
`;

const ConfirmationData = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
`;

class CheckInModalConfirmation extends React.Component {
  render() {
    const { checkInModalStore, prospectProfileStore } = this.props.rootStore;
    const fullName = prospectProfileStore.fullName;
    return (
      <ConfirmationContainer>
        <div className="row">
          {!checkInModalStore.playerNotPresent && (
            <div className="col-sm-12 col-md-4">
              <Photo>
                <img src={this.props.photo} alt={defaultPhoto} />
              </Photo>
            </div>
          )}

          <div className="col-sm-12 col-md-8">
            <div className="row">
              <ConfirmationListRow className="col-12">
                <ConfirmationLabel>
                  <FormattedMessage id="CheckInModalConfirmation.prospect" defaultMessage="Prospect" />
                </ConfirmationLabel>
                <ConfirmationData>{fullName}</ConfirmationData>
              </ConfirmationListRow>
            </div>

            <div className="row">
              <ConfirmationListRow className="col-12">
                <ConfirmationLabel>
                  <FormattedMessage id="CheckInModalConfirmation.checkingInto" defaultMessage="Checking Into" />
                </ConfirmationLabel>
                <ConfirmationData>{checkInModalStore.checkIn.trainingFacilityName}</ConfirmationData>
              </ConfirmationListRow>
            </div>

            <div className="row">
              <ConfirmationListRow className="col-6">
                <ConfirmationLabel>
                  <FormattedMessage id="CheckInModalConfirmation.on" defaultMessage="On" />
                </ConfirmationLabel>
                <ConfirmationData>{checkInModalStore.checkIn.checkedInDateDisplay}</ConfirmationData>
              </ConfirmationListRow>

              <ConfirmationListRow className="col-6">
                <ConfirmationLabel>
                  <FormattedMessage id="CheckInModalConfirmation.at" defaultMessage="At" />
                </ConfirmationLabel>
                <ConfirmationData>{checkInModalStore.checkIn.checkedInTimeDisplay}</ConfirmationData>
              </ConfirmationListRow>
            </div>
          </div>
        </div>
      </ConfirmationContainer>
    );
  }
}

CheckInModalConfirmation.propTypes = {
  rootStore: PropTypes.object.isRequired,
  photo: PropTypes.string
};

export default inject("rootStore")(observer(CheckInModalConfirmation));
