import React from "react";
import TravelAuthFormSection from "./TravelAuthFormSection";
import { DeleteIcon, FileAttachment, LabelFormatter } from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const fileName = <FormattedMessage id="FileAttachments.fileName" defaultMessage="Filename" />,
  label = <FormattedMessage id="FileAttachments.label" defaultMessage="Label" />,
  lastModified = <FormattedMessage id="FileAttachments.lastModified" defaultMessage="Last Modified" />,
  size = <FormattedMessage id="FileAttachments.size" defaultMessage="Size" />;

const FileAttachments = ({ rootStore: { travelAuthFormStore, authStore } }) => {
  const getFile = (event, fileName, fileIdentifier, contentType) => {
    event.preventDefault();
    travelAuthFormStore.getFile(fileName, fileIdentifier, contentType);
  };

  const FileNameFormatter = ({ row }) => {
    if (!!row.fileIdentifier) {
      //need to add this none existing #section to avoid node error
      return (
        <a href="#section" onClick={evt => getFile(evt, row.fileName, row.fileIdentifier, row.contentType)}>
          {row.fileName}
        </a>
      );
    } else {
      return <div>{row.fileName}</div>;
    }
  };

  const DeleteFormatter = data => {
    let row;
    let needsPadding = false;
    if (!!data.row) {
      // Desktop has .row, mobile does not
      row = data.row;
    } else {
      row = data;
      // Mobile needs padding
      needsPadding = true;
    }

    return (
      travelAuthFormStore.isFieldEditable("fileAttachmentsDelete") && (
        <DeleteIcon
          padding={needsPadding}
          deleteFunc={() => {
            travelAuthFormStore.deleteFileAttachment(row.fileAttachmentId);
          }}
        />
      )
    );
  };

  const cols = [
    {
      key: "fileName",
      name: fileName,
      formatter: FileNameFormatter
    },
    {
      key: "labels",
      name: label,
      width: 250,
      formatter: LabelFormatter
    },
    {
      key: "timeStampAsString",
      name: lastModified,
      width: 200
    },
    {
      key: "fileSizeAsString",
      name: size,
      width: 100
    },
    {
      key: "delete",
      name: "",
      width: 40,
      formatter: DeleteFormatter
    }
  ];

  return (
    <div className="col-12">
      <TravelAuthFormSection
        title={<FormattedMessage id="FileAttachments.title" defaultMessage="File Attachment(s)" />}
      >
        <FileAttachment
          cols={cols}
          editable={travelAuthFormStore.isFieldEditable("fileAttachments")}
          onFileAdd={files => travelAuthFormStore.addFileAttachments(files)}
          deleteFormatter={DeleteFormatter}
          files={travelAuthFormStore.travelAuthForm.fileAttachments}
          headerRowHeight={35}
        />
      </TravelAuthFormSection>
    </div>
  );
};

FileAttachments.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(FileAttachments));
