import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ErrorWarningConstants } from "../../constants/ErrorWarningConstants";
import { FormattedMessage } from "react-intl";
import {
  ModalBody,
  Modal,
  ModalHeaderWithTitleAndClose,
  LightButton,
  PrimaryButton,
  StickyFooterButtons,
  ModalFooter
} from "best-common-react";
import AgeRequirementWarning from "../profile/checkInModal/AgeRequirementWarning";

const ConfirmMessage = styled.div.attrs(() => ({
  className: "mb-3"
}))`
  font-size: 1.125rem;
  font-weight: 400;
`;

const Hint = styled.div`
  color: #ccc;
  font-size: 80%;
`;

const isRequired = <FormattedMessage id="ErrorWarningModal.isRequired" defaultMessage="is a required field." />;
const ageReqTitle = (
  <FormattedMessage id="ErrorWarningModal.ageReqTitle" defaultMessage="This Player does not meet age requirements!" />
);
const unableToCheckIn = (
  <FormattedMessage id="ErrorWarningModal.unableToCheckIn" defaultMessage="Unable to Check In Prospect" />
);
const ageReqOverrideButton = <FormattedMessage id="ErrorWarningModal.ageReqOverrideButton" defaultMessage="Check In" />;
const cancel = <FormattedMessage id="ErrorWarningModal.cancel" defaultMessage="Cancel" />;
const close = <FormattedMessage id="ErrorWarningModal.close" defaultMessage="Close" />;
const bulkTitle = <FormattedMessage id="ErrorWarningModal.bulkTitle" defaultMessage="Results" />;
const ignore = <FormattedMessage id="ErrorWarningModal.ignore" defaultMessage="Ignore" />;
const error = <FormattedMessage id="ErrorWarningModal.error" defaultMessage="Error" />;
const bulkError = (
  <FormattedMessage id="ErrorWarningModal.bulkError" defaultMessage="Please fix the following errors:" />
);

const getModalText = code => {
  switch (code) {
    case ErrorWarningConstants.CODES.AGE_REQUIREMENT_CHECK:
      return {
        title: ageReqTitle,
        subtitle: "",
        overrideButton: ageReqOverrideButton,
        button: cancel
      };

    case ErrorWarningConstants.CODES.OVERDUE_PERIOD_CHECK:
      return {
        title: unableToCheckIn,
        subtitle: "",
        overrideButton: ignore,
        button: close
      };
    default:
      return {
        title: error,
        subtitle: "",
        overrideButton: ignore,
        button: close
      };
  }
};

const renderOverrideableWarning = (message, hintMessage) => (
  <li key={message}>
    <div>{message}</div>
    <Hint>{hintMessage}</Hint>
  </li>
);

const renderErrors = (errors, language) => {
  if (!errors) {
    return null;
  }

  // Bulk Errors
  if (errors.length && errors.length > 0 && errors[0].errors) {
    errors = errors.reduce((allErrors, bulk) => {
      allErrors = allErrors.concat(bulk.errors);
      return allErrors;
    }, []);
  }

  const requiredFieldErrors = errors.filter(error => error.code === ErrorWarningConstants.CODES.REQUIRED_FIELD);

  if (requiredFieldErrors && requiredFieldErrors.length > 0) {
    return requiredFieldErrors.map((error, i) => (
      <li key={i}>
        {error.messageDetail ? error.messageDetail + ":" : ""} {getFieldIdentifier(error, language)} {isRequired}
      </li>
    ));
  }

  return errors.map((error, i) => <li key={i}>{getMessage(error, language)}</li>);
};

// Get the message (english, spanish) from an error/warning
const getMessage = (item, language) => {
  if (language === "es" && item.spanishMessage) {
    return item.spanishMessage;
  }
  return item.message;
};

const getFieldIdentifier = (item, language) => {
  if (language === "es" && item.spanishFieldIdentifier) {
    return item.spanishFieldIdentifier;
  }
  return item.fieldIdentifier;
};

class ErrorWarningModal extends Component {
  state = {
    bocApprovalFlag: false
  };

  onAgeWarningCheckboxChange = ({ target: { checked } }) => {
    this.setState({ bocApprovalFlag: checked });
  };

  renderWarnings = (warnings, language) => {
    return warnings.map(warning => {
      // Multi-part messages like the AGE_REQUIREMENT_CHECK have each part separated by # in the backend
      let message = getMessage(warning, language);
      let hintText = null;
      if (warning.message.includes("#")) {
        const messages = warning.message.split("#");
        message = messages[1];
        hintText = messages[2];
      }

      // Certain warnings need special rendering
      if (warning.code === ErrorWarningConstants.CODES.AGE_REQUIREMENT_CHECK) {
        return <AgeRequirementWarning message={message} hint={hintText} onChange={this.onAgeWarningCheckboxChange} />;
      }
      return renderOverrideableWarning(message, hintText);
    });
  };

  componentDidUpdate({ show: prevShow }) {
    if (!prevShow && this.props.show) {
      this.setState({ bocApprovalFlag: false });
    }
  }

  render() {
    const { rootStore, show = false, onClose } = this.props;
    const { errorWarningStore, translationsStore } = rootStore;
    const language = translationsStore.getCurrentLanguage();
    const modalText = getModalText(errorWarningStore.errorWarningCode);
    const buttonDisabled =
      errorWarningStore.errorWarningCode === ErrorWarningConstants.CODES.AGE_REQUIREMENT_CHECK &&
      !this.state.bocApprovalFlag;
    return (
      <Modal show={show} size="lg">
        <ModalHeaderWithTitleAndClose
          title={errorWarningStore.hasBulkResponses ? bulkTitle : modalText.title}
          close={() => errorWarningStore.closeModal()}
        />
        <ModalBody>
          <div>
            {!errorWarningStore.hasBulkResponses ? (
              <React.Fragment>
                <ConfirmMessage>{modalText.subtitle}</ConfirmMessage>
                <ul>
                  {renderErrors(errorWarningStore.errors, language)}
                  {this.renderWarnings(errorWarningStore.warnings, language)}
                </ul>
              </React.Fragment>
            ) : (
              <div>
                <ConfirmMessage>{errorWarningStore.getBulkResponseMessage}</ConfirmMessage>
                <ConfirmMessage>{bulkError}</ConfirmMessage>
                <ul>{renderErrors(errorWarningStore.bulkResponse.errors, language)}</ul>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <StickyFooterButtons>
            {errorWarningStore.overrideHandler && (
              <PrimaryButton
                onClick={() => {
                  errorWarningStore.overrideHandler();
                  errorWarningStore.closeModal();
                }}
                disabled={buttonDisabled}
              >
                {modalText.overrideButton}
              </PrimaryButton>
            )}
            <LightButton
              onClick={() => {
                errorWarningStore.closeModal();
              }}
            >
              {modalText.button}
            </LightButton>
          </StickyFooterButtons>
        </ModalFooter>
      </Modal>
    );
  }
}

ErrorWarningModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func
};

export default inject("rootStore")(observer(ErrorWarningModal));
