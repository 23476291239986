import React from "react";
import PropTypes from "prop-types";
import DateFormatter from "../../utilities/DateFormatter";
import ProspectName from "./ProspectName";

class ProspectsSearchResult extends React.Component {
  render() {
    const { suggestion, query } = this.props;

    return (
      <div>
        <ProspectName suggestion={suggestion} query={query} />
        {!!suggestion.birthCountry ? (
          <React.Fragment>
            <span> - </span>
            <span>{suggestion.birthCountry}</span>
          </React.Fragment>
        ) : (
          ""
        )}
        <span> - </span>
        <DateFormatter value={suggestion.dateOfBirth} />
      </div>
    );
  }
}

ProspectsSearchResult.propTypes = {
  suggestion: PropTypes.object.isRequired,
  query: PropTypes.string.isRequired
};

export default ProspectsSearchResult;
