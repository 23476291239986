import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import TravelAuthFormSection from "./TravelAuthFormSection";
import { FormInput, FormColumn2Wide, BestLabel } from "best-common-react";
import DateFormatter from "../../utilities/DateFormatter";
import Moment from "moment";
import { FormattedMessage } from "react-intl";
import NumberInput from "../../common/NumberInput";

class TripInfo extends React.Component {
  render() {
    const { travelAuthFormStore, lookupStore } = this.props.rootStore;
    const { travelAuthForm } = travelAuthFormStore;
    const countries = lookupStore.getDropdownOptions(lookupStore.lookups.INT_PLAYER_COUNTRIES);
    return (
      <FormColumn2Wide>
        <TravelAuthFormSection title={<FormattedMessage id="TripInfo.title" defaultMessage="Trip Info" />}>
          <div className="row">
            <FormColumn2Wide>
              <FormInput
                name="countryFlyingFrom"
                type="select"
                label={<FormattedMessage id="TripInfo.countryFlyingFrom" defaultMessage="Country Flying From" />}
                isRequired={true}
                value={lookupStore.getDropdownItem(countries, travelAuthForm.countryFlyingFromId)}
                options={countries}
                onChangeFn={country => (travelAuthForm.countryFlyingFromId = country.value)}
                editable={travelAuthFormStore.isFieldEditable("countryFlyingFrom")}
              />
            </FormColumn2Wide>
            <FormColumn2Wide>
              <FormInput
                name="countryFlyingTo"
                type="select"
                label={<FormattedMessage id="TripInfo.countryFlyingTo" defaultMessage="Country Flying To" />}
                isRequired={true}
                value={lookupStore.getDropdownItem(countries, travelAuthForm.countryFlyingToId)}
                options={countries}
                onChangeFn={country => (travelAuthForm.countryFlyingToId = country.value)}
                editable={travelAuthFormStore.isFieldEditable("countryFlyingTo")}
              />
            </FormColumn2Wide>
            <FormColumn2Wide>
              <FormInput
                type="date"
                name="arrivalDate"
                label={<FormattedMessage id="TripInfo.firstTravelDate" defaultMessage="First Travel Date" />}
                isRequired={true}
                value={
                  travelAuthFormStore.travelAuthForm.arrivalTsAsString
                    ? Moment(travelAuthFormStore.travelAuthForm.arrivalTsAsString, "MM/DD/YYYY")
                    : travelAuthFormStore.travelAuthForm.arrivalTsAsString
                }
                formatter={DateFormatter}
                onChangeFn={date => {
                  travelAuthFormStore.arrivalDateChanged(date);
                }}
                editable={travelAuthFormStore.isFieldEditable("arrivalDate")}
              />
            </FormColumn2Wide>
            <FormColumn2Wide>
              <FormInput
                type="date"
                name="departureDate"
                label={<FormattedMessage id="TripInfo.dateTravelCompleted" defaultMessage="Date Travel Completed" />}
                isRequired={true}
                value={
                  travelAuthFormStore.travelAuthForm.departureTsAsString
                    ? Moment(travelAuthFormStore.travelAuthForm.departureTsAsString, "MM/DD/YYYY")
                    : travelAuthFormStore.travelAuthForm.departureTsAsString
                }
                formatter={DateFormatter}
                onChangeFn={date => {
                  travelAuthFormStore.travelAuthForm.departureTsAsString = date
                    ? Moment(date).format("MM/DD/YYYY")
                    : date;
                  travelAuthFormStore.travelAuthForm.academyCheckoutTsAsString = date
                    ? Moment(date).format("MM/DD/YYYY")
                    : date;
                }}
                editable={travelAuthFormStore.isFieldEditable("departureDate")}
                minDate={Moment(travelAuthFormStore.travelAuthForm.arrivalTsAsString).toDate()}
              />
            </FormColumn2Wide>
            <FormColumn2Wide>
              <FormInput
                type="date"
                name="academyCheckIn"
                label={<FormattedMessage id="TripInfo.academyCheckIn" defaultMessage="Academy Check-in" />}
                isRequired={true}
                value={
                  travelAuthFormStore.travelAuthForm.academyCheckinTsAsString
                    ? Moment(travelAuthFormStore.travelAuthForm.academyCheckinTsAsString, "MM/DD/YYYY")
                    : travelAuthFormStore.travelAuthForm.academyCheckinTsAsString
                }
                formatter={DateFormatter}
                onChangeFn={date =>
                  (travelAuthFormStore.travelAuthForm.academyCheckinTsAsString = date
                    ? Moment(date).format("MM/DD/YYYY")
                    : date)
                }
                editable={travelAuthFormStore.isFieldEditable("academyCheckIn")}
              />
            </FormColumn2Wide>
            <FormColumn2Wide>
              <FormInput
                type="date"
                name="academyCheckOut"
                label={<FormattedMessage id="TripInfo.academyCheckOut" defaultMessage="Academy Check-out" />}
                isRequired={true}
                value={
                  travelAuthFormStore.travelAuthForm.academyCheckoutTsAsString
                    ? Moment(travelAuthFormStore.travelAuthForm.academyCheckoutTsAsString, "MM/DD/YYYY")
                    : travelAuthFormStore.travelAuthForm.academyCheckoutTsAsString
                }
                formatter={DateFormatter}
                onChangeFn={date =>
                  (travelAuthFormStore.travelAuthForm.academyCheckoutTsAsString = date
                    ? Moment(date).format("MM/DD/YYYY")
                    : date)
                }
                editable={travelAuthFormStore.isFieldEditable("academyCheckOut")}
                minDate={Moment(travelAuthFormStore.travelAuthForm.academyCheckinTsAsString).toDate()}
              />
            </FormColumn2Wide>
            <FormColumn2Wide>
              <FormInput
                type="number"
                name="daysInTrip"
                label={<FormattedMessage id="TripInfo.daysInTripLabel" defaultMessage="Days in Trip" />}
                value={travelAuthForm.daysInTrip === null ? "" : travelAuthForm.daysInTrip}
                onChangeFn={days => (travelAuthForm.daysInTrip = days)}
                editable={travelAuthFormStore.isFieldEditable("daysInTrip")}
              />
            </FormColumn2Wide>
          </div>
        </TravelAuthFormSection>
      </FormColumn2Wide>
    );
  }
}

TripInfo.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TripInfo));
