import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ProspectLinkFormatter from "../utilities/ProspectLinkFormatter";

const Wrapper = styled.span`
  display: flex;
`;

const Column = styled.span`
  flex: 33%;
`;

const PrimaryInfo = styled.div`
  color: black;
  font-size: 14px;
`;

const SecondaryInfo = styled.div`
  color: ${props => props.theme["dark-grey"]};
  font-size: 12px;
`;

class PotentialMatchMobileCard extends React.Component {
  render() {
    const { isMatch, match } = this.props;
    return (
      <Wrapper>
        <Column>
          {isMatch && match.onCurrentProspectList ? (
            <ProspectLinkFormatter value={match.lastName} row={match} />
          ) : (
            <PrimaryInfo>{match.lastName}</PrimaryInfo>
          )}
          <SecondaryInfo>{match.extendedLastName}</SecondaryInfo>
        </Column>
        <Column>
          {isMatch && match.onCurrentProspectList ? (
            <ProspectLinkFormatter value={match.firstName} row={match} />
          ) : (
            <PrimaryInfo>{match.firstName}</PrimaryInfo>
          )}
          <SecondaryInfo>{match.middleName}</SecondaryInfo>
        </Column>
      </Wrapper>
    );
  }
}

PotentialMatchMobileCard.propTypes = {
  match: PropTypes.object.isRequired,
  isMatch: PropTypes.bool.isRequired
};

export default PotentialMatchMobileCard;
