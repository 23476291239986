import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

export const EmptyCell = styled.div`
  color: ${props => props.theme["grey"]};
`;

class ZeroNumberCellFormatter extends React.Component {
  render() {
    const count = this.props.value;
    return count > 0 ? <div>{count}</div> : <EmptyCell>{count}</EmptyCell>;
  }
}

ZeroNumberCellFormatter.propTypes = {
  value: PropTypes.number.isRequired,
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ZeroNumberCellFormatter));
