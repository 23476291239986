import React from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeaderWithTitleAndClose,
  StickyFooterButtons,
  Icon,
  AccordionTableWrapper
} from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import NationalIdFormatter from "./NationalIdFormatter";
import DateFormatter from "../utilities/DateFormatter";
import ProspectLinkFormatter from "../utilities/ProspectLinkFormatter";
import PotentialMatchTableMobile from "./PotentialMatchTableMobile";
import { ProspectsConstants } from "../../constants/ProspectsConstants";
import { FormattedMessage, intlShape, defineMessages } from "react-intl";

const ConfirmMessage = styled.div.attrs(() => ({
  className: "mb-3"
}))`
  font-size: 1.125rem;
  font-weight: 400;
`;

const TableDescriptor = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${props => props.theme["dark-grey"]};
`;

const lastName = <FormattedMessage id="PotentialMatchModal.lastName" defaultMessage="Last" />,
  firstName = <FormattedMessage id="PotentialMatchModal.firstName" defaultMessage="First" />,
  extLast = <FormattedMessage id="PotentialMatchModal.extLast" defaultMessage="Ext Last" />,
  middleName = <FormattedMessage id="PotentialMatchModal.middleName" defaultMessage="Middle" />,
  birthCity = <FormattedMessage id="PotentialMatchModal.birthCity" defaultMessage="Birth City" />,
  dateOfBirth = <FormattedMessage id="PotentialMatchModal.dateOfBirth" defaultMessage="Birth Date" />,
  nationalIdNumber = <FormattedMessage id="PotentialMatchModal.nationalIdNumber" defaultMessage="National ID" />;

class PotentialMatchModal extends React.Component {
  constructor() {
    super();
    this.getMatchColumns = this.getMatchColumns.bind(this);
    this.cols = [
      {
        key: "addProspect",
        name: "",
        width: 40,
        resizable: false
      },
      {
        key: "checkIn",
        name: "",
        width: 40,
        resizable: false
      },
      {
        key: "lastName",
        name: lastName,
        width: 100
      },
      {
        key: "firstName",
        name: firstName,
        width: 100
      },
      {
        key: "extendedLastName",
        name: extLast,
        width: 75
      },
      {
        key: "middleName",
        name: middleName,
        width: 75
      },
      {
        key: "birthCity",
        name: birthCity,
        width: 110
      },
      {
        key: "dateOfBirth",
        name: dateOfBirth,
        width: 100,
        formatter: DateFormatter
      },
      {
        key: "nationalIdNumber",
        name: nationalIdNumber,
        formatter: NationalIdFormatter,
        getRowMetaData: row => row,
        width: 200
      },
      // We need to set a width for nationalIdNumber
      // But we still need a column with no width
      // So that it will grow and take up any empty space on the right
      {
        key: "filler",
        name: ""
      }
    ];
  }
  getMatchColumns(isEbisMatch) {
    const { newProspectStore, checkInModalStore, routerStore } = this.props.rootStore;
    // Copy the columns array so we don't mutate the original list
    let columns = this.cols.map(a => Object.assign({}, a));

    // These need to be defined differently becase we can't pass a FormattedMessage to Icon
    const messages = defineMessages({
      addProspect: {
        id: "PotentialMatchModal.addProspect",
        defaultMessage: "Add to current Prospect list"
      },
      checkInIcon: {
        id: "PotentialMatchModal.checkInIcon",
        defaultMessage: "Check In Prospect"
      }
    });

    let AddProspectFormatter = ({ row }) => {
      const showAddProspectIcon = !row.onCurrentProspectList;
      return showAddProspectIcon ? (
        // If the Club does not have a profile for the potential match, there will be an add player icon
        <div
          onClick={() => {
            // Clicking the add player icon will open the profile in edit mode
            newProspectStore.addToProspectList(row);
          }}
        >
          {
            <Icon
              iconName="fa-user-plus"
              className="fa-md"
              title={this.context.intl.formatMessage(messages.addProspect)}
            />
          }
        </div>
      ) : (
        ""
      );
    };

    let CheckInFormatter = ({ row }) => {
      // If the Club has a profile for the potential match, there will be a check in icon
      // Don't show for prospects who are already checked in
      const showCheckInIcon = row.status !== ProspectsConstants.CHECKED_IN && row.onCurrentProspectList;

      return showCheckInIcon ? (
        <div
          onClick={() => {
            // clicking the check in icon will load the prospect's profile and launch the check in flow
            routerStore.goToProspect(row.amtProspectId);
            // Run rule checks for the check in modal
            checkInModalStore.canCheckIn();
          }}
        >
          {
            <Icon
              iconName="fa-calendar-check"
              className="fa-md"
              title={this.context.intl.formatMessage(messages.checkInIcon)}
            />
          }
        </div>
      ) : (
        ""
      );
    };

    let MatchLinkFormatter = ({ row, value }) => {
      if (row.onCurrentProspectList) {
        // The names of potential matches will be a hyperlink if the Club already has a profile for that match
        return <ProspectLinkFormatter value={value} row={row} />;
      } else {
        // If the Club does not have a profile for the potential match, the name will not be a hyperlink
        return <div>{value}</div>;
      }
    };

    // Ebis matches require slightly different column names
    if (isEbisMatch) {
      columns[4].key = "extendedLastName";
      columns[6].key = "birthCityLkup";
      columns[7].key = "birthDateAsString";
      columns[8].key = "nationalIdValue";
    }

    // Add prospect button
    columns[0].formatter = AddProspectFormatter;

    // Add check in formatter
    columns[1].formatter = CheckInFormatter;

    // Last Name Hyperlink
    columns[2].formatter = MatchLinkFormatter;
    columns[2].getRowMetaData = row => row;
    // First Name Hyperlink
    columns[3].formatter = MatchLinkFormatter;
    columns[3].getRowMetaData = row => row;

    return columns;
  }
  render() {
    const { newProspectStore } = this.props.rootStore;
    const { prospect } = newProspectStore;

    const title = newProspectStore.isHardMatch ? (
      <FormattedMessage
        id="PotentialMatchModal.hardMatchTitle"
        defaultMessage="The info entered matches an existing profile"
      />
    ) : (
      <FormattedMessage
        id="PotentialMatchModal.softMatchTitle"
        defaultMessage="The info entered matches existing profile(s)"
      />
    );

    const message = newProspectStore.isHardMatch ? (
      <FormattedMessage
        id="PotentialMatchModal.hardMatchMessage"
        defaultMessage="You cannot create a profile for this player because one already exists."
      />
    ) : (
      <FormattedMessage
        id="PotentialMatchModal.softMatchMessage"
        defaultMessage="Is the player you're entering one of the prospects below?"
      />
    );

    const systemMatch = <FormattedMessage id="PotentialMatchModal.systemMatch" defaultMessage="System match:" />;
    const systemMatches = <FormattedMessage id="PotentialMatchModal.systemMatches" defaultMessage="System matches:" />;

    const matchDescriptor = newProspectStore.isHardMatch ? systemMatch : systemMatches;

    const matches = newProspectStore.potentialMatches;

    const isEbisMatch = newProspectStore.isEbisMatch(matches);
    const matchColumns = this.getMatchColumns(isEbisMatch);
    return (
      <div>
        <Modal show={this.props.show} onClose={() => {}} size="lg">
          <ModalHeaderWithTitleAndClose
            title={title}
            close={() => {
              newProspectStore.setShowMatchModal(false);
            }}
          />
          <ModalBody>
            <div>
              <ConfirmMessage>{message}</ConfirmMessage>
              <TableDescriptor>
                <FormattedMessage id="PotentialMatchModal.youEntered" defaultMessage="You entered:" />
              </TableDescriptor>
              <AccordionTableWrapper
                id={"entered-prospect-table"}
                data={[prospect]}
                columns={this.cols}
                accordion={PotentialMatchTableMobile}
                height={85}
                headerRowHeight={35}
              />
              <TableDescriptor>{matchDescriptor}</TableDescriptor>
              <AccordionTableWrapper
                id={"potential-matches-table"}
                data={matches}
                columns={matchColumns}
                accordion={PotentialMatchTableMobile}
                height={50 + matches.length * 35}
                headerRowHeight={35}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <StickyFooterButtons>
              {newProspectStore.isHardMatch ? null : (
                <LightButton onClick={newProspectStore.goToProfile}>
                  <FormattedMessage id="PotentialMatchModal.continue" defaultMessage="No, continue creating prospect" />
                </LightButton>
              )}
              <LightButton onClick={() => newProspectStore.setShowMatchModal(false)}>
                <FormattedMessage id="PotentialMatchModal.cancel" defaultMessage="Cancel" />
              </LightButton>
            </StickyFooterButtons>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

PotentialMatchModal.defaultProps = {
  show: false
};

PotentialMatchModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  show: PropTypes.bool
};

PotentialMatchModal.contextTypes = {
  intl: intlShape
};

export default inject("rootStore")(observer(PotentialMatchModal));
