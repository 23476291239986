import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import {
  MobileView,
  DesktopTabletView,
  NavbarDropdown,
  NavigationDropdownItem,
  NavigationDropdownLink,
  NavigationLink
} from "best-common-react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import LanguageSelector from "../translations/LanguageSelector";
import { RouteConstants } from "../../constants/RouteConstants";
import ClickableLinkWrapper from "../common/ClickableLinkWrapper";

const NavbarHolder = styled.div.attrs({
  id: "navbarSupportedContent"
})`
  @media (max-width: 991px) {
    height: ${props => (props.isProd ? "calc(100vh - 60px)" : "calc(100vh - 78px)")};
    margin-left: -1rem;
    margin-right: -1rem;
    overflow: scroll;
  }
  @media (min-width: 992px) {
    margin-top: -1rem;
    margin-bottom: -1rem;
    height: 60px;
  }
`;

const Navbar = styled.ul.attrs({
  className: "navbar-nav mr-auto"
})`
  height: 60px;
`;

class NavigationLinks extends React.Component {
  render() {
    const { authStore, routerStore, prospectsStore } = this.props.rootStore;
    const collapseClass = authStore.mobileNavOpen ? "show" : "hide";

    // Translations for navigation items
    const clubs = <FormattedMessage id="Navigation.clubs" defaultMessage="Clubs" />;
    const prospects = <FormattedMessage id="Navigation.prospects" defaultMessage="Prospects" />;
    const newProspect = <FormattedMessage id="Navigation.newProspect" defaultMessage="New Prospect" />;
    const travelForms = <FormattedMessage id="Navigation.travelForms" defaultMessage="Travel Forms" />;
    const help = <FormattedMessage id="Navigation.help" defaultMessage="Help" />;
    const signOut = this.props.signOut;
    const defaultPropspectOrgId = 0;
    return (
      <NavbarHolder isProd={authStore.isProd} className={`collapse navbar-collapse ${collapseClass}`}>
        <Navbar>
          <MobileView>
            <NavbarDropdown>
              {authStore.isBOC ? (
                <NavigationDropdownLink
                  to={RouteConstants.CLUBS}
                  name={clubs}
                  isActive={routerStore.isClubsTab}
                  toggleMobileNav={() => {
                    authStore.setMobileNavOpen(false);
                  }}
                />
              ) : (
                ""
              )}
              <ClickableLinkWrapper onClickCall={() => prospectsStore.setOrgId(defaultPropspectOrgId)}>
                <NavigationDropdownLink
                  to={RouteConstants.PROSPECTS}
                  name={prospects}
                  isActive={routerStore.isProspectsTab}
                  toggleMobileNav={() => {
                    authStore.setMobileNavOpen(false);
                  }}
                />
              </ClickableLinkWrapper>
              {authStore.isAdmin ? (
                <NavigationDropdownLink
                  to={RouteConstants.NEW_PROSPECT}
                  name={newProspect}
                  isActive={routerStore.isNewProspectTab}
                  toggleMobileNav={() => {
                    authStore.setMobileNavOpen(false);
                  }}
                />
              ) : (
                ""
              )}
              <NavigationDropdownLink
                to={RouteConstants.TRAVEL_FORMS}
                name={travelForms}
                isActive={routerStore.isTravelFormsTab}
                toggleMobileNav={() => {
                  authStore.setMobileNavOpen(false);
                }}
              />
              <NavigationDropdownLink
                to={RouteConstants.HELP}
                name={help}
                isActive={routerStore.isHelpTab}
                toggleMobileNav={() => {
                  authStore.setMobileNavOpen(false);
                }}
              />
            </NavbarDropdown>
            <NavbarDropdown title={authStore.userData.preferred_username}>
              <LanguageSelector />
              <NavigationDropdownItem onClick={authStore.logout} name={signOut} isActive={false} />
            </NavbarDropdown>
          </MobileView>
          <DesktopTabletView>
            {authStore.isBOC ? (
              <NavigationLink to={RouteConstants.CLUBS} name={clubs} isActive={routerStore.isClubsTab} />
            ) : (
              ""
            )}
            <ClickableLinkWrapper onClickCall={() => prospectsStore.setOrgId(defaultPropspectOrgId)}>
              <NavigationLink to={RouteConstants.PROSPECTS} name={prospects} isActive={routerStore.isProspectsTab} />
            </ClickableLinkWrapper>
            {authStore.isAdmin ? (
              <NavigationLink
                to={RouteConstants.NEW_PROSPECT}
                name={newProspect}
                isActive={routerStore.isNewProspectTab}
              />
            ) : (
              ""
            )}
            <NavigationLink
              to={RouteConstants.TRAVEL_FORMS}
              name={travelForms}
              isActive={routerStore.isTravelFormsTab}
            />
            <NavigationLink to={RouteConstants.HELP} name={help} isActive={routerStore.isHelpTab} />
          </DesktopTabletView>
        </Navbar>
      </NavbarHolder>
    );
  }
}

NavigationLinks.propTypes = {
  rootStore: PropTypes.object.isRequired,
  signOut: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(NavigationLinks));
