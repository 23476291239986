import React from "react";
import { inject, observer } from "mobx-react";
import ClubsFilter from "./StatusFilters";
import styled from "styled-components";
import PropTypes from "prop-types";

const HeaderWrapper = styled.div.attrs({
  className: "row"
})`
  margin-bottom: 20px;
`;

class ClubsHeader extends React.Component {
  render() {
    return (
      <HeaderWrapper>
        <div className="col-12">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-lg-3 order-lg-1">
              <ClubsFilter />
            </div>
          </div>
        </div>
      </HeaderWrapper>
    );
  }
}

ClubsHeader.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ClubsHeader));
