import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Checkbox } from "best-common-react";
import styled from "styled-components";

const CheckBoxListItem = styled.li`
  list-style-type: none;
  label {
    display: inline;
  }
`;

const Hint = styled.div`
  color: #ccc;
  font-size: 80%;
`;

const AgeRequirementWarning = ({ onChange, message, hint }) => {
  return (
    <CheckBoxListItem key={message}>
      <Checkbox label={message} onChange={onChange} />
      <Hint>{hint}</Hint>
    </CheckBoxListItem>
  );
};

AgeRequirementWarning.propTypes = {
  onChange: PropTypes.func.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default inject("rootStore")(observer(AgeRequirementWarning));
