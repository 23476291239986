import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BestLabel, Icon } from "best-common-react";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import DateFormatter from "../utilities/DateFormatter";
import defaultPhoto from "../../assets/image/default-prospect-photo.png";
import CheckInCheckOutButton from "../prospects/CheckInCheckOutButton";

const SummaryWrapper = styled.div.attrs({
  className: "mt-2"
})`
  > div {
    margin-bottom: 10px;
  }
`;

const Photo = styled.div`
  background-color: black;
  text-align: center;
  overflow: hidden;
  border: 2px solid ${props => props.theme["dark-grey"]};
  width: 100%;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Red = styled.div`
  color: ${props => props.theme["mlb-red"]};
`;

class ProfileSummary extends React.Component {
  render() {
    const { checkInHistoryStore, prospectProfileStore, lookupStore, authStore } = this.props.rootStore;
    const { prospect } = prospectProfileStore;

    // We can re-use this component and render slightly different things based on the page we're on or where we came from
    const { mode } = this.props;
    const isTravelAuthForm = mode === "travelAuthForm";
    const isNewProspect = prospectProfileStore.newProspectMode;
    // Profile will be the default
    const isProfile = mode === "profile" || (!isTravelAuthForm && !isNewProspect);

    return (
      <SummaryWrapper>
        {isTravelAuthForm ? null : (
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-12">
              {authStore.isAdmin ? <CheckInCheckOutButton prospect={prospect} /> : ""}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-12">
            <Photo>
              {checkInHistoryStore.latestPhoto !== null ? (
                <img src={checkInHistoryStore.latestPhoto} alt="" />
              ) : (
                <img src={defaultPhoto} alt="" />
              )}
            </Photo>
          </div>
          {isProfile ? (
            <React.Fragment>
              <div className="col-sm-12 col-md-4 col-lg-12">
                <div className="row">
                  <div className="col-12">
                    <div>
                      <BestLabel>
                        <FormattedMessage
                          id="ProfileSummary.eligibleToSignDate"
                          defaultMessage="Eligible To Sign Date"
                        />
                      </BestLabel>
                      <div>
                        <DateFormatter value={prospect.eligibleToSignDt} />
                      </div>
                    </div>
                    <div>
                      <BestLabel>
                        <FormattedMessage id="ProfileSummary.l1Reg" defaultMessage="L1 Reg" />
                      </BestLabel>
                      {prospect.l1RegistrationFlag ? (
                        <div>
                          <FormattedMessage id="ProfileSummary.yes" defaultMessage="Yes" />
                        </div>
                      ) : (
                        <Red>No</Red>
                      )}
                    </div>
                    <div>
                      <BestLabel>
                        <FormattedMessage id="ProfileSummary.monthsFromSigning" defaultMessage="Months From Signing" />
                        <Icon
                          style={{ color: "#ffae47", marginLeft: "5px" }}
                          onClick={() => prospectProfileStore.setShowMonthsFromSigningModal(true)}
                          iconName="fa-info-circle"
                          className="fa-md icon-cog"
                          title="Months From Signing"
                        />
                      </BestLabel>
                      <div>{prospect.monthsFromSigning}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-12">
                <div className="row">
                  <div className="col-12">
                    <div>
                      <BestLabel>
                        <FormattedMessage id="ProfileSummary.daysUsed" defaultMessage="Days Used" />
                      </BestLabel>
                      <div>{prospect.daysUsedTotal}</div>
                    </div>
                    <div>
                      <BestLabel>
                        <FormattedMessage id="ProfileSummary.daysLeftInPeriod" defaultMessage="Days Left In Period" />
                      </BestLabel>
                      <div>{prospect.daysLeftInPeriod}</div>
                    </div>
                    <div>
                      <BestLabel>
                        <FormattedMessage
                          id="ProfileSummary.nextCheckInAllowed"
                          defaultMessage="Next Check In Allowed"
                        />
                      </BestLabel>
                      <div>
                        <DateFormatter value={prospect.nextCheckInAllowed} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : isTravelAuthForm ? (
            <div className="col-sm-12 col-md-8 col-lg-12">
              <div>
                <BestLabel>
                  <FormattedMessage id="ProfileSummary.dateOfBirth" defaultMessage="Birth Date" />
                </BestLabel>
                <div>{prospect.dateOfBirthAsString}</div>
              </div>
              <div>
                <BestLabel>
                  <FormattedMessage id="ProfileSummary.countryOfBirth" defaultMessage="Birth Country" />
                </BestLabel>
                <div>{lookupStore.getValue(lookupStore.lookups.INT_PLAYER_COUNTRIES, prospect.birthCountryId)}</div>
              </div>
              <div>
                <BestLabel>
                  <FormattedMessage id="ProfileSummary.countryOfResidence" defaultMessage="Country of Residence" />
                </BestLabel>
                <div>
                  {lookupStore.getValue(lookupStore.lookups.INT_PLAYER_COUNTRIES, prospect.countryOfResidenceId)}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </SummaryWrapper>
    );
  }
}

ProfileSummary.propTypes = {
  rootStore: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired
};

export default inject("rootStore")(observer(ProfileSummary));
