import _ from "lodash";
// Helper functions for tables in AMT

class TableHelper {
  constructor() {
    this.sort = this.sort.bind(this);
    this.sortObj = this.sortObj.bind(this);
  }

  sort(list, keys, direction) {
    // If we aren't given a list of column keys to sort by, then just return the original list
    if (keys === undefined) {
      return list;
    }

    // If for some reason we're given a single key
    // Just coerce it into an array
    if (!Array.isArray(keys)) {
      keys = [keys];
    }

    // Map over the keys and return a function (application of sortObj)
    // This lets _.sortBy sort by the appropriate type
    // _.sortBy takes the list of functions and uses them in its internal comparator
    // Each column (key) is sorted
    const sorted = _.sortBy(
      list,
      keys.map(key => {
        return item => this.sortObj(item, key, direction);
      })
    );

    if (typeof direction === "string" && direction.toUpperCase() === "DESC") {
      // If descending sort, reverse the list
      // This isn't the most efficient, but we're dealing with small lists here
      sorted.reverse();
    }
    return sorted;
  }

  // If numeric key, or string representation of a number, sort by number
  // If string key, sort by lowercase for case insensitivity.
  // Otherwise just sort by the key
  sortObj(item, key, direction) {
    if (!isNaN(Number(item[key]))) {
      if (item[key] === null) {
        return Infinity;
      }
      return Number(item[key]);
    } else if (typeof item[key] === "string") {
      return item[key].toLowerCase();
    }
    return item[key];
  }
}

export default TableHelper;
