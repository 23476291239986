import { action, autorun, extendObservable, decorate, computed } from "mobx";
import { AlertConstants } from "../../constants/AlertConstants";
import { ErrorWarningConstants } from "../../constants/ErrorWarningConstants";
import { RouteConstants } from "../../constants/RouteConstants";
import FormHelper from "../../utilities/FormHelper";
import _ from "lodash";
import Moment from "moment";

class NewProspectStore {
  constructor(commonStore, prospectProfileStore) {
    this.authStore = commonStore.authStore;
    this.amtApi = commonStore.amtApi;
    this.lookupStore = commonStore.lookupStore;
    this.alertStore = commonStore.alertStore;
    this.routerStore = commonStore.routerStore;
    this.errorWarningStore = commonStore.errorWarningStore;
    this.prospectProfileStore = prospectProfileStore;
    this.formHelper = new FormHelper();
    const dateOfBirth = null;

    this.defaults = {
      prospect: {
        orgId: 0,
        countryOfResidenceId: 0,
        firstName: "",
        lastName: "",
        dateOfBirthAsString: "",
        birthCountry: "",
        birthCountryId: 0,
        birthProvince: "",
        birthProvinceId: 0,
        birthStateId: 0,
        birthCity: "",
        birthCityId: 0,
        countryOfIssueId: 0,
        nationalIdNumber: "",
        get dateOfBirth() {
          return this.dateOfBirthAsString;
        }
      },
      showMatchModal: false,
      isHardMatch: false,
      potentialMatches: []
    };

    extendObservable(this, {
      prospect: this.defaults["prospect"],
      showMatchModal: this.defaults["showMatchModal"],
      overrideFlag: false,
      potentialMatches: this.defaults["potentialMatches"],
      setProspect: action(prospect => {
        this.prospect = prospect;
      }),
      setShowMatchModal: action(bool => {
        this.showMatchModal = bool;
      }),
      setIsHardMatch: action(bool => {
        this.isHardMatch = bool;
      }),
      setValue: action((key, value) => {
        this.prospect[key] = value;
      }),
      next: action(() => {
        // Reset store
        this.prospectProfileStore.resetStore();

        // Populate profile with form info
        const profile = this.getProspectProfileFromInput(this.prospect);
        profile.overrideFlag = this.overrideFlag;
        this.prospectProfileStore.setProspect(profile);

        this.amtApi.getPotentialProspectMatches(this.prospectProfileStore.prospect).then(response => {
          if (!response) {
            this.goToProfile();
          }
          if (this.errorWarningStore.errorWarningCode === ErrorWarningConstants.CODES.INT_PLAYER_STATUS_CHECK_NEEDED) {
            this.errorWarningStore.setOverrideHandler(() => {
              this.overrideFlag = true;
              this.next();
            });
          } else {
            this.overrideFlag = false;
          }
          let matches = response;
          if (!!matches && matches.length > 0) {
            if (this.hasHardMatch(response)) {
              matches = this.getHardMatch(response);
            } else {
              matches = this.getSoftMatches(response);
            }
            // Set the Country of Issue Code for display
            this.populateCountryOfIssueCode(this.prospect.countryOfIssueId);

            // Set the Birth City for display
            if (!this.prospect.birthCity) {
              const cityName = this.getCityName(this.lookupStore.filteredCities, this.prospect.birthCityId);
              this.prospect.birthCity = cityName;
            }
            this.showMatchModal = true;
            // Display soft match modal vs hard match modal
            this.isHardMatch = this.hasHardMatch(matches);

            matches.forEach(match => {
              if (match.dateOfBirthAsString) {
                match.dateOfBirth = match.dateOfBirthAsString;
              }
            });

            this.potentialMatches = matches;
          } else if (!response.response) {
            this.goToProfile();
          }
        });
      }),
      goToProfile: action(() => {
        // Go to the prospect profile page from the new prospect form.
        // Assumes that the prospect profile has already been set up with the correct prospect state

        // Set state to put profile in the correct mode
        this.prospectProfileStore.editMode = true;
        this.prospectProfileStore.newProspectMode = true;
        // Make sure the match modal is closed
        this.showMatchModal = false;
        // Redirect to profile
        this.routerStore.goToProspect(0);
      }),
      updateBirthCountryId: action(countryId => {
        this.prospect.birthCountryId = parseInt(countryId, 10);
        this.prospect.birthProvinceId = this.defaults.prospect["birthProvinceId"];
        this.prospect.birthStateId = this.defaults.prospect["birthStateId"];
        this.prospect.birthProvince = this.defaults.prospect["birthProvince"];
        this.prospect.birthCityId = this.defaults.prospect["birthCityId"];
        this.prospect.birthCity = this.defaults.prospect["birthCity"];
        this.lookupStore.setSelectedCountryId(countryId);
      }),
      updateBirthStateId: action(stateId => {
        this.prospect.birthStateId = stateId;
        this.prospect.birthProvinceId = this.defaults.prospect["birthProvinceId"];
        this.prospect.birthProvince = this.defaults.prospect["birthProvince"];
        this.prospect.birthCityId = this.defaults.prospect["birthCityId"];
        this.prospect.birthCity = this.defaults.prospect["birthCity"];
        this.lookupStore.setSelectedDistrictId(stateId);
      }),
      updateBirthProvinceId: action(provinceId => {
        this.prospect.birthProvinceId = provinceId;
        this.prospect.birthProvince = this.defaults.prospect["birthProvince"];
        this.prospect.birthStateId = this.defaults.prospect["birthStateId"];
        this.prospect.birthCityId = this.defaults.prospect["birthCityId"];
        this.prospect.birthCity = this.defaults.prospect["birthCity"];
        this.lookupStore.setSelectedDistrictId(provinceId);
      }),
      resetStore: action(() => {
        this.prospect = this.defaults["prospect"];
        this.showMatchModal = this.defaults["showMatchModal"];
        this.isHardMatch = this.defaults["isHardMatch"];
      })
    });

    autorun(() => {
      if (this.routerStore.isNewProspectTab) {
        this.resetStore();
      }
    });

    autorun(() => {
      if (!this.authStore.isBOC && this.prospect) {
        this.prospect.orgId = this.authStore.userData.org;
      }
    });
  }

  // Given form input, build a valid prospectProfileStore.prospect object
  // That we can use to display on the profile
  getProspectProfileFromInput(data) {
    let profile = this.getDefaultProfile();

    // Set some fields from the form
    profile.orgId = data.orgId;
    profile.lastName = data.lastName;
    profile.firstName = data.firstName;
    profile.middleName = data.middleName;
    profile.birthCountry = data.birthCountry;
    profile.birthCountryId = data.birthCountryId;
    profile.dateOfBirthAsString = data.dateOfBirthAsString;
    profile.birthCity = data.birthCity;
    profile.birthCityId = data.birthCityId;
    profile.countryOfResidenceId = data.countryOfResidenceId;
    profile.trainerAlias = data.trainerAlias;
    profile.personId = data.personId;
    profile.countryOfIssueId = data.countryOfIssueId;
    profile.nationalIdNumber = data.nationalIdNumber;
    profile.phoneNumber = data.phoneNumber;
    profile.trainerPhoneNumber = data.trainerPhoneNumber;

    // Set birth (state, province, district) info from the form
    profile = this.populateProfileDistrictFromBirthCountry(profile, data);

    return profile;
  }

  // Given data from a match, build a valid prospectProfileStore.prospect object
  // That we can use to display on the profile
  getProspectProfileFromMatch(data, matchType) {
    // Match is an ebis match, and we had permission to make the "Get Player" call to ebis2
    // This returns an actual prospect object, so we don't have to do anything special here.
    if (matchType === "EBIS") {
      if (!data.middleName || data.middleName === "") {
        data.middleNameFlag = true;
      }

      if (!data.extendedLastName || data.extendedLastName === "") {
        data.extendedLastNameFlag = true;
      }

      if (!data.birthStateId && this.prospect.birthStateId) {
        data.birthStateId = this.prospect.birthStateId;
      }

      return data;
    }

    let profile = this.getDefaultProfile();
    if (matchType === "EBIS-FROM-MODAL") {
      // Set some fields that are the same key name
      profile.orgId = data.orgId;
      profile.lastName = data.lastName;
      profile.firstName = data.firstName;
      profile.middleName = data.middleName;
      profile.extendedLastName = data.extendedLastName;
      profile.birthCountry = data.birthCountry;
      profile.birthCountryId = data.birthCountryId;
      profile.birthStateId = data.birthStateId;
      profile.birthProvinceId = data.birthProvinceId;
      profile.birthDistrict = data.birthDistrict;
      profile.birthDistrictId = data.birthDistrictId;
      profile.birthCity = data.birthCity;
      profile.birthCityId = data.birthCityId;

      // Set some fields that are different key name
      profile.personId = data.playerId;
      profile.countryOfResidenceId = data.homeCountryId;
      profile.countryOfIssueId = data.nationalIdCountryId;
      profile.nationalIdNumber = data.nationalIdValue;
      profile.dateOfBirthAsString = data.dateOfBirthAsString;

      // Update filtered lookups
      this.lookupStore.setSelectedCountryId(profile.birthCountryId);
      if (profile.birthCountryId === 1) {
        this.lookupStore.setSelectedDistrictId(profile.birthStateId);
      } else {
        this.lookupStore.setSelectedDistrictId(profile.birthDistrictId);
      }
    }

    return profile;
  }

  // The profile can have a birth(State, Province, District)
  // Depending on the birth Country.
  // Set the appropriate field on the profile based on the birthCountryId
  populateProfileDistrictFromBirthCountry(prospect, data) {
    let profile = _.cloneDeep(prospect);
    // Reset State/Province/District first
    profile.birthProvinceId = null;
    profile.birthDistrict = null;
    profile.birthDistrictId = null;
    profile.birthStateId = null;
    profile.birthState = null;

    const US = 1;
    const CAN = 2;
    // Populate based on country
    if (data.birthCountryId === US) {
      profile.birthStateId = data.birthStateId;
    } else if (CAN === data.birthCountryId) {
      profile.birthProvinceId = data.birthProvinceId;
    } else {
      profile.birthDistrict = data.birthProvince;
      profile.birthDistrictId = data.birthProvinceId;
    }

    return profile;
  }

  getDefaultProfile() {
    // We can get a sane default prospect profile state
    // For when we come from the new prospects page

    // Reset the profile
    this.prospectProfileStore.resetStore();
    let profile = {};

    // Prospect fields that we are defaulting
    profile.amtProspectId = 0;
    profile.status = "Draft";
    // Set blank positions
    profile.positions = [{ sequenceNumber: 1 }, { sequenceNumber: 2 }, { sequenceNumber: 3 }, { sequenceNumber: 4 }];
    return profile;
  }

  get nextEnabled() {
    // Alias functions to remove some clutter
    const hasAny = this.formHelper.isAnyDefined;
    const hasAll = this.formHelper.isAllDefined;

    // Required fields
    const {
      orgId,
      countryOfResidenceId,
      firstName,
      lastName,
      dateOfBirthAsString,
      birthCountry,
      birthCountryId,
      birthProvince,
      birthProvinceId,
      birthStateId,
      birthCity,
      birthCityId,
      countryOfIssueId,
      nationalIdNumber
    } = this.prospect;
    return !!(
      hasAll([orgId, countryOfResidenceId, firstName, lastName, dateOfBirthAsString]) &&
      hasAny([birthCountry, birthCountryId]) &&
      hasAny([birthProvince, birthProvinceId, birthStateId]) &&
      hasAny([birthCity, birthCityId]) &&
      ((countryOfIssueId && nationalIdNumber) || ((!countryOfIssueId || countryOfIssueId === 0) && !nationalIdNumber))
    );
  }

  populateCountryOfIssueCode(countryId) {
    if (this.authStore.loggedIn && !!countryId) {
      this.amtApi.getLookupByTypeAndId("COUNTRIES", countryId).then(response => {
        this.prospect.countryOfIssueCode = response.code;
      });
    }
  }

  // Given list of dropdown items for cities (e.g. this.lookupStore.filteredCities)
  // Get a city name by id
  getCityName(cities, cityId) {
    const city = cities.find(city => {
      return Number(city.value) === Number(cityId);
    });
    return !city ? "" : city.label;
  }

  // There can be many soft matches
  getSoftMatches(matches) {
    if (!matches) {
      return [];
    }
    return matches.filter(match => {
      return !match.hardMatch;
    });
  }

  // There should only be a single hard match
  getHardMatch(matches) {
    if (!matches) {
      return [];
    }
    return matches.filter(match => {
      return match.hardMatch || match.onCurrentProspectList;
    });
  }

  // Check if any of the entries have hardMatch = true
  hasHardMatch(matches) {
    if (!matches) {
      return false;
    }
    return matches.some(match => match.hardMatch);
  }

  isEbisMatch(matches) {
    // If any matches have an amtProspectId, then these are not ebis matches
    return !matches.some(match => !!match.amtProspectId);
  }

  addToProspectList(row) {
    if (row.amtProspectId) {
      // AMT prospect
      this.amtApi.addToProspectList(row.amtProspectId).then(response => {
        // Show success message
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Prospect added to current prospects list"
        });

        this.resetStore();
      });
    } else {
      // EBIS prospect
      this.amtApi.createProspectFromEbis(this.prospect.orgId, row.playerId).then(createResponse => {
        if (createResponse) {
          // Use the response to populate the profile
          const prospect = this.getProspectProfileFromMatch(createResponse, "EBIS");
          this.prospectProfileStore.setProspect(prospect);
          this.goToProfile();
        } else {
          // Error - populate the profile with information from the match
          row.orgId = this.prospect.orgId;
          const prospect = this.getProspectProfileFromMatch(row, "EBIS-FROM-MODAL");
          this.prospectProfileStore.setProspect(prospect);
          this.goToProfile();
        }
      });
    }
  }
}

decorate(NewProspectStore, {
  nextEnabled: computed
});

export default NewProspectStore;
