import { action, extendObservable, autorun, decorate, computed } from "mobx";
import { AlertConstants } from "../../constants/AlertConstants";
import { RouteConstants } from "../../constants/RouteConstants";
import _ from "lodash";
import FormHelper from "../../utilities/FormHelper";
import { ErrorWarningConstants } from "../../constants/ErrorWarningConstants";

class ProspectProfileStore {
  constructor(commonStore) {
    this.authStore = commonStore.authStore;
    this.amtApi = commonStore.amtApi;
    this.lookupStore = commonStore.lookupStore;
    this.alertStore = commonStore.alertStore;
    this.errorWarningStore = commonStore.errorWarningStore;
    this.routerStore = commonStore.routerStore;
    this.formHelper = new FormHelper();

    // Register functions for external use
    this.fetchProspect = this.fetchProspect.bind(this);

    this.defaults = {
      prospectId: 0,
      prospect: { middleNameFlag: false, extendedLastNameFlag: false },
      pristineProspect: { middleNameFlag: false, extendedLastNameFlag: false },
      pristinePerson: {},
      showCancelModal: false,
      showMonthsFromSigningModal: false,
      showProspectCheckInModal: false,
      showProspectCheckOutModal: false,
      showProspectPhotoModal: false,
      showDeleteProspectModal: false,
      editMode: false,
      newProspectMode: false
    };

    extendObservable(this, {
      prospectId: this.defaults["prospectId"],
      prospect: this.defaults["prospect"],
      pristineProspect: this.defaults["pristineProspect"],
      pristinePerson: this.defaults["pristinePerson"],
      showCancelModal: this.defaults["cancelModalOpen"],
      showMonthsFromSigningModal: this.defaults["showMonthsFromSigningModal"],
      showProspectCheckInModal: this.defaults["showProspectCheckInModal"],
      showProspectCheckOutModal: this.defaults["showProspectCheckOutModal"],
      showProspectPhotoModal: this.defaults["showProspectPhotoModal"],
      showDeleteProspectModal: this.defaults["showDeleteProspectModal"],
      editMode: this.defaults["editMode"],
      newProspectMode: this.defaults["newProspectMode"],
      setProspectId: action(prospectId => {
        this.prospectId = prospectId;
      }),
      setProspect: action(prospect => {
        this.prospect = prospect;
      }),
      setShowCancelModal: action(bool => {
        this.showCancelModal = bool;
      }),
      setShowMonthsFromSigningModal: action(bool => {
        this.showMonthsFromSigningModal = bool;
      }),
      setShowProspectCheckInModal: action(bool => {
        this.showProspectCheckInModal = bool;
      }),
      openProspectCheckOutModal: action(() => {
        this.showProspectCheckOutModal = true;
      }),
      closeProspectCheckOutModal: action(() => {
        this.showProspectCheckOutModal = false;
      }),
      openProspectPhotoModal: action(() => {
        this.showProspectPhotoModal = true;
      }),
      closeProspectPhotoModal: action(() => {
        this.showProspectPhotoModal = false;
      }),
      openDeleteProspectModal: action(() => {
        this.showDeleteProspectModal = true;
      }),
      closeDeleteProspectModal: action(() => {
        this.showDeleteProspectModal = false;
      }),
      updateBirthCountryId: action(countryId => {
        this.prospect.birthCountryId = parseInt(countryId, 10);
        this.prospect.birthDistrict = "";
        this.prospect.birthCity = "";
        this.prospect.birthDistrictId = 0;
        this.prospect.birthCityId = 0;
        this.prospect.birthStateId = 0;
        this.lookupStore.setSelectedCountryId(countryId);
      }),
      updateBirthStateId: action(stateId => {
        this.prospect.birthStateId = parseInt(stateId, 10);
        this.prospect.birthCity = "";
        this.prospect.birthCityId = 0;
        this.lookupStore.setSelectedDistrictId(stateId);
      }),
      updateBirthDistrictId: action(districtId => {
        this.prospect.birthDistrictId = parseInt(districtId, 10);
        this.prospect.birthCity = "";
        this.prospect.birthCityId = 0;
        this.lookupStore.setSelectedDistrictId(districtId);
      }),
      close: action(() => {
        if (this.routerStore.previousPathName === "/prospect/0") {
          this.routerStore.history.go(-2);
        } else {
          this.routerStore.history.goBack();
        }
      }),
      save: action(() => {
        if (this.prospect) {
          this.saveProspect(this.prospect);
        }
      }),
      edit: action(() => {
        this.editMode = true;
        // Clone objects at time edit was pressed so we can revert
        this.pristineProspect = _.cloneDeep(this.prospect);
      }),
      cancel: action(() => {
        if (this.newProspectMode) {
          this.routerStore.pushHistory(RouteConstants.NEW_PROSPECT);
        }
        this.editMode = false;
        this.showCancelModal = false;
        this.prospect = this.pristineProspect;
        // Reset the state/city lookups to our original list
        this.updateStateAndCityLookups();
      }),
      showAlert: action(alert => {
        this.alertStore.addAlert(alert);
      }),
      resetStore: action(() => {
        this.prospect = this.defaults["prospect"];
        this.pristineProspect = this.defaults["pristineProspect"];
        this.showCancelModal = this.defaults["showCancelModal"];
        this.showMonthsFromSigningModal = this.defaults["showMonthsFromSigningModal"];
        this.showProspectCheckInModal = this.defaults["showProspectCheckInModal"];
        this.showProspectCheckOutModal = this.defaults["showProspectCheckOutModal"];
        this.editMode = this.defaults["editMode"];
        this.newProspectMode = this.defaults["newProspectMode"];
      })
    });

    autorun(() => {
      if (this.onProfile()) {
        this.parseUrl();
        if (this.prospectId !== "0") {
          this.resetStore();
          this.fetchProspect(this.prospectId);
        } else {
          this.edit();
        }
      }
    });
  }

  parseUrl = () => {
    const pathParams = this.routerStore.getPathParams(RouteConstants.PROSPECT_PROFILE);
    this.setProspectId(this.getProspectIdFromUrl(pathParams));
  };

  fetchProspect(id) {
    return this.amtApi.getProspect(id).then(response => {
      if (!!response) {
        this.prospect = response;
        this.updateStateAndCityLookups();
      }
    });
  }

  // set country/district so there will be values for the lookups
  // Populating the state and city lists so that we can display those fields on profile
  updateStateAndCityLookups() {
    this.lookupStore.setSelectedCountryId(this.prospect.birthCountryId);
    if (this.prospect.birthCountryId === 1) {
      this.lookupStore.setSelectedDistrictId(this.prospect.birthStateId);
    } else {
      this.lookupStore.setSelectedDistrictId(this.prospect.birthDistrictId);
    }
  }

  saveProspect(prospect) {
    const newFromEbis = this.addingFromEbis;
    this.amtApi.saveProspect(prospect).then(response => {
      if (this.errorWarningStore.errorWarningCode === ErrorWarningConstants.CODES.INT_PLAYER_STATUS_CHECK_NEEDED) {
        this.errorWarningStore.setOverrideHandler(() => {
          prospect.overrideFlag = true;
          this.saveProspect(prospect);
        });
      }
      if (response && response.amtProspectId) {
        // If new form, change url.
        // Otherwise, just reload the data and let autorun handle things
        if (!prospect.amtProspectId && !prospect.prospectId) {
          this.routerStore.goToProspect(response.amtProspectId, true);
        } else {
          this.fetchProspect(response.amtProspectId);
          this.editMode = false;
        }

        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: newFromEbis ? "Prospect added to current prospects list" : "Prospect saved"
        });
      }
    });
  }

  deleteProspect() {
    this.amtApi.deleteProspect(this.prospectId).then(response => {
      // Success.  Close modal, show success alert, return to prospects page
      this.closeDeleteProspectModal();
      this.routerStore.pushHistory("/prospects", true);

      this.alertStore.addAlert({
        type: AlertConstants.TYPES.SUCCESS,
        text: "Prospect deleted"
      });
    });
  }

  getProspectIdFromUrl(pathParams) {
    return pathParams["prospectId"] || this.defaults["prospectId"];
  }

  onProfile() {
    return this.routerStore.isProspectProfileTab && this.authStore.loggedIn;
  }

  // Specific helper to get a country value by lookup id
  getCountry(id) {
    return this.lookupStore.getValue(this.lookupStore.lookups.INT_PLAYER_COUNTRIES, id);
  }

  getPosition(id) {
    return this.lookupStore.getValue(this.lookupStore.lookups.AMT_POSITION_LKUP, id);
  }

  get aliasRequired() {
    return !(this.prospect.trainerFirstName || this.prospect.trainerLastName);
  }

  get firstLastRequired() {
    return !this.prospect.trainerAlias;
  }

  get addingFromEbis() {
    return !this.prospect.amtProspectId && this.prospect.personId;
  }

  get saveEnabled() {
    // Alias functions to remove some clutter
    const has = this.formHelper.isDefined;
    const hasAny = this.formHelper.isAnyDefined;
    const hasAll = this.formHelper.isAllDefined;

    // Required fields
    const {
      firstName,
      middleName,
      lastName,
      extendedLastName,
      middleNameFlag,
      extendedLastNameFlag,
      dateOfBirthAsString,
      birthCountry,
      birthCountryId,
      birthStateId,
      birthProvince,
      birthProvinceId,
      birthDistrict,
      birthDistrictId,
      birthCity,
      birthCityId,
      trainerFirstName,
      trainerLastName,
      trainerAlias,
      trainerPhoneNumber,
      positions,
      countryOfIssueId,
      nationalIdNumber,
      phoneNumber,
      personTypeId
    } = this.prospect;

    return !!(
      hasAll([firstName, lastName, dateOfBirthAsString]) &&
      (hasAny([middleName, middleNameFlag]) || personTypeId === 2) &&
      (hasAny([extendedLastName, extendedLastNameFlag]) || personTypeId === 2) &&
      hasAny([birthCountry, birthCountryId]) &&
      hasAny([birthStateId, birthProvince, birthProvinceId, birthDistrict, birthDistrictId]) &&
      hasAny([birthCity, birthCityId]) &&
      (hasAll([trainerFirstName, trainerLastName]) || has(trainerAlias)) &&
      has(trainerPhoneNumber) &&
      has(phoneNumber) &&
      positions[0] &&
      has(positions[0].positionId) &&
      ((countryOfIssueId && nationalIdNumber) || ((!countryOfIssueId || countryOfIssueId === 0) && !nationalIdNumber))
    );
  }

  get fullName() {
    const { firstName, middleName, lastName, extendedLastName } = this.prospect;
    return [firstName, middleName, lastName, extendedLastName].join(" ");
  }
}

decorate(ProspectProfileStore, {
  aliasRequired: computed,
  firstLastRequired: computed,
  saveEnabled: computed,
  fullName: computed
});

export default ProspectProfileStore;
