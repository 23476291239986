import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Accordion, FormColumn4Wide, FormInput } from "best-common-react";
import { inject, observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
const TitleHolder = styled.div`
  color: ${props => props.theme["dark-grey"]};
`;

class ProfileCountryAndId extends React.Component {
  render() {
    const { prospectProfileStore, lookupStore } = this.props.rootStore;
    const { prospect } = prospectProfileStore;
    const countriesForDropdown = lookupStore.getDropdownOptions(lookupStore.lookups.INT_PLAYER_COUNTRIES);

    const birthStateLabel = <FormattedMessage id="Profile.CountryAndId.birthState" defaultMessage="Birth State" />;
    const birthDistrictLabel = (
      <FormattedMessage id="Profile.CountryAndId.birthDistrict" defaultMessage="Birth District" />
    );
    const birthCityLabel = <FormattedMessage id="Profile.CountryAndId.birthCity" defaultMessage="Birth City" />;

    return (
      <div className="mt-2">
        <Accordion startOpen={true}>
          <Accordion.Header isToggle={true}>
            <TitleHolder>
              <FormattedMessage id="Profile.CountryAndId.title" defaultMessage="Country & ID" />
            </TitleHolder>
          </Accordion.Header>
          <Accordion.Body color="white">
            <div className="row">
              <FormColumn4Wide>
                <FormInput
                  name="birthCountry"
                  type="select"
                  label={<FormattedMessage id="ProfilePersonalInfo.countryOfBirth" defaultMessage="Birth Country" />}
                  isRequired={true}
                  value={lookupStore.getDropdownItem(countriesForDropdown, prospect.birthCountryId)}
                  options={countriesForDropdown}
                  onChangeFn={country => prospectProfileStore.updateBirthCountryId(country.value)}
                  editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                {prospect.birthCountryId === 1 ? (
                  <FormInput
                    name="birthState"
                    type="select"
                    label={birthStateLabel}
                    isRequired={true}
                    isDisabled={!prospect.birthCountryId}
                    value={lookupStore.getDropdownItem(lookupStore.filteredDistricts, prospect.birthStateId)}
                    options={lookupStore.filteredDistricts}
                    onChangeFn={district => prospectProfileStore.updateBirthStateId(district.value)}
                    editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                  />
                ) : prospect.birthCountryId && lookupStore.filteredDistricts.length === 0 ? (
                  <FormInput
                    name="birthDistrict"
                    label={birthDistrictLabel}
                    isRequired={true}
                    value={prospect.birthDistrict}
                    onChangeFn={value => (prospect.birthDistrict = value)}
                    editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                  />
                ) : (
                  <FormInput
                    name="birthDistrict"
                    type="select"
                    label={birthDistrictLabel}
                    isRequired={true}
                    isDisabled={!prospect.birthCountryId}
                    value={lookupStore.getDropdownItem(lookupStore.filteredDistricts, prospect.birthDistrictId)}
                    options={lookupStore.filteredDistricts}
                    onChangeFn={district => prospectProfileStore.updateBirthDistrictId(district.value)}
                    editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                  />
                )}
              </FormColumn4Wide>
              <FormColumn4Wide>
                {prospect.birthDistrict ||
                ((prospect.birthDistrictId || prospect.birthStateId) &&
                  (lookupStore.filteredCities.length === 0 ||
                    lookupStore.filteredCities[0].label === "Not in System" ||
                    prospect.birthCountryId === 1)) ? (
                  <FormInput
                    name="birthCity"
                    label={birthCityLabel}
                    isRequired={true}
                    value={prospect.birthCity}
                    onChangeFn={value => (prospect.birthCity = value)}
                    editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                  />
                ) : (
                  <FormInput
                    name="birthCity"
                    type="select"
                    label={birthCityLabel}
                    isRequired={true}
                    isDisabled={!prospect.birthDistrictId}
                    value={lookupStore.getDropdownItem(lookupStore.filteredCities, prospect.birthCityId)}
                    options={lookupStore.filteredCities}
                    onChangeFn={city => (prospect.birthCityId = city.value)}
                    editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                  />
                )}
              </FormColumn4Wide>
              <FormColumn4Wide>
                <FormInput
                  name="countryOfResidence"
                  type="select"
                  label={
                    <FormattedMessage
                      id="Profile.CountryAndId.countryOfResidence"
                      defaultMessage="Country of Residence"
                    />
                  }
                  value={lookupStore.getDropdownItem(countriesForDropdown, prospect.countryOfResidenceId)}
                  options={countriesForDropdown}
                  onChangeFn={selected => (prospect.countryOfResidenceId = selected.value)}
                  editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <FormInput
                  name="countryOfIssue"
                  type="select"
                  isRequired={prospect.nationalIdNumber}
                  label={
                    <FormattedMessage
                      id="Profile.CountryAndId.nationalIdCountry"
                      defaultMessage="National ID Country"
                    />
                  }
                  value={lookupStore.getDropdownItem(countriesForDropdown, prospect.countryOfIssueId)}
                  options={countriesForDropdown}
                  onChangeFn={selected => (prospect.countryOfIssueId = parseInt(selected.value))}
                  editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <FormInput
                  name="nationalId"
                  label={<FormattedMessage id="Profile.CountryAndId.number" defaultMessage="Number (Cedula)" />}
                  value={prospect.nationalIdNumber}
                  isRequired={prospect.countryOfIssueId !== 0 && prospect.countryOfIssueId}
                  onChangeFn={value => (prospect.nationalIdNumber = value)}
                  editable={prospectProfileStore.editMode && !prospectProfileStore.addingFromEbis}
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <FormInput
                  name="phoneNumber"
                  label={<FormattedMessage id="Profile.CountryAndId.phoneNumber" defaultMessage="Phone Number" />}
                  value={prospect.phoneNumber}
                  isRequired={true}
                  onChangeFn={value => (prospect.phoneNumber = value)}
                  editable={prospectProfileStore.editMode}
                />
              </FormColumn4Wide>
            </div>
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}
ProfileCountryAndId.propTypes = {
  rootStore: PropTypes.object.isRequired
};
export default inject("rootStore")(observer(ProfileCountryAndId));
