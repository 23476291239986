import { action, computed, decorate, extendObservable } from "mobx";
import { autorun } from "mobx";
import { ClubsConstants } from "../../constants/ClubsConstants";
import TableHelper from "../../utilities/TableHelper";
import { saveAs } from "file-saver";
import WidgetStore from "../WidgetStore";

/**
 * This store will house all logic related to the
 * CLubs page (/clubs)
 */

class ClubsStore extends WidgetStore {
  constructor(commonStore) {
    super();
    this.authStore = commonStore.authStore;
    this.amtApi = commonStore.amtApi;
    this.routerStore = commonStore.routerStore;
    this.tableHelper = new TableHelper();

    this.defaults = {
      // Data necessary
      clubs: [],
      currentStatusFilter: ClubsConstants.ALL_CLUBS,
      checkIns: [],
      checkInOrgName: "",
      showCheckInsModal: false,

      // Misc
      sortFilters: {
        direction: "ASC",
        keys: ["orgName"]
      }
    };

    extendObservable(this, {
      clubs: this.defaults["clubs"],
      currentStatusFilter: this.defaults["currentStatusFilter"],
      checkIns: this.defaults["checkIns"],
      checkInOrgName: this.defaults["checkInOrgName"],
      showCheckInsModal: this.defaults["showCheckInsModal"],
      sortFilters: this.defaults["sortFilters"],
      setClubs: action(clubs => {
        this.clubs = clubs;
      }),
      setCurrentStatusFilter: action(status => {
        this.currentStatusFilter = status;
      }),
      setCheckIns: action(checkIns => {
        this.checkIns = checkIns;
      }),
      setCheckInOrgName: action(name => {
        this.checkInOrgName = name;
      }),
      setShowCheckInsModal: action(bool => {
        this.showCheckInsModal = bool;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.keys = [col];
        this.sortFilters.direction = direction;
      }),
      /**
       * We should reset the store when we are no
       * longer on the page. However, because the
       * prospectProfile store is dependent on the
       * data here, we won't reset it until we have
       * the backend running properly
       */
      resetStore: action(() => {
        this.clubs = this.defaults["clubs"];
        this.currentStatusFilter = this.defaults["currentStatusFilter"];
        this.checkIns = this.defaults["checkIns"];
        this.checkInOrgName = this.defaults["checkInOrgName"];
        this.showCheckInsModal = this.defaults["showCheckInsModal"];
        this.sortFilters = this.defaults["sortFilters"];
      }),
      loadCheckInsModal: action((orgId, openModal) => {
        this.loadCheckIns(orgId, openModal);
      }),
      exportClubTable: action(() => {
        let params = {};

        if (this.currentStatusFilter !== "" && this.currentStatusFilter !== ClubsConstants.ALL_CLUBS) {
          params.status = this.currentStatusFilter;
        }

        if (this.sortFilters.direction !== "NONE") {
          params.sortDirection = this.sortFilters.direction;
        }

        params.sortKeys = this.sortFilters.keys.toString();

        this.amtApi.exportClubTable(params).then(file => {
          saveAs(file, "clubs.xls");
        });
      })
    });

    autorun(() => {
      if (this.routerStore.isClubsTab && this.authStore.loggedIn) {
        this.updateClubs();
        this.setShowCheckInsModal(false);
      }
    });
  }

  /**
   * Gets called from within autorun (upon component render)
   * and makes call to backend (using amtApi) to get clubs
   */
  updateClubs() {
    // The population of clubs for the table
    this.amtApi.getClubs().then(response => {
      if (!!response) {
        this.setClubs(response);
      }
    });
  }

  loadCheckIns(orgId, openModal) {
    if (orgId) {
      this.amtApi.getClubCheckIns(orgId).then(response => {
        if (!!response) {
          this.setCheckIns(response);
          this.setShowCheckInsModal(openModal);
        }
      });
    }
  }

  get displayedClubs() {
    if (!this.clubs) {
      return [];
    }

    let toDisplay = this.filterByStatus(this.clubs, this.currentStatusFilter);
    return this.sort(toDisplay, this.sortFilters);
  }

  get checkedInCount() {
    return this.filterByStatus(this.clubs, ClubsConstants.CHECKED_IN).reduce(
      (sum, club) => sum + club.checkedInProspects,
      0
    );
  }

  get approachingCount() {
    return this.filterByStatus(this.clubs, ClubsConstants.APPROACHING).reduce(
      (sum, club) => sum + club.approachingProspects,
      0
    );
  }

  get overdueCount() {
    return this.filterByStatus(this.clubs, ClubsConstants.OVERDUE).reduce(
      (sum, club) => sum + club.overdueProspects,
      0
    );
  }

  /**
   * Sort the data by the selected column and direction.
   **/
  sort(clubs, searchFilters) {
    let { direction, keys } = searchFilters;
    return this.tableHelper.sort(clubs, keys, direction);
  }

  filterByStatus(clubs, status) {
    if (!clubs) {
      return [];
    }
    switch (status) {
      case ClubsConstants.CHECKED_IN:
        return clubs.filter(club => {
          return club.checkedInProspects > 0;
        });
      case ClubsConstants.APPROACHING:
        return clubs.filter(club => {
          return club.approachingProspects > 0;
        });
      case ClubsConstants.OVERDUE:
        return clubs.filter(club => {
          return club.overdueProspects > 0;
        });
      default:
        return clubs;
    }
  }
}

decorate(ClubsStore, {
  displayedClubs: computed,
  checkedInCount: computed,
  approachingCount: computed,
  overdueCount: computed
});

export default ClubsStore;
