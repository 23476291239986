import { Accordion, FormInput, FormColumn4Wide } from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

const TitleHolder = styled.div`
  color: ${props => props.theme["dark-grey"]};
`;

class ProfileTrainerDetails extends React.Component {
  render() {
    const { prospectProfileStore } = this.props.rootStore;
    const prospect = prospectProfileStore.prospect;

    return (
      <div className="mt-2">
        <Accordion startOpen={true}>
          <Accordion.Header isToggle={true}>
            <TitleHolder>
              <FormattedMessage id="Profile.TrainerDetails.title" defaultMessage="Trainer/Agent" />
            </TitleHolder>
          </Accordion.Header>
          <Accordion.Body color="white">
            <div className="row">
              <FormColumn4Wide>
                <FormInput
                  name="trainerFirstName"
                  label={<FormattedMessage id="Profile.TrainerDetails.firstName" defaultMessage="First Name" />}
                  isRequired={prospectProfileStore.firstLastRequired}
                  value={prospect.trainerFirstName}
                  onChangeFn={value => (prospect.trainerFirstName = value)}
                  editable={prospectProfileStore.editMode}
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <FormInput
                  name="trainerLastName"
                  label={<FormattedMessage id="Profile.TrainerDetails.lastName" defaultMessage="Last Name" />}
                  isRequired={prospectProfileStore.firstLastRequired}
                  value={prospect.trainerLastName}
                  onChangeFn={value => (prospect.trainerLastName = value)}
                  editable={prospectProfileStore.editMode}
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <FormInput
                  name="trainerAlias"
                  label={<FormattedMessage id="Profile.TrainerDetails.alias" defaultMessage="Alias" />}
                  isRequired={prospectProfileStore.aliasRequired}
                  value={prospect.trainerAlias}
                  onChangeFn={value => (prospect.trainerAlias = value)}
                  editable={prospectProfileStore.editMode}
                />
              </FormColumn4Wide>
              <FormColumn4Wide>
                <FormInput
                  name="trainerPhoneNumber"
                  label={
                    <FormattedMessage id="Profile.TrainerDetails.trainerPhoneNumber" defaultMessage="Phone Number" />
                  }
                  isRequired={true}
                  value={prospect.trainerPhoneNumber}
                  onChangeFn={value => (prospect.trainerPhoneNumber = value)}
                  editable={prospectProfileStore.editMode}
                />
              </FormColumn4Wide>
            </div>
          </Accordion.Body>
        </Accordion>
      </div>
    );
  }
}

ProfileTrainerDetails.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ProfileTrainerDetails));
