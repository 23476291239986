import React from "react";
import MobileLanguageSelector from "./MobileLanguageSelector";
import DesktopLanguageSelector from "./DesktopLanguageSelector";
import { DesktopTabletView, MobileView } from "best-common-react";

class LanguageSelector extends React.Component {
  render() {
    return (
      <span>
        <MobileView>
          <MobileLanguageSelector />
        </MobileView>
        <DesktopTabletView>
          <DesktopLanguageSelector />
        </DesktopTabletView>
      </span>
    );
  }
}

export default LanguageSelector;
