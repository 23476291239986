import { action, extendObservable } from "mobx";
import translations from "../../i18n/locales";
import Cookies from "js-cookie";

class TranslationsStore {
  constructor() {
    this.english = "English";
    this.spanish = "Español";
    this.defaults = {
      language: "en"
    };

    extendObservable(this, {
      language: Cookies.get("language") || this.defaults["language"],
      setLanguage: action(value => {
        Cookies.set("language", value);
        this.language = value;
      })
    });
  }

  getCurrentLanguage() {
    return this.language;
  }

  getTranslations() {
    return translations[this.language];
  }
}

export default TranslationsStore;
