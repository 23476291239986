import { action, autorun, computed, decorate, extendObservable } from "mobx";
import _ from "lodash";
import TableHelper from "../../utilities/TableHelper";
import moment from "moment";

class CheckInHistoryStore {
  constructor(commonStore, prospectProfileStore) {
    this.amtApi = commonStore.amtApi;
    this.lookupStore = commonStore.lookupStore;
    this.authStore = commonStore.authStore;
    this.prospectProfileStore = prospectProfileStore;
    this.tableHelper = new TableHelper();
    this.alertStore = commonStore.alertStore;

    // Re-bind "this" to fix scope when used from other components
    this.getTrainingFacilityLocation = this.getTrainingFacilityLocation.bind(this);

    this.defaults = {
      prospectId: "",
      history: [],
      pristineHistory: [],
      latestPhoto: null,
      selectedAmtProspectCheckInPhoto: null,
      showDeleteCheckInModal: false,
      checkInRowToDelete: null,
      editMode: false,
      sortFilters: {
        direction: "DESC",
        key: "checkedInDate"
      }
    };

    extendObservable(this, {
      prospectId: this.defaults["prospectId"],
      history: this.defaults["history"],
      pristineHistory: this.defaults["pristineHistory"],
      editMode: this.defaults["editMode"],
      sortFilters: this.defaults["sortFilters"],
      latestPhoto: this.defaults["latestPhoto"],
      selectedAmtProspectCheckInPhoto: this.defaults["selectedAmtProspectCheckInPhoto"],
      showDeleteCheckInModal: this.defaults["showDeleteCheckInModal"],
      checkInRowToDelete: this.defaults["showDeleteCheckInModal"],
      setProspectId: action(prospectId => {
        this.prospectId = prospectId;
      }),
      setHistory: action(history => {
        this.history = history;
      }),
      setSortDirection: action((col, direction) => {
        this.sortFilters.key = col;
        this.sortFilters.direction = direction;
      }),
      setLatestPhoto: action(checkinId => {
        this.amtApi.getCheckInPhoto(checkinId).then(photo => {
          this.latestPhoto = photo;
        });
      }),
      setSelectedAmtProspectCheckInPhoto: action(amtProspectCheckInId => {
        this.amtApi.getCheckInPhoto(amtProspectCheckInId).then(photo => {
          this.selectedAmtProspectCheckInPhoto = photo;
        });
      }),
      setDeleteCheckInRow: action(row => {
        this.checkInRowToDelete = row;
      }),
      setOpenDeleteCheckInModal: action(() => {
        this.showDeleteCheckInModal = !this.showDeleteCheckInModal;
      }),
      showAlert: action(alert => {
        this.alertStore.addAlert(alert);
      }),
      setHistoryRowProp: action((checkInId, prop, val) => {
        let history = _.cloneDeep(this.history);
        const checkIn = history.find(c => Number(c.amtProspectCheckInId) === Number(checkInId));

        if (!checkIn) {
          return;
        }

        if ("photo" === prop) {
          // For photos, we need to parse the uploaded file
          // As a base64 data url, so it can be displayed properly and saved
          const target = val.target;
          const files = target.files;

          if (files.length > 0) {
            const file = files[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              checkIn[prop] = reader.result;
              // Set newPhoto flag, which will tell the backend that we should save this photo
              checkIn.newPhoto = true;
              this.setHistory(history);
              // If club user is uploading this photo, then we need to save now
              // BOC has an edit mode and can hit Save, but club doesn't
              if (this.authStore.isClub) {
                this.saveWithProspect();
              }
            };
          }
        } else {
          // For non-photo props, just set the value and update history
          checkIn[prop] = val;
          this.setHistory(history);
        }
      }),
      handleCheckInHistoryDateChange: action((checkInId, prop, date) => {
        // Don't allow modification of the date to something falsy, e.g. null or "" by deleting the date
        // Also don't allow partial dates, e.g. 03/05/2, to propogate to the onChange handler.
        if (!date || !moment(date).isValid()) {
          return;
        }
        const formattedDate = moment(date).format("YYYY-MM-DD");
        this.setHistoryRowProp(checkInId, prop, formattedDate);
      }),
      setEditMode: action(bool => {
        this.editMode = bool;
      }),
      edit: action(() => {
        this.setEditMode(true);
        // Clone objects at time edit was pressed so we can revert
        this.pristineHistory = _.cloneDeep(this.history);
      }),
      cancel: action(() => {
        this.setEditMode(false);
        this.history = this.pristineHistory;
      }),
      saveWithProspect: action(() => {
        if (this.prospectId) {
          this.saveCheckInsAndProspect();
        }
      }),
      resetStore: action(() => {
        this.prospectId = this.defaults["prospectId"];
        this.history = this.defaults["history"];
        this.pristineHistory = this.defaults["pristineHistory"];
        this.latestPhoto = this.defaults["latestPhoto"];
        this.editMode = this.defaults["editMode"];
        this.sortFilters = this.defaults["sortFilters"];
      }),
      resetHistoryAndPhoto: action(() => {
        this.history = this.defaults["history"];
        this.pristineHistory = this.defaults["pristineHistory"];
        this.latestPhoto = this.defaults["latestPhoto"];
      })
    });
    autorun(() => {
      if (
        prospectProfileStore.onProfile() &&
        this.prospectProfileStore.prospectId &&
        this.prospectProfileStore.prospectId !== 0 &&
        this.lookupStore.loaded
      ) {
        this.prospectId = this.prospectProfileStore.prospectId;
        this.editMode = false;
        this.fetchCheckinHistory();
      }
    });
  }
  fetchCheckinHistory() {
    // Before getting new history, reset the history and most recent photo
    this.resetHistoryAndPhoto();
    this.amtApi.getCheckins(this.prospectId).then(response => {
      this.history = response;
      if (response.length > 0) {
        this.setLatestPhoto(response[response.length - 1].amtProspectCheckInId);
      }
    });
  }

  sort(checkIns, searchFilters) {
    let { direction, key } = searchFilters;
    return this.tableHelper.sort(checkIns, key, direction);
  }

  get displayedHistory() {
    let data = this.history;
    data = data.map((d, index) => {
      d.index = index;
      return d;
    });
    return this.sort(data, this.sortFilters);
  }

  getTrainingFacilityLocation(id) {
    if (this.lookupStore.lookups.AMT_TRAINING_FACILITIES) {
      let locationId = id;
      if (!!id && !!id.value) {
        locationId = id.value;
      }
      return this.lookupStore.getValue(this.lookupStore.lookups.AMT_TRAINING_FACILITIES, locationId) || "";
    }
  }

  getCountry(id) {
    if (this.lookupStore.lookups.INT_PLAYER_COUNTRIES) {
      let countryId = id;
      if (!!id && !!id.value) {
        countryId = id.value;
      }
      return this.lookupStore.getValue(this.lookupStore.lookups.INT_PLAYER_COUNTRIES, countryId) || "";
    }
  }

  saveCheckInsAndProspect() {
    this.amtApi.saveCheckins(this.prospectId, this.history).then(response => {
      if (response && Array.isArray(response)) {
        this.editMode = false;
        // Reload history
        this.fetchCheckinHistory();
        // Save prospect after check-ins have been updated
        this.prospectProfileStore.save();
      }
    });
  }

  isCountryOtherOffsite(row) {
    return row.trainingFacilityId === 6;
  }

  isCountryDROffsite(row) {
    return row.trainingFacilityId === 4;
  }

  isCountryVZOffsite(row) {
    return row.trainingFacilityId === 5;
  }

  showComments(row) {
    return this.isCountryOtherOffsite(row) || this.isCountryDROffsite(row) || this.isCountryVZOffsite(row);
  }

  showPhotoUpload(row) {
    // BOC is always allowed to upload new check in photos in edit mode
    // Club users will only have 24-hours to upload this photo
    if (this.authStore.isBOC && this.editMode) {
      return true;
    } else if (this.authStore.isClub && !row.photo) {
      const checkInTs = moment(`${row.checkedInDate} ${row.checkedInTime}`, "YYYY-MM-DD HH.mm.ss.SSS");
      if (
        moment()
          .subtract(24, "hours")
          .isBefore(checkInTs)
      ) {
        return true;
      }
    }
    return false;
  }
}

decorate(CheckInHistoryStore, {
  displayedHistory: computed
});

export default CheckInHistoryStore;
