import { Auth } from "@okta/okta-react";

/**
 * All Authentication items should go here
 * In particular, we should put the OKTA related
 * things here.
 */

class AuthApi {
  constructor(routerStore) {
    this.oktaAuth = new Auth({
      issuer: process.env.REACT_APP_OIDC_ISSUER,
      client_id: process.env.REACT_APP_OIDC_CLIENTID,
      redirect_uri: window.location.origin + "/implicit/callback",
      history: routerStore.history,
      onAuthRequired: () => routerStore.history.push("/login")
    });
  }

  getToken = () => {
    return [this.oktaAuth.getAccessToken(), this.oktaAuth.getIdToken()];
  };

  getUserInfo = () => {
    return this.oktaAuth.getUser();
  };

  getAccessToken = () => {
    return this.oktaAuth.getAccessToken();
  };

  getTokenManager = () => {
    return this.oktaAuth._oktaAuth.tokenManager;
  };
}

export default AuthApi;
