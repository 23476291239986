import { extendObservable, action, autorun } from "mobx";

class SystemEventStore {
  constructor(authStore, amtApi) {
    this.authStore = authStore;
    this.amtApi = amtApi;

    this.defaults = {
      events: {},
      loaded: false
    };

    extendObservable(this, {
      events: this.defaults["events"],
      loaded: this.defaults["loaded"],
      setEvents: action(events => {
        this.events = events;
      })
    });

    autorun(() => {
      if (this.authStore.loggedIn) {
        this.fetchEvents();
      }
    });
  }

  fetchEvents() {
    this.amtApi.getSystemEvents().then(response => {
      if (!!response) {
        this.events = response;
      }
      this.loaded = true;
    });
  }
}

export default SystemEventStore;
