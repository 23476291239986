/* eslint-disable prettier/prettier */
import React from "react";
import {
  LightButton,
  Modal,
  ModalBody,
  ModalHeaderWithTitleAndClose,
  PrimaryButton
} from "best-common-react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { AlertConstants } from './../../../constants/AlertConstants';


const ModalFooterDiv = styled.div.attrs({
  id: "modal-footer"
})`
  justify-content: space-between;
  background-color: #ececec;
  padding: 16px;
`;

ModalFooterDiv.displayName = "Footer";

const FooterButtons = styled.div`
  button:not(:last-child) {
    margin-right: 0.625rem;
  }
`;



class DeleteCheckInModalConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { checkInButtonDisabled: false };
  }

  render() {
    const { commonStore, checkInHistoryStore, checkInModalStore, prospectProfileStore} = this.props.rootStore;
    
    const { show } = this.props;

    function deleteCheckIn(row){
      commonStore.amtApi.deleteCheckIn(row).then(response => {
        if (response && response.amtProspectCheckInId) {
          // The checkin and alert store should get reset on success.
          checkInModalStore.resetStore();
          checkInHistoryStore.resetStore();
          commonStore.alertStore.resetStore();
          commonStore.errorWarningStore.resetStore();
  
          // Refresh the profile
          commonStore.routerStore.refresh();
          prospectProfileStore.showAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Deleted Successfully"
          });
          } else {
            // The store should get reset on a hard error.
            checkInHistoryStore.resetStore();
            prospectProfileStore.showAlert({
              type: AlertConstants.TYPES.DANGER,
              text: "Failed to Delete Check-In"
            });
          }
          checkInHistoryStore.setOpenDeleteCheckInModal();
      });;  
    }
    return (
      <div>
        <Modal show={checkInHistoryStore.showDeleteCheckInModal} onClose={() => {}} size="lg">
          <ModalHeaderWithTitleAndClose
            title="Warning"
            close={() => {
              checkInHistoryStore.setOpenDeleteCheckInModal();
            }}
          ></ModalHeaderWithTitleAndClose>
          <ModalBody>
            <FormattedMessage
              id="DeleteCheckInModal.body"
              defaultMessage="Are you sure you want to delete this check in?  This is a permanent action and cannot be undone"
            />
          </ModalBody>
          <ModalFooterDiv>
          <FooterButtons>
              <PrimaryButton onClick={()=>{deleteCheckIn(checkInHistoryStore.checkInRowToDelete);}}>Continue</PrimaryButton>
              <LightButton onClick={()=>{checkInHistoryStore.setOpenDeleteCheckInModal();}}>Cancel</LightButton>
            </FooterButtons>
          </ModalFooterDiv>
        </Modal>
      </div>
    );
  }
}

DeleteCheckInModalConfirmation.defaultProps = {
  show: false
};

DeleteCheckInModalConfirmation.propTypes = {
  rootStore: PropTypes.object.isRequired,
  show: PropTypes.bool
};

export default inject("rootStore")(observer(DeleteCheckInModalConfirmation));
