import styled from "styled-components";
import React from "react";
import { inject, observer } from "mobx-react";
import TravelFormStatusDropdown from "./filters/TravelFormStatusDropdown";
import TravelFormsSearch from "./filters/TravelFormsSearch";
import TravelFormOrgDropdown from "./filters/TravelFormOrgDropdown";
import PropTypes from "prop-types";
const HeaderWrapper = styled.div.attrs({
  className: "row"
})`
  margin-bottom: 20px;
`;

class TravelFormsHeader extends React.Component {
  render() {
    let { authStore } = this.props.rootStore;

    return (
      <HeaderWrapper>
        <div className="col-12">
          <div className="row">
            <div className="col-sm-12 col-md-4">
              <TravelFormStatusDropdown />
            </div>
            {authStore.isBOC ? (
              <div className="col-sm-12 col-md-4">
                <TravelFormOrgDropdown />
              </div>
            ) : null}
            <div className="col-sm-12 col-md-4">
              <TravelFormsSearch />
            </div>
          </div>
        </div>
      </HeaderWrapper>
    );
  }
}

TravelFormsHeader.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TravelFormsHeader));
